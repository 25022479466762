import { useContext } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { NOTES_1BAR_WIDTH, PATTERN_BODY_HEIGHT, PATTERN_FOOTER_HEIGHT, PATTERN_HEADER_HEIGHT } from "../../../../utils/systemConst";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";

const PatternPedal = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const update = () => dispatcher.backing.setState(state);
    const chordInfo = state.chordInfo;

    const itemJsxList: JSX.Element[] = [];
    const pattern = state.pattern as BackingState.Pattern;

    // 左手のレイヤー時のみ固定表示
    const layer = pattern.layers[0];

    const beatProps = TheoryUtil.getBeatProps(chordInfo.init.beatSignature);
    const adjustRate = beatProps.beatMemoriCount === 4 ? 1 : (3 / 2);


    let cur: BackingState.PedalAction = 'off';
    pattern.pedalActs.forEach((pedal, i) => {
        const isActive = pattern.layerIndex === 0 && pattern.lenIndex === i;
        const note = layer.noteDivList[i];
        let rate = note.rate * adjustRate;
        if (note.isDot) rate /= 1.5;
        if (note.div3 !== 0) rate /= note.div3 / 3;

        let isUse = false;
        const prev = cur;
        if (prev === 'change') {
            cur = 'on';
        }
        if (pedal != null) {
            isUse = true;
            cur = pedal;
        }
        itemJsxList.push(
            <_Column key={i} rate={rate} isActive={isActive} isUse={isUse} onClick={() => {
                if (prev === 'off') {
                    switch (pedal) {
                        case null: pattern.pedalActs[i] = 'on'; break;
                        case 'on': pattern.pedalActs[i] = null; break;
                    }
                } else if (prev === 'on') {
                    switch (pedal) {
                        case null: pattern.pedalActs[i] = 'off'; break;
                        case 'off': pattern.pedalActs[i] = 'change'; break;
                        case 'change': pattern.pedalActs[i] = null; break;
                    }
                } else if (prev === 'change') {
                    switch (pedal) {
                        case null: pattern.pedalActs[i] = 'off'; break;
                        case 'off': pattern.pedalActs[i] = null; break;
                    }
                }
                // 終端までnullで更新
                if (i < pattern.pedalActs.length - 1) {
                    for (let j = i + 1; j < pattern.pedalActs.length; j++) {
                        pattern.pedalActs[j] = null;
                    }
                }
                update();
            }}><div><_Item action={cur} /></div></_Column>
        );
    });

    return (<_Wrap>
        {itemJsxList}
    </_Wrap>);
}

export default PatternPedal;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
    /* background-color: #4e4e4e; */
`;


const _Column = styled.div<{
    rate: number;
    isActive: boolean;
    isUse: boolean;
}>`
    display: inline-block;
    position: relative;
    width: ${props => NOTES_1BAR_WIDTH / props.rate - 1}px;
    height: calc(100% - 4px);
    margin: 2px 0 0 1px;
    background-color: #00635eac;
    ${props => !props.isActive ? '' : css`
        background-color: #179483ac;
    `}
    box-sizing: border-box;
    text-align: center;
    white-space: normal;

    &>div {
        display: inline-block;
        width: 10px;
        height: calc(100% - 2px);
        background-color: #ffffffa0;
        border-radius: 6px;
        margin: 1px 0;
        ${props => props.isUse ? '' : css`
            background-color: #ffffff2f;
        `}
    }
`;

const _Item = styled.div<{
    action: BackingState.PedalAction;
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 14px;
    border: 1px solid #9c9c9c;
    ${props => {
        switch (props.action) {
            case 'off': return css`
                margin-top: 0;
                background-color: #271db8;
            `;
            case 'change': return css`
                margin-top: 15px;
                background-color: #f3d631;
            `;
            case 'on': return css`
                margin-top: 30px;
                background-color: #e03030;
            `;
        }
    }}
    box-sizing: border-box;
    border-radius: 6px;
`;
