import { useContext } from "react";
import styled from "styled-components";
import { TabbedPane } from "../../base/components/tab/tabbedPane";
import { SCORE_TAB_MARGIN } from "../../utils/systemConst";
import { GlobalContext } from "../entry/systemEntry";

const SettingChannel = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    return (
        <_Wrap>
            {/* <DraggableDialog /> */}
            {/* <ExecutableButton labelText={'test'} size={'midium'} /> */}
            <TabbedPane
                tabElements={[
                    { name: 'Sound', disable: true, cont: <div></div> },
                    { name: 'Design', disable: true, cont: <div></div> }
                ]}
                activeNo={store.systemState.scoreTabIndex}
                selectTabIndex={(index: number) => dispatcher.score.setScoreTabIndex(index)}
            />
        </_Wrap>
    );
}

export default SettingChannel;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    margin: ${SCORE_TAB_MARGIN}px 0 0 ${SCORE_TAB_MARGIN}px;
    width: calc(100% - ${SCORE_TAB_MARGIN * 2}px);
    height: calc(100% - ${SCORE_TAB_MARGIN * 2}px);
    background-color: transparent;
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;