import { Action, ActionType } from "../reducer";
import { RefsCache } from "../store/refsCache";
import { BackingLayerData, BackingSignalProps, ChordBacking, ChordElement, DetailAlter, DetailChord, DetailInit, DetailSection, Scale, ScoreData, MelodyNotes, VoicingProps, BackingPatternProps } from "../store/score/scoreData";
import { NoteKeySwitch, ScoreState, SymbolDiagram } from "../store/score/scoreState";
import { Store } from "../store/store";
import { MelodyKeySwitch, MelodyPitchMode, MelodyState } from "../store/score/melodyState";
import Soundfont from 'soundfont-player';
import { SystemState } from "../store/systemState";
import { getDefaultBacking } from "../../utils/instrumentsUtil";
import MelodyUtil, { getAdditionNotsLen, getKeyIndexFromPitcher, getMinimumLenUnit, getNormalizeLenFromNotes, getNotesLenFromQuantize, PITCH_MAX, playSoundfont } from "../../utils/melodyUtil";
import { TUNE_PITCH_HEIGHT } from "../../utils/systemConst";
import { Invalidate } from "../store/invalidate";
import BackingState from "../store/score/backing/backingState";
import BackingUtil from "../../utils/backingUtil";
import CacheUtil from "../../utils/score/cacheUtil";
import DataUtil from "../../utils/dataUtil";
import TheoryUtil from "../../utils/theoryUtil";
import ScoreCache from "../store/score/scoreCache";
import { UserEnv } from "../store/userEnv";
import { Instruments } from "../store/instrument";
import AccountFrame from "../../contents/dialog/accountFrame";

export class Dispatcher {

    private store: Store;
    private dispatch: Function;

    constructor(store: Store, dispatch: React.Dispatch<Action>) {
        this.store = store;
        this.dispatch = (type: ActionType, payload: object) => dispatch({ type, payload });
    }

    public system = {
        setUserEnv: (userEnv: UserEnv) => {
            this.dispatch(ActionType.SET_USER_ENV, { userEnv });
        },

        setState: (systemState: SystemState) => {
            this.dispatch(ActionType.SET_SYSTEM_STATE, { systemState });
        },

        setDialog: (jsx: JSX.Element) => {
            this.store.systemState.dialog = jsx;
            this.system.setState(this.store.systemState);
        },

        closeDialog: () => {
            this.store.systemState.dialog = null;
            this.system.setState(this.store.systemState);
        },

        switchSplashToMain: () => {
            const systemState = this.store.systemState;
            systemState.isSplash = false;
            this.dispatch(ActionType.SET_SYSTEM_STATE, { systemState });

            if(this.store.userEnv.login != null) {
                this.system.setDialog(<AccountFrame />);
            }
        },

        setMainTabIndex: (index: number) => {
            const systemState = this.store.systemState;
            systemState.mainChannelIndex = index;
            this.dispatch(ActionType.SET_SYSTEM_STATE, { systemState });
        },

        setRefs: (refs: RefsCache) => {
            this.dispatch(ActionType.SET_REFS, { refs });
        },

        setReserveTasks: (reserveTasks: NodeJS.Timeout[]) => {
            this.dispatch(ActionType.SET_RESERVE_TASKS, { reserveTasks });
        },

        setInvalidate: (invalidate: Invalidate) => {
            this.dispatch(ActionType.SET_INVALIDATE, { invalidate });
        }
    }

    public score = {
        saveFile: () => {
            // プレビュー時は保存できない
            const isDirect = this.store.systemState.directScore != undefined;
            if (isDirect) return;

            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const options = {
                types: [
                    {
                        accept: {
                            'text/plain': ['.lcrs'],
                        },
                    },
                ],
            };
            const fileHandle = state.fileHandle;
            if (fileHandle != null) {
                (async () => {
                    //ファイルへ書き込むための FileSystemWritableFileStream を作成
                    const writable = await fileHandle.createWritable();
                    //テキストデータの書き込み
                    const text = DataUtil.gZip(JSON.stringify(data));
                    await writable.write(text);
                    //ファイルを閉じる
                    await writable.close();
                    state.fileHandle = fileHandle;
                    this.score.setScoreState({ ...state });
                    const file = await fileHandle.getFile();
                    // alert('saved successfully!');
                    console.log('saved successfully!');
                })();
            } else {
                window.showSaveFilePicker(options).then((handle) => {
                    (async () => {
                        //ファイルへ書き込むための FileSystemWritableFileStream を作成
                        const writable = await handle.createWritable();
                        //テキストデータの書き込み
                        const text = DataUtil.gZip(JSON.stringify(data));
                        await writable.write(text);
                        //ファイルを閉じる
                        await writable.close();
                        state.fileHandle = handle;
                        this.score.setScoreState({ ...state });
                    })();
                }).catch(() => {
                    console.log('キャンセルされました');
                });
            }
        },

        setHarmonyFont: (soundfontPlayer: Soundfont.Player) => {
            this.dispatch(ActionType.SET_INSTRUMENTS, {
                instruments: {
                    ...this.store.instruments, harmony: soundfontPlayer
                }
            });
        },

        setInstruments: (instruments: Instruments) => {
            this.dispatch(ActionType.SET_INSTRUMENTS, {
                instruments
            });
        },

        setScoreTabIndex: (index: number) => {
            const systemState = this.store.systemState;
            systemState.scoreTabIndex = index;
            this.dispatch(ActionType.SET_SYSTEM_STATE, { systemState });
        },

        setScoreState: (scoreState: ScoreState) => {
            this.dispatch(ActionType.SET_SCORE_STATE, { scoreState });
        },

        clearCache: () => {
            const scoreState = this.store.scoreState;
            scoreState.focusIndex = -1;
            scoreState.distIndex = -1;
            scoreState.initialBeat = 4;
            scoreState.elementCacheList = [];
            scoreState.beatCacheList = [];
            scoreState.chordCacheList = [];
            scoreState.scaleCacheList = [];
            scoreState.tempoCacheList = [];
            scoreState.measureCacheList = [];
            scoreState.sectionCacheList = [];
            this.dispatch(ActionType.SET_SCORE_STATE, { scoreState });
        },

        setKeySwitch: (keySwitch: NoteKeySwitch) => {
            const state = this.store.scoreState;
            state.keySwitch = keySwitch;
            this.score.setScoreState(state);
        },

        setScoreData: (scoreData: ScoreData) => {
            this.dispatch(ActionType.SET_SCORE_DATA, { scoreData });
        },

        moveElement: (isNext: boolean) => {
            const state = this.store.scoreState;
            const list = this.store.scoreData.chordList;
            const sub = isNext ? 1 : -1;
            if (list[state.focusIndex + sub].type !== 'fixed') {
                state.focusIndex += sub;
                state.distIndex = state.focusIndex;
                this.score.setScoreState(state);
            }
        },

        /**
         * 選択範囲の終点を移動する
         * @param isNext 
         */
        moveDist: (isNext: boolean) => {
            const state = this.store.scoreState;
            const list = this.store.scoreData.chordList;
            const sub = isNext ? 1 : -1;
            if (list[state.distIndex + sub].type !== 'fixed') {
                state.distIndex += sub;
                this.score.setScoreState(state);
            }
        },

        releaseRange: (isTail: boolean) => {
            const state = this.store.scoreState;
            const distHead = state.focusIndex < state.distIndex ? state.focusIndex : state.distIndex;
            const distTail = state.focusIndex > state.distIndex ? state.focusIndex : state.distIndex;
            state.focusIndex = !isTail ? distHead : distTail;
            state.distIndex = state.focusIndex;
            this.score.setScoreState(state);
        },

        moveSection: (isNext: boolean) => {
            const state = this.store.scoreState;
            const curSectionIndex = state.elementCacheList[state.focusIndex].sectionNo;
            const sectionCacheList = state.sectionCacheList;
            const curSectionInfo = sectionCacheList[curSectionIndex];
            const curElementType = this.store.scoreData.chordList[state.focusIndex].type;
            if (!isNext) {
                // alert('prev');
                if (curElementType === 'section' && curSectionIndex > 0) {
                    // 1つ前のセクションの先頭に移動
                    state.focusIndex = sectionCacheList[curSectionIndex - 1].rangeIndexStart;
                } else {
                    // 今のセクションの先頭に移動
                    state.focusIndex = curSectionInfo.rangeIndexStart;
                }
            } else {
                // alert('next');
                if (curSectionIndex < sectionCacheList.length - 1) {
                    // 次のセクションの先頭に移動
                    state.focusIndex = sectionCacheList[curSectionIndex + 1].rangeIndexStart;
                } else {
                    // 今のセクションの終端に移動
                    state.focusIndex = curSectionInfo.rangeIndexEnd;
                }
            }
            state.distIndex = state.focusIndex;
            this.score.setScoreState(state);
        },

        flashElement: (index: number) => {
            // const state = this.store.scoreState;
            // const cache = state.elementCacheList[index];

            // const bodyRef = cache.reffers.body as HTMLDivElement;

            // bodyRef.style.backgroundColor = '#ffef5f';
            // setTimeout(() => {
            //     bodyRef.style.backgroundColor = '';
            // }, 10);
        },

        incrementDegreeRoot: (isUp: boolean) => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            const sub = isUp ? 1 : -1;
            if (detail.root == null) {
                if (isUp) {
                    detail.root = { index: 0, isFlat: false };
                    this.score.setScoreData(data);
                    this.score.flashElement(state.focusIndex);
                }
            } else if (detail.root.index + sub >= 0 && detail.root.index + sub <= 11) {
                detail.root.index += sub;
                detail.root.isFlat = !isUp && ![0, 2, 4, 5, 7, 9, 11].includes(detail.root.index);
                this.score.setScoreData(data);
                this.score.flashElement(state.focusIndex);
            } else if (detail.root.index + sub === -1) {
                detail.root = null;
                detail.symbolKey = 'major';
                this.score.setScoreData(data);
                this.score.flashElement(state.focusIndex);
            }
            // キャッシュの更新
            const cache = state.elementCacheList[state.focusIndex];
            const chordCache = state.chordCacheList[cache.chordBlockNo];
            if (detail.root != null) {
                const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                [chordCache.chordFullName, chordCache.structList] =
                    BackingUtil.getChordDetails(detail.root, cache.keyIndex, detail.on, symbol);
            } else {
                chordCache.chordFullName = '';
                chordCache.structList = [];
            }
            this.score.setScoreState(state);
        },

        incrementBeatLen: (isUp: boolean) => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const chordList = data.chordList.slice();
            const detail = chordList[state.focusIndex].detail as DetailChord;
            const sub = isUp ? 1 : -1;
            if (detail.beatLen + sub >= 1 && detail.beatLen + sub <= 4) {
                detail.beatLen += sub;
                data.chordList = chordList;
                this.score.setScoreData(data);
                // キャッシュの更新
                // setTimeout(() => {
                CacheUtil.updateHarmonizeCache(this.store, this);
                // }, 1);
                this.score.flashElement(state.focusIndex);
            }
        },

        incrementMinuteVal: (pos: number, isTail: boolean, value: number) => {
            const data = this.store.scoreData;
            const cur = data.chordList[pos];
            const detail = cur.detail as DetailChord;
            let target = !isTail ? detail.minute.head : detail.minute.tail;
            target += value;
            if (target >= -2 && target <= 2) {
                if (!isTail) detail.minute.head = target;
                else detail.minute.tail = target;
                // data.chordList[this.store.scoreState.focusIndex].detail = detail;
                data.chordList = data.chordList.slice();
                this.score.setScoreData(data);
                // キャッシュの更新
                CacheUtil.updateHarmonizeCache(this.store, this);
            }
        },

        incrementMinuteValRelation: (isTail: boolean, value: number) => {
            const focusIndex = this.store.scoreState.focusIndex;
            const chordList = this.store.scoreData.chordList;

            let nearIndex = -1;
            if (!isTail) {
                for (let i = focusIndex - 1; i > 2; i--) {
                    if (chordList[i].type === 'chord') {
                        nearIndex = i;
                        break;
                    }
                }
            } else {
                for (let i = focusIndex + 1; i < chordList.length - 1; i++) {
                    if (chordList[i].type === 'chord') {
                        nearIndex = i;
                        break;
                    }
                }
            }
            if (nearIndex != -1) {
                this.score.incrementMinuteVal(focusIndex, isTail, value);
                this.score.incrementMinuteVal(nearIndex, !isTail, -value);
            }
        },

        addChordElement: () => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const cur = data.chordList[state.focusIndex];

            // 拍の初期値を取得
            const beatSignature = state.elementCacheList[state.focusIndex].beatSignature;
            const initialBeat = TheoryUtil.getBeatProps(beatSignature).barBeatCnt;
            let curBeatLen = initialBeat;
            if (cur.type === 'chord') {
                const curChord = cur.detail as DetailChord;
                // 後にシンコペーションが設定されている場合は追加できない
                if (curChord.minute.tail != 0) return;

                curBeatLen = curChord.beatLen;
            }
            const element = {
                type: 'chord', detail: {
                    beatLen: curBeatLen,
                    minute: { head: 0, tail: 0 },
                    // degreeIndex: 0,
                    // isFlat: false,
                    root: null,
                    on: null,
                    symbolKey: 'major',
                    backing: {
                        voicingList: [],
                        pattern: null,
                        hasError: false
                    }
                } as DetailChord
            } as ChordElement;
            data.chordList.splice(state.focusIndex + 1, 0, element);
            this.score.setScoreData(data);

            // キャッシュの更新
            CacheUtil.updateHarmonizeCache(this.store, this);

            // const cache = state.elementCacheList[state.focusIndex + 1];
            // const wrapRef = cache.reffers.wrap as HTMLDivElement;
            // const baseWidth = wrapRef.clientWidth;
            // wrapRef.style.width = '0';
            // setTimeout(() => {
            //     wrapRef.style.width = `${baseWidth}px`;
            // }, 1);
            // setTimeout(() => {
            //     wrapRef.style.width = '';
            // }, 1000);
        },

        addSectionElement: () => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const element = {
                type: 'section', detail: {
                    labelName: 'Section'
                } as DetailSection
            } as ChordElement;
            data.chordList.splice(state.focusIndex + 1, 0, element);
            this.score.setScoreData(data);

            // キャッシュの更新
            CacheUtil.updateHarmonizeCache(this.store, this);
        },

        addAlterElement: () => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const element = {
                type: 'alter', detail: {
                    alterType: null,
                    modRel: null,
                    dommVal: 0,
                    keyAdj: 0,
                    tempoRel: null,
                    tempoDiff: 0,
                    tempoRate: 1,
                    tempoAbs: 0,
                    beatSignature: '4/4'
                } as DetailAlter
            } as ChordElement;
            data.chordList.splice(state.focusIndex + 1, 0, element);
            this.score.setScoreData(data);

            // キャッシュの更新
            CacheUtil.updateHarmonizeCache(this.store, this);
        },

        removeElement: () => {
            const state = this.store.scoreState;
            // const cache = state.elementCacheList[state.focusIndex];

            const data = this.store.scoreData;
            const distHead = state.focusIndex < state.distIndex ? state.focusIndex : state.distIndex;
            const distTail = state.focusIndex > state.distIndex ? state.focusIndex : state.distIndex;

            for (let i = distHead; i <= distTail; i++) {
                const element = data.chordList[i];
                const sectionNo = this.store.scoreState.elementCacheList[i].sectionNo;
                // 先頭のセクション要素が含まれている場合削除しない
                if (element.type === 'section' && sectionNo === 0) {
                    return;
                }
            }
            data.chordList.splice(distHead, (distTail - distHead) + 1);
            this.score.setScoreData(data);
            state.focusIndex = distHead - 1;
            state.distIndex = state.focusIndex;
            this.score.setScoreState(state);
            // キャッシュの更新
            CacheUtil.updateHarmonizeCache(this.store, this);

            // const wrapRef = cache.reffers.wrap as HTMLDivElement;
            // const bodyRef = cache.reffers.body as HTMLDivElement;
            // // reffer.style.width = '300px';
            // wrapRef.style.opacity = '0';
            // wrapRef.style.overflow = 'hidden';
            // // wrapRef.style.padding = '0';
            // wrapRef.style.width = `${wrapRef.clientWidth}px`;
            // setTimeout(() => {
            //     wrapRef.style.width = '0';
            // }, 1);
            // setTimeout(() => {
            //     wrapRef.style.opacity = '';
            //     wrapRef.style.width = '';
            //     wrapRef.style.overflow = '';
            //     // wrapRef.style.padding = '';
            //     const data = this.store.scoreData;
            //     data.chordList.splice(state.focusIndex, 1);
            //     this.score.setScoreData(data);
            //     state.focusIndex--;
            //     this.score.setScoreState(state);
            //     // setTimeout(() => {
            //     //     reffer.style.opacity = '';
            //     //     reffer.style.width = '';
            //     //     const data = this.store.scoreData;
            //     //     data.chordList.splice(state.focusIndex, 1);
            //     //     this.score.setScoreData(data);
            //     // }, 200);
            // }, 200);
        },

        removeBacking: () => {
            const state = this.store.scoreState;
            // const cache = state.elementCacheList[state.focusIndex];

            const data = this.store.scoreData;
            const distHead = state.focusIndex < state.distIndex ? state.focusIndex : state.distIndex;
            const distTail = state.focusIndex > state.distIndex ? state.focusIndex : state.distIndex;

            for (let i = distHead; i <= distTail; i++) {
                const element = data.chordList[i];
                // コード要素のみ処理
                if (element.type === 'chord') {
                    const chord = element.detail as DetailChord;
                    chord.backing = { voicingList: [], pattern: null };
                }
            }
            this.score.setScoreData(data);
            this.score.setScoreState({ ...state });

            // キャッシュの更新
            CacheUtil.updateHarmonizeCache(this.store, this);
        },

        copyElementList: () => {
            const state = this.store.scoreState;
            // const cache = state.elementCacheList[state.focusIndex];

            const data = this.store.scoreData;
            const distHead = state.focusIndex < state.distIndex ? state.focusIndex : state.distIndex;
            const distTail = state.focusIndex > state.distIndex ? state.focusIndex : state.distIndex;

            const list: ChordElement[] = [];
            for (let i = distHead; i <= distTail; i++) {
                const element = data.chordList[i];
                list.push(JSON.parse(JSON.stringify(element)));
            }
            console.log(list.length);
            state.copiedElementList = list;
            state.cloneBacking = null;
            this.score.setScoreState(state);
        },

        pasteElementList: () => {
            const state = this.store.scoreState;

            const data = this.store.scoreData;
            const list = state.copiedElementList;
            if (list == null) return;

            console.log(list.length);
            list.forEach((element, i) => {
                data.chordList.splice(state.focusIndex + 1 + i, 0, element);
            });
            this.score.setScoreData(data);
            state.focusIndex++;
            state.distIndex = state.focusIndex + list.length - 1;
            state.copiedElementList = null;
            this.score.setScoreState(state);
            // キャッシュの更新
            CacheUtil.updateHarmonizeCache(this.store, this);
        },

        // incrementSymbol: (isUp: boolean) => {
        //     const state = this.store.scoreState;
        //     const data = this.store.scoreData;
        //     const detail = data.chordList[state.focusIndex].detail as DetailChord;
        //     const sub = isUp ? 1 : -1;
        //     if (detail.root == null) {
        //         detail.root = { index: 0, isFlat: false };
        //         this.score.setScoreData(data);
        //     } else if (detail.root.index + sub >= 0 && detail.root.index + sub <= 11) {
        //         detail.root.index += sub;
        //         detail.root.isFlat = !isUp && ![0, 2, 4, 5, 7, 9, 11].includes(detail.root.index);
        //         this.score.setScoreData(data);
        //     }
        // },

        setDiatonicChord: (scale: Scale, diatonicIndex: number) => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            const scaleList = scale === 'major' ? TheoryUtil.DIATONIC_MAJOR_SCALE_LIST : TheoryUtil.DIATONIC_MINOR_SCALE_LIST;
            const chord = scaleList[diatonicIndex];
            const root: TheoryUtil.DegreeProps = { ...chord.root };
            detail.root = root;
            detail.symbolKey = chord.symbolKey;
            // オンコードの設定を解除
            detail.on = null;
            this.score.setScoreData(data);

            // キャッシュの更新
            const cache = state.elementCacheList[state.focusIndex];
            const chordCache = state.chordCacheList[cache.chordBlockNo];
            const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
            [chordCache.chordFullName, chordCache.structList] =
                BackingUtil.getChordDetails(root, cache.keyIndex, null, symbol);
            chordCache.backingError =
                BackingUtil.hasBackingError(detail, cache.beatSignature, chordCache.structList.length);
            this.score.setScoreState(state);
        },

        setDefaultBacking: () => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
            if (symbol.attr != undefined) {
                detail.backing = getDefaultBacking(symbol.attr);
                this.score.setScoreData(data);
            }
        },

        cloneBacking: () => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            state.cloneBacking = JSON.parse(JSON.stringify(detail.backing));
            state.copiedElementList = null;
            this.score.setScoreState(state);
            console.log(`clone backing! \n${JSON.stringify(state.cloneBacking)}`);
        },

        pastBacking: () => {
            const state = this.store.scoreState;
            if (state.cloneBacking != null) {
                console.log(`paste backing! \n${JSON.stringify(state.cloneBacking)}`);
                const data = this.store.scoreData;
                const detail = data.chordList[state.focusIndex].detail as DetailChord;
                detail.backing = JSON.parse(JSON.stringify(state.cloneBacking));
                this.score.setScoreData(data);
            }
        },

        openLibraryWnd: (isPreset: boolean) => {
            const state = this.store.scoreState;
            if (!isPreset) {
                state.hideMenu = 'library';

                // バッキングを作成
                const chordList = this.store.scoreData.chordList;
                const focusIndex = this.store.scoreState.focusIndex;
                const chord = chordList[focusIndex].detail as DetailChord;
                const cache = state.elementCacheList[state.focusIndex];
                const chordCache = state.chordCacheList[cache.chordBlockNo];
                const structList = chordCache.structList;
                const symbol = TheoryUtil.getSymbolFromKey(chord.symbolKey) as TheoryUtil.SymbolParams;
                const chordInfo: BackingState.ChordInfo = {
                    symbolAttr: symbol.attr,
                    chordFullName: chordCache.chordFullName,
                    structList,
                    beatLen: chord.beatLen,
                    minute: chord.minute,
                    init: { ...cache }
                };

                // エラーチェックのためにテーブルからリストへ逆変換
                // const voicingList = BackingUtil.getVoicingListFromTable([]);
                // const hasError = BackingUtil.checkEditorError(chordInfo, voicingList, null);
                this.backing.setState({
                    chordInfo,
                    tabIndex: 0,
                    voicingTable: [],
                    pattern: null,
                    isPreview: false,
                    hasError: false,
                    closeAction: () => { }
                });
            }
            else state.hideMenu = 'preset';

            this.score.setScoreState(state);

        },

        closeLibraryWnd: () => {
            const state = this.store.scoreState;
            state.hideMenu = 'none';
            this.score.setScoreState(state);
        },
    }

    public symbol = {

        toNext: (isUp: boolean) => {
            const state = this.store.scoreState;
            const mng = state.symbolMng as SymbolDiagram;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            const sub = isUp ? 1 : -1;
            const index = mng.index + sub;
            if (index >= 0 && index <= mng.curLine.length - 1) {
                mng.index += sub;
                detail.symbolKey = mng.curLine[mng.index];
                this.score.setScoreData(data);

                // キャッシュの更新
                const root = detail.root as TheoryUtil.DegreeProps;
                const cache = state.elementCacheList[state.focusIndex];
                const chordCache = state.chordCacheList[cache.chordBlockNo];
                const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                [chordCache.chordFullName, chordCache.structList] =
                    BackingUtil.getChordDetails(root, cache.keyIndex, detail.on, symbol);
                this.score.setScoreState(state);
            }
        },

        toBase: () => {
            const state = this.store.scoreState;
            const mng = state.symbolMng as SymbolDiagram;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            if (mng.parentLine.length > 0) {
                detail.symbolKey = mng.parentLine[0];
                this.score.setScoreData(data);

                // キャッシュの更新
                const root = detail.root as TheoryUtil.DegreeProps;
                const cache = state.elementCacheList[state.focusIndex];
                const chordCache = state.chordCacheList[cache.chordBlockNo];
                const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                [chordCache.chordFullName, chordCache.structList] =
                    BackingUtil.getChordDetails(root, cache.keyIndex, detail.on, symbol);
                chordCache.backingError =
                    BackingUtil.hasBackingError(detail, cache.beatSignature, chordCache.structList.length);
                this.score.setScoreState(state);
            }
        },

        toDerive: () => {
            const state = this.store.scoreState;
            const mng = state.symbolMng as SymbolDiagram;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            if (mng.childrenLine.length > 0) {
                detail.symbolKey = mng.childrenLine[0];
                this.score.setScoreData(data);

                // キャッシュの更新
                const root = detail.root as TheoryUtil.DegreeProps;
                const cache = state.elementCacheList[state.focusIndex];
                const chordCache = state.chordCacheList[cache.chordBlockNo];
                const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                [chordCache.chordFullName, chordCache.structList] =
                    BackingUtil.getChordDetails(root, cache.keyIndex, detail.on, symbol);
                chordCache.backingError =
                    BackingUtil.hasBackingError(detail, cache.beatSignature, chordCache.structList.length);
                this.score.setScoreState(state);
            }
        },

    }

    /**
     * メロディ画面のディスパッチ
     */
    public melody = {

        setState: (tuneState: MelodyState) => {
            this.dispatch(ActionType.SET_TUNE_STATE, { tuneState });
        },

        /**
         * 全てのノーツを選択する
         */
        allSelect: () => {
            const state = this.store.melodyState;
            const notesList = this.store.scoreData.notesList;
            if (notesList.length > 0) {
                state.focusIndex = 0;
                state.destIndex = notesList.length - 1;
                this.melody.setState(state);
            }
        },

        setNotesList: (notesList: MelodyNotes[]) => {
            const data = this.store.scoreData;
            data.notesList = notesList;
            this.score.setScoreData(data);
        },

        setMelodyFont: (soundfontPlayer: Soundfont.Player) => {
            this.dispatch(ActionType.SET_INSTRUMENTS, {
                instruments: {
                    ...this.store.instruments, soundfontPlayer
                }
            });
        },

        /**
         * 選択中のノーツの音程を変更する
         * @param isUpper
         * @param mode 
         * @returns 
         */
        incrementCurPitch: (isUpper: boolean, mode: MelodyPitchMode) => {
            const scoreState = this.store.scoreState;
            const state = this.store.melodyState;
            const focusIndex = state.focusIndex;
            const distIndex = state.destIndex;
            const notesList = this.store.scoreData.notesList;

            // フォーカスされていない場合は処理しない
            if (focusIndex === -1) return;

            if (focusIndex !== distIndex) {

                const targetList = notesList.filter(
                    (notes, i) => i >= focusIndex && i <= distIndex
                );

                targetList.forEach(notes => {
                    notes.pitchIndex += 1 * (isUpper ? -1 : 1);
                });

                // 履歴を作成
                state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));
                this.melody.setNotesList(notesList.slice());
            } else {
                const cur = notesList[focusIndex];
                // const init = this.store.scoreData.chordList[0].detail as DetailInit;
                const normalLeft = getNormalizeLenFromNotes(cur.pos);
                const scaleInfo = CacheUtil.getKeyScaleFromNotesPos(normalLeft, scoreState.scaleCacheList);
                const scaleIndexList = scaleInfo.scale === 'major' ? TheoryUtil.MAJOR_SCALE_INTERVALS : TheoryUtil.MINOR_SCALE_INTERVALS;
                const scaleIndexies = scaleIndexList.map(value => (scaleInfo.keyIndex + value) % 12);

                const prevPitchIndex = cur.pitchIndex;
                const interval = mode !== 'octave' ? 1 : 12;
                let tempPitchIndex = cur.pitchIndex;

                while (isUpper ? cur.pitchIndex > 0 : cur.pitchIndex < PITCH_MAX - 1) {
                    tempPitchIndex += interval * (isUpper ? -1 : 1);

                    if (mode !== 'scale' ||
                        (mode === 'scale' && scaleIndexies.includes(getKeyIndexFromPitcher(tempPitchIndex)))
                    ) {
                        cur.pitchIndex = tempPitchIndex;
                        break;
                    }
                }

                // 値が変わっていた場合のみ処理する
                if (prevPitchIndex !== cur.pitchIndex) {
                    playSoundfont(cur.pitchIndex, this.store);
                    // 履歴を作成
                    state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));
                    this.melody.setNotesList(notesList.slice());

                    this.melody.adjustScrollY();
                }

            }
        },

        /**
         * キースイッチを設定する
         * @param keySwitch 
         */
        setKeySwitch: (keySwitch: MelodyKeySwitch) => {
            const state = this.store.melodyState;
            state.keySwitch = keySwitch;
            this.melody.setState(state);
        },

        /**
         * フォーカスを移動する
         * @param isNext 移動方向（trueの場合次のノーツに移動）
         */
        moveFocus: (isNext: boolean) => {
            const state = this.store.melodyState;
            const data = this.store.scoreData;
            const notesList = data.notesList;

            // ノーツがフォーカスされていない時は最後にフォーカスされたノーツを選択する
            if (state.focusIndex === -1) {
                if (notesList.length > 0) {
                    state.focusIndex = state.lastFocus;
                    state.destIndex = state.focusIndex;
                    this.melody.setState(state);
                }
            } else {
                if (!isNext ? state.focusIndex > 0 : state.focusIndex < notesList.length - 1) {
                    state.focusIndex += (isNext ? 1 : -1);
                    state.destIndex = state.focusIndex;
                    this.melody.setState(state);
                }
            }

            this.melody.adjustScrollX();
            this.melody.updateCurChord(state.focusIndex);
        },

        /**
         * 複数選択From～ToのToを移動する
         * @param isNext 移動方向（trueの場合次のノーツに移動）
         * @returns 
         */
        moveDestFocus: (isNext: boolean) => {
            const state = this.store.melodyState;
            const data = this.store.scoreData;
            const notesList = data.notesList;

            if (state.focusIndex === -1) return;

            if (!isNext ? state.destIndex > 0 : state.destIndex < notesList.length - 1) {
                state.destIndex += (isNext ? 1 : -1);
                this.melody.setState(state);
            }
            this.melody.adjustScrollX();
        },

        /**
         * ノーツのエリアのコードを更新する
         * @param focusIndex ノーツのフォーカス
         */
        updateCurChord: (focusIndex: number) => {
            const state = this.store.melodyState;
            const chordList = this.store.scoreData.chordList;
            const x = getNormalizeLenFromNotes(this.store.scoreData.notesList[focusIndex].pos) * state.beatWidth;
            let left = 0;
            const searchedChord = chordList.find((element) => {
                if (element.type === 'chord') {
                    const detail = element.detail as DetailChord;
                    const width = detail.beatLen * state.beatWidth;
                    if (x >= left && x < left + width) {
                        // alert(`x=${x}, left=${left}`);
                        return element;
                    }
                    left += width;
                }
            });
            state.tableMouse.curChord = searchedChord == undefined ? null : searchedChord;
            this.melody.setState(state);
        },

        /**
         * フォーカスの後ろに新しいノーツを追加する
         * @returns 
         */
        addNotes: () => {
            const state = this.store.melodyState;
            const focus = state.focusIndex;
            if (focus === -1) return;
            state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));

            const data = this.store.scoreData;
            const notesList = data.notesList;
            const cur = notesList[focus];
            const pos = getAdditionNotsLen(cur.pos, cur.len);
            const newLeftNormal = getNormalizeLenFromNotes(pos);
            const nextNormalPos = focus === notesList.length - 1 ? -1 : getNormalizeLenFromNotes(notesList[state.focusIndex + 1].pos);
            // 新しい要素の位置
            const newRightNormal = newLeftNormal + 1 / state.quantize;

            // 次のノーツが存在しないか、挿入する隙間があるかを判定
            if (nextNormalPos === -1 || newRightNormal <= nextNormalPos) {
                data.notesList.splice(focus + 1, 0, {
                    pos,
                    pitchIndex: cur.pitchIndex,
                    len: getNotesLenFromQuantize(state.quantize, 1)
                });
                this.score.setScoreData(data);
                state.focusIndex++;
                state.destIndex = state.focusIndex;
                this.melody.setState(state);
            }

            this.melody.adjustScrollX();
        },

        /**
         * ノーツを削除する
         */
        removeNotes: () => {
            const state = this.store.melodyState;
            const notesList = this.store.scoreData.notesList;
            // 履歴を作成
            state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));
            // 対象の開始と終了のインデックスを取得
            const [start, end] = state.focusIndex < state.destIndex ? [state.focusIndex, state.destIndex] : [state.destIndex, state.focusIndex];
            // 削除する個数
            const delNum = end - start + 1;
            notesList.splice(start, delNum);
            this.melody.setNotesList(notesList);
            // 削除後は対象の開始インデックスの１つ前を選択
            state.focusIndex = start - 1;
            state.destIndex = state.focusIndex;
            this.melody.setState(state);
            this.melody.adjustScrollX();
        },

        /**
         * 複数選択を解除する
         * @param isTail 解除時複数選択のどこにフォーカスを移すか（trueの場合終端を選択）
         */
        releaseRange: (isTail: boolean) => {
            const state = this.store.melodyState;
            const [start, end] = state.focusIndex < state.destIndex ? [state.focusIndex, state.destIndex] : [state.destIndex, state.focusIndex];
            state.focusIndex = (!isTail ? start : end);
            state.destIndex = state.focusIndex;
            this.melody.setState(state);
            this.melody.adjustScrollX();
            this.melody.updateCurChord(state.focusIndex);
        },

        resizeNotes: (isSpread: boolean) => {

            const state = this.store.melodyState;
            if (state.focusIndex === -1) return;
            state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));

            const data = this.store.scoreData;
            const notesList = data.notesList;
            const cur = notesList[state.focusIndex];
            const curLenNormal = getNormalizeLenFromNotes(cur.len);
            const curNormalRight = getNormalizeLenFromNotes(cur.pos) + curLenNormal;
            const nextNormalPos = state.focusIndex === notesList.length - 1 ? -1 : getNormalizeLenFromNotes(notesList[state.focusIndex + 1].pos);
            const resizeNormal = 1 / state.quantize;
            const condition = !isSpread ?
                // 縮めることが可能か
                curLenNormal > resizeNormal :
                // 広げることが可能か
                nextNormalPos === -1 || curNormalRight + resizeNormal <= nextNormalPos
            // 次のノーツが存在しないか、挿入する隙間があるかを判定
            if (condition) {
                const normal = getNormalizeLenFromNotes(cur.len) + resizeNormal * (isSpread ? 1 : -1);
                cur.len = getNotesLenFromQuantize(state.quantize, normal * state.quantize);
                data.notesList = notesList.slice();
                this.score.setScoreData(data);
            }
        },

        moveNotes: (isBehind: boolean) => {

            const state = this.store.melodyState;
            if (state.focusIndex === -1) return;
            state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));

            const data = this.store.scoreData;
            const notesList = data.notesList;

            const distIndex = state.destIndex;
            const focusIndex = state.focusIndex;

            const [start, end] = distIndex > focusIndex ? [focusIndex, distIndex] : [distIndex, focusIndex];
            const prevNormalRight = start === 0 ? 0 : getNormalizeLenFromNotes(notesList[start - 1].pos) +
                getNormalizeLenFromNotes(notesList[state.focusIndex - 1].len);
            const nextNormalLeft = end === notesList.length - 1 ? -1 : getNormalizeLenFromNotes(notesList[end + 1].pos);
            const moveNormal = 1 / state.quantize;

            const curNormalLeft = getNormalizeLenFromNotes(notesList[start].pos);
            const curNormalRight = getNormalizeLenFromNotes(notesList[end].pos) + getNormalizeLenFromNotes(notesList[end].len);

            const condition = !isBehind ?
                // 前に移動可能か
                curNormalLeft - moveNormal >= prevNormalRight :
                // 後に移動可能か
                nextNormalLeft === -1 || curNormalRight + moveNormal <= nextNormalLeft
            // 移動可能かを判定
            if (condition) {
                for (let i = start; i <= end; i++) {
                    const cur = notesList[i];
                    const normal = getNormalizeLenFromNotes(cur.pos) + moveNormal * (isBehind ? 1 : -1);
                    cur.pos = getNotesLenFromQuantize(state.quantize, normal * state.quantize);
                }
                data.notesList = notesList.slice();
                this.score.setScoreData(data);
            }
        },

        firstNotes: () => {
            const state = this.store.melodyState;
            const notesList = this.store.scoreData.notesList;

            if (notesList.length > 0) {
                state.focusIndex = 0;
                state.destIndex = state.focusIndex;
                this.melody.setState(state);
                this.melody.adjustScrollX();
                // this.tune.adjustScrollY();
            }
        },

        adjustScrollX: () => {
            const state = this.store.melodyState;
            const table = this.store.refs.melody.table;
            const focusIndex = state.destIndex;
            if (focusIndex !== -1 && table != null) {
                const notesX = getNormalizeLenFromNotes(this.store.scoreData.notesList[focusIndex].pos) * state.beatWidth;
                table.scrollTo({ left: notesX - 300, behavior: "smooth" });
            }
        },

        adjustScrollY: () => {
            const state = this.store.melodyState;
            const table = this.store.refs.melody.table;
            if (state.focusIndex !== -1 && table != null) {
                const notesY = this.store.scoreData.notesList[state.focusIndex].pitchIndex * TUNE_PITCH_HEIGHT;
                table.scrollTo({ top: notesY - table.clientHeight / 2, behavior: "smooth" });
            }
        },

        previewStart: () => {
            const state = this.store.melodyState;
            // if (state.focusIndex === -1) return;

            MelodyUtil.previewStart(this.store, this);
        },

        copyNotes: () => {

            const state = this.store.melodyState;
            const notesList = this.store.scoreData.notesList;
            const [focusStart, focusEnd] = state.focusIndex < state.destIndex ? [state.focusIndex, state.destIndex] : [state.destIndex, state.focusIndex];
            const num = focusEnd - focusStart + 1;

            const cloneNotesList: MelodyNotes[] = [];
            const base = notesList[focusStart].pos;
            const baseUnit = getMinimumLenUnit(base);
            for (let i = 0; i < num; i++) {
                const notes = notesList[i + focusStart];
                const unit = getMinimumLenUnit(notes.pos);
                const useUnit = baseUnit > unit ? baseUnit : unit;
                const normalPos = getNormalizeLenFromNotes(notes.pos) - getNormalizeLenFromNotes(base);
                const pos = getNotesLenFromQuantize(useUnit, normalPos / (1 / useUnit));
                console.log(`normalPos = ${normalPos}, unit = ${useUnit}`);
                console.log(JSON.stringify(pos));
                cloneNotesList.push({
                    pitchIndex: notes.pitchIndex,
                    pos,
                    len: { ...notes.len }
                });
            }
            state.cloneNotesList = cloneNotesList;
            // notesList.splice(state.focusIndex, num);
            // this.tune.setNotesList(notesList);
            // state.focusIndex = focusStart - 1;
            // state.distIndex = state.focusIndex;
            this.melody.setState(state);
            // alert(JSON.stringify(state.cloneNotesList));
        },

        pasteNotes: () => {
            // alert('pasteNotes');

            const state = this.store.melodyState;
            if (state.focusIndex === -1) return;

            state.dataHistory.add(JSON.stringify(this.store.scoreData.notesList));

            const data = this.store.scoreData;
            const notesList = data.notesList;
            const cur = notesList[state.focusIndex];
            const newLeft = getAdditionNotsLen(cur.pos, cur.len);
            const nextNormalPos = state.focusIndex === notesList.length - 1 ? -1 : getNormalizeLenFromNotes(notesList[state.focusIndex + 1].pos);
            const pastList: MelodyNotes[] = JSON.parse(JSON.stringify(state.cloneNotesList));
            pastList.forEach(notes => {
                notes.pos = getAdditionNotsLen(notes.pos, newLeft);
            });
            // ペーストするノーツの最後（右端）の要素
            const pastLast = pastList[pastList.length - 1];
            const newRightNormal = getNormalizeLenFromNotes(pastLast.pos) + getNormalizeLenFromNotes(pastLast.len);
            // 次のノーツが存在しないか、挿入する隙間があるかを判定
            console.log(`newRight: ${newRightNormal}, nextLeft: ${nextNormalPos}`);
            if (nextNormalPos === -1 || newRightNormal <= nextNormalPos) {
                data.notesList = notesList.concat(pastList);
                // ノーツ配置後は座標の昇順でソートする
                data.notesList.sort((a, b) => {
                    const posA = getNormalizeLenFromNotes(a.pos);
                    const posB = getNormalizeLenFromNotes(b.pos);
                    return posA === posB ? 0 : (
                        posA < posB ? -1 : 1
                    );
                });
                this.score.setScoreData(data);

                state.focusIndex++;
                state.destIndex = state.focusIndex + pastList.length - 1;
                this.melody.setState(state);
            }

            // this.tune.adjustScrollX();
        },

        undo: () => {
            const state = this.store.melodyState;
            const data = this.store.scoreData;
            const history = state.dataHistory;
            console.log(`undo start ★ length: ${history.list.length}, cur: ${history.cur}`);
            const prev = history.undo();
            if (prev != null) {
                if (history.cur === 0) {
                    console.log('first undo');
                    history.list.unshift(JSON.stringify(data.notesList));
                    history.cur = 1;
                }
                data.notesList = JSON.parse(prev);
                if (state.focusIndex >= data.notesList.length - 1) {
                    state.focusIndex = data.notesList.length - 1;
                    state.destIndex = state.focusIndex;
                }
                this.score.setScoreData(data);
            }
            console.log(`undo end ★ length: ${history.list.length}, cur: ${history.cur}`);
        },

        redo: () => {
            const state = this.store.melodyState;
            const data = this.store.scoreData;
            const history = state.dataHistory;
            console.log(`redo start ★ length: ${history.list.length}, cur: ${history.cur}`);
            const next = history.redo();
            if (next != null) {
                data.notesList = JSON.parse(next);
                if (state.focusIndex >= data.notesList.length - 1) {
                    state.focusIndex = data.notesList.length - 1;
                    state.destIndex = state.focusIndex;
                }
                this.score.setScoreData(data);
            }
            console.log(`redo end ★ length: ${history.list.length}, cur: ${history.cur}`);
        }
    }

    public backing = {

        openBackingEditor: () => {
            const state = this.store.scoreState;
            const data = this.store.scoreData;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            const cache = state.elementCacheList[state.focusIndex];
            const chordCache = state.chordCacheList[cache.chordBlockNo];
            const chordBacking = detail.backing;
            const structList = chordCache.structList;
            // const cache = state.elementCacheList[state.focusIndex];
            state.hideMenu = 'backing';
            this.score.setScoreState(state);

            const voicingTable = BackingUtil.getInitVoicingTable(detail.backing.voicingList, structList.length);
            const pattern = BackingUtil.getEditorPatternFromChordBacking(voicingTable, chordBacking.pattern);

            const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
            const chordInfo: BackingState.ChordInfo = {
                symbolAttr: symbol.attr,
                chordFullName: chordCache.chordFullName,
                structList,
                beatLen: detail.beatLen,
                minute: detail.minute,
                init: { ...cache }
            };
            // エラーチェックのためにテーブルからリストへ逆変換
            const voicingList = BackingUtil.getVoicingListFromTable(voicingTable);
            const hasError = BackingUtil.checkEditorError(chordInfo, voicingList, pattern);

            const updateAction = (editor: BackingState.Editor, isUpdate: boolean) => {
                if (isUpdate) {
                    this.backing.mappingEditorToScore(editor);
                }
                this.backing.closeBackingEditor();
            }
            // バッキングステートの初期化
            this.backing.setState({
                chordInfo,
                tabIndex: 0,
                voicingTable,
                pattern,
                isPreview: false,
                hasError,
                closeAction: updateAction
                // editType: 'normal'
            });
        },

        closeBackingEditor: () => {
            const state = this.store.scoreState;
            state.hideMenu = 'none';
            this.score.setScoreState(state);
            setTimeout(() => {
                this.backing.setState(null);
            }, 50);
        },

        setState: (backingState: null | BackingState.Editor) => {
            this.dispatch(ActionType.SET_BACKING_STATE, { backingState });
        },

        getInitialLayer: (): BackingState.Layer => {
            return {
                noteDivList: [{ rate: 4, isDot: false, div3: 0 }],
                table: [[null]],
                velocityList: [5]
            } as BackingState.Layer;
        },

        /**
         * エディタで設定したバッキング情報を譜面データに反映
         */
        mappingEditorToScore: (editor: BackingState.Editor) => {
            const scoreState = this.store.scoreState;
            const data = this.store.scoreData;
            const focus = scoreState.focusIndex;
            const detail = data.chordList[focus].detail as DetailChord;
            // const editor = this.store.backingState as BackingState.Editor;
            console.log(editor);
            detail.backing = {
                voicingList: BackingUtil.getVoicingListFromTable(editor.voicingTable),
                pattern: editor.pattern == null ? null : BackingUtil.buildPatternProps(editor.pattern),
                // hasError: editor.hasError
            };
            this.score.setScoreData(data);

            // エラーの反映
            const cache = scoreState.elementCacheList[focus];
            const scoreCache = scoreState.chordCacheList[cache.chordBlockNo];
            scoreCache.backingError = editor.hasError;
            this.score.setScoreState(scoreState);
        },

        addPatternColumn: () => {

            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;

            const layer = pattern.layers[pattern.layerIndex];
            const lenList = layer.noteDivList;
            const curLen = lenList[pattern.lenIndex];
            const newLen: BackingState.NoteDiv = { rate: curLen.rate, div3: curLen.div3, isDot: curLen.isDot };
            lenList.splice(pattern.lenIndex + 1, 0, newLen);
            layer.noteDivList = lenList.slice();

            // テーブル追加
            layer.table.forEach((column) => {
                column.splice(pattern.lenIndex + 1, 0, null);
            });

            // ベロシティ追加
            layer.velocityList.splice(pattern.lenIndex + 1, 0, 5);

            // ペダル追加
            if (pattern.layerIndex === 0) {
                pattern.pedalActs.splice(pattern.lenIndex + 1, 0, null);
            }

            this.backing.updateErrorState();
            this.backing.setState(state);
        },

        movePatternColumn: (isRight: boolean) => {

            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];

            let tempIndex = pattern.lenIndex;
            tempIndex += 1 * (isRight ? 1 : -1);
            if (tempIndex >= 0 && tempIndex <= layer.noteDivList.length - 1) {
                pattern.lenIndex = tempIndex;
            }
            this.backing.setState(state);
        },

        moveChannelIndex: (isDown: boolean) => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const index = pattern.channelIndex;

            if (!isDown ? index > 0 : index < pattern.channels.length - 1) {
                pattern.channelIndex += (!isDown ? -1 : 1);
            }
            this.backing.setState(state);
        },

        setCurrentDivRate: (rate: BackingState.NoteRate) => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];
            const cur = layer.noteDivList[pattern.lenIndex];
            cur.rate = rate;
            cur.isDot = false;
            cur.div3 = 0;
            layer.noteDivList = layer.noteDivList.slice();
            this.backing.updateErrorState();
            this.backing.setState(state);
        },

        toggleCurrentDivHalf: () => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];
            const cur = layer.noteDivList[pattern.lenIndex];
            if (BackingUtil.isPermitDot(cur)) {
                cur.isDot = !cur.isDot;
                layer.noteDivList = layer.noteDivList.slice();
                this.backing.setState(state);
                this.backing.updateErrorState();
            }
        },


        getNotes: () => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];
            return layer.table[pattern.channels.length - 1 - pattern.channelIndex][pattern.lenIndex];
        },

        setNotes: (notes: BackingState.NoteStatus | null) => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];
            layer.table[pattern.channels.length - 1 - pattern.channelIndex][pattern.lenIndex] = notes;
            layer.table = layer.table.slice();
            this.backing.updateErrorState();
            this.backing.setState(state);
        },

        incrementVel: (value: number) => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];
            const after = layer.velocityList[pattern.lenIndex] + value;
            if (after >= 1 && after <= 10) {
                layer.velocityList[pattern.lenIndex] = after;
                this.backing.setState(state);
            }
        },

        deleteColumn: () => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            const layer = pattern.layers[pattern.layerIndex];
            // 2つ以上要素がないと消せない
            if (layer.noteDivList.length >= 2) {
                layer.noteDivList.splice(pattern.lenIndex, 1);
                layer.table.forEach((column) => {
                    column.splice(pattern.lenIndex, 1);
                });
                layer.velocityList.splice(pattern.lenIndex, 1);
                // for(let i = 0; i < pattern.channels.length; i ++) {
                //     layer.table[i].splice(pattern.lenIndex, 1);
                // }
                if (pattern.layerIndex === 0) {
                    // 終端までnullで更新
                    if (pattern.lenIndex < pattern.pedalActs.length - 1) {
                        for (let j = pattern.lenIndex + 1; j < pattern.pedalActs.length; j++) {
                            pattern.pedalActs[j] = null;
                        }
                    }
                    pattern.pedalActs.splice(pattern.lenIndex, 1);
                }

                if (pattern.lenIndex > 0) {
                    pattern.lenIndex--;
                }
                this.backing.setState(state);
            }
            this.backing.updateErrorState();
        },

        /**
         * 左右レイヤーを切り替える
         */
        switchLayer: () => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            pattern.layerIndex = pattern.layerIndex === 0 ? 1 : 0;
            pattern.lenIndex = 0;
            pattern.channelIndex = -1;
            this.backing.setState(state);
        },

        /**
         * 音量orペダルのモードを切り替える
         */
        switchMode: () => {
            const state = this.store.backingState as BackingState.Editor;
            const pattern = state.pattern as BackingState.Pattern;
            if (pattern.mode === 'velocity') pattern.mode = 'pedal';
            else if (pattern.mode === 'pedal') pattern.mode = 'velocity';
            this.backing.setState(state);
        },

        /**
         * バッキングのプレビュー再生をする
         */
        testBacking: () => {
            const editor = this.store.backingState as BackingState.Editor;
            const reserveTasks = this.store.reserveTasks;
            const chordInfo = editor.chordInfo;
            const init = chordInfo.init;

            // const rootIndex = (chordInfo.root.index + chordInfo.init.keyIndex) % 12;
            // const onIndex = chordInfo.on == null ? -1 : ((chordInfo.on.index + chordInfo.init.keyIndex) % 12);
            // const symbol = TheoryUtil.getSymbolFromKey(chordInfo.symbolKey);
            // const len = chordInfo.beatLen + chordInfo.minute.head + chordInfo.minute.tail;
            // const chordTime = 60 / init.bpm * len;

            const chordTime = TheoryUtil.calcSustainMsec(
                chordInfo.beatLen,
                chordInfo.minute.head + chordInfo.minute.tail,
                init.bpm,
                init.beatSignature
            );
            const backing: ChordBacking = {
                voicingList: BackingUtil.getVoicingListFromTable(editor.voicingTable as boolean[][]),
                pattern: editor.pattern == null ? null : BackingUtil.buildPatternProps(editor.pattern)
            };
            const pitchList = BackingUtil.getPitchListFromVoicing(backing.voicingList, chordInfo.structList);

            BackingUtil.playBacking(this.store, reserveTasks, init, backing, pitchList, chordTime);

            reserveTasks.push(setTimeout(() => {
                editor.isPreview = false;
                this.backing.setState(editor);
            }, chordTime));

            editor.isPreview = true;
            this.backing.setState(editor);
        },

        updateErrorState: () => {
            const editor = this.store.backingState as BackingState.Editor;
            const voicingList = BackingUtil.getVoicingListFromTable(editor.voicingTable);
            editor.hasError = BackingUtil.checkEditorError(editor.chordInfo, voicingList, editor.pattern);
            this.backing.setState(editor);
        },
    }

    public thema = {
        openBackingEditor: (
            beatLen: number, head: number, tail: number,
            symbolKey: TheoryUtil.SymbolKey, rootIndex: number, backing: ChordBacking,
            update: (editor: BackingState.Editor) => void,
            close: () => void
        ) => {
            const symbol = TheoryUtil.getSymbolFromKey(symbolKey) as TheoryUtil.SymbolParams;
            const voicingTable = BackingUtil.getInitVoicingTable(backing.voicingList, symbol.structs.length);
            const pattern = BackingUtil.getEditorPatternFromChordBacking(voicingTable, backing.pattern);

            const [chordFullName, structList] = BackingUtil.getChordDetails(
                { index: rootIndex, isFlat: false } as TheoryUtil.DegreeProps,
                0,
                null,
                symbol
            );
            const chordInfo: BackingState.ChordInfo = {
                symbolAttr: symbol.attr,
                chordFullName: chordFullName,
                structList,
                beatLen,
                minute: { head, tail },
                init: {
                    beatSignature: "4/4",
                    bpm: 100,
                    keyIndex: 0,
                    scale: "major"
                }
            };
            // エラーチェックのためにテーブルからリストへ逆変換
            const voicingList = BackingUtil.getVoicingListFromTable(voicingTable);
            const hasError = BackingUtil.checkEditorError(chordInfo, voicingList, pattern);

            const closeAction = (editor: BackingState.Editor, isUpdate: boolean) => {
                if (isUpdate) {
                    update(editor);
                }
                close();
                this.backing.setState(null);
            }
            // バッキングステートの初期化
            this.backing.setState({
                chordInfo,
                tabIndex: 0,
                voicingTable,
                pattern,
                isPreview: false,
                hasError,
                closeAction
                // editType: 'thema-pattern'
            });
        },

        mappingEditorToThema: (editor: BackingState.Editor) => {
            console.log(editor);
            if (editor.pattern != null) {
                const pattern = BackingUtil.buildPatternProps(editor.pattern)
            }
        },

        /**
         * セレクタで選択したバッキング情報を譜面データに反映
         */
        mappingSelectorToScore: (voicingList: VoicingProps[], pattern: BackingPatternProps | null) => {
            const scoreState = this.store.scoreState;
            const data = this.store.scoreData;
            const focus = scoreState.focusIndex;
            const detail = data.chordList[focus].detail as DetailChord;

            detail.backing = { voicingList, pattern };
            this.score.setScoreData(data);

            // エラーの反映
            // const cache = scoreState.elementCacheList[focus];
            // const scoreCache = scoreState.chordCacheList[cache.chordBlockNo];
            // scoreCache.backingError = editor.hasError;
            // this.score.setScoreState(scoreState);
        },
    }
}