import styled, { css } from "styled-components";

const JUDGE_INFO_LIST: {
    value: number, label: string
}[] = [
        { value: -2, label: '-/8' },
        { value: -1, label: '-/16' },
        { value: 0, label: '±0' },
        { value: 1, label: '+/16' },
        { value: 2, label: '+/8' }
    ];

export const MinuteForm = (props: {
    isHead: boolean;
    value: number;
    setValue: (value: number) => void;
}) => {

    const rateMinus = props.value >= 0 ? 0 : Math.abs(props.value) * 50;
    const ratePlus = props.value <= 0 ? 0 : Math.abs(props.value) * 50;

    const createJudgeItem = (key: number, value: number, label: string) => {
        const isActive = props.value === value;
        return (
            <_JudgeItem key={key} isActive={isActive} onClick={() => props.setValue(value)} >
                {!isActive ? '' : label}
            </_JudgeItem>
        )
    }
    const isRed = Math.abs(props.value) === 1;

    const JudgeJsxList = JUDGE_INFO_LIST.map((judge, i) => {
        return createJudgeItem(i, judge.value, judge.label);
    });

    return (
        <_Wrap isHead={props.isHead}>
            <_View>
                <_ViewFrame isLeft={true} isRed={isRed} rate={!props.isHead ? rateMinus : ratePlus}><div /></_ViewFrame>
                <_ViewFrame isLeft={false} isRed={isRed} rate={!props.isHead ? ratePlus : rateMinus}><div /></_ViewFrame>
            </_View>
            <_Judge>{!props.isHead ? JudgeJsxList : JudgeJsxList.reverse()}</_Judge>
        </_Wrap>
    );
};

export default MinuteForm;

const _Wrap = styled.div<{
    isHead: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 30px;
    background-color: #bfd380;
    text-align: left;
`;
const _Judge = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
`;

const _JudgeItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    width: calc(20% - 8px);
    height: calc(100% - 8px);
    margin: 4px;
    text-align: left;
    vertical-align: top;
    background-color: #0000001a;
    /* border: 1px solid #4d4d4d91; */
    border-radius: 4px;
    ${props => !props.isActive ? '' : css`
        border: 1px solid #c00000c5;
    `}
    font-size: 12px;
    line-height: 18px;
    font-weight: 600;
    color: #ffffff;
    box-sizing: border-box;
    text-align: center;
    /* opacity: 0.15; */
    &:hover {
        background-color: #ffffff24;
    }
`;
const _View = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: center;
    /* position: absolute;
    top: 0;
    left: 0; */
    z-index: 8;
    text-align: center;
`;

const _ViewFrame = styled.div<{
    isRed: boolean;
    isLeft: boolean;
    rate: number;
}>`
    display: inline-block;
    width: 50%;
    height: 16px;
    margin-top: 7px;
    text-align: left;
    box-sizing: border-box;
    position: relative;

    & div {
        position: absolute;
        top: 0;
        height: 100%;
        width: ${props => props.rate}%;
        ${props => props.isRed ? css`
            background: linear-gradient(to right, #0066ff, #7691a3);
        ` : css`
            background: linear-gradient(to left, #ff0000, #a37676);
        `}
        ${props => props.isLeft ? css`
            right: 0;
            border-radius: 10px 0 0 10px;
        ` : css`
            left: 0;
            border-radius: 0 10px 10px 0;
        `}
    }
`;
