import styled from "styled-components";
import Styles from "../../../../base/design/styles";

namespace ProgStyle {


    /** 実行ボタン */
    export const _TitleLabel = styled.div<{
    }>`
        ${Styles.CSS_LABEL_MIDIUM};
        background-color: #f0f0f049;
        width: 120px;
        text-align: center;
        border-radius: 10px;
        color: #2c2c2c;
        border: solid 1px #ffffff79;
        box-sizing: border-box;
        vertical-align: top;
    `;

    /** コンボボックス */
    export const _Combobox = styled.select<{
        isEnable: boolean;
        width: number;
    }>`
        display: inline-block;
        height: 30px;
        ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
        font-size: ${Styles.FONT_MIDIUM}px;
        text-align: left;
        color: black;
        line-height: 24px;
        width: ${props => props.width}px;
        box-sizing: border-box;
    `;


    /** ラベル */
    export const _Label = styled.div<{
        isEnable: boolean;
        width: number;
    }>`
        display: inline-block;
        height: 30px;
        ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
        font-size: ${Styles.FONT_MIDIUM}px;
        text-align: left;
        color: #002bc5;
        font-weight: 600;
        background-color: #e1f9fa;
        padding-left: 4px;
        border-radius: 4px;
        line-height: 24px;
        width: ${props => props.width}px;
        box-sizing: border-box;
        vertical-align: top;
    `;
}

export default ProgStyle;