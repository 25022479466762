import zlib from "zlib";
import { ScoreState } from "../redux/store/score/scoreState";
// import { createClient } from "@supabase/supabase-js";
import { Base64 } from "js-base64";
import Hashids from 'hashids';

// const SUPABASE_URL = "https://rxjdrbdnfvfdbjvaljsp.supabase.co";
// const SUPABASE_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJ4amRyYmRuZnZmZGJqdmFsanNwIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NTQxNTQwMTgsImV4cCI6MTk2OTczMDAxOH0.Iq-ilL7ayVatIsG--Lq4WpdL_o1h-6RpR4KjWBdr24M';

// const supabase = createClient(SUPABASE_URL, SUPABASE_KEY);

namespace DataUtil {

    // export const DB_SERVER_DOMAIN = 'http://localhost:5000';
    export const DB_SERVER_DOMAIN = 'https://licresia-demo01-server.glitch.me';
    // export const DB_SERVER_DOMAIN = 'https://licresia-server.glitch.me';

    // export const APP_SERVER_DOMAIN = 'http://localhost:4000';
    // export const APP_SERVER_DOMAIN = 'https://cyber-kohno.github.io/licresia_demo_app';
    export const APP_SERVER_DOMAIN = 'https://demo-app.licresia.com';

    // const fetchData = async () => {
    //     try {
    //         // sampleテーブルから全カラムのデータをid順に取得
    //         // dataに入る型はそのままだとany[]となるため.from<T>で指定
    //         const { data, error } = await supabase.from<Table>('test').select().order('data');

    //         if (error) {
    //             throw error;
    //         }
    //         if (data) {
    //             console.log(data);
    //         }
    //     } catch (error: any) {
    //         alert(error.message);
    //     } finally {

    //     }
    // };

    const getUserHashBuilder = () => {
        return new Hashids('licresia-demo-user', 8);
    }

    export const getHashedFromUserSeq = (seq: number) => {
        const hashids = getUserHashBuilder();
        const hash = hashids.encode(seq);
        const hashedKey = Base64.encode(hash, true);
        return hashedKey;
    }

    export const getDecryptionedUserSeq = (hashedSeq: string) => {
        const hashids = getUserHashBuilder();
        const decryptioned = Number(hashids.decode(Base64.decode(hashedSeq)));
        return decryptioned;
    }

    const getScoreHashBuilder = () => {
        return new Hashids('licresia-demo', 8);
    }

    export const getHashedFromScoreSeq = (seq: number) => {
        const hashids = getScoreHashBuilder();
        const hash = hashids.encode(seq);
        const hashedKey = Base64.encode(hash, true);
        return hashedKey;
    }

    export const getDecryptionedScoreSeq = (hashedSeq: string) => {
        const hashids = getScoreHashBuilder();
        const decryptioned = Number(hashids.decode(Base64.decode(hashedSeq)));
        return decryptioned;
    }

    export const getPreviewURL = (scoreSeq: number) => {
        const hashedKey = getHashedFromScoreSeq(scoreSeq);
        const url = `${APP_SERVER_DOMAIN}/#/preview?v=${hashedKey}`;
        return url;
    }

    /**
     * クエリリクエストを生成する
     * @param sql 
     * @returns 
     */
    const createQueryRequestInit = (sql: string): RequestInit => {
        return {
            mode: 'cors',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sql })
        }
    }

    export type SendMailParam = {
        from: string,
        to: string,
        subject: string,
        text: string
    }

    const createSendMailRequestInit = (json: SendMailParam): RequestInit => {
        return {
            mode: 'cors',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(json)
        }
    }

    export const sendQueryRequestToMailAPI = (json: SendMailParam): Promise<Response> => {
        return fetch(DB_SERVER_DOMAIN + '/mail',
            createSendMailRequestInit(json)
        );
    }

    /**
     * select/updateを指定してSQLを実行する。<br>
     * selectの場合、結果をjsonで返す。
     * @param queryType
     * @param sql 
     * @returns 
     */
    export const sendQueryRequestToAPI = (queryType: 'select' | 'update', sql: string): Promise<Response> => {
        return fetch(DB_SERVER_DOMAIN + '/' + queryType,
            createQueryRequestInit(sql)
        );
    }

    export const findUserInfoList = async () => {
        const response = await sendQueryRequestToAPI('select', `SELECT id, email FROM user_tbl`);
        const results = await response.json();
        return results as { id: string, email: string}[];
    };

    export const findUserIdList = async () => {
        const results = await findUserInfoList();
        return (results as { id: string}[]).map(res => res.id);
    };

    export const findUserInfoFromEmail = async (email: string) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id FROM user_tbl WHERE email = '${email}'`);
        const results = await response.json();
        return results as { seq: number, id: string }[];
    };

    export const findUserInfoFromSeq = async (seq: number) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id, password, email FROM user_tbl WHERE seq = ${seq}`);
        const results = await response.json();
        return results as { seq: number, id: string, password: string, email: string }[];
    };

    export const findUserInfoLogin = async (id: string, password: string) => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq, id, password, email FROM user_tbl WHERE id = '${id}' and password = '${password}'`);
        const results = await response.json();
        return results as { seq: number, id: string, password: string, email: string }[];
    };

    export const getNextUserSeq = async () => {
        const response = await sendQueryRequestToAPI('select', `SELECT seq FROM sqlite_sequence WHERE name = 'user_tbl'`);
        const results = await response.json();
        const nextSeq = (results[0]['seq'] as number) + 1;
        DataUtil.sendQueryRequestToAPI('update', `update sqlite_sequence set seq = seq + 1 where name='user_tbl'`);

        return nextSeq;
    };

    export const findUserScoreList = async (userSeq: number) => {
        const response = await DataUtil.sendQueryRequestToAPI('select', `SELECT seq, dispnam, share FROM scoretbl WHERE user_seq = ${userSeq}`);
        const results = await response.json();
        return results as any[];
    };

    export const findScore = async (scoreSeq: number) => {
        const response = await DataUtil.sendQueryRequestToAPI('select', `SELECT (select id from user_tbl where seq = score.user_seq) as owner, dispnam, data FROM scoretbl score WHERE seq = ${scoreSeq}`);
        const results = await response.json();
        return results as any[];
    };

    /**
     * 文字列を圧縮する
     * @param val 圧縮前の文字列
     * @returns 圧縮後の文字列
     */
    export const gZip = (val: string) => {
        // エンコード
        const content = encodeURIComponent(val);
        // 圧縮
        const result = zlib.gzipSync(content);
        // Buffer => base64変換
        const value = result.toString('base64');
        return value;
    }

    /**
     * 圧縮された文字列を複号する
     * @param val 圧縮された文字列
     * @returns 複号後の文字列
     */
    export const unZip = (val: string) => {
        // base64 => Bufferに変換
        const buffer = Buffer.from(val, 'base64')
        // 復号化
        const result = zlib.unzipSync(buffer)
        // デコード
        const str = decodeURIComponent(result.toString())
        return str;
    }

    /**
     * Date型の日時を文字列に変換する
     * @param date Date型の日時
     * @returns 文字列の日時
     */
    export const getStringFromDate = (date: Date) => {

        const year_str = date.getFullYear().toString();
        //月だけ+1すること
        const month_str = (1 + date.getMonth()).toString();
        const day_str = date.getDate().toString();
        const hour_str = date.getHours().toString();
        const minute_str = date.getMinutes().toString();
        const second_str = date.getSeconds().toString();

        let format_str = 'YYYY-MM-DD hh:mm:ss';
        format_str = format_str.replace(/YYYY/g, year_str);
        format_str = format_str.replace(/MM/g, month_str);
        format_str = format_str.replace(/DD/g, day_str);
        format_str = format_str.replace(/hh/g, hour_str);
        format_str = format_str.replace(/mm/g, minute_str);
        format_str = format_str.replace(/ss/g, second_str);

        return format_str;
    };

    export const saveFile = (plainData: string, state: ScoreState) => {
        const options = {
            types: [
                {
                    accept: {
                        'text/plain': ['.lcrs'],
                    },
                },
            ],
        };
        const fileHandle = state.fileHandle;
        if (fileHandle != null) {
            (async () => {
                //ファイルへ書き込むための FileSystemWritableFileStream を作成
                const writable = await fileHandle.createWritable();
                //テキストデータの書き込み
                const text = DataUtil.gZip(plainData);
                await writable.write(text);
                //ファイルを閉じる
                await writable.close();
                state.fileHandle = fileHandle;
                // this.score.setScoreState({ ...state });
                const file = await fileHandle.getFile();
                alert('saved successfully!');
            })();
        } else {
            window.showSaveFilePicker(options).then((handle) => {
                (async () => {
                    //ファイルへ書き込むための FileSystemWritableFileStream を作成
                    const writable = await handle.createWritable();
                    //テキストデータの書き込み
                    const text = DataUtil.gZip(plainData);
                    await writable.write(text);
                    //ファイルを閉じる
                    await writable.close();
                    state.fileHandle = handle;
                    // this.score.setScoreState({ ...state });
                })();
            }).catch(() => {
                console.log('キャンセルされました');
            });
        }
    }
}

export default DataUtil;