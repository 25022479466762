import { useContext } from "react";
import styled, { css } from "styled-components";
import Styles, { _CSS_DISABLE } from "../../../../base/design/styles";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { GlobalContext } from "../../../entry/systemEntry";

const PatternDetail = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const update = () => dispatcher.backing.setState(state);

    const pattern = state.pattern as BackingState.Pattern;
    const layer = pattern.layers[pattern.layerIndex];
    /** 基準となるベロシティ */
    const channelIndex = (pattern.channels.length - 1) - pattern.channelIndex;
    const cell = layer.table[channelIndex][pattern.lenIndex];
    const baseVel = layer.velocityList[pattern.lenIndex];
    const timing = (cell == null || cell.timing == undefined) ? 0 : cell.timing;
    const velAdj = (cell == null || cell.velAdj == undefined) ? 0 : cell.velAdj;

    return (<_Wrap>
        {
            // ノーツが設定されていない場合は何も表示しない
            cell == null ? <></> : (<>
                <_DelayDiv>
                    <_Label>delay</_Label>
                    <_TimingValue type="range" min={-10} max={10} step={1} value={timing} onChange={e => {
                        cell.timing = Number(e.target.value);
                        update();
                    }} />
                </_DelayDiv>
                <_VelocityDiv>
                    <_Label>velocity</_Label>
                    <_VelFrame isFocus={false}>
                        <_VelItem value={baseVel} isEdit={false}/>
                    </_VelFrame>
                    <_VelFrame isFocus={store.scoreState.keySwitch.sustainMode}>
                        <_VelItem value={baseVel + velAdj} isEdit={velAdj != 0} />
                    </_VelFrame>
                    {/* <_Value type="range" min={-10} max={10} step={1} value={velAdj} onChange={e => {
                        cell.velAdj = Number(e.target.value);
                        update();
                    }} /> */}
                </_VelocityDiv>
            </>)
        }
    </_Wrap>);
}

export default PatternDetail;

const VEL_DIV_WIDTH = 180;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    /* white-space: nowrap; */
`;

const _Label = styled.div`
    ${Styles.CSS_LABEL_SMALL}
    line-height: 18px;
    margin-top: 14px;
    margin-left: 5px;
    width: 80px;
    background-color: #868686;
    color: #e7e7e7;
    border: 1px solid #616161;
    border-radius: 4px;
`;

const _DelayDiv = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - ${VEL_DIV_WIDTH + 1}px);
    height: 100%;
    background-color: #9c9c9c;
`;

const _TimingValue = styled.input<{
}>`
    display: inline-block;
    outline: none;
    margin: 20px 0 0 8px;
    width: calc(100% - 100px);
    height: 10px;
    box-sizing: border-box;
`;

const _VelocityDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: ${VEL_DIV_WIDTH}px;
    height: 100%;
    margin-left: 1px;
    /* background-color: #9a2020; */
`;

const _VelFrame = styled.div<{
    isFocus: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 46px;
    height: 100%;
    ${props => !props.isFocus ? '' : css`
        background-color: #ffffff17;
    `}
    /* background-color: #63a0a1;
    border-radius: 20px; */
`;

const _VelItem = styled.div<{
    value: number;
    isEdit: boolean;
}>`
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 5px);
    width: 10px;
    height: ${props => props.value * 10}%;
    background-color: #5cceddaf;
    ${props => !props.isEdit ? '' : css`
        background-color: #ddc35cae;
    `}
`;