import { Store } from "./store/store";

/**
 * ディスパッチメソッドの処理種別を定義します。
 */
export enum ActionType {
    SET_USER_ENV,
    SET_SYSTEM_STATE,
    SET_SCORE_STATE,
    SET_TUNE_STATE,
    SET_SCORE_DATA,
    SET_REFS,
    SET_INSTRUMENTS,
    SET_BACKING_STATE,
    SET_RESERVE_TASKS,
    SET_INVALIDATE,
}

/**
 * ディスパッチメソッドに指定するオブジェクトを定義します。
 */
export type Action = {
    type: ActionType;
    payload: any;
}

export const reducer = (store: Store, action: Action): Store => {
    const type = action.type;
    const payload = action.payload;
    switch (type) {
        case ActionType.SET_USER_ENV:
            store.userEnv = payload.userEnv;
            break;
        case ActionType.SET_SYSTEM_STATE:
            store.systemState = payload.systemState;
            break;
        case ActionType.SET_SCORE_STATE:
            store.scoreState = payload.scoreState;
            break;
        case ActionType.SET_SCORE_DATA:
            store.scoreData = payload.scoreData;
            break;
        case ActionType.SET_TUNE_STATE:
            store.melodyState = payload.tuneState;
            break;
        case ActionType.SET_REFS:
            store.refs = payload.refs;
            break;
        case ActionType.SET_INSTRUMENTS:
            store.instruments = payload.instruments;
            break;
        case ActionType.SET_BACKING_STATE:
            store.backingState = payload.backingState;
            break;
        case ActionType.SET_RESERVE_TASKS:
            store.reserveTasks = payload.reserveTasks;
            break;
        case ActionType.SET_INVALIDATE:
            store.invalidate = payload.invalidate;
            break;
    }
    return { ...store };
}