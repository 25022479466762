import styled from "styled-components";
import { DetailSection } from "../../../../../../redux/store/score/scoreData";

/**
 * セクションブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const ElementDetailSection = (props: {
    detail: DetailSection;
}) => {
    // const { store } = useContext(GlobalContext);

    // const state = store.scoreState;
    // const sectionNo = state.elementCacheList[props.index].sectionNo;
    // const curSection = state.sectionCacheList[sectionNo];

    return (
        <_Wrap>
            {/* <_Label>{curSection.labelName}</_Label> */}
            <_Label>{props.detail.labelName}</_Label>
        </_Wrap>
    );
}

export default ElementDetailSection;

const _Wrap = styled.div`
    display: inline-block;
    height: 100%;
    position: relative;
    /* width: 100px; */
    text-align: center;
    border: 1px;
    /* border: 1px solid #c5c5cc; */
`;

const _Label = styled.div`
    display: block;
    position: relative;
    min-width: 80px;
    height: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #c00;
    text-align: center;
    line-height: 20px;
    /* background-color: #1000eb42; */
    margin-top: 13px;
`;