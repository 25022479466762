import { useContext } from "react";
import { Player } from "soundfont-player";
import styled, { css } from "styled-components";
import { _CSS_DISABLE } from "../../../../base/design/styles";
import BackingState from "../../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../../utils/backingUtil";
import { GlobalContext } from "../../../entry/systemEntry";

const ChannelItem = (props: {
    soundfont: Player | null;
    index: number;
    soundFullName: string;
}) => {
    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const pattern = state.pattern as BackingState.Pattern;
    const index = pattern.channels.length - 1 - props.index;
    const channelCount = pattern.channels.length;

    const update = () => {
        dispatcher.backing.setState(state);
        dispatcher.backing.updateErrorState();
    }
    const addRecord = () => {
        pattern.layers.forEach((layer) => {
            const initialCellArray = BackingUtil.getInitialRecord(layer.noteDivList.length);
            layer.table.splice(index, 0, initialCellArray);
        });
        // alert(pattern.layers[0].table.length);
        const structs = BackingUtil.buildVoicsFromTable(state.voicingTable as boolean[][]);
        pattern.channels = BackingUtil.getChannels(structs, pattern.channels.length + 1);
        pattern.channelIndex = -1;
        update();
    }

    const deleteRecord = () => {
        pattern.layers.forEach((layer) => {
            layer.table.splice(index, 1);
        });
        // alert(pattern.layers[0].table.length);
        const structs = BackingUtil.buildVoicsFromTable(state.voicingTable as boolean[][]);
        pattern.channels = BackingUtil.getChannels(structs, pattern.channels.length - 1);
        pattern.channelIndex = -1;
        update();
    }

    const preview = () => {
        if (props.soundfont != null) {
            props.soundfont.play(props.soundFullName, 0, { duration: 1 });
        }
    }

    return (<>
        <_Wrap isError={props.soundFullName === '-'}>
            <_Struct onClick={preview}>{props.soundFullName}</_Struct>
            <_Option isEnable={channelCount > 1} onClick={deleteRecord}>-</_Option>
            <_Option isEnable={channelCount < BackingUtil.CHANNEL_MAX} onClick={addRecord}>+</_Option>
        </_Wrap>
    </>);
}

export default ChannelItem;

const _Wrap = styled.div<{
    isError: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 19px;
    background-color: #b4b3b36f;
    ${props => !props.isError ? '' : css`
        background-color: #ff0000c3;
    `}
    margin: 0 0 1px 0;
`;

const _Item = styled.div`
    display: inline-block;
    height: 100%;
    margin: 0 1px 0 0;
    border: 1px solid #322e4292;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    &:hover {
        background-color: #f1ef866c;
    }
`;

const _Struct = styled(_Item)`
    width: 47px;

`;

const _Option = styled(_Item)<{
    isEnable: boolean;
}>`
    width: 25px;
    &:hover {
        background-color: #f1ef866c;
    }
    ${props => props.isEnable ? '' : _CSS_DISABLE}
    
`;