import { BeatSignature, Scale } from "../redux/store/score/scoreData";

namespace TheoryUtil {

    export const MAJOR_SCALE_INTERVALS = [0, 2, 4, 5, 7, 9, 11];
    export const MINOR_SCALE_INTERVALS = [0, 2, 3, 5, 7, 8, 10];

    export const KEY12_FLAT_LIST = [
        'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'
    ];
    export const KEY12_SHARP_LIST = [
        'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'
    ];

    export const DEGREE12_FLAT_LIST = [
        'Ⅰ', 'bⅡ', 'Ⅱ', 'bⅢ', 'Ⅲ', 'Ⅳ', 'bⅤ', 'Ⅴ', 'bⅥ', 'Ⅵ', 'bⅦ', 'Ⅶ'
    ];
    // export const DEGREE12_FLAT_LIST = [
    //     'I', 'bII', 'II', 'bIII', 'III', 'IV', 'bV', 'V', 'bVI', 'VI', 'bVII', 'VII'
    // ];
    export const DEGREE12_SHARP_LIST = [
        'Ⅰ', '#Ⅰ', 'Ⅱ', '#Ⅱ', 'Ⅲ', 'Ⅳ', '#Ⅳ', 'Ⅴ', '#Ⅴ', 'Ⅵ', '#Ⅵ', 'Ⅶ'
    ];

    export const KEY12_MAJOR_SCALE_LIST = [
        'C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'
    ];

    export const KEY12_MINOR_SCALE_LIST = [
        'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'
    ];

    export const getDisplayKeyScaleName = (keyIndex: number, scale: Scale) => {
        return scale === 'major' ? KEY12_MAJOR_SCALE_LIST[keyIndex] :
            KEY12_MINOR_SCALE_LIST[keyIndex] + 'm';
    };

    export const isFlat = (keyIndex: number, scale: Scale) => {
        const scaleName = scale === 'major' ? KEY12_MAJOR_SCALE_LIST[keyIndex] :
            KEY12_MINOR_SCALE_LIST[keyIndex];
        return scaleName.indexOf('b') !== -1;
    };

    export const SCALE_DEF_LIST = ['major', 'minor'] as const;
    export const BEAT_DEF_LIST = ['2/4', '4/4', '3/4', '6/8', '9/8', '12/8'] as const;

    export type IntervalRelation =
        'p1' | 'm2' | 'M2' | 'm3' | 'M3' | 'p4' |
        'd5' | 'p5' | 'a5' | 'm6' | 'M6' |
        'd7' | 'm7' | 'M7'
        ;

    export const isFlatFromRelation = (relation: IntervalRelation) => {
        // 以下の場合#（シャープ）とする
        switch (relation) {
            case 'M2':
            case 'M3':
            case 'M6':
            case 'M7':
                return false;
        }
        return true;
    }

    export const SymbolAttributeList = ["triad", "seventh", "add9th", "ninth"] as const;

    export type SymbolAttribute = typeof SymbolAttributeList[number];

    export type SymbolParams = {
        key: SymbolKey;
        name: string;
        base: null | SymbolKey;
        attr?: SymbolAttribute;
        structs: IntervalRelation[];
    }

    export type BeatProps = {
        // mol: number;
        // den: number;
        // 1拍のメモリ（16分音符）の数
        beatMemoriCount: number;
        // 1小節の拍数
        barBeatCnt: number;
    }

    /**
     * 拍子より拍情報を取得する
     * @param beatSignature 拍子
     * @returns 拍情報
     */
    export const getBeatProps = (beatSignature: BeatSignature): BeatProps => {
        switch (beatSignature) {
            case '4/4': return { beatMemoriCount: 4, barBeatCnt: 4 };
            case '2/4': return { beatMemoriCount: 4, barBeatCnt: 2 };
            case '3/4': return { beatMemoriCount: 4, barBeatCnt: 3 };
            case '6/8': return { beatMemoriCount: 6, barBeatCnt: 2 };
            case '9/8': return { beatMemoriCount: 6, barBeatCnt: 3 };
            case '12/8': return { beatMemoriCount: 6, barBeatCnt: 4 };
        };
        // return {} as BeatProps;
    }

    /**
     * 拍の長さからコードの持続時間をミリ秒で算出する
     * @param beatLen 
     * @param minute 
     * @param bpm 
     * @param beatSignature 
     * @returns 
     */
    export const calcSustainMsec = (beatLen: number, minute: number, bpm: number, beatSignature: BeatSignature): number => {
        const beatMemoriCount = getBeatProps(beatSignature).beatMemoriCount;
        const minuteLen = minute * (1 / beatMemoriCount);
        return 60000 / bpm * (beatLen + minuteLen);
    }


    export type ScaleRelation = 'diatonic' | 'secondaly' | 'none'

    export type SymbolKey =
        'major' |
        'minor' |
        'suspend4th' |
        'diminish' |
        'augument' |
        'major-maj7th' |
        'major-min7th' |
        'minor-7th' |
        'minor-maj7th' |
        'sus4-7th' |
        'diminish-7th' |
        'augument-7th' |
        'major-add9th' |
        'minor-add9th' |
        'sixth-9th' |
        'minor-minus5' |
        'major-minus5' |
        'minor7-minus5' |
        'major-maj9th' |
        'major-9th' |
        'sixth' |
        'minor-sixth'
        ;

    export type DegreeProps = {
        index: number;
        isFlat: boolean;
    };

    export type ChordProps = {
        root: DegreeProps;
        symbolKey: SymbolKey;
    };

    export const SYMBOL_LIST: readonly SymbolParams[] = [
        {
            key: 'major', name: '',
            base: null,
            structs: ['p1', 'M3', 'p5'],
            attr: 'triad'
        },
        {
            key: 'minor', name: 'm',
            base: null,
            structs: ['p1', 'm3', 'p5'],
            attr: 'triad'
        },
        {
            key: 'suspend4th', name: 'sus4',
            base: null,
            structs: ['p1', 'p4', 'p5'],
            attr: 'triad'
        },
        {
            key: 'diminish', name: 'dim',
            base: null,
            structs: ['p1', 'm3', 'd5'],
            attr: 'triad'
        },
        {
            key: 'diminish-7th', name: 'dim7',
            base: 'diminish',
            structs: ['p1', 'm3', 'd5', 'd7'],
            attr: 'seventh'
        },
        {
            key: 'augument', name: 'aug',
            base: null,
            structs: ['p1', 'M3', 'a5'],
            attr: 'triad'
        },
        {
            key: 'augument-7th', name: 'aug7',
            base: 'augument',
            structs: ['p1', 'M3', 'a5', 'm7']
        },
        {
            key: 'minor-minus5', name: 'm(-5)',
            base: null,
            structs: ['p1', 'm3', 'd5'],
            attr: 'triad'
        },
        {
            key: 'major-minus5', name: '-5',
            base: null,
            structs: ['p1', 'M3', 'd5'],
            attr: 'triad'
        },
        {
            key: 'minor7-minus5', name: 'm7(-5)',
            base: 'minor-minus5',
            structs: ['p1', 'm3', 'd5', 'm7'],
            attr: 'seventh'
        },
        {
            key: 'major-maj7th', name: 'maj7',
            base: 'major',
            structs: ['p1', 'M3', 'p5', 'M7'],
            attr: 'seventh'
        },
        {
            key: 'major-min7th', name: '7',
            base: 'major',
            structs: ['p1', 'M3', 'p5', 'm7'],
            attr: 'seventh'
        },
        {
            key: 'minor-7th', name: 'm7',
            base: 'minor',
            structs: ['p1', 'm3', 'p5', 'm7'],
            attr: 'seventh'
        },
        {
            key: 'sus4-7th', name: '7sus4',
            base: 'suspend4th',
            structs: ['p1', 'p4', 'p5', 'm7']
        },
        {
            key: 'minor-maj7th', name: 'mmaj7',
            base: 'minor',
            structs: ['p1', 'm3', 'p5', 'M7'],
            attr: 'seventh'
        },
        {
            key: 'major-add9th', name: 'add9',
            base: 'major',
            structs: ['p1', 'M2', 'M3', 'p5'],
            attr: 'add9th'
        },
        {
            key: 'minor-add9th', name: 'madd9',
            base: 'minor',
            structs: ['p1', 'M2', 'm3', 'p5'],
            attr: 'add9th'
        },
        {
            key: 'major-maj9th', name: 'maj9',
            base: 'major-maj7th',
            structs: ['p1', 'm3', 'p5'],
            attr: 'add9th'
        },
        {
            key: 'major-9th', name: '9',
            base: 'major-min7th',
            structs: ['p1', 'm3', 'p5']
        },
        {
            key: 'sixth', name: '6',
            base: 'major',
            structs: ['p1', 'M3', 'p5', 'M6']
        },
        {
            key: 'minor-sixth', name: 'm6',
            base: 'minor',
            structs: ['p1', 'm3', 'p5', 'M6']
        },
    ];

    /** インターバルの関係性 */
    export const IntervalRelationProps = {
        'p1': 0,
        'm2': 1,
        'M2': 2,
        'm3': 3,
        'M3': 4,
        'p4': 5,
        'd5': 6,
        'p5': 7,
        'a5': 8,
        'm6': 8,
        'M6': 9,
        'd7': 9,
        'm7': 10,
        'M7': 11
    }

    export const DIATONIC_MAJOR_SCALE_LIST: readonly ChordProps[] = [
        { root: { index: 0, isFlat: false }, symbolKey: 'major' },
        { root: { index: 2, isFlat: false }, symbolKey: 'minor' },
        { root: { index: 4, isFlat: false }, symbolKey: 'minor' },
        { root: { index: 5, isFlat: false }, symbolKey: 'major' },
        { root: { index: 7, isFlat: false }, symbolKey: 'major' },
        { root: { index: 9, isFlat: false }, symbolKey: 'minor' },
        { root: { index: 11, isFlat: false }, symbolKey: 'minor-minus5' },
    ];

    export const DIATONIC_MINOR_SCALE_LIST: readonly ChordProps[] = [
        { root: { index: 0, isFlat: false }, symbolKey: 'minor' },
        { root: { index: 2, isFlat: false }, symbolKey: 'minor-minus5' },
        { root: { index: 3, isFlat: true }, symbolKey: 'major' },
        { root: { index: 5, isFlat: false }, symbolKey: 'minor' },
        { root: { index: 7, isFlat: false }, symbolKey: 'minor' },
        { root: { index: 8, isFlat: true }, symbolKey: 'major' },
        { root: { index: 10, isFlat: true }, symbolKey: 'major' },
    ];



    export const DEGREE_ALL: readonly (DegreeProps | null)[] = [
        null,
        { index: 0, isFlat: false },
        { index: 1, isFlat: false },
        { index: 1, isFlat: true },
        { index: 2, isFlat: false },
        { index: 3, isFlat: false },
        { index: 3, isFlat: true },
        { index: 4, isFlat: false },
        { index: 5, isFlat: false },
        { index: 6, isFlat: false },
        { index: 6, isFlat: true },
        { index: 7, isFlat: false },
        { index: 8, isFlat: false },
        { index: 8, isFlat: true },
        { index: 9, isFlat: false },
        { index: 10, isFlat: false },
        { index: 10, isFlat: true },
        { index: 11, isFlat: false }
    ];

    export const getDegreeRoot = (props: DegreeProps) => {
        const list = props.isFlat ? DEGREE12_FLAT_LIST : DEGREE12_SHARP_LIST;
        return list[props.index];
    }

    export const DIATONIC_MAJOR_SCALE_DEGREE_NAMES = [
        'Ⅰ', 'Ⅰmaj7',
        'Ⅱm', 'Ⅱm7',
        'Ⅲm', 'Ⅲm7',
        'Ⅳ', 'Ⅳmaj7',
        'Ⅴ', 'Ⅴ7',
        'Ⅵm', 'Ⅵm7',
        'Ⅶm(-5)', 'Ⅶm7(-5)'
    ];

    export const DIATONIC_NATURAL_MINOR_SCALE_DEGREE_NAMES = [
        'Ⅰm', 'Ⅰm7',
        'Ⅱm(-5)', 'Ⅱm7(-5)',
        'bⅢ', 'bⅢM7',
        'Ⅳm', 'Ⅳm7',
        'Ⅴm', 'Ⅴm7',
        'bⅥ', 'bⅥM7',
        'bⅦ', 'bⅦ7'
    ];

    export const getDiatonicDegreeList = (scale: Scale) => {
        return scale === 'major' ? DIATONIC_MAJOR_SCALE_DEGREE_NAMES :
            DIATONIC_NATURAL_MINOR_SCALE_DEGREE_NAMES;
    }

    export const getRootName = (
        props: DegreeProps,
        baseKeyIndex: number
    ): string => {
        const list = props.isFlat ? TheoryUtil.KEY12_FLAT_LIST : TheoryUtil.KEY12_SHARP_LIST;
        return list[(baseKeyIndex + props.index) % 12];
    }



    export const getSymbolFromKey = (key: string): SymbolParams | undefined => {
        return SYMBOL_LIST.find((symbol) => {
            if (key === symbol.key) {
                return symbol;
            }
        });
        // return ret != undefined ? ret : SYMBOL_LIST[0];
    }

    export const getSymbolsFromAttr = (attr: SymbolAttribute): SymbolParams[] => {
        const list: SymbolParams[] = [];
        SYMBOL_LIST.forEach((symbol) => {
            if (attr === symbol.attr) {
                list.push(symbol);
            }
        });
        return list;
    }

    export const getSymbolDerives = (key: SymbolKey): SymbolKey[] => {
        let list: SymbolKey[] = [];
        SYMBOL_LIST.forEach((symbol) => {
            if (key === symbol.base) {
                list.push(symbol.key);
            }
        });
        return list;
    }

    export const getIntervalValues = (rootIndex: number, structs: IntervalRelation[]): number[] => {
        return structs.map(relation => rootIndex + IntervalRelationProps[relation]);
    }
}

export default TheoryUtil;

