import { useContext, useMemo } from "react";
import styled from "styled-components";
import { ELEMENT_NOTE_PADDING_LEN, ELEMENT_NOTE_PADDING_SID } from "../../../../utils/systemConst";
import { GlobalContext } from "../../../entry/systemEntry";
import ScoreElement from "./elements/scoreElement";


const HarmNote = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const chordList = store.scoreData.chordList;
    const cacheList = state.elementCacheList;

    // alert(`num=${num}, cache=${store.scoreState.elementCacheList.length}`);
    const list: JSX.Element[] = [];
    if (cacheList.length === chordList.length) {
        for (let i = 0; i < cacheList.length; i++) {
            list.push(
                <ScoreElement key={list.length}
                    index={i}
                    element={chordList[i]}
                    state={store.scoreState}
                    setState={dispatcher.score.setScoreState}
                />
            );
        };
    }
    return (
        <_Wrap>
            {list}
            {/* <_Frame> */}
            {/* <_Note>
                {list}
            </_Note>
            <_Note>
            </_Note> */}
            {/* </_Frame> */}
        </_Wrap>
    );
}

export default HarmNote;


const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    margin: 4px 0 0 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    /* background-color: #e7dddd; */
    background-color: #e9e9e9;
    border: 1px solid #b3b3b3;
    border-radius: 2px;
    padding: 15px;
    box-sizing: border-box;
    text-align: left;
    /* overflow: auto; */
`;

const _Frame = styled.div`
    transform-origin: top left;
    /* width: 100%;
    height: 100%; */
    transform: scale(1);
`;

const _Note = styled.div`
    display: inline-block;
    /* position: relative; */
    padding: ${ELEMENT_NOTE_PADDING_LEN}px ${ELEMENT_NOTE_PADDING_SID}px;
    width: ${891}px;
    height: ${1260}px;
    background-color: #d8d8d8;
    /* overflow: hidden; */
    margin: 10px 0 0 10px;
    box-shadow: 0 0 18px 0 #000b3a48;
`;