import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../../entry/systemEntry";
import Soundfont from 'soundfont-player';
import { SoundfontInfo } from "../../../../../redux/store/score/melodyState";


const InstrumentSwitch = (props: {
    labelName: string;
    icon: JSX.Element;
    soundfontInfo: SoundfontInfo;
}) => {
    const { store, dispatcher } = useContext(GlobalContext);
    const state = store.melodyState;

    return (
        <_Wrap
            isActive={props.soundfontInfo.name === state.soundfontInfo.name}
            onClick={() => {
                // state.quantize = props.value;
                // dispatcher.tune.setState(state);

                state.soundfontInfo = props.soundfontInfo;
                state.isPreview = true;
                dispatcher.melody.setState(state);

                const info = props.soundfontInfo;
                Soundfont.instrument(new AudioContext(), info.name,
                    { gain: info.gain, release: 0.5 }).then((soundFontPlayer) => {
                        const instruments = store.instruments;
                        instruments.melodyFont = soundFontPlayer;
                        dispatcher.score.setInstruments(instruments);
                    }).then(() => {
                        state.isPreview = false;
                        dispatcher.melody.setState(state);
                    });
            }}
        >
            <_Icon>{props.icon}</_Icon>
            <_Label>{props.labelName}</_Label>
        </_Wrap>
    );
}

export default InstrumentSwitch;

const _Wrap = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    width: 85px;
    height: 40px;
    text-align: center;
    border: 1px solid #4b4b4b;
    background-color: #cacacada;
    ${props => !props.isActive ? '' : css`
        background-color: #f7f7f7da;
    `}
    border-radius: 6px;
    margin-left: 4px;
    box-sizing: border-box;

    &:hover {
        background-color: #e4e4e4da;
        ${props => !props.isActive ? '' : css`
            background-color: #f7f7f7da;
        `}
    }
`;


const _Icon = styled.div`
    display: inline-block;
    width: 100%;
    height: 30px;
    /* background-color: #ff0000da; */
    font-size: 20px;
    color: #4b4b4b;
    text-align: center;
    /* padding-top: 5px; */
    box-sizing: border-box;
`;

const _Label = styled.div`
    display: inline-block;
    width: 100%;
    height: 10px;
    /* background-color: #0400ffdc; */
    font-size: 16px;
    font-weight: 600;
    line-height: 0;
    color: #3b3b3b;
    text-align: center;
`;