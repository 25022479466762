import styled from "styled-components";
import { DetailInit } from "../../../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../../../utils/theoryUtil";

/**
 * イニットブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const ElementDetailInit = (props: {
    detail: DetailInit;
}) => {

    return (
        <_Wrap>
            <_Record>
                <_Title>Scale:</_Title>
                <_Value>{props.detail.scale}</_Value>
            </_Record>
            <_Record>
                <_Title>Key:</_Title>
                <_Value>{TheoryUtil.KEY12_MAJOR_SCALE_LIST[props.detail.keyIndex]}</_Value>
            </_Record>
            <_Record>
                <_Title>tempo:</_Title>
                <_Value>{props.detail.bpm}</_Value>
            </_Record>
            <_Record>
                <_Title>Rate:</_Title>
                <_Value>{props.detail.beatSignature}</_Value>
            </_Record>
        </_Wrap>
    );
}

export default ElementDetailInit;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100px;
`;

const _Record = styled.div`
    display: inline-block;
    position: relative;
    width: 100px;
    height: 16px;
    margin: 1px 0;
    /* background-color: #0066ff16; */
    /* border-bottom: 1px solid #000; */
    font-size: 12px;
    font-weight: 600;
    color: #2a2a2c;
    line-height: 16px;
`;
const _Title = styled.div`
    display: inline-block;
    margin: 0 2px;
    width: 50px;
    height: 100%;
    color: #14a800;
    /* background-color: #ff00b329; */
`;
const _Value = styled.div`
    display: inline-block;
    margin: 0 2px;
    width: 40px;
    height: 100%;
    color: #000000;
    /* background-color: #ff00b329; */
`;
