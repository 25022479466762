import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { Store } from "../../../../../redux/store/store";
import { KeyboardEvent } from "react";
import BackingState from "../../../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../../../utils/backingUtil";
import { ChordBacking } from "../../../../../redux/store/score/scoreData";
import DataUtil from "../../../../../utils/dataUtil";
import { NoteKeySwitch } from "../../../../../redux/store/score/scoreState";

namespace BackingEditorInput {

    export const main = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher) => {

        const config = store.userEnv.keyConfig.note;
        const keySwitch = store.scoreState.keySwitch;
        // alert(e.key);
        // alert(config.nextElement);

        const data = store.scoreData;
        const state = store.backingState;

        if (state == null || state.tabIndex === 1) return;

        if (!state.isPreview) {
            edit(e, state, keySwitch, dispatcher);
        } else {

            switch (e.key) {
                case ' ': {
                    BackingUtil.stopPreview(store, state, dispatcher, store.reserveTasks);
                }
                    break;
            }
        }
    }

    const edit = (e: KeyboardEvent, state: BackingState.Editor, keySwitch: NoteKeySwitch, dispatcher: Dispatcher) => {

        const pattern = state.pattern;

        // 共通処理
        common(e, state, dispatcher);

        if (pattern != null) {
            if (pattern.channelIndex === -1) {
                patternNormalMode(e, state, pattern, dispatcher);
            } else {
                patternDetailMode(e, state, pattern, keySwitch.sustainMode, dispatcher);
            }
        }
    }

    const common = (e: KeyboardEvent, state: BackingState.Editor, dispatcher: Dispatcher) => {

        switch (e.key) {
            case 'b': {
                // dispatcher.backing.closeBackingEditor();
                state.closeAction(state, false);
            } break;
            case 'z': {
                const voicingList = BackingUtil.getVoicingListFromTable(state.voicingTable);
                const pattern = state.pattern == null ? null : BackingUtil.buildPatternProps(state.pattern);
                const test: ChordBacking = { voicingList, pattern };
                console.log('voicingList: ' + DataUtil.gZip(JSON.stringify(voicingList)));
                console.log('pattern: ' + DataUtil.gZip(JSON.stringify(pattern)));
                console.log('source: ' + DataUtil.gZip(JSON.stringify(test)));
                console.log('source: ' + JSON.stringify(test));
            } break;
            case ' ': {
                if (!state.hasError) {
                    dispatcher.backing.testBacking();
                }
            } break;
            case 'Enter': {
                // dispatcher.backing.mappingEditorToScore();
                // dispatcher.backing.closeBackingEditor();
                state.closeAction(state, true);
            } break;
        }
    }

    /**
     * パターン編集の通常モード
     * @param e 
     * @param state 
     * @param pattern 
     * @param dispatcher 
     */
    const patternNormalMode = (e: KeyboardEvent, state: BackingState.Editor, pattern: BackingState.Pattern, dispatcher: Dispatcher) => {

        const update = () => dispatcher.backing.setState(state);
        switch (e.key) {
            case 'ArrowLeft': {
                dispatcher.backing.movePatternColumn(false);
            } break;
            case 'ArrowRight': {
                dispatcher.backing.movePatternColumn(true);
            } break;
            case 'ArrowUp': {
                dispatcher.backing.incrementVel(1);
            } break;
            case 'ArrowDown': {
                dispatcher.backing.incrementVel(-1);
            } break;
            case 'a': {
                dispatcher.backing.addPatternColumn();
            } break;
            case 'w': {
                dispatcher.backing.switchLayer();
            } break;
            case 'e': {
                dispatcher.backing.switchMode();
            } break;
            case '1': {
                dispatcher.backing.setCurrentDivRate(16);
            } break;
            case '2': {
                dispatcher.backing.setCurrentDivRate(8);
            } break;
            case '3': {
                dispatcher.backing.setCurrentDivRate(4);
            } break;
            case '4': {
                dispatcher.backing.setCurrentDivRate(2);
            } break;
            case '5': {
                dispatcher.backing.setCurrentDivRate(1);
            } break;
            case '.': {
                dispatcher.backing.toggleCurrentDivHalf();
            } break;
            case 'Delete': {
                dispatcher.backing.deleteColumn();
            } break;
            case 's': {
                pattern.channelIndex = 0;
                update();
            } break;
        }
    }

    /**
     * パターン編集の詳細モード
     * @param e 
     * @param state 
     * @param pattern 
     * @param isEdit 
     * @param dispatcher 
     */
    const patternDetailMode = (e: KeyboardEvent, state: BackingState.Editor, pattern: BackingState.Pattern, isEdit: boolean, dispatcher: Dispatcher) => {

        const update = () => dispatcher.backing.setState(state);
        if (!isEdit) {
            switch (e.key) {
                case 'ArrowLeft': {
                    dispatcher.backing.movePatternColumn(false);
                }
                    break;
                case 'ArrowRight': {
                    dispatcher.backing.movePatternColumn(true);
                }
                    break;
                case 'ArrowUp': {
                    dispatcher.backing.moveChannelIndex(false);
                }
                    break;
                case 'ArrowDown': {
                    dispatcher.backing.moveChannelIndex(true);
                }
                    break;
                case 's': {
                    pattern.channelIndex = -1;
                    update();
                }
                    break;
                case 'a': {
                    const curNotes = dispatcher.backing.getNotes();

                    const hasStaccato = () => {
                        const layer = pattern.layers[pattern.layerIndex];
                        const search = layer.table.find((record) => {
                            const cell = record[pattern.lenIndex];
                            if (cell != null && cell.signal === 's') return cell;
                        });
                        return search != undefined;
                    }
                    // 同列にスタッカートがある場合、スタッカートを設定
                    const signal = !hasStaccato() ? 'n' : 's';
                    const notes: BackingState.NoteStatus | null = curNotes == null ? { signal, timing: 0, velAdj: 0 } : null;
                    dispatcher.backing.setNotes(notes);
                    dispatcher.backing.updateErrorState();
                }
                    break;
            }
        } else {

            const layer = pattern.layers[pattern.layerIndex];
            const baseVel = layer.velocityList[pattern.lenIndex];
            const channelIndex = (pattern.channels.length - 1) - pattern.channelIndex;
            const cell = layer.table[channelIndex][pattern.lenIndex];

            if (cell != null) {
                let tempTiming = cell.timing == undefined ? 0 : cell.timing;
                let tempVelAdj = cell.velAdj == undefined ? 0 : cell.velAdj;
                const vel = baseVel + tempVelAdj;
                switch (e.key) {
                    case 'ArrowUp': {
                        if (vel < 10) {
                            tempVelAdj++;
                        }
                    }
                        break;
                    case 'ArrowDown': {
                        if (vel > 1) {
                            tempVelAdj--;
                        }
                    }
                        break;
                    case 'ArrowLeft': {
                        if (tempTiming > -10) {
                            tempTiming--;
                        }
                    }
                        break;
                    case 'ArrowRight': {
                        if (tempTiming < 10) {
                            tempTiming++;
                        }
                    }
                        break;
                }
                if (tempVelAdj !== 0) {
                    cell.velAdj = tempVelAdj;
                } else {
                    delete cell.velAdj;
                }
                if (tempTiming != 0) {
                    cell.timing = tempTiming;
                } else {
                    delete cell.timing;
                }
                update();
            }
        }
    }
}
export default BackingEditorInput;