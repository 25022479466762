import { createContext, useCallback, useEffect, useReducer } from 'react';
import styled, { css } from 'styled-components';
import { ExecutableButton } from '../../base/components/button/executableButton';
import { DraggableDialog } from '../../base/components/dialog/draggableDialog';
import { Dispatcher } from '../../redux/dispatcher/dispatcher';
import { reducer } from '../../redux/reducer';
import { initialStore, Store } from '../../redux/store/store';
import { MAIN_FRAME_MARGIN, TOTAL_HEADER_WIDTH } from '../../utils/systemConst';
import GloballDialog from '../dialog/globalDialog';
import MainFrame from './mainFrame';
import Splash from './splash';
import TotalHeader from './totalHeader';


type GlobalContextType = {
    store: Store;
    dispatcher: Dispatcher;
}

export const GlobalContext = createContext({} as GlobalContextType);

const SystemEntry = () => {

    const [store, dispatch] = useReducer(reducer, initialStore);
    const dispatcher = new Dispatcher(store, dispatch);
    const callback = useCallback(dispatcher.system.switchSplashToMain, []);
    return (
        <GlobalContext.Provider value={{ store, dispatcher }}>
            <>
                {!store.systemState.isSplash ? <></> :
                    <Splash
                        remove={callback} /
                    >}
                <_Wrap>
                    <GloballDialog />
                    <TotalHeader />
                    <_MainDiv>
                        <MainFrame />
                    </_MainDiv>
                </_Wrap>
            </>
        </GlobalContext.Provider>
    );
}

export default SystemEntry;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #d8d8d8;
`;

const _MainDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: calc(100% - ${TOTAL_HEADER_WIDTH}px);
    /* background-color: #c2c2c2; */
`;