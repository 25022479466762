import styled from "styled-components";
import { DetailAlter } from "../../../../../../redux/store/score/scoreData";

/**
 * アルターブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const ElementDetailAlter = (props: {
    detail: DetailAlter;
}) => {

    const type = props.detail.alterType;

    const getDetailInfo = (): string => {
        let ret = '-';
        if (type != null) {
            switch (type) {
                case 'modulate': {

                    const modRel = props.detail.modRel;
                    if (modRel != null) {
                        switch (modRel) {
                            case 'domm': {
                                const val = props.detail.dommVal;
                                const sign = val > 0 ? '+' : ''; 
                                ret = `${modRel}: ${sign}${val}`;
                            } break;
                            case 'relative':
                            case 'parallel': {
                                ret = modRel;
                            } break;
                            case 'key': {
                                const val = props.detail.keyAdj;
                                const sign = val > 0 ? '+' : ''; 
                                ret = `${modRel}: ${sign}${val}`;
                            } break;
                        }
                    }
                } break;
                case 'tempo' : {
                    const tempoRel = props.detail.tempoRel;
                    switch(tempoRel) {
                        case 'diff': {
                            const val = props.detail.tempoDiff;
                            const sign = val > 0 ? '+' : ''; 
                            ret = `${tempoRel}: ${sign}${val}`;
                        } break;
                        case 'rate': {
                            ret = `${tempoRel}: ${props.detail.tempoRate}%`;
                        } break;
                        case 'abs': {
                            ret = `${tempoRel}: ${props.detail.tempoAbs}`;
                        } break;
                    }
                }
            }
        }
        return ret;
    };

    return (
        <_Wrap>
            <_Type>
                {type == null ? 'alter' : type}
            </_Type>
            <_Detail>
                {getDetailInfo()}
            </_Detail>
        </_Wrap>
    );
}

export default ElementDetailAlter;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    /* background-color: red; */
`;

const _Label = styled.div`
    display: block;
    position: relative;
    min-width: 100px;
    height: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    line-height: 20px;
    /* background-color: #1000eb42; */
    margin-top: 3px;
`;

const _Type = styled(_Label)`
    color: #0014cc;
`;

const _Detail = styled(_Label)`
    color: #5e5e5e;
`;