import { symbolName } from "typescript";
import { Dispatcher } from "../../redux/dispatcher/dispatcher";
import BackingState from "../../redux/store/score/backing/backingState";
import ScoreCache from "../../redux/store/score/scoreCache";
import { ChordElement, DetailAlter, DetailChord, DetailFixed, DetailInit, DetailSection } from "../../redux/store/score/scoreData";
import { Store } from "../../redux/store/store";
import BackingUtil from "../backingUtil";
import TheoryUtil from "../theoryUtil";

/**
 * データを高速で参照するために、
 * 事前に計算したデータを保持するためのユーティリティを提供する
 */
namespace CacheUtil {

    /**
     * ハーモナイズのキャッシュを生成する
     * @param store ストア
     * @param dispatcher ディスパッチャー
     */
    export const updateHarmonizeCache = (store: Store, dispatcher: Dispatcher) => {
        const chordList = store.scoreData.chordList;
        const scoreState = store.scoreState;
        const init = (chordList[0].detail as DetailInit);

        const elementCacheList: ScoreCache.Element[] = [];
        const sectionCacheList: ScoreCache.Section[] = [];
        const beatCacheList: ScoreCache.BeatRange[] = [];
        const scaleCacheList: ScoreCache.KeyScaleRange[] = [];
        const tempoCacheList: ScoreCache.TempoRange[] = [];
        const measureCacheList: ScoreCache.Measure[] = [];

        // 初期値情報
        let keyIndex = init.keyIndex;
        let scale = init.scale;
        let bpm = init.bpm;

        let totalMinute = 0;
        let sectionNo = -1;
        let sectionBeatSum = 0;
        let pastSecond = 0;
        let curSecond = 0;
        let chordIndex = -1;
        // let curTotalBeat = 0;
        let curMemoriCount = 0;
        let totalBarCount = 0;

        // 経過した16分音符のカウント
        let totalMemoriCount = 0;
        let beatRangeMemoriCount = 0;
        let totalBeat = 0;
        let totalMemoriBarCount = 0;

        let chordBlockNo = -1;

        //拍キャッシュの産出に使う変数
        let beatSignature = init.beatSignature;

        let scaleMemoriCount = 0;
        let tempoMemoriCount = 0;

        const addScaleRange = () => {
            scaleCacheList.push({
                keyIndex,
                scale,
                memoriCnt: scaleMemoriCount
            });
            scaleMemoriCount = 0;
        }
        const addTempoRange = () => {
            tempoCacheList.push({
                bpm,
                beatRate: beatProps.beatMemoriCount / 4,
                memoriCnt: tempoMemoriCount
            });
            tempoMemoriCount = 0;
        }


        let beatProps = TheoryUtil.getBeatProps(beatSignature);
        chordList.forEach((element, i) => {

            const prevBeat = totalBeat;
            // ブロック頭のメモリ
            const prevMemoriCount = totalMemoriCount;
            // ブロック頭の小節
            const prevBarCount = totalBarCount;
            const prevMinute = totalMinute;
            const type = element.type;

            switch (type) {
                // 固定要素ブロック
                case 'fixed': {
                    const detail = element.detail as DetailFixed;
                    if (detail.fixed === 'End') {
                        pastSecond += curSecond;
                    }
                } break;

                // セクション要素ブロック
                case 'section': {
                    const detail = element.detail as DetailSection;
                    sectionCacheList.push({
                        labelName: detail.labelName,
                        beatSum: 0,
                        rangeIndexStart: i,
                        rangeIndexEnd: chordList.length - 2
                    });
                    sectionNo++;
                    // 2つ目以降のセクションの開始では、1つ前のセクションのEndを設定する
                    if (sectionNo > 0) {
                        sectionCacheList[sectionNo - 1].rangeIndexEnd = i - 1;
                        sectionCacheList[sectionNo - 1].beatSum = sectionBeatSum;
                        sectionBeatSum = 0;
                    }
                } break;

                // コード要素ブロック
                case 'chord': {
                    pastSecond += curSecond;
                    // curTotalBeat += curSustain;

                    const detail = element.detail as DetailChord;
                    sectionBeatSum += detail.beatLen;

                    const minute = detail.minute.head + detail.minute.tail;
                    totalMinute += minute;
                    chordIndex++;

                    curMemoriCount = detail.beatLen * beatProps.beatMemoriCount + minute;

                    const prevBar = Math.floor(totalBeat / beatProps.barBeatCnt);
                    totalBeat += detail.beatLen;
                    const curBar = Math.floor(totalBeat / beatProps.barBeatCnt);
                    // 小節が変わった場合、合計小節をインクリメント
                    if (prevBar !== curBar) {
                        totalBarCount++
                    }

                    // スケールキャッシュの長さを加算
                    scaleMemoriCount += curMemoriCount;
                    tempoMemoriCount += curMemoriCount;

                    // 時間の計算
                    curSecond = TheoryUtil.calcSustainMsec(
                        detail.beatLen,
                        minute,
                        bpm,
                        beatSignature
                    );

                    // メモリの加算
                    for (let j = 0; j < curMemoriCount; j++) {
                        let rate: ScoreCache.MeasureRate = 16;
                        if (beatRangeMemoriCount % beatProps.beatMemoriCount === 0) rate = 4;
                        else if (beatRangeMemoriCount % (beatProps.beatMemoriCount / (beatProps.beatMemoriCount / 2)) === 0) rate = 8;

                        const barMemoriCount = beatProps.barBeatCnt * beatProps.beatMemoriCount;

                        let dispBarCount = -1;
                        if (beatRangeMemoriCount % barMemoriCount === 0) {
                            // 拍子セクションの合計メモリ数が小節のメモリ数で割り切れる場合、小節数を表示する
                            totalMemoriBarCount++;
                            dispBarCount = totalMemoriBarCount;
                        }
                        measureCacheList.push({ rate, barCount: dispBarCount });
                        totalMemoriCount++;
                        beatRangeMemoriCount++;
                    }

                    // コードブロック連番の設定
                    chordBlockNo++;
                } break;

                // アルター要素ブロックする
                case 'alter': {
                    const detail = element.detail as DetailAlter;
                    switch (detail.alterType) {
                        // 音階が変わる
                        case 'modulate': {
                            addScaleRange();
                            switch (detail.modRel) {
                                case 'domm': {
                                    keyIndex = (keyIndex + 24 + 7 * detail.dommVal) % 12;
                                } break;
                                case 'key': {
                                    keyIndex = (keyIndex + 12 + detail.keyAdj) % 12;
                                } break;
                                case 'parallel': {
                                    keyIndex = (keyIndex + 12 + (scale === 'major' ? -1 : 1) * 3) % 12;
                                    // スケールを逆転する
                                    scale = scale === 'major' ? 'minor' : 'major';
                                } break;
                                case 'relative': {
                                    // スケールを逆転する
                                    scale = scale === 'major' ? 'minor' : 'major';
                                } break;
                            }
                        } break;
                        // テンポが変わる
                        case 'tempo': {
                            addTempoRange();
                            switch (detail.tempoRel) {
                                case 'diff': {
                                    bpm += detail.tempoDiff;
                                } break;
                                case 'rate': {
                                    bpm = Math.floor(bpm * 1 * (detail.tempoRate / 100));
                                } break;
                                case 'abs': {
                                    bpm = detail.tempoAbs;
                                } break;
                            }
                        } break;
                        // 拍子が変わる
                        case 'beat': {
                            addTempoRange();
                            // const prevBeatPos = beatCacheList.map(cache => cache.length).reduce((total, len) => total + len, 0);
                            beatCacheList.push({
                                beatSignature,
                                memoriCnt: beatRangeMemoriCount
                            });

                            beatSignature = detail.beatSignature;
                            // 拍を初期化する（拍変更後はいかなる場合も0から数える）
                            totalBeat = 0;
                            beatRangeMemoriCount = 0;

                            beatProps = TheoryUtil.getBeatProps(beatSignature);
                        } break;
                    }
                }
                    break;
            }

            elementCacheList.push({
                bar: prevBarCount,
                surplusBeat: prevBeat % beatProps.barBeatCnt,
                minute: prevMinute,
                keyIndex,
                scale,
                bpm,
                beatSignature,
                sectionNo,
                pastSecond,
                chordIndex,
                curTotalMemori: prevMemoriCount,
                // chordFullName,
                // structList,
                chordBlockNo,
                reffers: {}
            });
        });
        sectionCacheList[sectionNo].beatSum = sectionBeatSum;

        // const prevBeatPos = beatCacheList.map(cache => cache.length).reduce((total, len) => total + len, 0);
        scaleCacheList.push({
            keyIndex,
            scale,
            memoriCnt: scaleMemoriCount
        });

        beatCacheList.push({
            beatSignature,
            memoriCnt: beatRangeMemoriCount
        });
        addTempoRange();

        scoreState.elementCacheList = elementCacheList;
        scoreState.sectionCacheList = sectionCacheList;
        scoreState.beatCacheList = beatCacheList;
        scoreState.tempoCacheList = tempoCacheList;
        scoreState.scaleCacheList = scaleCacheList;
        scoreState.measureCacheList = measureCacheList;

        scoreState.chordCacheList = createChordCacheList(chordList, elementCacheList);

        dispatcher.score.setScoreState(scoreState);
    }

    export const createChordCacheList = (chordList: ChordElement[], elementCacheList: ScoreCache.Element[]) => {

        const chordCacheList: ScoreCache.Chord[] = [];

        chordList.forEach((element, i) => {

            const type = element.type;
            if (type === 'chord') {
                const detail = element.detail as DetailChord;
                const cache = elementCacheList[i];


                const keyIndex = cache.keyIndex;
                const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                const [chordFullName, structList] = detail.root != null ? BackingUtil.getChordDetails(
                    detail.root,
                    keyIndex,
                    detail.on,
                    symbol
                ) : ['', []];

                const backingError = BackingUtil.hasBackingError(detail, cache.beatSignature, structList.length);

                chordCacheList.push({
                    structList,
                    chordFullName,
                    backingError
                });

            }
        });
        return chordCacheList;
    }

    // export const getChordCache = (focusIndex: number, ) => {

    // }


    // export const updateMelodyCache = (store: Store, dispatcher: Dispatcher) => {

    //     const notesList = store.scoreData.notesList;
    //     const state = store.melodyState;

    //     const notesCacheList: ScoreCache.NotesCache[] = [];

    //     notesList.forEach((notes) => {
    //         const startTime = 0;
    //         const duration = 0;
    //         notesCacheList.push({
    //             startTime,
    //             duration
    //         });
    //     });
    // }

    export const getKeyScaleFromNotesPos = (pos: number, scaleCacheList: ScoreCache.KeyScaleRange[]) => {

        const curMemori = pos * 4;
        let tail = 0;
        let range: ScoreCache.KeyScaleRange | null = null;
        // console.log(`count: ${scaleCacheList.length}`);
        for (let i = 0; i < scaleCacheList.length; i++) {
            const info = scaleCacheList[i];

            if (curMemori < tail) {
                range = scaleCacheList[i - 1];
                break;
            }
            tail += info.memoriCnt;
            // console.log(`curPos: ${tail}`);
        }
        // console.log(`curMemori: ${curMemori}`);
        return range === null ? scaleCacheList[scaleCacheList.length - 1] : range;
    }

    /**
     * ノーツを再生する開始時間と持続時間を産出して返す
     * @param pos ノーツの位置
     * @param len ノーツの長さ
     * @param tempoCacheList テンポのキャッシュリスト
     * @returns 
     */
    export const getTimeInfoFromNotesPos = (pos: number, len: number, tempoCacheList: ScoreCache.TempoRange[]): [number, number] => {

        const curMemori = pos * 4;
        let tail = 0;
        let posTime = 0;
        let pastLen = 0;
        let last: ScoreCache.TempoRange | null = null;
        for (let i = 0; i < tempoCacheList.length; i++) {
            const info = tempoCacheList[i];

            if (curMemori < tail) {
                last = tempoCacheList[i - 1];
                break;
            }
            const beatLen = info.memoriCnt / 4;
            posTime += 60 / info.bpm * beatLen;
            pastLen += beatLen;
            tail += info.memoriCnt;
        }
        if (last === null) {
            last = tempoCacheList[tempoCacheList.length - 1];
        }

        const beatLen = (pos - pastLen) * last.beatRate;
        posTime += 60 / last.bpm * beatLen;

        const lenTime = 60 / last.bpm * len * last.beatRate;

        return [posTime, lenTime];
    }
}

export default CacheUtil;
