import styled from "styled-components";
import FormUtil from "../../utils/form/formUtiil";
import { GlobalContext } from "../entry/systemEntry";
import { useContext } from "react";

namespace ConfirmDialog {

    export const Component = (props: {
        message: string;
        width?: number;
        height?: number;
        buttons?: FormUtil.ButtonProps[];
    }) => {

        const { dispatcher } = useContext(GlobalContext);

        const width = props.width ?? 600;
        const height = props.height ?? 150;
        const buttons = props.buttons ?? [
            {
                label: 'OK',
                callback: () => {
                    dispatcher.system.closeDialog();
                }
            }
        ];

        return (
            <_Frame width={width} height={height}>
                <_Main><_Inner>
                    {props.message.split('\n').map((msg, i) => {
                        return <FormUtil.Message
                            key={i}
                            message={msg}
                            isCenter
                        />
                    })}
                </_Inner></_Main>
                <FormUtil.ButtonRecord
                    isCenter
                    buttons={buttons} />
            </_Frame>
        );
    }
}

export default ConfirmDialog;

const _Frame = styled.div<{
    width: number;
    height: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: calc(50% - ${props => props.height / 2}px);
    left: calc(50% - ${props => props.width / 2}px);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: #e7e7e7e2;
    border: 1px solid #8f8f8f;
    border-radius: 4px;
    box-shadow: 10px 15px 15px #0000004b;
`;

const _Main = styled.div<{}>`
    display: inline-block;
    width: 100%;
    height: 110px;
    display: flex;
    align-items: center;

`;

const _Inner = styled.div<{}>`
    /* text-align: center; */
    display: inline-block;
    width: 100%;
    /* background-color: #bb6868e1; */
`;