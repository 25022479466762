import { useContext } from "react";
import styled from "styled-components";
import BackingState from "../../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../../utils/backingUtil";
import { NOTES_1BAR_WIDTH, PATTERN_BODY_HEIGHT, PATTERN_FOOTER_HEIGHT, PATTERN_HEADER_HEIGHT } from "../../../../utils/systemConst";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";
import PatternDetail from "./patternDetail";
import PatternLength from "./patternLength";
import PatternPedal from "./patternPedal";
import PatternTable from "./patternTable";
import PatternVelocity from "./patternVelocity";

const PatternBuilder = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const chordInfo = state.chordInfo;

    const pattern = state.pattern as BackingState.Pattern;

    const layer = pattern.layers[pattern.layerIndex];
    const divs = layer.noteDivList;
    const limitLen = BackingUtil.getLimitLen(chordInfo.beatLen, chordInfo.minute, chordInfo.init.beatSignature);
    const useTotalLen = BackingUtil.getUsingLenFromDivs(divs, chordInfo.init.beatSignature);
    const isLenOverError = 0 <= limitLen - useTotalLen;

    const beatProps = TheoryUtil.getBeatProps(chordInfo.init.beatSignature);
    const viewRate = NOTES_1BAR_WIDTH / 4;

    // メモリの数を算出
    let memoriCount = chordInfo.beatLen * beatProps.beatMemoriCount;
    memoriCount += chordInfo.minute.head + chordInfo.minute.tail;

    const barMemoriCount = beatProps.beatMemoriCount === 4 ? 16 : 24;
    const memoriWidth = NOTES_1BAR_WIDTH / barMemoriCount;
    // メモリJSXの生成
    const memoriJsxList: JSX.Element[] = [];
    for (let i = 0; i < memoriCount + 1; i++) {
        const pos = i;
        let bean: { thickness: number, height: number, color: string } = { thickness: 1, height: 10, color: '#555' };
        if (beatProps.beatMemoriCount === 4) {
            switch (true) {
                case pos % 16 === 0: bean = { thickness: 2, height: 22, color: '#747474' }; break;
                case pos % 4 === 0: bean = { thickness: 2, height: 18, color: '#646464' }; break;
                case pos % 2 === 0: bean = { thickness: 1, height: 14, color: '#7a7a7a' }; break;
            }
        } else {
            switch (true) {
                case pos % 24 === 0: bean = { thickness: 2, height: 22, color: '#747474' }; break;
                case pos % 6 === 0: bean = { thickness: 2, height: 18, color: '#646464' }; break;
                case pos % 2 === 0: bean = { thickness: 1, height: 14, color: '#7a7a7a' }; break;
            }
        }
        memoriJsxList.push(
            <_Memori
                key={memoriJsxList.length}
                width={memoriWidth}
                height={bean.height}
                color={bean.color}
                thickness={bean.thickness}
            />
        );
    }

    return (<>
        <_Header>
            <_LengthDiv>
                <PatternLength />
            </_LengthDiv>
            <_MeasureDiv>
                <_MemoriList>{memoriJsxList}</_MemoriList>
                {isLenOverError ? (
                    <_ChordLenLine pos={limitLen * viewRate} />
                ) : (
                    // 定義した拍を超えた場合のみ表示
                    <_OverCover pos={limitLen * viewRate} width={(useTotalLen - limitLen) * viewRate} />
                )}
            </_MeasureDiv>
        </_Header>
        <_Body>
            <PatternTable />
        </_Body>
        <_Footer>
            {pattern.channelIndex === -1 ?
                // ベロシティorペダル情報を表示
                (pattern.mode === 'velocity' ? <PatternVelocity /> : <PatternPedal />) :
                // セルごとの詳細情報を表示
                <PatternDetail />
            }
        </_Footer>
    </>);
}

export default PatternBuilder;

const _Header = styled.div`
    display: inline-block;
    width: 100%;
    height: ${PATTERN_HEADER_HEIGHT}px;
    background-color: #9292bdd6;
`;
const _LengthDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: ${PATTERN_HEADER_HEIGHT / 2}px;
    background-color: #9292bdd6;
`;
const _MeasureDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${PATTERN_HEADER_HEIGHT / 2}px;
    background-color: #b492bdd5;
    background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(98, 97, 114));
`;

const _MemoriList = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;

const _Memori = styled.div<{
    width: number;
    height: number;
    color: string;
    thickness: number;
}>`
    display: inline-block;
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border-left: solid ${props => props.thickness}px ${props => props.color};
    box-sizing: border-box;
`;

const _OverCover = styled.div<{
    pos: number;
    width: number;
}>`
    position: absolute;
    z-index: 10;
    left: ${props => props.pos}px;
    width: ${props => props.width}px;
    height: ${PATTERN_HEADER_HEIGHT / 2 + PATTERN_BODY_HEIGHT}px;
    background-color: #f8000053;
    pointer-events: none;
`;
const _Body = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${PATTERN_BODY_HEIGHT}px;
    background-color: #97d3cec8;
`;
const _Footer = styled.div`
    display: inline-block;
    width: 100%;
    height: ${PATTERN_FOOTER_HEIGHT}px;
    background-color: #898997cc;
`;

const _ChordLenLine = styled.div<{
    pos: number;
}>`
    position: absolute;
    z-index: 10;
    left: ${props => props.pos}px;
    width: 4px;
    height: ${PATTERN_HEADER_HEIGHT / 2 + PATTERN_BODY_HEIGHT}px;
    background-color: #ffffffc7;
`;