import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { DetailChord, MelodyNotes } from "../../../../../redux/store/score/scoreData";
import { Store } from "../../../../../redux/store/store";
import { getNormalizeLenFromNotes, getNotesLenFromQuantize, playSoundfont } from "../../../../../utils/melodyUtil";
import { TUNE_PITCH_HEIGHT } from "../../../../../utils/systemConst";

class MelodyMouseOperation {

    static mouseDownEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        store: Store, dispatcher: Dispatcher, timelineLength: number, update: Function) => {

        if (e.button !== 0) return;

        const refs = store.refs.melody;
        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {
            const state = store.melodyState;
            const mouse = store.melodyState.tableMouse;
            const notesState = mouse.notesState;
            const notesList = store.scoreData.notesList;
            const rate = 1 / state.quantize;

            const getNextLeft = () => {
                const next = notesList.find(note => x < getNormalizeLenFromNotes(note.pos) * state.beatWidth);
                let nextLeft = timelineLength;
                if (next != undefined) {
                    nextLeft = getNormalizeLenFromNotes(next.pos) * state.beatWidth;
                }
                return nextLeft;
            }

            const rect = refs.table.getBoundingClientRect();
            const x = e.clientX + refs.table.scrollLeft - rect.left;
            const y = e.clientY + refs.table.scrollTop - rect.top;

            // マウスにノーツにフォーカスしていない時
            if (notesState.index === -1) {

                mouse.isPut = true;
                mouse.lock = {
                    x: Math.floor(x / state.beatWidth / rate) * state.beatWidth * rate,
                    y: Math.floor(y / TUNE_PITCH_HEIGHT) * TUNE_PITCH_HEIGHT
                };

                mouse.nextLeft = getNextLeft();
                mouse.width = 0;


            } else {
                if (notesState.side === 'normal') {

                        const refs = store.refs.melody;
                        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {
                            state.dataHistory.add(JSON.stringify(store.scoreData.notesList));
                            const rect = refs.table.getBoundingClientRect();
                            const y = e.clientY + refs.table.scrollTop - rect.top;
                            const pitchIndex = Math.floor(y / TUNE_PITCH_HEIGHT);
                            notesList[notesState.index].pitchIndex = pitchIndex;
                            store.scoreData.notesList = notesList.slice();

                            playSoundfont(pitchIndex, store);
                        }
                } else {
                    const notes = notesList[notesState.index];
                    mouse.lock = {
                        // x: (Math.floor(x / state.beatWidth / rate) * state.beatWidth * rate) - getNormalizeLenFromNotes(notes.len) * state.beatWidth,
                        x: getNormalizeLenFromNotes(notes.pos) * state.beatWidth,
                        y: 0
                    };
                    mouse.nextLeft = getNextLeft();

                    if (notesState.side === 'left') {
                        notesState.isMove = true;
                        mouse.prevRight = 0;
                        if (notesState.index > 0) {
                            const prev = notesList[notesState.index - 1];
                            mouse.prevRight = (getNormalizeLenFromNotes(prev.pos) + getNormalizeLenFromNotes(prev.len)) * state.beatWidth;
                        }
                    }
                    else if (notesState.side === 'right') {
                        notesState.isResize = true;
                    }

                    mouse.notesState = { ...notesState };
                }
            }

            update();
        }
    }

    static mouseMoveEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        store: Store, dispatcher: Dispatcher, timelineLength: number, update: Function) => {

        if (e.button !== 0) return;

        const refs = store.refs.melody;
        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {
            const state = store.melodyState;
            const mouse = store.melodyState.tableMouse;
            // const update = () => dispatcher.tune.setState(state);
            const notesState = mouse.notesState;
            const notesList = store.scoreData.notesList;
            const chordList = store.scoreData.chordList;
            const rate = 1 / state.quantize;

            const rect = refs.table.getBoundingClientRect();
            const x = e.clientX + refs.table.scrollLeft - rect.left;
            const y = e.clientY + refs.table.scrollTop - rect.top;

            if (!mouse.isPut && !notesState.isResize && !notesState.isMove) {
                mouse.mousePitchIndex = Math.floor(y / TUNE_PITCH_HEIGHT);
                mouse.mouseMeasurePos = Math.floor(x / state.beatWidth / rate);

                let left = 0;
                const searchedChord = chordList.find((element) => {
                    if (element.type === 'chord') {
                        const detail = element.detail as DetailChord;
                        const width = detail.beatLen * state.beatWidth;
                        if (x >= left && x < left + width) {
                            return element;
                        }
                        left += width;
                    }
                });
                mouse.curChord = searchedChord == undefined ? null : searchedChord;

                notesState.index = -1;
                const prevFocus = state.focusIndex;
                state.focusIndex = -1;
                notesState.side = 'normal';
                for (let i = 0; i < notesList.length; i++) {
                    const notes = notesList[i];
                    const left = getNormalizeLenFromNotes(notes.pos) * state.beatWidth;
                    const right = left + getNormalizeLenFromNotes(notes.len) * state.beatWidth;
                    if (x >= left && x <= right) {
                        notesState.index = i;
                        state.focusIndex = i;

                        if (x < left + 10) notesState.side = 'left';
                        else if (x > right - 10) notesState.side = 'right';
                        break;
                    }
                };
                // Distへコピー
                state.destIndex = state.focusIndex;
                if (prevFocus !== -1 && prevFocus !== state.focusIndex) {
                    state.lastFocus = prevFocus;
                    // dispatcher.tune.setState(state);
                }
                // dispatcher.tune.setState(state);
                mouse.notesState = { ...notesState };
            } else {
                if (mouse.isPut) {
                    const curPos = (Math.floor(x / state.beatWidth / rate) + 1) * state.beatWidth * rate;
                    mouse.width = (curPos > mouse.nextLeft ? mouse.nextLeft : curPos) - mouse.lock.x;
                } else if (notesState.isResize) {
                    const curPos = (Math.floor(x / state.beatWidth / rate) + 1) * state.beatWidth * rate;
                    const notes = notesList[notesState.index];
                    // console.log(`${mouse.nextLeft}, ${curPos}`);
                    let posX = curPos - mouse.lock.x;
                    // 右隣に接触時
                    if (curPos > mouse.nextLeft) {
                        posX = mouse.nextLeft - mouse.lock.x;
                    }
                    // 最小チェック
                    if (curPos <= mouse.lock.x) {
                        posX = state.beatWidth / state.quantize;
                    }
                    const newLen = getNotesLenFromQuantize(state.quantize, Math.floor(posX / state.beatWidth * state.quantize));
                    if(getNormalizeLenFromNotes(notes.len) !== getNormalizeLenFromNotes(newLen)) {
                        state.dataHistory.add(JSON.stringify(store.scoreData.notesList));
                        notes.len = newLen;
                        // dispatcher.tune.setNotesList(notesList.slice());
                        store.scoreData.notesList = notesList.slice();
                    }
                } else if (notesState.isMove) {
                    const curPos = (Math.floor(x / state.beatWidth / rate) + 0) * state.beatWidth * rate;
                    const notes = notesList[notesState.index];
                    const lenWidth = getNormalizeLenFromNotes(notes.len) * state.beatWidth;
                    // console.log(`${mouse.nextLeft}, ${curPos}`);
                    let posX = curPos;
                    // 右隣に接触時
                    if (curPos + lenWidth > mouse.nextLeft) {
                        posX = mouse.nextLeft - lenWidth;
                    }
                    // 左隣に接触時
                    if (curPos < mouse.prevRight) {
                        posX = mouse.prevRight;
                    }
                    const newPos = getNotesLenFromQuantize(state.quantize, Math.floor(posX / state.beatWidth * state.quantize));
                    if(getNormalizeLenFromNotes(notes.pos) !== getNormalizeLenFromNotes(newPos)) {
                        state.dataHistory.add(JSON.stringify(store.scoreData.notesList));
                        notes.pos = newPos;
                        // dispatcher.tune.setNotesList(notesList.slice());
                        store.scoreData.notesList = notesList.slice();
                    }
                }
            }
            update();
        }
    }

    static mouseUpEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        store: Store, dispatcher: Dispatcher, timelineLength: number, update: Function) => {

        if (e.button !== 0) return;

        const state = store.melodyState;
        const mouse = store.melodyState.tableMouse;
        // const update = () => dispatcher.tune.setState(state);
        const notesState = mouse.notesState;
        const notesList = store.scoreData.notesList;
        const quantize = state.quantize;

        if (mouse.isPut) {
            mouse.isPut = false;
            if (mouse.width > 0) {
                //履歴を作成
                state.dataHistory.add(JSON.stringify(store.scoreData.notesList));

                const pitchIndex = Math.floor(mouse.lock.y / TUNE_PITCH_HEIGHT);
                // alert(mouse.width / state.beatWidth * quantize);
                // const a = getNotesLenFromQuantize(quantize, Math.floor(mouse.lock.x / state.beatWidth * quantize));
                // const b = getNotesLenFromQuantize(quantize, Math.floor(mouse.width / state.beatWidth * quantize));
                // alert(`lockX: ${JSON.stringify(a)},/n width: ${JSON.stringify(b)}`);
                notesList.push({
                    pos: getNotesLenFromQuantize(quantize, Math.floor(mouse.lock.x / state.beatWidth * quantize)),
                    pitchIndex,
                    len: getNotesLenFromQuantize(quantize, Math.floor(mouse.width / state.beatWidth * quantize)),

                });
                // ノーツ配置後は座標の昇順でソートする
                notesList.sort((a, b) => {
                    const posA = getNormalizeLenFromNotes(a.pos);
                    const posB = getNormalizeLenFromNotes(b.pos);
                    return posA === posB ? 0 : (
                        posA < posB ? -1 : 1
                    );
                });
                dispatcher.melody.setNotesList(notesList);

                playSoundfont(pitchIndex, store);
            }
        } else {
            notesState.isMove = false;
            notesState.isResize = false;
            mouse.notesState = { ...notesState };
            dispatcher.melody.setNotesList(notesList.slice());
        }
        update();
    }
}

export default MelodyMouseOperation;