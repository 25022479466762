import { ScoreData } from "../../../../redux/store/score/scoreData";
import { ScoreState } from "../../../../redux/store/score/scoreState";
import TheoryUtil from "../../../../utils/theoryUtil";

/**
 * コード進行生成のバッキング関連のユーティリティ
 */
 namespace progBackingUtil {

    export type Props = {
        name: string;
        patternConditions: PatternCondition[];
    }
    export type VoicingCondition = {
        attr: TheoryUtil.SymbolAttribute;
        source: string;
    }
    export type PatternCondition = {
        beatLen: number;
        source: string;
        voicingConditions: VoicingCondition[];
    }

    export const BACKING_SAMPLE_LIST: Props[] = [
        {
            name: 'none',
            patternConditions: []
        },
        {
            name: 'barrade-01',
            patternConditions: [
                {
                    beatLen: 2,
                    source: 'H4sIAAAAAAAAA8VTwRKCIBD9Gu4FOnbVvDTTrS9ghIyGsJHNyb4+BCkxb416YWD3LW/fY0FJhjAuLlQpLk/ixc0JkTRGeG92d86o1C6E4gxZMBPNQTH+dOGNQ9ICRKV6JMZum+Qozl1e0pbXEzeNQzWFvoXIFQqdV+AiZ9MLd1FTSTx/YilG1btf3PR1f1d7haoCro9Cw0hR6FMbnDRQeHgLLFqLUlH58dHaaKEgbkKVQXXDZcquAxsGWnpWvArrNmDdzsXqnTe57xh1k9uNDunSg9ZmnLVo7mnBq7zbUqyhVrKK1qVYQ63R4j/D/wq7vgHgSWwr/AUAAA==',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmtCghuwqAW7wdQFAL14+prfAAAA'
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BqRoNYYl1oTHG4wdQEAGcWLSt8AAAA='
                        },
                    ]
                },
                {
                    beatLen: 4,
                    source: 'H4sIAAAAAAAAA9WVwVLDIBCGn4a7XcB4bc3Fmd58AqbBioPECdtO69NLINGAdexBYLwwsOzPB7sskGZDAHbPwhipH9W7dCNC17cE7l3vTXZC22AifEO8c6eOD6aTp2C+CZ5ih6o3kydA6DYt4W2Y1+IshwsrpaZB4LQFFoTKtj0Gy5PbiwxWp6Qzv/GIRH333e/ychnU7Hc1Doc/QM/BNT1Ku1UWk2DGKTpHI4sCD3P0vbdVeyP0Zwp9Br0rqldl9pH6KPW6e1lkYHGWUxSFwtRVRF0VotIqVIiokIs63zI391Wt3NnGDI8nH/swui22mLG0ryivbKX9X9RXvgy0zA1K7i2vQo1fQVrlPSpFZVWocYRZlQiXokIVKqtCjfPKq0Q4G/XH341OPxwkP5xvPwCCCyaGNwsAAA==',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTFlag1JUGuES60JCW7AohbsBlMXAA68b5wLAQAA'
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTFlag1JUGtEglpjXGpNcLjB1AUAqgEeTAsBAAA='
                        },
                    ]
                }
            ]
        },
        {
            name: 'barrade-02',
            patternConditions: [
                {
                    beatLen: 2,
                    source: 'H4sIAAAAAAAAA8VTXQ+CIBT9NbwbxPRV86Wtt34BUzIaYQty2a+PQFLMuT7tgQ3OPZdzOZcLwgRAmG2JEJSv2YXqE0AxBnChdweaEy4tBHACDDln1VLk9GzhwDJJplgpGiaEdhumAKc2zklNjwM39aEjUU0JkU1kMi2VRTa6FmpRnYmcfmgkhrO7vOHr/pzt/BGlonLFpOr54btceyepiDo5Aw1bskIQfu+CaYKhKrZnovCyK8rjfNcxsfOWRnXmqc4mUkWeKpxIFXqq6Feqrt861n7927S1C3vljc7I/IUZeeunTeV+8EX30TMS848koseHwfEGYxcM0ysSYVFzcwUAAA==',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmtCghuwqAWba+oCACBiEqvfAAAA'
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmtCghuwqDUGqTV1AQCUaWUN3wAAAA=='
                        },
                    ]
                },
                {
                    beatLen: 4,
                    source: 'H4sIAAAAAAAAA+2V0W7CIBSGn4b7eUrFW11vTHbnExCLjgWpEWbUp5dC2cA1y1YSuPGiLRzOz8dJfwCRFQLYvlMpmdjwGzM9VC3nCF5N68haKpQLoXqFbHLLz2vZsosLv7hMutW8k0MmgGuSBtWNGxf0yk4jMz2GTlQPS1g4IVdNp11kZ9bCXNQoK88nFjGuDvPGp3uqi6i9L2SnmXrjSj/4IHbXNeopTfWnN47NVnwvqfhynzWfTdX8wOU+Up+ZWLYfgXmCWgbqLKLOMlFxEWoVUSETtS5CJUWoEFGrTNR5Hqrfx2bs+yjv/65/cPDtH+glwXJ/vQNw0h2Ak06z/6gnnWa5nFDGf3GtOImKf1LhL7WmUafWWidRycS9hod9Zd93wUPW69YJAAA=',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmtCghuwqMXpBlMc5pq6AADXs/OfCwEAAA=='
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmtCghuwqCXFXGOQWlMXACkdNMwLAQAA'
                        },
                    ]
                }
            ]
        },
        {
            name: '8beat-01',
            patternConditions: [
                {
                    beatLen: 2,
                    source: 'H4sIAAAAAAAAA8VTSw7CIBQ8DXulJbht7cbEnScgLVYMUlOwsZ5e5BUtTeNGU1bAMMM83gfRHGFcnphSXB7Eg9sTSrIU4a3dXXnFpAYIkRw5ciW6nar4HeAVMFlpRKMGJsawpQUiBdxL1vN25qUp1DIzhLABodBFYwA52lg4oFaZeH/qLObVY978c5HVPj+qMVzvhTaTfIRZ7oOTNszcfAIdW4taMfmugiuCoxpxEaoO1B2XWXUeJXH0l8F1HcUVR3FNlnH19bZ3n9YnFnv5w0qC8L7OSPrTjKT/79OwY9YL1S6cDhxlOpZyDf+aROhT4gm0eAKCqPtePgYAAA==',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTFlag1JUGsEUmvqAgBJsewgswAAAA=='
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BqRoNYYpNbUBQClqctlswAAAA=='
                        },
                    ]
                },
                {
                    beatLen: 4,
                    source: 'H4sIAAAAAAAAA+2V0Q6CIBSGn4b7Omp2W3nT1l1PwJSKRtiEXPb0IWhJc67N5bnpRuBwDh+w3x8SrwlAeqJSMrHnD2ZGJFiFBDamd2UZFcqFSLQmNjnj5VZm7O7CM5dJU81z2WQCuG6ckChx84JWrOhZ6TNUUN1sYekKuUpy7SIHsxfmoqYyaPmxRfRXd/P6l/tXo1S3upC5ZmrHlf7Qga+uyhspTfWtFY7NVvwoqXipz4rPpmp+4fLoVZdMrLJzRzydszTUOQoVUKgBCjVEoUYo1AUKNZ6G2v7HZu5t5fU917rqbyNvu4NvQDjqDQhHudl01V96oe9KcxR/mIrq+z6g+P5UVECh+jccoJz1Z9RBV4KOC9nvE4/6Z+UECwAA',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmuCww2mLgAeyOCMswAAAA=='
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BqRoNYYpNbUBQClqctlswAAAA=='
                        },
                    ]
                }
            ]
        },
        {
            name: '8beat-02',
            patternConditions: [
                {
                    beatLen: 2,
                    source: 'H4sIAAAAAAAAA8VTQQ6CMBB8Te9SJHgFuZh48wUNVKypxdCFiK+3dqlSwsXE0Attp7M7y26HpDmhtLwwpbg8iSc3JxJnW0L3ZnfnFZMaIZLkxJIr0R9UxR8Ib5DJShCNGpmU4jYtSFLgvWQDbxcyzaGWwVjCDgOFLhpA5Gxq4YiayNjpp1ZiOXrKW04XONr1RzXA9VFomPXD7/LgnTQw6FwDLVuLWjH5mYIdgqWCuAlVe9E9l1l1nTRx8i+jahRElQZRjddRdfM2d9+nnxjsrY9r4pX3o0eg7f5oEZttrTfuv7Zopbn7zqJBnLWWKg2i6nc4DuIs5yr7fQFCZXGQ9AYAAA==',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTFlag1JUGsEUmvqAgBJsewgswAAAA=='
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BqRoNYYpNbUBQClqctlswAAAA=='
                        },
                    ]
                },
                {
                    beatLen: 4,
                    source: 'H4sIAAAAAAAAA+2VwU4DIRCGn4a7nQXx2roXE28+AelixSBrFtrYPn0ps+jSEBMTw8TEE+wwPx/L/jvD5IYBbF+Uc9o+mZOOT6xbcwb3cfauB2U9hpjYsJQ8mMODG/QHhm8wU22DGd2cCYBT2TPR47pVRz1VdroOTSrMR7hDofH9GDDyHM+iMRqVXebLhKirl3n17f7VJOrsCzcG7R+ND1c+KN11LJ58UGGfjZOyvdk5ZT/dl8yXUoN5M25XqA/arofXhXkW7zJTVyRUIKF2JFROQhUk1FsSqmxDzf9xXPsq5Zd7vviqPoriuD/sAWHa/2ILSLtVxfyP1NEmVbishyuSytSKKkioZZ8Dkj7XigokVE5CLb9rR3LDrai8DfXbjgPziHORBbI/A9v3xeToDAAA',
                    voicingConditions: [
                        {
                            attr: 'triad',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BrhUmuCww2mLgAeyOCMswAAAA=='
                        },
                        {
                            attr: 'seventh',
                            source: 'H4sIAAAAAAAAA1M1dVI1d1I1MspPLkksSwUyVI0djVSNnIGs4pKi0uQSiJCBqrkLSBRDrTGmWkMS1BqRoNYYpNbUBQClqctlswAAAA=='
                        },
                    ]
                }
            ]
        },
        {
            name: 'arpeggio-01',
            patternConditions: []
        },
        {
            name: 'arpeggio-02',
            patternConditions: []
        },
        {
            name: 'rock-01',
            patternConditions: []
        },
        {
            name: '16beat-01',
            patternConditions: []
        },
    ];
 }

 export default progBackingUtil;