import { useContext, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { DetailChord } from "../../../../../redux/store/score/scoreData";
import { SymbolDiagram } from "../../../../../redux/store/score/scoreState";
import TheoryUtil from "../../../../../utils/theoryUtil";
import { GlobalContext } from "../../../../entry/systemEntry";

const SymbolEditor = (props: {
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const detail = store.scoreData.chordList[state.focusIndex].detail as DetailChord;

    // 表示時に呼ばれる
    useEffect(() => {

        const curSymbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
        const baseSymbol = curSymbol.base == null ? null : TheoryUtil.getSymbolFromKey(curSymbol.base);

        const curLine: TheoryUtil.SymbolKey[] = [];
        const parentLine: TheoryUtil.SymbolKey[] = [];
        const childrenLine: TheoryUtil.SymbolKey[] = [];
        let index = 0;

        if (baseSymbol == null) {
            curLine.push('major');
            curLine.push('minor');
            curLine.push('suspend4th');
            curLine.push('diminish');
            curLine.push('augument');
            curLine.push('minor-minus5');
        } else {
            TheoryUtil.SYMBOL_LIST.forEach((symbol) => {
                if (symbol.key === baseSymbol.key) {
                    parentLine.push(symbol.key);
                }
            });
            const derives = TheoryUtil.getSymbolDerives(baseSymbol.key);
            TheoryUtil.SYMBOL_LIST.forEach((symbol) => {
                if (derives.includes(symbol.key)) {
                    curLine.push(symbol.key);
                }
            });
        }
        curLine.forEach((symbol, i)=> {
            if(symbol === detail.symbolKey) {
                index = i;
            }
        });
        const derives = TheoryUtil.getSymbolDerives(curSymbol.key);
        TheoryUtil.SYMBOL_LIST.forEach((symbol) => {
            if (derives.includes(symbol.key)) {
                childrenLine.push(symbol.key);
            }
        });
        state.symbolMng = {
            index,
            curLine,
            parentLine,
            childrenLine
        };
        dispatcher.score.setScoreState(state);
    }, [detail.symbolKey]);

    // const bodyRef = state.elementCacheList[state.focusIndex].reffers.wrap as HTMLDivElement;

    // const [posX, posY]: [number, number] = useMemo(() => {
    //     return [bodyRef.clientLeft, bodyRef.clientTop + bodyRef.clientHeight];
    // }, []);
    // const posX = bodyRef.getBoundingClientRect().left;
    // const posY = bodyRef.getBoundingClientRect().bottom;
    // alert(`${posX}, ${posY}`);

    const symbolMng = state.symbolMng as SymbolDiagram;

    const parentJsx: JSX.Element[] = [];
    const curJsx: JSX.Element[] = [];
    const childrenJsx: JSX.Element[] = [];

    if (symbolMng != null) {
        parentJsx.push(<_Header key={parentJsx.length}>Base</_Header>);
        symbolMng.parentLine.forEach((item, i) => {
            const symbol = TheoryUtil.getSymbolFromKey(item);
            parentJsx.push(
                <_Item key={parentJsx.length}
                    isActive={false}
                >[{symbol?.name}]</_Item>
            );
        });
        curJsx.push(<_Header key={curJsx.length}>Current</_Header>);
        symbolMng.curLine.forEach((item, i) => {
            const symbol = TheoryUtil.getSymbolFromKey(item);
            curJsx.push(
                <_Item key={curJsx.length}
                    isActive={symbolMng.index === i}
                >[{symbol?.name}]</_Item>
            );
        });
        childrenJsx.push(<_Header key={childrenJsx.length}>Derives</_Header>);
        symbolMng.childrenLine.forEach((item, i) => {
            const symbol = TheoryUtil.getSymbolFromKey(item);
            childrenJsx.push(
                <_Item key={childrenJsx.length}
                    isActive={false}
                >[{symbol?.name}]</_Item>
            );
        });
    }
    return (
        <_Wrap>
            <_Row>{parentJsx}</_Row>
            <_Row>{curJsx}</_Row>
            <_Row>{childrenJsx}</_Row>
        </_Wrap>
    );
}

export default SymbolEditor;

const _Wrap = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 30;
    top: 100px;
    left: 0;
    width: 250px;
    height: 160px;
    background-color: #a9cfd422;
    border: 2px solid #000000ec;
    box-sizing: border-box;
    text-align: center;
    opacity: 0.9;
    box-shadow: 0 4px 16px 4px #000b3a48;
    backdrop-filter: blur(6px);
`;

const _Row = styled.div`
    display: inline-block;
    width: 80px;
    height: 100%;
    /* background-color: #e6e6e6; */
    margin: 1px;
    vertical-align: top;
`;


const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 20px;
    box-sizing: border-box;
    /* background-color: #78dab4;
    border: 1px solid #000000; */
    background: linear-gradient(to bottom, #7a9ea3, #78cfda, #7a9ea3);
    /* background: linear-gradient(to bottom, #a37a7a, #da7878, #a37a7a); */
    margin: 1px;

    font-size: 16px;
    font-weight: 600;
    color: #f3f3f3;
    line-height: 17px;
`;
const _Item = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    width: calc(100% - 2px);
    height: 20px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    ${props => !props.isActive ? '' : css`background-color: #ffef64;`}
    border: 1px solid #000000;
    margin: 1px;

    font-size: 16px;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
`;