import { useContext } from "react";
import styled from "styled-components";
import TheoryUtil from "../../utils/theoryUtil";
import { GlobalContext } from "../entry/systemEntry";

const PerformChannel = () => {

    // const { store, dispatcher } = useContext(GlobalContext);

    // const sampler = store.instruments.sampler;
    const createLane = (label: string, items: {
        degree: TheoryUtil.DegreeProps;
        symbolKey: TheoryUtil.SymbolKey;
    }[]): JSX.Element => {
        const jsxList: JSX.Element[] = [];

        // ヘッダ情報の要素を設定
        jsxList.push(<_Header key={jsxList.length}><div>{label}</div></_Header>);
        items.forEach((item) => {
            const degree = item.degree;
            const degreeRootName = (degree.isFlat ? TheoryUtil.DEGREE12_FLAT_LIST : TheoryUtil.DEGREE12_SHARP_LIST)[degree.index];
            const chordRootName = (degree.isFlat ? TheoryUtil.KEY12_FLAT_LIST : TheoryUtil.KEY12_SHARP_LIST)[degree.index];
            const symbol = TheoryUtil.getSymbolFromKey(item.symbolKey) as TheoryUtil.SymbolParams;
            const symbolName = symbol.name;
            jsxList.push(<_Item key={jsxList.length}
                onMouseDown={(e) => {
                    if (e.button !== 0) return;
                    // sampler.releaseAll();

                    const symbol = TheoryUtil.getSymbolFromKey(item.symbolKey) as TheoryUtil.SymbolParams;

                    const voicingList = symbol.structs.map((struct) => {
                        const interval = TheoryUtil.IntervalRelationProps[struct];
                        return 12 * 3 + item.degree.index + interval;
                    });

                    voicingList.forEach((index) => {
                        const soundName = TheoryUtil.KEY12_SHARP_LIST[index % 12];
                        const octave = Math.floor(index / 12);
                        const soundFullName = soundName + octave;
                        // sampler.triggerAttack(soundFullName, '+0', 0.5);
                    });
                }}
            >
                <div>{degreeRootName}{symbolName}</div>
                <div>{chordRootName}{symbolName}</div>
            </_Item>);
        });
        return (<_Lane>{jsxList}</_Lane>);
    }

    return (
        <_Wrap
            onMouseDown={(e) => {
                if (e.button !== 2) return;
                // sampler.releaseAll();
            }}>
            {createLane('Diatonic Triad', [
                { degree: { index: 0, isFlat: false }, symbolKey: 'major' },
                { degree: { index: 2, isFlat: false }, symbolKey: 'minor' },
                { degree: { index: 4, isFlat: false }, symbolKey: 'minor' },
                { degree: { index: 5, isFlat: false }, symbolKey: 'major' },
                { degree: { index: 7, isFlat: false }, symbolKey: 'major' },
                { degree: { index: 9, isFlat: false }, symbolKey: 'minor' },
                { degree: { index: 11, isFlat: false }, symbolKey: 'minor-minus5' },
            ])}
            {createLane('Diatonic Tetrad', [
                { degree: { index: 0, isFlat: false }, symbolKey: 'major-maj7th' },
                { degree: { index: 2, isFlat: false }, symbolKey: 'minor-7th' },
                { degree: { index: 4, isFlat: false }, symbolKey: 'minor-7th' },
                { degree: { index: 5, isFlat: false }, symbolKey: 'major-maj7th' },
                { degree: { index: 7, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 9, isFlat: false }, symbolKey: 'minor-7th' },
                { degree: { index: 11, isFlat: false }, symbolKey: 'minor7-minus5' },
            ])}
            {createLane('Parallel Triad', [
                { degree: { index: 0, isFlat: true }, symbolKey: 'minor' },
                { degree: { index: 2, isFlat: true }, symbolKey: 'major' },
                { degree: { index: 3, isFlat: true }, symbolKey: 'major' },
                { degree: { index: 5, isFlat: true }, symbolKey: 'minor' },
                { degree: { index: 7, isFlat: true }, symbolKey: 'minor' },
                { degree: { index: 8, isFlat: true }, symbolKey: 'major' },
                { degree: { index: 10, isFlat: true }, symbolKey: 'major' },
            ])}
            {createLane('Secondary Dominant', [
                { degree: { index: 7, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 9, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 11, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 0, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 2, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 4, isFlat: false }, symbolKey: 'major-min7th' },
                { degree: { index: 10, isFlat: false }, symbolKey: 'major-min7th' },
            ])}
        </_Wrap>
    );
}

export default PerformChannel;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 0 4px;
    background-color: #c3d8dd6f;
    border: solid 1px #888;
    border-radius: 5px;
    box-sizing: border-box;
`;

const _Lane = styled.div`
    display: inline-block;
    width: 160px;
    background-color: #bbbbbb;
    padding-bottom: 5px;
`;

const _Box = styled.div`
    display: inline-block;
    width: 150px;
    height: 80px;
    margin: 5px 0 0 5px;
    box-sizing: border-box;
    border-radius: 4px;
`;

const _Header = styled(_Box)`
    background-color: #51608f;
    /* border: 1px solid #dfdfdf; */
    font-weight: 600;
    & div:nth-child(1) {
        width: 100%;
        text-align: center;
        height: 100%;
        font-size: 26px;
        color: #ebebeb;
    }
`;

const _Item = styled(_Box)`
    background-color: #e0ebe5;
    border: 1px solid #777;
    & *{
        width: 100%;
        text-align: center;
        height: 50%;
    }
    & div:nth-child(1) {
        font-size: 26px;
        color: #d40000;
    }
    & div:nth-child(2) {
        font-size: 24px;
        color: #000000;
    }
    &:hover {
        background-color: #fcf29f;
    }
`;