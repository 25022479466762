import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { Store } from "../../../../../redux/store/store";
import { KeyboardEvent } from "react";
import HarmonizeUtil from "../../../../../utils/score/harmonizeUtil";
import { DetailChord } from "../../../../../redux/store/score/scoreData";
import LibraryUtil from "../../../../../utils/LibraryUtil";

export const NoteBasicInput = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher) => {

    const config = store.userEnv.keyConfig.note;
    const keySwitch = store.scoreState.keySwitch;
    // alert(e.key);
    // alert(config.nextElement);

    const data = store.scoreData;
    const state = store.scoreState;
    const element = data.chordList[state.focusIndex];
    const cache = state.elementCacheList[state.focusIndex];
    const chordCache = state.chordCacheList[cache.chordBlockNo];
    const curType = element.type;
    const isElement = curType !== 'init'

    const libEnables = LibraryUtil.getEnableState(store);

    switch (curType) {
        case 'chord': {
            const hideMode = store.scoreState.hideMenu;
            const detail = data.chordList[state.focusIndex].detail as DetailChord;
            const cache = state.elementCacheList[state.focusIndex];
            switch (e.key) {
                case 'z': {
                    HarmonizeUtil.previewCurHarmony(store, dispatcher);
                } break;
                case 'e': {
                    if (libEnables.isUseLib && libEnables.isChordElement && !libEnables.isRangeSelect) {
                        dispatcher.score.openLibraryWnd(false);
                    }
                } break;
                case 'b': {
                    if (detail.root != null) {
                        dispatcher.backing.openBackingEditor();
                    }
                } break;
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7': {
                    dispatcher.score.setDiatonicChord(cache.scale, Number(e.key) - 1);
                } break;
            }
        } break;
    }
    switch (e.key) {
        // 要素の移動
        case config.left: {
            if (state.focusIndex === state.distIndex) {
                dispatcher.score.moveElement(false);
            } else {
                dispatcher.score.releaseRange(false);
            }
        } break;
        case config.right: {
            if (state.focusIndex === state.distIndex) {
                dispatcher.score.moveElement(true);
            } else {
                dispatcher.score.releaseRange(true);
            }
        } break;
        // セクションの移動
        case config.up: {
            dispatcher.score.moveSection(false);
        } break;
        case config.down: {
            dispatcher.score.moveSection(true);
        } break;
        case config.addChord: {
            dispatcher.score.addChordElement();
        } break;
        case config.addSection: {
            dispatcher.score.addSectionElement();
        } break;
        case 'm': {
            dispatcher.score.addAlterElement();
        } break;
        case config.deleteCur: {
            dispatcher.score.removeElement();
        } break;
        case 'e': {
            if (libEnables.isUseLib && libEnables.isRangeSelect) {
                dispatcher.score.openLibraryWnd(true);
            }
        } break;
        case 'n': {
            dispatcher.score.removeBacking();
        } break;
        case ' ': {
            // if (!chordCache.backingError) {
            HarmonizeUtil.previewStart(store, dispatcher);
            // }
        } break;
    }
}