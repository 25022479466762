import { useContext, useEffect, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../../../redux/store/score/backing/backingState";
import ScoreCache from "../../../../../redux/store/score/scoreCache";
import PianoViewer from "../../../../../utils/pianoViewer";
import TheoryUtil from "../../../../../utils/theoryUtil";

const StructViewer = (props: {
    cache: ScoreCache.Element;
    structList: BackingState.StructInfo[];
}) => {

    const cache = props.cache;
    const structList = props.structList;
    const indexList = structList.map(struct => struct.soundIndex + struct.adjustOctave * 12);
    const scaleIndexies = cache.scale === 'major' ? TheoryUtil.MAJOR_SCALE_INTERVALS : TheoryUtil.MINOR_SCALE_INTERVALS;
    const scaleList = scaleIndexies.map(value => (cache.keyIndex + value) % 12);

    useEffect(() => {
    }, []);

    return (
        <_Wrap isUse={indexList.length > 0}>
            <PianoViewer.Component
                uiParam={{ width: 380, height: 80, wKeyNum: 14 }}
                uses={indexList}
                scaleList={scaleList}
            />
        </_Wrap>
    );
}

export default StructViewer;

const _Wrap = styled.div<{
    isUse: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    ${props => props.isUse ? '' : css`
        opacity: 0.5;
    `}
    & * {
        vertical-align: top;
    }
`;