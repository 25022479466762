import styled from "styled-components";
import { HARMONIZE_FOOT_WIDTH, HARMONIZE_HEAD_WIDTH, HARMONIZE_STRUCT_WIDTH, HARMONIZE_RIGHT_WIDTH, HARMONIZE_SECTIONS_WIDTH } from "../../../../utils/systemConst";
import HarmNote from "./harmNote";
import HarmSections from "./harmSections";
import { GlobalContext } from "../../../entry/systemEntry";
import { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { NoteKeySwitch } from "./input/noteInput";
import HarmSideForm from "./side/harmSideForm";
import StructViewer from "./info/structViewer2";
import HarmHideFrame from "./harmHideFrame";
import { _CSS_DISABLE } from "../../../../base/design/styles";
import HarmClipboard from "./initial/harmClipboard";
import HarmMeasure from "./measure/harmMeasure";
import HarmBackingLibrary from "./initial/harmBackingLibrary";

/**
 * ハーモナイズタブ
 * @param props 引数
 * @returns コンポーネント
 */
const HarmonizeTab = (props: {
    isActive: boolean;
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const wrapRef = useRef<HTMLDivElement>({} as HTMLDivElement);
    const [invalidate, setInvalidate] = useState<{}>({});

    const state = store.scoreState;

    const isMelody = store.scoreState.isMelody;

    useEffect(() => {
        dispatcher.system.setInvalidate({
            ...store.invalidate, harmonize: () => {
                dispatcher.system.setInvalidate({
                    ...store.invalidate, harmoniizeInvalidate: {}
                });
            }
        });
    }, []);

    useEffect(() => {
        if (!isMelody) {
            wrapRef.current.focus();
        }
    }, [isMelody]);


    const cache = state.elementCacheList[state.focusIndex];
    const element = store.scoreData.chordList[state.focusIndex];
    
    const timelineJsx = state.elementCacheList.length === 0 ? <></> : <HarmMeasure />;

    let structViewerJsx = <></>;
    if (state.elementCacheList.length > 0) {
        const scoreCache = state.chordCacheList[cache.chordBlockNo];
        const structList = element.type === 'chord' ? scoreCache.structList : [];
        structViewerJsx = <StructViewer cache={cache} structList={structList} />;
    }

    /** サイドフォームの活性状態 */
    const sideFormEnable = !state.isPreview && state.hideMenu === 'none';
    return (
        <_Wrap
            tabIndex={999}
            ref={wrapRef}
            isActive={props.isActive}
            onKeyDown={(e: KeyboardEvent) => NoteKeySwitch(e, store, dispatcher, true)}
            onKeyUp={(e: KeyboardEvent) => NoteKeySwitch(e, store, dispatcher, false)}
        >
            <_SectionsDiv isEnable={sideFormEnable}><HarmSections /></_SectionsDiv>
            <_Left>
                <_Head isEnable={!state.isPreview}>
                    <HarmClipboard />
                    <HarmBackingLibrary />
                </_Head>
                <_Middle>
                    <HarmHideFrame />
                    <_Main>
                        <HarmNote />
                    </_Main>
                    <_PrevireBrind isUse={state.isPreview} />
                </_Middle>
                <_Foot>
                    <_TIMELINE_WRAP>{timelineJsx}</_TIMELINE_WRAP>
                    <_INFO_WRAP>{structViewerJsx}</_INFO_WRAP>
                </_Foot>
            </_Left>
            <_Right isEnable={sideFormEnable}>
                <HarmSideForm />
            </_Right>
        </_Wrap>
    );
}
export default HarmonizeTab;

const _Wrap = styled.div<{
    isActive: boolean;
}>`
    display: ${props => !props.isActive ? 'none' : 'inline-block'};
    width: 100%;
    height: 100%;
    outline: none;
`;

const _SectionsDiv = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: ${HARMONIZE_SECTIONS_WIDTH}px;
    height: 100%;
    background-color: #d8d8d8;
    border-right: 1px solid #3e3f57;
    box-sizing: border-box;
    vertical-align: top;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
`;

const _Left = styled.div`
    display: inline-block;
    width: calc(100% - ${HARMONIZE_RIGHT_WIDTH + HARMONIZE_SECTIONS_WIDTH}px);
    height: 100%;
    background-color: #c7c7c7;
    vertical-align: top;
`;
const _Right = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: ${HARMONIZE_RIGHT_WIDTH}px;
    height: 100%;
    background-color: #d6d6d6;
    vertical-align: top;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
`;

const _Head = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: ${HARMONIZE_HEAD_WIDTH}px;
    background-color: #d4d4d4;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
`;

const _Middle = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - ${HARMONIZE_HEAD_WIDTH + HARMONIZE_FOOT_WIDTH}px);
    background-color: #e0e0e0;
    overflow: auto;
    
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #1959A8;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        box-shadow: 0 0 4px #aaa inset;
    }
`;

const _Main = styled.div`
    position: absolute;
    margin-left: 25px;
    width: calc(100% - 25px);
    min-height: 100%;
    vertical-align: top;
    /* overflow: auto; */
`;

const _Foot = styled.div`
    display: inline-block;
    width: 100%;
    height: ${HARMONIZE_FOOT_WIDTH}px;
    background-color: #cacaca;
    & * {
        vertical-align: top;
    }
`;
const _TIMELINE_WRAP = styled.div`
    display: inline-block;
    width: calc(100% - ${HARMONIZE_STRUCT_WIDTH}px);
    height: 100%;
    /* background-color: #ddc2c2; */
`;
const _INFO_WRAP = styled.div`
    display: inline-block;
    width: ${HARMONIZE_STRUCT_WIDTH}px;
    height: 100%;
    background-color: #757575;
`;

const _PrevireBrind = styled.div<{
    isUse: boolean;
}>`
    position: absolute;
    display: ${props => props.isUse ? 'block' : 'none'};
    z-index: 80;
    width: 100%;
    height: 100%;
    background-color: #7a270028;
`;