import { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../../base/design/styles";
import { BeatSignature } from "../../../../redux/store/score/scoreData";
import DummyUtil from "../../../../utils/dummyUtil";
import TheoryUtil from "../../../../utils/theoryUtil";
import ProgUnitSearcher from "./progUnitSearcher";
import ProgStyle from "./progStyle";
import ProgUtil from "./progUtil";

/**
 * コード進行を編集する画面
 * @param props 引数
 * @returns コンポーネント
 */
const ProgCustomFrame = (props: {
    /** 拍子フィルターの値 */
    beat: '' | BeatSignature;
    /** ユニット数 */
    unitNum: number;
    /** 出力リストを初期化するコールバック関数 */
    reset: () => void;
    /** 出力リストに進行ユニットを挿入するコールバック関数 */
    insertProgUnit: (sectionName: string, sectionNo: string, chordList: ProgUnitSearcher.ChordItemBean[]) => void;
}) => {

    const [step, setStep] = useState<ProgUtil.Step>(0);
    const [degreeFilters, setDegreeFilters] = useState<(ProgUtil.DegreeFilter)[]>(['', '']);

    /** ブロックの最後のコード */
    const [lastChord, setLastChord] = useState<ProgUnitSearcher.ChordItemBean | null>(null);

    // const [progUnitList, setProgUnitList] = useState<ProgSearcher.UnitBean[]>([]);
    const [focusIndex, setFocusIndex] = useState(-1);

    const stepList = useMemo(() => {
        return props.beat === '' ? [0] : ProgUtil.getFilteredStepList(props.beat);
    }, [props.beat]);

    /** 拍ステップ絞り込みのコンボボックス */
    const stepOptionJsxList = useMemo(() => {
        // console.log(stepList.length);
        return stepList.map((val, i) => {
            // 0の場合は表示しない制御
            const disp = val === 0 ? '' : val;
            return (
                <option key={i} value={val}>{disp}</option>
            );
        });
    }, [stepList]);

    /** ディグリーネーム絞り込みのコンボボックス */
    const degreeOptionJsxListArray: JSX.Element[][] = useMemo(() => {
        return [ProgUtil.DEGREE_FILTER_LIST.map((filter, i) => {
            // 先頭のフィルター
            return (<option key={i} value={filter}>{filter}</option>);
        }), ProgUtil.DEGREE_FILTER_LIST.map((filter, i) => {
            // 2番目のフィルター
            return (<option key={i} value={filter}>{filter}</option>);
        })];
    }, [degreeFilters]);

    /** リストに表示する進行ユニットのJSX */
    const [progUnitList, progJsxList] = useMemo(() => {
        if (props.beat === '') return [[], []];
        const beat = props.beat as BeatSignature;
        // フィルタリングしたユニットリスト
        const unitList = ProgUnitSearcher.search({
            beat: props.beat,
            step, firstChord: degreeFilters[0], secondChord: degreeFilters[1]
        });
        const jsx = unitList.map((unit, i) => {
            const isActive = focusIndex === i;

            /** 表示する進行ユニット */
            const dispUnitJsx: JSX.Element[] = [];
            /** 表示する進行ユニット */
            let tootTipStr: string = '';
            let totalBeat = 0;
            const beatCycle = TheoryUtil.getBeatProps(beat).barBeatCnt;
            unit.chordList.forEach((chord, j) => {
                const degreeRoot = TheoryUtil.getDegreeRoot(chord.root);
                const symbol = TheoryUtil.getSymbolFromKey(chord.symbolKey) as TheoryUtil.SymbolParams;
                const symbolName = symbol.name;
                const chordName = degreeRoot + symbolName;
                if (j !== 0) {
                    const divStr = totalBeat % beatCycle === 0 ? ' | ' : '・';
                    dispUnitJsx.push(<_Span key={dispUnitJsx.length}>{divStr}</_Span>);
                    tootTipStr += divStr;
                }
                let isMatch = false;
                if (j === 0) {
                    isMatch = degreeFilters[0] !== '';
                } else if (j === 1) {
                    isMatch = degreeFilters[1] !== '';
                }
                dispUnitJsx.push(<_ChordName key={dispUnitJsx.length} isMatch={isMatch}>{chordName}</_ChordName>);
                tootTipStr += chordName;
                totalBeat += chord.beatLen;
                // console.log(totalBeat);
            });
            return (
                <_ProgItem key={i} isActive={isActive} title={tootTipStr} onClick={() => {
                    setFocusIndex(i);
                }}>{dispUnitJsx}</_ProgItem>
            )
        });
        return [unitList, jsx];
    }, [props.beat, step, degreeFilters, focusIndex]);

    /**
     * 拍ステップ変更時の処理
     * @param e イベント
     */
    const stepChangeAction = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStep(Number(e.target.value) as ProgUtil.Step);
        setFocusIndex(-1);
    }

    /**
     * ディグリーフィルター変更時の処理
     * @param e イベント
     */
    const degreeChangeAction = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const value = e.target.value as ProgUtil.DegreeFilter;
        degreeFilters[index] = value;
        if (index === 0 && value === '') {
            degreeFilters[1] = '';
        }
        setDegreeFilters(degreeFilters.slice());
        setFocusIndex(-1);
    }


    /**
     * リセットボタン押下時の処理
     */
    const resetAction = () => {
        setStep(0);
        setDegreeFilters(['', '']);
        props.reset();
    };

    /**
     * 追加ボタン押下時の処理
     */
    const insertAction = () => {
        const progUnit = progUnitList[focusIndex];
        props.insertProgUnit('Section', '', progUnit.chordList);
        setFocusIndex(-1);
        setStep(0);
        setDegreeFilters(['', '']);

        const chord = progUnit.chordList[progUnit.chordList.length - 1];
        setLastChord(chord);
    };

    /**
     * ユニットの最後のコードネームを取得
     */
    const getLastChordName = () => {
        let name = '-';
        if (lastChord != null) {
            const rootName = TheoryUtil.getDegreeRoot(lastChord.root);
            const symbol = TheoryUtil.getSymbolFromKey(lastChord.symbolKey) as TheoryUtil.SymbolParams;
            name = rootName + symbol.name;
        }
        return name;
    }

    return (
        <_Frame isEnable={props.beat !== ''}>
            {/* 拍ステップコンボボックス */}
            <_Record>
                <ProgStyle._TitleLabel>Step</ProgStyle._TitleLabel>
                <ProgStyle._Combobox isEnable={true} width={80} value={step} onChange={(e) => {
                    stepChangeAction(e);
                }}>{stepOptionJsxList}</ProgStyle._Combobox>
                <ProgStyle._TitleLabel>Last</ProgStyle._TitleLabel>
                <ProgStyle._Label isEnable={true} width={80} >{getLastChordName()}</ProgStyle._Label>
            </_Record>
            {/* ディグリーネームフィルターコンボボックス */}
            <_Record>
                <ProgStyle._TitleLabel>Chord</ProgStyle._TitleLabel>
                <ProgStyle._Combobox isEnable={true} width={80} value={degreeFilters[0]} onChange={(e) => {
                    degreeChangeAction(e, 0);
                }}>{degreeOptionJsxListArray[0]}</ProgStyle._Combobox>
                <ProgStyle._Combobox isEnable={degreeFilters[0] !== ''} width={80} value={degreeFilters[1]} onChange={(e) => {
                    degreeChangeAction(e, 1);
                }}>{degreeOptionJsxListArray[1]}</ProgStyle._Combobox>
            </_Record>
            {/* 進行ユニットリスト */}
            <_ListDiv><div>{progJsxList}</div></_ListDiv>
            {/* 実行ボタン */}
            <_ButtonDiv>
                <_ExecutableButton isEnable={props.unitNum > 0} onClick={resetAction}>Reset</_ExecutableButton>
                <_ExecutableButton isEnable={focusIndex !== -1} onClick={insertAction}>Add</_ExecutableButton>
            </_ButtonDiv>
        </_Frame>
    );
}

export default ProgCustomFrame;

const BUTTON_DIV_HEIGHT = 40;
const RECORD_HEIGHT = 40;

const _Frame = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
`;

/**
 * 条件レコード
 */
const _Record = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${RECORD_HEIGHT}px;
    /* background-color: #d5eae4; */
    /* margin: 2px 0 0 0; */
    text-align: left;
    & *{
        margin: 5px 0 0 5px;
    }
`;

/**
 * 進行リスト
 */
const _ListDiv = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: calc(100% - ${RECORD_HEIGHT * 2 + BUTTON_DIV_HEIGHT}px);
    /* background-color: #babaa8; */
    text-align: left;
    & >div{
        display: inline-block;
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        margin: 4px 0 0 4px;
        background-color: #dde0e7;
        box-sizing: border-box;
        border: 1px solid #797979;
        overflow-y: auto;
        
        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background: #1959A8;
        }
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            box-shadow: 0 0 4px #aaa inset;
        }
    }
`;

const _ProgItem = styled.div<{
    isActive: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    display: inline-block;
    width: 100%;
    background-color: #00000010;
    ${props => !props.isActive ? '' : css`
        background-color: #f6ff0062;
    `}
    text-align: left;
    margin: 2px 0 0 0;
    padding: 0 0 0 5px;
    overflow: hidden;
`;

const _ChordName = styled.span<{
    isMatch: boolean;
}>`
    color: #000f59;
    ${props => !props.isMatch ? '' : css`
        color: #a0000d;
        /* background-color: #5eff0061; */
    `}
`;
const _Span = styled.span<{
}>`
    color: #0000005d;
`;

/**
 * ボタンエリア（画面汎用）
 */
const _ButtonDiv = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${BUTTON_DIV_HEIGHT}px;
    /* background-color: #94babd; */
    text-align: center;
    & *{
        margin: 5px 0 0 5px;
    }
`;

/**
 * 実行ボタン
 */
const _ExecutableButton = styled.div<{
    isEnable: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE};
    background-color: #737d94;
    width: 130px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #505971;
    box-sizing: border-box;
    &:hover {
        background-color: #8da0b0;
    }
`;