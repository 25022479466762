import { useContext } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../utils/backingUtil";
import { GlobalContext } from "../../entry/systemEntry";

const PatternOptions = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const pattern = state.pattern as BackingState.Pattern;
    const layer = pattern.layers[pattern.layerIndex];
    const channelIndex = (pattern.channels.length - 1) - pattern.channelIndex;
    const cur = layer.noteDivList[pattern.lenIndex];
    const chordInfo = state.chordInfo;
    const update = () => {
        layer.noteDivList = layer.noteDivList.slice();
        dispatcher.backing.setState(state);
        dispatcher.backing.updateErrorState();
    }

    const setRate = (rate: BackingState.NoteRate) => {
        cur.rate = rate;
        update();
    }

    const createItem = (name: string, isActive: boolean, isEnable: boolean, callback: () => void): JSX.Element => {
        return (
            <_Item isEnable={isEnable} isActive={isActive}
                onClick={callback}>
                {name}
            </_Item>
        );
    }
    const createRateItem = (rate: BackingState.NoteRate): JSX.Element => {
        return createItem(String(rate), cur.rate === rate, true, () => setRate(rate));
    }

    const sizeJsxList: JSX.Element[] = [];
    for (let i = 0; i < BackingUtil.CHANNEL_MAX; i++) {
        const value = i + 1;
        sizeJsxList.push(
            <_ListItem key={i}
                value={value}>
                {value}
            </_ListItem>
        );
    }

    const isPermit3ren = ['2/4', '4/4', '4/3'].includes(chordInfo.init.beatSignature);
    const isPermitDot = BackingUtil.isPermitDot(cur);

    const lenModeJsx = <_WrapLen>
        <_List
            value={pattern.channels.length}
            onChange={(e) => {
                const prevSize = pattern.channels.length;
                const newSize = Number(e.target.value);
                const voicingStructs = BackingUtil.buildVoicsFromTable(state.voicingTable as boolean[][]);
                pattern.channels = BackingUtil.getChannels(voicingStructs, newSize);
                const sub = Number(e.target.value) - prevSize;
                pattern.layers.forEach((layer, i) => {
                    if (sub > 0) {
                        const initialCellArray = new Array<BackingState.NoteStatus | null>(layer.noteDivList.length).fill(null);
                        for (let j = 0; j < sub; j++) layer.table.push(JSON.parse(JSON.stringify(initialCellArray)));
                    } else {
                        const delCnt = Math.abs(sub);
                        layer.table.splice(-delCnt, delCnt);
                    }
                });
                update();
            }}>
            {sizeJsxList}
        </_List>
        {createRateItem(16)}
        {createRateItem(8)}
        {createRateItem(4)}
        {createRateItem(2)}
        {createRateItem(1)}
        {createItem('.', cur.isDot,
            isPermitDot,
            () => {
                cur.isDot = !cur.isDot;
                update();
            })}
        {createItem('1/3', cur.div3 === 1,
            isPermit3ren,
            () => {
                if (cur.div3 === 1) cur.div3 = 0;
                else cur.div3 = 1;
                update();
            })}
        {createItem('2/3', cur.div3 === 2,
            isPermit3ren,
            () => {
                if (cur.div3 === 2) cur.div3 = 0;
                else cur.div3 = 2;
                update();
            })}
    </_WrapLen>;

    /** テーブルの行 */
    const tableRecord = layer.table[channelIndex];
    const curCell = pattern.channelIndex === -1 ? null :
        tableRecord[pattern.lenIndex];

    const isEnableTai = () => {
        if(tableRecord == null) return false;

        const search = layer.table.find((record) => {
            const cell = record[pattern.lenIndex];
            if (cell != null && cell.signal === 's') return cell;
        });
        const hasStaccato = search != undefined;
        let isExistNext = false;
        if(pattern.lenIndex < tableRecord.length - 1 && tableRecord[pattern.lenIndex + 1] != null) {
            isExistNext = true;
        }
        return !hasStaccato && isExistNext;
    };

    const isNormalRow = () => {
        const search = layer.table.find((record) => {
            const cell = record[pattern.lenIndex];
            if (cell != null && cell.signal === 't') return cell;
        });
        return search == undefined;
    };

    const setRowStaccato = (isSet: boolean) => {
        layer.table.forEach((record) => {
            const cell = record[pattern.lenIndex];
            if (cell != null) {
                cell.signal = isSet ? 's' : 'n';
            }
        });
    }

    const editModeJsx = <_WrapEdit>

        {createItem('-', curCell == null,
            true,
            () => {
                tableRecord[pattern.lenIndex] = null;
                update();
            })}
        {createItem('□', curCell != null && curCell.signal === 'n',
            true,
            () => {
                if (curCell != null) {
                    if(curCell.signal === 's') {
                        setRowStaccato(false);
                    } else {
                        curCell.signal = 'n';
                    }
                } else {
                    tableRecord[pattern.lenIndex] = { signal: 'n', timing: 0, velAdj: 0 };
                }
                update();
            })}
        {createItem('・', curCell != null && curCell.signal === 's',
            curCell != null && isNormalRow(),
            () => {
                setRowStaccato(true);
                update();
            })}
        {createItem('⁀', curCell != null && curCell.signal === 't',
            isEnableTai(),
            () => {
                const status = curCell as BackingState.NoteStatus;
                status.signal = 't'
                update();
            })}
    </_WrapEdit>;
    return (pattern.channelIndex === - 1 ? lenModeJsx : editModeJsx);
}

export default PatternOptions;

const _WrapLen = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #86c5b2;
`;
const _WrapEdit = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #86b8c5;
`;


const _List = styled.select<{

}>`
    display: inline-block;
    width: 50px;
    height: 26px;
    margin: 2px 2px 0 0;
    color: #eeff00;
    font-size: 20px;
    font-weight: 600;
    background-color: #d4d4d422;
    border: solid 1px #555;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 20px;
    text-align: center;
    outline: none;
`;
const _ListItem = styled.option<{
}>`
    background-color: black;
    color: white;
    font-size: 16px;
    font-weight: 600;
`;

const _Item = styled.div<{
    isEnable: boolean;
    isActive: boolean;
}>`
    display: inline-block;
    width: 50px;
    height: 26px;
    margin: 2px 2px 0 0;
    font-size: 20px;
    color: white;
    font-weight: 600;
    background-color: #ff008023;
    border: solid 1px #555;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 20px;
    text-align: center;

    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}

    ${props => !props.isActive ? '' : css`
        background-color: #fbff0050;
    `}

    &:hover {
        background-color: #ff008039;
        ${props => !props.isActive ? '' : css`
            background-color: #dfe07850;
        `}
    }
`;