import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../base/design/styles";
import DataUtil from "../../../utils/dataUtil";
import FormUtil from "../../../utils/form/formUtiil";
import ValidateUtil from "../../../utils/form/validateUtil";
import { GlobalContext } from "../../entry/systemEntry";
import ConfirmDialog from "../confirmDialog";

const LoginLogin = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const [isForget, setForget] = useState(false);

    const [userId, setUserId] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [password, setPassword] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [email, setEmail] = useState<FormUtil.CheckableValue>({ value: '', errors: [] });
    const [messageList, setMessageList] = useState<string[]>([]);

    useEffect(() => {
        const list: string[] = [];
        if (userId.errors.length > 0) {
            switch (userId.errors[0]) {
                case 'required': list.push('[user id] is required.'); break;
                case 'length': list.push('[user id] set a value between 4 and 20.'); break;
                case 'value': list.push('[user id] accepts only half-width alphanumeric characters.'); break;
                case 'relation': list.push('[user id] already in use.'); break;
            }
        }
        if (password.errors.length > 0) {
            switch (password.errors[0]) {
                case 'required': list.push('[password] is required.'); break;
                case 'length': list.push('[password] set a value between 4 and 20.'); break;
            }
        }
        setMessageList(list);
    }, [userId, password]);

    /**
     * Enterボタン押下時の処理
     */
    const enterAction = () => {
        // alert(`Confirmation passwords don't match.`);
        (async () => {
            const results = await DataUtil.findUserInfoLogin(userId.value, password.value);
            console.log(JSON.stringify(results));
            if (results.length > 0) {
                store.userEnv.login = {
                    seq: results[0].seq,
                    id: results[0].id,
                    password: results[0].password,
                    email: results[0].email,
                };
                dispatcher.system.setUserEnv(store.userEnv);
                dispatcher.system.setDialog(<ConfirmDialog.Component
                    message="Login successful!"
                />);
            } else {
                setMessageList(['Incorrect user id or password.']);
                // setPassword({ ...password, value: '' });
            }
        })();
    }

    const isLoginInfoOK = () => {
        return userId.errors.length === 0 && password.errors.length === 0;
    }

    return (<>
        <_Frame isVisible={!isForget}>
            <FormUtil.BorderPanel
                title="Login information"
                innerJsx={<>
                    <FormUtil.Message
                        message={'Please, enter User ID/Pass.'}
                    />
                    <FormUtil.InputTextForm
                        placeholder={'user id'}
                        checkable={userId}
                        setCheckable={setUserId}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            },
                            {
                                checker: (value) => ValidateUtil.checkAsciiChars(value),
                                errorType: "value"
                            }
                        ]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InputTextForm
                        placeholder={'password'}
                        isPassword
                        checkable={password}
                        setCheckable={setPassword}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 20),
                                errorType: "length"
                            }
                        ]}
                        width={300}
                        isCenter
                    />
                    <FormUtil.InfoFrame textRecords={messageList} />
                    <FormUtil.ButtonRecord
                        buttons={[
                            { label: 'Enter', isEnable: isLoginInfoOK(), callback: enterAction },
                        ]}
                    />
                </>}
            />
            <FormUtil.MessageLink
                message="Forgot your login information?"
                clickAction={() => {
                    setForget(true);
                }}
            />
        </_Frame>
        <_Frame isVisible={isForget}>
            <FormUtil.BorderPanel
                title="Email"
                innerJsx={<>
                    <FormUtil.Message
                        message={'Submit login information to account address.'}
                    />
                    <FormUtil.InputTextForm
                        placeholder={'email'}
                        checkable={email}
                        setCheckable={setEmail}
                        validates={[
                            {
                                checker: (value) => ValidateUtil.checkRequired(value),
                                errorType: "required"
                            },
                            {
                                checker: (value) => ValidateUtil.checkStringLength(value, 4, 40),
                                errorType: "length"
                            },
                            {
                                checker: (value) => {
                                    return value.length === 0 || value.split('@').length === 2;
                                },
                                errorType: "value"
                            }
                        ]}
                        width={500}
                        isCenter
                    />
                    <FormUtil.ButtonRecord
                        buttons={[
                            {
                                label: 'Send', isEnable: email.errors.length === 0, callback: () => {
                                    DataUtil.findUserInfoFromEmail(email.value).then((list) => {
                                        if (list.length > 0) {
                                            const info = list[0];
                                            const hash = DataUtil.getHashedFromUserSeq(info.seq);
                                            const url = `https://demo-app.licresia.com/#/user?v=${hash}`;
                                            DataUtil.sendQueryRequestToMailAPI({
                                                from: 'info@licresia.com',
                                                to: email.value,
                                                subject: '【LiCreSia】support',
                                                text: `Your login information is as follows.\n\nURL: ${url}\nUSR ID: ${info.id}`
                                            });
                                            dispatcher.system.setDialog(<ConfirmDialog.Component message="Your login information has been sent!" />);
                                        } else {
                                            dispatcher.system.setDialog(<ConfirmDialog.Component message="This email address is not registered." />);
                                        }
                                    });
                                }
                            }
                        ]}
                    />
                </>}
            />
            <FormUtil.MessageLink
                message="Return to login information."
                clickAction={() => {
                    setForget(false);
                }}
            />
        </_Frame>
    </>);
};

export default LoginLogin;


const _Frame = styled.div<{
    isVisible: boolean;
}>`
    display: ${props => props.isVisible ? 'block' : 'none'};
`;
