import { Dispatcher } from "../redux/dispatcher/dispatcher";
import BackingState from "../redux/store/score/backing/backingState";
import ScoreCache from "../redux/store/score/scoreCache";
import { BackingLayerData, BackingPatternProps, BackingSignalProps, BeatSignature, ChordBacking, DetailChord, DetailInit, PedalInfo, VoicingProps } from "../redux/store/score/scoreData";
import { Store } from "../redux/store/store";
import { playHarmony } from "./melodyUtil";
import TheoryUtil from "./theoryUtil";

/**
 * バッキングエディタで利用するユーティリティ
 */
export namespace BackingUtil {

    // オクターブの数
    export const OCTAVE_NUM = 8;
    // 管理する構成音の数
    export const STRUCT_NUM = 6;
    // チャンネルの最大数
    export const CHANNEL_MAX = 12;

    /**
     * オンコードを考慮したコードのフル名称と構成音を返す。
     * @param root ルート
     * @param keyIndex キー
     * @param on オンコードのルート
     * @param symbol シンボル
     * @returns オンコードを考慮したコードのフル名称と構成音
     */
    export const getChordDetails = (
        root: TheoryUtil.DegreeProps,
        keyIndex: number,
        on: null | TheoryUtil.DegreeProps,
        symbol: TheoryUtil.SymbolParams
    ): [
            string, BackingState.StructInfo[]
        ] => {

        // const symbol = TheoryUtil.getSymbolFromKey(chordInfo.symbolKey);
        const chordRootIndex = (root.index + keyIndex) % 12;
        const rootName = TheoryUtil.getRootName(root, keyIndex);
        let chordFullName = rootName + symbol.name;

        let chordOnIndex = -1;
        // オンコードが設定されている場合
        if (on != null) {
            const onName = TheoryUtil.getRootName(on, keyIndex);
            chordFullName = `${chordFullName}/${onName}`;

            chordOnIndex = (on.index + keyIndex) % 12;
        }

        // 構成音情報を算出
        let structList: BackingState.StructInfo[] = symbol.structs.map(struct => {
            const relation = struct;
            const interval = TheoryUtil.IntervalRelationProps[relation];
            const soundIndex = (chordRootIndex + interval) % 12;
            const adjustOctave = Math.floor((chordRootIndex + interval) / 12);
            const isFlat = TheoryUtil.isFlatFromRelation(relation);
            return {
                relation, soundIndex, isFlat, adjustOctave
            }
        });
        // オンコードを考慮
        if (chordOnIndex !== -1) {
            let sameIndex = -1;
            structList.some((info, i) => {
                if (info.soundIndex === chordOnIndex) {
                    sameIndex = i;
                    return;
                }
            });
            // オンコードが構成音と被っている場合リストから削除
            if (sameIndex !== -1) {
                structList.splice(sameIndex, 1);
            }
            // オンコードをリストに追加
            structList.unshift({
                relation: null,
                soundIndex: chordOnIndex,
                isFlat: false,
                adjustOctave: 0
            });
            // 音程の昇順でソート
            structList.sort((a, b) => {
                return a.soundIndex - b.soundIndex;
            });

            /** ソート後のオンコードの場所 */
            let onIndex = -1;
            structList.some((sound, i) => {
                if (sound.relation == null) {
                    onIndex = i;
                    return;
                }
            });
            const onChordIndex = structList[onIndex].soundIndex;
            const tempList: BackingState.StructInfo[] = [];
            for (let i = onIndex; i < structList.length; i++) {
                const sound = structList[i];
                tempList.push(sound);
            }
            for (let i = 0; i < onIndex; i++) {
                const sound = structList[i];
                tempList.push(sound);
            }
            tempList.forEach(sound => {
                sound.adjustOctave = 0;
                if (sound.soundIndex < onChordIndex) {
                    sound.adjustOctave = 1;
                }
            });
            structList = tempList;
        }
        return [chordFullName, structList];
    }

    /**
     * ノーツレコードを返す
     * @param divCount 区切り数
     * @returns ノーツレコード
     */
    export const getInitialRecord = (divCount: number) => {
        return new Array<BackingState.NoteStatus | null>(divCount).fill(null);
    }

    /**
     * ボイシングテーブルをリストに変換して返す。
     * @param vctbl ボイシングテーブル
     * @returns チャンネルリスト
     */
    export const buildVoicsFromTable = (vctbl: boolean[][]): BackingState.VoicParam[] => {
        const voics: BackingState.VoicParam[] = [];
        for (let i = 0; i < vctbl.length; i++) {
            for (let j = 0; j < vctbl[i].length; j++) {
                if (vctbl[i][j]) {
                    voics.push({ octave: i, structIndex: j });
                }
            }
        }
        if (voics.length === 0) {
            voics.push({ octave: -1, structIndex: 0 });
        }
        return voics;
    }

    /**
     * ボイシングテーブルをボイシングリストに変換して返す。
     * @param vctbl ボイシングテーブル
     * @returns ボイシングリスト
     */
    export const getVoicingListFromTable = (vctbl: boolean[][]): VoicingProps[] => {

        const voicingList: VoicingProps[] = [];
        // ボイシングを設定
        if (vctbl != null) {
            for (let i = 0; i < vctbl.length; i++) {
                for (let j = 0; j < vctbl[i].length; j++) {
                    if (vctbl[i][j]) {
                        voicingList.push({ octave: i, struct: j });
                    }
                }
            }
        }
        return voicingList;
    }

    /**
     * コードのパターン情報に変換して返す。
     * @param editorPattern エディタのパターン
     * @returns コードのパターン情報
     */
    export const buildPatternProps = (
        editorPattern: BackingState.Pattern
    ): BackingPatternProps => {
        const channelSize = editorPattern.channels.length;
        const layers: BackingLayerData[] = [];
        editorPattern.layers.forEach(layer => {
            const divs: BackingState.NoteDiv[] = layer.noteDivList;
            const signals: BackingSignalProps[] = [];
            layer.table.forEach((record, i) => {
                record.forEach((cell, j) => {
                    if (cell != null) {
                        signals.push({ x: j, y: i, status: cell });
                    }
                });
            });
            layers.push({ divs, notesList: signals, vels: layer.velocityList.slice() });
        });
        const pedals: PedalInfo[] = [];
        editorPattern.pedalActs.forEach((pedal, i) => {
            if (pedal != null) pedals.push({
                divIndex: i, action: pedal
            });
        });
        return { channelSize, pedals, layers };
    }

    /**
     * 
     * @param channelParams 
     * @param size 
     * @returns 
     */
    export const getChannels = (
        channelParams: BackingState.VoicParam[],
        size: number
    ) => {
        const channels: BackingState.VoicParam[] = [];
        for (let i = 0; i < size; i++) {
            const channel: BackingState.VoicParam = { octave: -1, structIndex: 0 };
            if (i < channelParams.length) {
                channel.octave = channelParams[i].octave;
                channel.structIndex = channelParams[i].structIndex;
            }
            channels.push(channel);
        }
        return channels.reverse();
    }

    export const convertEditorLayerFromScore = (channelSize: number, scoreLayers: BackingLayerData[]) => {

        const layers: BackingState.Layer[] = [];
        // console.log(JSON.stringify(scoreLayers));
        scoreLayers.forEach(scoreLayer => {
            const table: (BackingState.NoteStatus | null)[][] = [];
            for (let i = 0; i < channelSize; i++) {
                const record: (BackingState.NoteStatus | null)[] = [];
                for (let j = 0; j < scoreLayer.divs.length; j++) {
                    const notes = scoreLayer.notesList.find(notes => notes.x === j && notes.y === i);
                    record.push(notes == null ? null : notes.status);
                }
                table.push(record);
            }
            layers.push({
                noteDivList: scoreLayer.divs,
                table,
                velocityList: scoreLayer.vels
            });
        });
        // console.log(JSON.stringify(layers));
        return layers;
    }

    export const getPitchListFromVoicing = (
        voicingList: VoicingProps[],
        structList: BackingState.StructInfo[]
    ) => {
        // return voicingList.map((item) => {
        //     const interval = TheoryUtil.IntervalRelationProps[symbol.structs[item.struct]];
        //     return 12 * item.octave + rootIndex + interval;
        // });
        return voicingList.map((item) => {
            // const interval = TheoryUtil.IntervalRelationProps[symbol.structs[item.struct]];
            // return 12 * item.octave + rootIndex + interval;
            const struct = structList[item.struct];
            const octave = item.octave + struct.adjustOctave;
            return 12 * octave + struct.soundIndex;
        });
    }

    export const playBacking = (
        store: Store,
        reserveTasks: NodeJS.Timeout[],
        baseInfo: DetailInit,
        backing: ChordBacking,
        pitchIndexList: number[],
        chordTime: number
    ) => {
        if (backing.pattern == null) {
            pitchIndexList.forEach((index) => {
                const soundName = TheoryUtil.KEY12_SHARP_LIST[index % 12];
                const octave = Math.floor(index / 12);
                const soundFullName = soundName + octave;
                playHarmony(soundFullName, store, chordTime / 1000, 5);
            });
        } else {

            const bpm = baseInfo.bpm;
            const beatProps = TheoryUtil.getBeatProps(baseInfo.beatSignature);
            const adjustRate = beatProps.beatMemoriCount === 4 ? 1 : (2 / 3);

            const pattern = backing.pattern;
            // const pedalIndexes = pattern.pedals === undefined ? null : pattern.pedals.map(pedal => pedal.divIndex);

            /** ペダルの有効範囲を定義したリスト */
            const pedalRangeList: { start: number, end: number, action: BackingState.PedalAction }[] = [];
            pattern.layers.forEach((layer, i) => {
                /** バッキング内で経過した時間（ノーツごとの開始時間） */
                let total = 0;
                const timeList: {
                    start: number, sustain: number
                }[] = layer.divs.map(div => {
                    /** 開始時間 */
                    const start = 60000 / bpm * total;
                    let normal = 4 / div.rate * adjustRate;
                    if (div.isDot) normal *= 1.5;
                    else if (div.div3 > 0) normal /= 3 * div.div3;
                    total += normal;
                    return { start, sustain: 60000 / bpm * normal };
                });

                // ペダル状態のキャッシュを作成
                if (i === 0 && pattern.pedals != undefined) {
                    timeList.forEach((divTime, j) => {
                        const pedalInfo = pattern.pedals?.find((pedal) => {
                            if (j === pedal.divIndex) return pedal;
                        });
                        if (pedalInfo != undefined) {

                            if (pedalRangeList.length > 0) {
                                pedalRangeList[pedalRangeList.length - 1].end = divTime.start;
                            }
                            pedalRangeList.push({
                                start: divTime.start,
                                end: chordTime,
                                action: pedalInfo.action
                            });
                        }
                    });
                }

                /** 除外リスト（タイで吸収されるノーツ） */
                const ignoreList: { x: number, y: number }[] = [];
                layer.notesList.forEach((notes) => {

                    // 除外リストに含まれている場合、以降の処理をしない
                    if (ignoreList.find(ig => {
                        if (ig.x === notes.x && ig.y === notes.y) return ig;
                    }) !== undefined) return;

                    const timeInfo = timeList[notes.x];
                    const timing = notes.status.timing == undefined ? 0 : notes.status.timing;
                    const timingAdj = timing * (60000 / bpm * 1 / 32);
                    const startTime = timeInfo.start + timingAdj;

                    /** 持続時間 */
                    let sustainTime = timeInfo.sustain;
                    if (notes.status.signal === 's') {
                        // スタッカートの場合半分の音価にする
                        sustainTime *= 0.5;
                    } else if (notes.status.signal === 't') {

                        let inc = notes.x;
                        let isNext = true;
                        while (isNext) {
                            inc++;
                            // 次のノーツを探す
                            const nextNotes = layer.notesList.find(next => {
                                if (next.x === inc && next.y === notes.y) {
                                    return next;
                                }
                            });
                            if (nextNotes != undefined) {
                                sustainTime += timeList[inc].sustain;
                                // 次の要素を除外する
                                ignoreList.push({ x: inc, y: notes.y });
                                // 次のノーツがタイだったらループを継続
                                isNext = nextNotes.status.signal === 't';
                            }
                        }
                    }

                    //ペダルを検出
                    for (let k = 0; k < pedalRangeList.length; k++) {
                        const pedal = pedalRangeList[k];
                        const notesTail = startTime + timeInfo.sustain;
                        if (pedal.action !== 'off' && notesTail > pedal.start &&
                            notesTail <= pedal.end
                        ) {
                            // ペダルの持続時間で更新
                            sustainTime = pedal.end - startTime;
                            break;
                        }
                    }

                    /** 音量 */
                    const velAdj = notes.status.velAdj == undefined ? 0 : notes.status.velAdj;
                    let gain = layer.vels[notes.x] + velAdj;
                    if (gain < 1) gain = 1;
                    if (gain > 10) gain = 10;
                    gain *= 0.6;
                    reserveTasks.push(
                        setTimeout(() => {
                            const index = pitchIndexList[notes.y];
                            const soundName = TheoryUtil.KEY12_SHARP_LIST[index % 12];
                            const octave = Math.floor(index / 12);
                            const soundFullName = soundName + octave;
                            playHarmony(soundFullName, store, sustainTime / 1000, gain);
                        }, startTime)
                    );
                });
            });
        }
    }

    export const stopPreview = (store: Store, state: BackingState.Editor, dispatcher: Dispatcher, reserveTasks: NodeJS.Timeout[]) => {
        store.instruments.harmonyFont?.stop();
        state.isPreview = false;
        reserveTasks.forEach(id => clearInterval(id));
        dispatcher.backing.setState(state);
    }

    export const getLimitLen = (beatLen: number, minute: BackingState.MinuteProps, beatSignature: BeatSignature) => {
        // 16分音符の長さで割る
        const memoriCount = TheoryUtil.getBeatProps(beatSignature).beatMemoriCount;
        const minuteLen = minute.head / memoriCount + minute.tail / memoriCount;
        return (beatLen + minuteLen);
    }

    export const getUsingLenFromDivs = (divs: BackingState.NoteDiv[], beatSignature: BeatSignature) => {
        const beatProps = TheoryUtil.getBeatProps(beatSignature);
        const adjustRate = 4 / beatProps.beatMemoriCount;
        return divs.reduce((prev, cur) => {
            let len = 1 / cur.rate * adjustRate;
            if (cur.isDot) len *= 1.5;
            if (cur.div3 !== 0) len *= cur.div3 / 3;
            return prev + len * 4;
        }, 0);
    }

    export const getInitVoicingTable = (voicingList: VoicingProps[], structCnt: number): boolean[][] => {
        const table = new Array<boolean[]>(8);
        for (let i = 0; i < table.length; i++) {
            table[i] = new Array<boolean>(6);
            const col = table[i];
            for (let j = 0; j < col.length; j++) {
                // 構成音をオーバーしていた場合セットしない
                if (j >= structCnt) continue;

                const isExist = voicingList.find(voicing => {
                    if (voicing.octave === i && voicing.struct === j) {
                        return voicing;
                    }
                });
                table[i][j] = isExist != null;
            }
        }
        return table;
    };

    export const getEditorPatternFromChordBacking = (voicingTable: boolean[][], patternProps: BackingPatternProps | null) => {
        let pattern: BackingState.Pattern | null = null;
        if (patternProps != null) {
            const voicingStructs = BackingUtil.buildVoicsFromTable(voicingTable);
            const channels = BackingUtil.getChannels(voicingStructs, patternProps.channelSize);
            const layers = BackingUtil.convertEditorLayerFromScore(patternProps.channelSize, patternProps.layers);
            let pedalActs: (null | BackingState.PedalAction)[] = [];
            if (patternProps.pedals != undefined) {
                for (let i = 0; i < patternProps.layers[0].divs.length; i++) {
                    const pedal = patternProps.pedals.find(pedal => {
                        if (pedal.divIndex === i) return pedal;
                    });
                    if (pedal == undefined) pedalActs.push(null);
                    else pedalActs.push(pedal.action);
                }
            } else {
                for (let i = 0; i < patternProps.layers[0].divs.length; i++) {
                    if (i === 0) pedalActs.push('on');
                    else pedalActs.push(null);
                }
            }
            pattern = {
                channelIndex: -1,
                lenIndex: 0,
                mode: 'velocity',
                layerIndex: 0,
                channels,
                layers,
                pedalActs
            };
        }
        return pattern;
    }

    export const hasBackingError = (detail: DetailChord, beatSignature: BeatSignature, structCnt: number) => {
        const chordBacking = detail.backing;
        const voicingList = detail.backing.voicingList;
        const pattern = chordBacking.pattern;

        let hasError = false;

        // ボイシングが構成音に収まっているかチェック
        voicingList.some(voicing => {
            if (voicing.struct > structCnt - 1) {
                hasError = true;
                return true; // ループから脱出
            }
        });
        if (pattern != null) {

            const limitLen = BackingUtil.getLimitLen(detail.beatLen, detail.minute, beatSignature);
            pattern.layers.forEach(layer => {

                const totalLen = getUsingLenFromDivs(layer.divs, beatSignature);
                // パターンがコードの長さを超えている場合
                if (limitLen < totalLen) hasError = true;
            });
        }

        return hasError;
    }

    export const checkEditorError = (
        chordInfo: BackingState.ChordInfo,
        voicingList: VoicingProps[],
        pattern: null | BackingState.Pattern
    ): boolean => {
        let hasError = false;
        if (pattern != null) {

            // 選択しているボイシングがチャンネル数に満たない場合
            if (voicingList.length < pattern.channels.length) hasError = true;

            const limitLen = BackingUtil.getLimitLen(chordInfo.beatLen, chordInfo.minute, chordInfo.init.beatSignature);
            pattern.layers.forEach(layer => {

                const totalLen = getUsingLenFromDivs(layer.noteDivList, chordInfo.init.beatSignature);
                // パターンがコードの長さを超えている場合
                if (limitLen < totalLen) hasError = true;
            });

            // 裏レイヤーのノーツと重なっている場合
            pattern.layers[0].table.forEach((record, i) => {
                record.forEach((cell, j) => {
                    if (cell != null) {
                        if (checkOverlapBackLayer(pattern.layers, 0, i, j)) {
                            hasError = true;
                        }
                    }
                });
            })
        }

        return hasError;
    }

    export const checkOverlapBackLayer = (layers: BackingState.Layer[], layerIndex: number, channelIndex: number, divIndex: number): boolean => {
        const baseLayer = layers[layerIndex];
        const backLayer = layers[layerIndex === 0 ? 1 : 0];
        const [left, right] = getDivRange(baseLayer.noteDivList, divIndex);
        // console.log(`left=${left}, right=${right}`);
        let isOverlap = false;
        for (let i = 0; i < backLayer.noteDivList.length; i++) {
            if (backLayer.table[channelIndex][i] != null) {
                const [backLeft, backRight] = getDivRange(backLayer.noteDivList, i);
                if (right > backLeft && left < backRight) {
                    isOverlap = true;
                    break;
                }
            }
        }
        return isOverlap;
    }

    export const getDivRange = (divs: BackingState.NoteDiv[], divIndex: number): [number, number] => {
        let [left, right] = [0, 0];

        divs.slice(0, divIndex + 1).forEach((cur, i) => {
            let len = 1 / cur.rate;
            if (cur.isDot) len *= 1.5;
            if (cur.div3 !== 0) len *= cur.div3 / 3;
            left = right;
            right += len * 4;
        });
        return [left, right];
    }

    export const isPermitDot = (note: BackingState.NoteDiv) => {
        return [8, 4, 2].includes(note.rate) && note.div3 === 0;
    }

    export const isPermit3ren = (note: BackingState.NoteDiv) => {
        return [8, 4].includes(note.rate) && !note.isDot;
    }
}

export default BackingUtil;