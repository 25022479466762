import { Redirect } from "react-router-dom";
import CacheUtil from "../../utils/score/cacheUtil";
import DataUtil from "../../utils/dataUtil";
import { useContext, useEffect } from "react";
import { GlobalContext } from "./systemEntry";
import { ScoreData } from "../../redux/store/score/scoreData";

const DirectView = (props: {
    hashKey: string;
}) => {

    const { store, dispatcher } = useContext(GlobalContext);
    const conteseq = DataUtil.getDecryptionedScoreSeq(props.hashKey);

    const setScoreData = (owner: string, dispnam: string, data: ScoreData) => {
        store.scoreState.focusIndex = 2;
        store.scoreState.distIndex = store.scoreState.focusIndex;
        dispatcher.score.setScoreState({ ...store.scoreState });
        // dispatcher.score.setScoreTabIndex(1);
        dispatcher.score.setScoreData(data);
        // キャッシュの更新
        store.scoreData = data;
        CacheUtil.updateHarmonizeCache(store, dispatcher);
        store.systemState.mainChannelIndex = 5;
        store.systemState.scoreTabIndex = 0;
        store.systemState.directScore = {
            owner, name: dispnam
        };
        dispatcher.system.setState(store.systemState);
    };

    useEffect(() => {
        DataUtil.findScore(conteseq).then(resList => {
            if (resList.length > 0) {
                const source = resList[0]['data'];
                const owner = resList[0]['owner'];
                const dispnam = resList[0]['dispnam'];
                const text = DataUtil.unZip(source);
                setScoreData(owner, dispnam, JSON.parse(text));
            }
        });
    }, []);
    if (store.systemState.directScore == null) {
        return <></>;
    } else {
        return <Redirect to="/" />;
        // return <MainFrame />;
    }
}

export default DirectView;