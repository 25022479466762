import { Redirect } from "react-router-dom";
import DataUtil from "../../utils/dataUtil";
import { useContext, useEffect } from "react";
import { GlobalContext } from "./systemEntry";

const DirectLogin = (props: {
    hashKey: string;
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    useEffect(() => {
        const seq = DataUtil.getDecryptionedUserSeq(props.hashKey);
        console.log(seq);
        DataUtil.findUserInfoFromSeq(seq).then(resList => {
            console.log(resList);
            if (resList.length > 0) {
                const loginProps = resList[0];
                store.userEnv.login = loginProps;
                dispatcher.system.setUserEnv(store.userEnv);
            }
        });
    }, []);
    if (store.userEnv.login == null) {
        return <></>;
    } else {
        return <Redirect to="/" />;
        // return <MainFrame />;
    }
}

export default DirectLogin;