
namespace SystemConst {
    export const AUTH_TEXT_WIDTH = 350;
    export const AUTH_TEXT_HEIGHT = 70;
}

export default SystemConst;

export const APP_MIN_WIDTH = 800;
export const APP_MIN_HEIGHT = 600;

export const TOTAL_HEADER_WIDTH = 44;

export const MAIN_FRAME_MARGIN = 15;
export const SCORE_TAB_MARGIN = 8;

export const BUTTON_SMALL_HEIGHT = 18;
export const BUTTON_MIDIUM_HEIGHT = 28;
export const BUTTON_LARGE_HEIGHT = 34;


// ホームチャンネル
export const HOME_USER_DIV_HEIGHT = 400;

// ハーモナイズタブ

export const FIRST_CHORD_INDEX = 3;

export const HARMONIZE_RIGHT_WIDTH = 220;
export const HARMONIZE_HEAD_WIDTH = 35;
export const HARMONIZE_FOOT_WIDTH = 80;
export const HARMONIZE_STRUCT_WIDTH = 380;
export const HARMONIZE_SECTIONS_WIDTH = 110;

export const ELEMENT_HEIGHT = 134;
export const ELEMENT_HEAD_WIDTH = 15;
export const ELEMENT_FOOT_WIDTH = 60;
export const ELEMENT_SIDE_PADDING = 8;
export const ELEMENT_NOTE_PADDING_LEN = 10;
export const ELEMENT_NOTE_PADDING_SID = 30;
export const ELEMENT_POS_INFO_WIDTH = 50;
export const ELEMENT_OPERATIONS_WIDTH = 20;

export const TIMELINE_BEAT_WIDTH = 80;


export const TUNE_HEADER_WIDTH = 80;
export const TUNE_TIMELINE_WIDTH = 70;
export const TUNE_PITCH_WIDTH = 100;
export const TUNE_PITCH_HEIGHT = 30;



export const PATTERN_OPERATION_HEIGHT = 30;
export const PATTERN_HEADER_HEIGHT = 50;
export const PATTERN_BODY_HEIGHT = 240;
export const PATTERN_FOOTER_HEIGHT = 50;
export const PATTERN_FRAME_HEIGHT = PATTERN_OPERATION_HEIGHT + PATTERN_HEADER_HEIGHT + PATTERN_BODY_HEIGHT + PATTERN_FOOTER_HEIGHT;


export const NOTES_1BAR_WIDTH = 480;