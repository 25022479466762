import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { Store } from "../../../../../redux/store/store";
import { KeyboardEvent } from "react";
import ThemaSelectorFrame from "../../../../thema/themaSelectorFrame";
import LibraryUtil from "../../../../../utils/LibraryUtil";

namespace ThemaSelectorInput {

    export const main = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher) => {

        const config = store.userEnv.keyConfig.note;
        const keySwitch = store.scoreState.keySwitch;
        // alert(`[${e.key}]`);
        // alert(config.nextElement);

        const cache = store.systemState.cache as ThemaSelectorFrame.CachePorps;
        const update = () => {
            // cache.updateBacking();
            cache.invalidate();
        }
        const field = cache.field;

        const moveList = (isDown: boolean) => {
            if (field.focusSide === 0) {
                // パターン
                if (!isDown && field.pattIndex >= 1) {
                    field.pattIndex--;
                    field.voicIndex = 0;
                    update();
                } else if (isDown && field.pattIndex < cache.patternList.length - 1) {
                    field.pattIndex++;
                    field.voicIndex = 0;
                    update();
                }
            } else {
                // ボイシング
                const patternList = cache.patternList;
                const filteredList = cache.voicingList.filter((voic) => {
                    return LibraryUtil.acceptVoic(voic, patternList, field.pattIndex);
                });
                if (!isDown && field.voicIndex >= 1) {
                    field.voicIndex--;
                    update();
                } else if (isDown && field.voicIndex < filteredList.length - 1) {
                    field.voicIndex++;
                    update();
                }
            }
        }

        const moveSide = (isRight: boolean) => {
            if (!isRight) {
                if (field.focusSide === 1) {
                    field.focusSide = 0;
                    update();
                }
            } else {
                if (field.focusSide === 0) {
                    field.focusSide = 1;
                    update();
                }
            }
        }

        if (!cache.isPreview) {
            switch (e.key) {
                case 'e': {
                    dispatcher.score.closeLibraryWnd();
                } break;
                case 'Enter': {
                    const pattern = cache.patternList[field.pattIndex];
                    const voicing = cache.voicingList.filter(voic => {
                        return LibraryUtil.acceptVoic(voic, cache.patternList, field.pattIndex);
                    })[field.voicIndex];
                    dispatcher.thema.mappingSelectorToScore(voicing.source, pattern.source);
                    dispatcher.score.closeLibraryWnd();
                } break;
                case 'ArrowLeft': {
                    moveSide(false);
                } break;
                case 'ArrowRight': {
                    moveSide(true);
                } break;
                case 'ArrowUp': {
                    moveList(false);
                } break;
                case 'ArrowDown': {
                    moveList(true);
                } break;
            }
        }
        // プレビュー中か否かに影響されない
        switch (e.key) {
            case ' ': {
                if (cache.isPermitTest()) {
                    cache.testBacking();
                }
            } break;
        }
    }

}
export default ThemaSelectorInput;