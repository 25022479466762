import { useContext } from "react";
import styled, { css } from "styled-components";
import { DetailChord } from "../../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../../utils/theoryUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import { SideFormStyles } from "./sideFromStyles";
import MinuteForm from "./minuteForm";
import BackingUtil from "../../../../../utils/backingUtil";

/**
 * コードブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const SideFormChord = (props: {
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const cur = store.scoreData.chordList[state.focusIndex];
    const detail = cur.detail as DetailChord;
    const cache = state.elementCacheList[state.focusIndex];
    const chordCache = state.chordCacheList[cache.chordBlockNo];

    const beatItemsJsx: JSX.Element[] = [];
    for (let i = 0; i < 4; i++) {
        const isExist = detail.beatLen >= (i + 1);
        beatItemsJsx.push(
            <_BeatItem key={i}
                isExist={isExist}
                isActive={detail.beatLen === i + 1}
                onClick={() => {
                    const data = store.scoreData;
                    data.chordList = data.chordList.slice();
                    detail.beatLen = (i + 1);
                    data.chordList[store.scoreState.focusIndex].detail = detail;
                    dispatcher.score.setScoreData(data);
                }}>
                <span></span>
            </_BeatItem>
        );
    }

    const rootItemsJsx: JSX.Element[] = [];
    TheoryUtil.DEGREE_ALL.forEach((degree, i) => {
        const isRootActive = JSON.stringify(detail.root) === JSON.stringify(degree);
        const isOnActive = detail.on != null && JSON.stringify(detail.on) === JSON.stringify(degree);
        const scaleIndexies = cache.scale === 'major' ? TheoryUtil.MAJOR_SCALE_INTERVALS : TheoryUtil.MINOR_SCALE_INTERVALS;
        const isDiatonic = degree != null && scaleIndexies.includes(degree.index);
        rootItemsJsx.push(
            <_RootItem key={i}
                isRootActive={isRootActive}
                isOnActive={isOnActive}
                isDiatonic={isDiatonic}
                onClick={() => {
                    const data = store.scoreData;
                    // 分母が設定済みの箇所を上書きする
                    if (isOnActive || degree == null) {
                        detail.on = null;
                    }
                    detail.root = degree == null ? null : { ...degree };
                    if (degree == null) {
                        detail.symbolKey = 'major';
                    }
                    data.chordList[store.scoreState.focusIndex].detail = detail;
                    dispatcher.score.setScoreData(data);

                    // キャッシュの更新
                    const root = detail.root as TheoryUtil.DegreeProps;
                    const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                    [chordCache.chordFullName, chordCache.structList] =
                        BackingUtil.getChordDetails(root, cache.keyIndex, detail.on, symbol);
                    dispatcher.score.setScoreState(state);
                }}
                onContextMenu={() => {
                    const data = store.scoreData;
                    // ルートが未設定の箇所にのみ設定可能
                    if (detail.root != null && !isRootActive) {
                        // 設定済みの箇所をクリックで解除
                        if (isOnActive) {
                            detail.on = null;
                        } else {
                            detail.on = degree == null ? null : { ...degree };
                        }
                        data.chordList[store.scoreState.focusIndex].detail = detail;
                        dispatcher.score.setScoreData(data);

                        // キャッシュの更新
                        const root = detail.root as TheoryUtil.DegreeProps;
                        const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                        [chordCache.chordFullName, chordCache.structList] =
                            BackingUtil.getChordDetails(root, cache.keyIndex, detail.on, symbol);
                        dispatcher.score.setScoreState(state);
                    }
                }}
            >
                {degree == null ? '-' : <>
                    {TheoryUtil.getDegreeRoot(degree)} ({TheoryUtil.getRootName(degree, cache.keyIndex)})
                </>}
            </_RootItem>
        );
    });

    const symbolItemsJsx: JSX.Element[] = [];
    TheoryUtil.SYMBOL_LIST.forEach((symbol, i) => {
        const isActive = detail.root != null && symbol.key === detail.symbolKey;
        symbolItemsJsx.push(
            <_SymbolItem key={i}
                isActive={isActive}
                isDiatonic={true}
                onClick={() => {
                    const data = store.scoreData;
                    detail.symbolKey = symbol.key;
                    data.chordList[store.scoreState.focusIndex].detail = detail;
                    dispatcher.score.setScoreData(data);

                    // キャッシュの更新
                    const root = detail.root as TheoryUtil.DegreeProps;
                    [chordCache.chordFullName, chordCache.structList] =
                        BackingUtil.getChordDetails(root, cache.keyIndex, detail.on, symbol);
                    dispatcher.score.setScoreState(state);
                }}
            >
                [{symbol.name}]
            </_SymbolItem>
        );
    });

    /**
     * シンコペーションの入力フォームを生成するコールバック
     * @param isHead 頭対象フラグ（falseの場合後ろが対象）
     * @returns JSX
     */
    const createMinuteForm = (isHead: boolean): JSX.Element => {
        const minute = detail.minute;
        const [value, setValue]: [number, (value: number) => void] = isHead ? [
            minute.head,
            (value: number) => {
                // minute.head = value;
                // dispatcher.score.setScoreData(store.scoreData);
                const adjust = value - minute.head;
                dispatcher.score.incrementMinuteValRelation(false, adjust);
            }
        ] : [
            minute.tail,
            (value: number) => {
                // minute.tail = value;
                // dispatcher.score.setScoreData(store.scoreData);
                const adjust = value - minute.tail;
                dispatcher.score.incrementMinuteValRelation(true, adjust);
            }
        ];
        return <MinuteForm value={value} setValue={setValue} isHead={isHead} />
    }

    return (
        <_Wrap>
            <_Timing>
                <_CategoryLabel>{'Beat length'}</_CategoryLabel>
                <_BeatDiv>
                    {beatItemsJsx}
                </_BeatDiv>
                <_Minute>
                    <_CategoryLabel>{'Minute head'}</_CategoryLabel>
                    {createMinuteForm(true)}
                    {/* <_MinuteItem isReverse={true} type="range" step={0.25} min={-0.5} max={0.5}
                        value={detail.minute.head}
                        onChange={(e) => {
                            const data = store.scoreData;
                            data.chordList = data.chordList.slice();
                            detail.minute.head = Number(e.target.value);
                            data.chordList[store.scoreState.focusIndex].detail = detail;
                            dispatcher.score.setScoreData(data);
                        }}
                    /> */}
                    <_CategoryLabel>{'Minute tail'}</_CategoryLabel>
                    {createMinuteForm(false)}
                    {/* <MinuteForm isHeadAdjust={false} /> */}
                    {/* <_MinuteItem isReverse={false} type="range" step={0.25} min={-0.5} max={0.5}
                        value={detail.minute.tail}
                        onChange={(e) => {
                            const data = store.scoreData;
                            data.chordList = data.chordList.slice();
                            detail.minute.tail = Number(e.target.value);
                            data.chordList[store.scoreState.focusIndex].detail = detail;
                            dispatcher.score.setScoreData(data);
                        }}
                    /> */}
                </_Minute>
            </_Timing>
            <_Chord>
                <_RootDiv>
                    {rootItemsJsx}
                </_RootDiv>
                <_SymbolDiv
                    isDisable={detail.root == null}
                >
                    {symbolItemsJsx}
                </_SymbolDiv>
            </_Chord>
        </_Wrap>
    );
}

export default SideFormChord;


const _CategoryLabel = styled.div`
    ${SideFormStyles.___CSS_TITLE}
    margin-top: 4px;
`;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    background-color: #8a8a8a;
`;

const _Timing = styled.div`
    display: inline-block;
    width: 100%;
    /* height: 100px; */
    text-align: left;
    background-color: #979797;
`;

const _BeatDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 25px;
    text-align: left;
    background-color: #afafaf;
`;
const _BeatItem = styled.div<{
    isExist: boolean;
    isActive: boolean;
}>`
    display: inline-block;
    width: 25%;
    height: 20px;
    text-align: left;
    background-color: #696969;

    & span{
        display: inline-block;
        margin: 2px 0 0 3px;
        width: calc(100% - 6px);
        height: calc(100% - 4px);
        background-color: #9b8585;
        ${props => !props.isActive ? '' : css`
            /* border: solid 1px #fff; */
        `}
        box-sizing: border-box;
        border-radius: 6px;
        ${props => !props.isExist ? '' : css`
            /* background-color: #ebf37eb7; */
            background: linear-gradient(to bottom, #c5c5c5d2, #f3f3f3d2, #c5c5c5d2);
        `}
    }
`;

const _Minute = styled.div`
    display: inline-block;
    width: 100%;
    text-align: left;
    background-color: #cecece;
`;

const _Chord = styled.div`
    display: inline-block;
    margin-top: 5px;
    width: 100%;
    /* height: 360px; */
    text-align: left;
    /* background-color: #000000; */
    & * {
        vertical-align: top;
    }
`;

const _RootDiv = styled.div`
    display: inline-block;
    width: 90px;
    margin: 0 0 0 1px;
    height: 100%;
    text-align: left;
    /* background-color: #e6e6e6; */
`;
const _RootItem = styled.div<{
    isRootActive: boolean;
    isOnActive: boolean;
    isDiatonic: boolean;
}>`
    display: inline-block;
    margin: 1px 0 0 0;
    width: 100%;
    height: 25px;
    text-align: left;
    background-color: #90bbcc;
    ${props => !props.isRootActive ? '' : css`background-color: #d1c521b8;`}
    ${props => !props.isOnActive ? '' : css`background-color: #6070ffbc;`}
    border: 1px solid #000;
    border-radius: 2px;
    box-sizing: border-box;
    color: #616161;
    ${props => !props.isDiatonic ? '' : css`color: #ffffff;`}
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    & span {
        color: #9e9e9e;
    }
    &:hover {
        background-color: #99999940;
        ${props => !props.isRootActive ? '' : css`background-color: #cc6363b8;`}
        ${props => !props.isOnActive ? '' : css`background-color: #6374ccb8;`}
    }
`;

const _SymbolDiv = styled.div<{
    isDisable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 93px);
    margin: 0 1px 0 1px;
    height: 100%;
    text-align: left;
    ${props => !props.isDisable ? '' : css`
        opacity: 0.3;
        pointer-events: none;
    `}
`;

const _SymbolItem = styled.div<{
    isActive: boolean;
    isDiatonic: boolean;
}>`
    ${SideFormStyles.___CSS_FORM}
    margin: 1px 0 0 0;
    width: 100%;
    background-color: #a1cc90;
    ${props => !props.isActive ? '' : css`background-color: #d1c521b8;`}
    color: #fff;
    ${props => !props.isDiatonic ? '' : css`color: #fff;`}
    /* & span {
        color: #f000;
    } */
    &:hover {
        background-color: #dddddd40;
        ${props => !props.isActive ? '' : css`background-color: #cc6363b8;`}
    }
`;