import { useContext } from "react";
import styled, { css } from "styled-components";
import { _CSS_DISABLE } from "../../../../../base/design/styles";
import { GlobalContext } from "../../../../entry/systemEntry";


const QuantizeSwitch = (props: {
    labelName: string;
    isActive: boolean;
    isDisable: boolean;
    callback: () => void;
}) => {
    // const { store, dispatcher } = useContext(GlobalContext);
    // const state = store.melodyState;

    return (
        <_Wrap
            isActive={props.isActive}
            isDisable={props.isDisable}
            onClick={props.callback}
        >
            {props.labelName}
        </_Wrap>
    );
}

export default QuantizeSwitch;

const _Wrap = styled.div<{
    isActive: boolean;
    isDisable: boolean;
}>`
    display: inline-block;
    width: 100px;
    height: 28px;
    background: linear-gradient(to bottom, #e0e0e0, #aaaaaa, #e0e0e0);
    ${props => !props.isActive ? '' : css`
        background: linear-gradient(to bottom, #b7d9df, #59c6da, #b7d9df);
    `}
    ${props => !props.isDisable ? '' : _CSS_DISABLE}
    color: #5d6e63;
    border-radius: 2px;
    border: solid 1px #747373;
    font-size: 20px;
    font-weight: 600;
    text-align: center;

    margin-left: 4px;
`;