import { useContext } from "react";
import styled, { css } from "styled-components";
import { ELEMENT_POS_INFO_WIDTH, FIRST_CHORD_INDEX } from "../../../../../../utils/systemConst";
import { GlobalContext } from "../../../../../entry/systemEntry";
import { DetailChord } from "../../../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../../../utils/theoryUtil";

const CalcChord = (props: {
    index: number;
    detail: DetailChord;
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const chordList = store.scoreData.chordList;
    const element = chordList[props.index];
    const cache = state.elementCacheList[props.index];

    const symbolName = TheoryUtil.getSymbolFromKey(props.detail.symbolKey)?.name;

    let degreeRoot: null | string = null;
    let degreeOn: null | string = null;
    let chordRoot: null | string = null;
    let chordOn: null | string = null;
    if (props.detail.root != null) {
        degreeRoot = TheoryUtil.getDegreeRoot({
            index: props.detail.root.index,
            isFlat: props.detail.root.isFlat
        });
        chordRoot = TheoryUtil.getRootName({
            index: props.detail.root.index,
            isFlat: TheoryUtil.isFlat(cache.keyIndex, cache.scale)
        }, cache.keyIndex);

        if (props.detail.on != null) {
            degreeOn = TheoryUtil.getDegreeRoot({
                index: props.detail.on.index,
                isFlat: props.detail.on.isFlat
            });
            chordOn = TheoryUtil.getRootName({
                index: props.detail.on.index,
                isFlat: props.detail.on.isFlat
            }, cache.keyIndex);
        }
    }

    let bar = -1;
    const prevCache = state.elementCacheList[props.index - 1];
    // 先頭のコード要素である、もしくは小節が繰り上がった際に表示
    // 実際の小節は1から数える
    if (props.index === FIRST_CHORD_INDEX || prevCache.bar !== cache.bar) {
        // 見た目上１から数えるため
        bar = cache.bar + 1;
    }
    // 小節変更タイミングで、余りの拍がある（頭でない）場合エラー
    const isError = bar !== -1 && cache.surplusBeat !== 0;

    let chordName = '-';
    if (chordRoot != null) {
        chordName = `${chordRoot}${symbolName}`;
        if(chordOn != null) {
            chordName += `/${chordOn}`;
        }
    }

    let minuteStr = '';
    if(cache.minute !== 0) {
        switch(cache.minute) {
            case 1: minuteStr = '→/16'; break;
            case 2: minuteStr = '→/8'; break;
            case -1: minuteStr = '←/16'; break;
            case -2: minuteStr = '←/8'; break;
        }
    }
    return (
        <_Detail>
            <_Chord>{chordName}</_Chord>
            {/* <_Backing>
            </_Backing> */}
            <_Pos>
                <_Section
                    isError={isError}
                >
                    {bar === -1 ? <>&nbsp;</> : bar}
                </_Section>
                <_Beat>
                    <span>
                        {cache.surplusBeat === 0 ? <>&nbsp;</> : cache.surplusBeat}
                    </span>
                    {cache.minute === 0 ? '' : minuteStr}
                </_Beat>
                <_PastSecond>
                    {Math.round(cache.pastSecond / 100) / 10}s
                </_PastSecond>
            </_Pos>
        </_Detail>
    );
}

export default CalcChord;



const _Section = styled.div<{
    isError: boolean;
}>`
    font-size: 12px;
    font-weight: 600;
    color: #c72929;
    ${props => !props.isError ? '' : css`
        background-color: #ee5959;
        color: #ffffff;
        /* border: 2px solid #c72929; */
        border-radius: 2px;
    `}
    box-sizing: border-box;
`;
const _PastSecond = styled.div`
    font-size: 12px;
    font-weight: 600;
    color: #000000a0;
    box-sizing: border-box;
`;

const _Beat = styled.div``;

const _Detail = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 50px;
    // background-color: #00ff2a11;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    text-align: center;
    box-sizing: border-box;
`;

const _Chord = styled.div`
    display: inline-block;
    // width: calc(100% - ${ELEMENT_POS_INFO_WIDTH + 10}px);
    width: 100%;
    height: 18px;
    // background-color: #ffc4001c;
    font-size: 12px;
    font-weight: 600;
    color: #2b2b2b;
`;

const _Backing = styled.div`
    display: inline-block;
    width: 56px;
    height: 30px;
    background-color: #1100ff1c;
`;

const _Pos = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 5;
    width: ${ELEMENT_POS_INFO_WIDTH}px;
    height: 100%;
    // background-color: #0000ff11;
    top: 0;
    left: -${ELEMENT_POS_INFO_WIDTH / 2}px;
    box-sizing: border-box;
    text-align: center;
    
    & ${_Section} {
    }
    & ${_Beat} {
        font-size: 12px;
        color: #333;
        & span {
            color: #00a;
            font-weight: 600;
        }
    }
`;
