import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../base/design/styles";
import CanvasUtil from "../../../utils/canvasUtil";
import FormUtil from "../../../utils/form/formUtiil";
import ValidateUtil from "../../../utils/form/validateUtil";
import { GlobalContext } from "../../entry/systemEntry";
import LoginCreateAccount from "./loginCreateAccount";
import LoginLogin from "./loginLogin";

const WIDTH = 600;
const HEIGHT = 500;

/**
 * ログインフレームのコンポーネント
 * @returns 
 */
const LoginFrame = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const [isCreate, setCreate] = useState(false);

    const logo = useMemo(() => {
        return CanvasUtil.getLogoURL();
    }, []);

    return (
        <_Frame
            width={WIDTH} height={HEIGHT}
        >
            <_LogoDiv>
                <img src={logo} />
            </_LogoDiv>
            <_Header>
                <_TypeSwitch isActive={!isCreate} onClick={() => {
                    setCreate(false);
                }}>Login</_TypeSwitch>
                <_TypeSwitch isActive={isCreate} onClick={() => {
                    setCreate(true);
                }}>Create Account</_TypeSwitch>
            </_Header>
            {!isCreate ? <LoginLogin /> : <LoginCreateAccount />}
            {/* <_Footer>
                <FormUtil.ButtonRecord
                    buttons={[
                        { name: 'Close', callback: closeAction },
                        { name: 'Enter', callback: enterAction },
                    ]}
                />
            </_Footer> */}
        </_Frame>
    );
}

export default LoginFrame;

const _Frame = styled.div<{
    width: number;
    height: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 11;
    top: calc(50% - ${props => props.height / 2}px);
    left: calc(50% - ${props => props.width / 2}px);
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: #e7e7e7e2;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
`;

const _LogoDiv = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 80px;
    background-color: #eeeaea;
    text-align: center;
`;
const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 50px;
    /* background-color: #d1d1d1; */
    text-align: center;
`;

const _TypeSwitch = styled.div<{
    isActive: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    background-color: #bf808075;
    ${props => !props.isActive ? '' : css`
        background-color: #c7a73e;
    `}
    color: white;
    width: 250px;
    margin: 10px 10px;
    text-align: center;
    &:hover {
    background-color: #bf8080a2;
        ${props => !props.isActive ? '' : css`
            background-color: #c7a73e;
        `}
    }
`;
const _Record = styled.div<{
}>`
    display: inline-block;
    margin: 2px 0 0 0;
    width: 100%;
    height: 50px;
    /* background-color: #e6e3c1; */
    text-align: left;
`;

const _Label = styled.div<{
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    /* background-color: #80e45832; */
    color: #bd2d2d;
    width: 150px;
    margin: 10px 0 0 5px;
    text-align: center;
`;

const _TextField = styled.input<{
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    background-color: #f7f09787;
    width: 300px;
    margin: 10px 0 0 15px;
    text-align: center;
    border: solid 1px #5e5e5e;
    text-align: left;
    box-sizing: border-box;
    border-radius: 4px;
`;

const _Footer = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 6px 4px 0 0;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    background-color: #d1d1d1;
    text-align: right;
`;

const _ExecutableButton = styled.div<{
    isEnable: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE};
    background-color: #5c9253;
    width: 150px;
    margin: 10px 5px 0 0;
    text-align: center;
    &:hover {
        background-color: #7eaf75;
    }
`;