
import { useContext, useMemo } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../utils/backingUtil";
import TheoryUtil from "../../../utils/theoryUtil";
import { GlobalContext } from "../../entry/systemEntry";

/**
 * ボイシングを定義するテーブルのコンポーネント
 * @returns コンポーネント
 */
const VoicingChooser = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const table = state.voicingTable;
    const pattern = state.pattern;
    // 選択可能なチャンネル数
    const channelLimitNum = pattern == null ? BackingUtil.OCTAVE_NUM * BackingUtil.STRUCT_NUM : pattern.channels.length;

    // return useMemo(() => {

    const chordInfo = state.chordInfo;
    const structList = chordInfo.structList;

    const rowJsxList: JSX.Element[] = [];

    /** 選択数 */
    let selectCount = 0;
    // オクターブ（テーブル横）のループ
    for (let i = 0; i < BackingUtil.OCTAVE_NUM; i++) {
        const cellJsxList: JSX.Element[] = [];
        // 構成音（テーブル縦）のループ
        for (let j = 0; j < BackingUtil.STRUCT_NUM; j++) {
            let isEnable: boolean = structList.length > j;
            let isSelected: boolean = table[i][j];
            if (isSelected) {
                selectCount++;
            }

            /** セルに表示する音程 */
            const getSoundFullName = (): string => {
                if (!isEnable) return '';
                // const relation = symbol.structs[j];
                // const interval = TheoryUtil.IntervalRelationProps[relation];
                // const structIndex = (chordRootIndex + interval) % 12;
                const sound = structList[j];
                const octave = i + sound.adjustOctave;

                const key12List = !sound.isFlat ?
                    TheoryUtil.KEY12_SHARP_LIST :
                    TheoryUtil.KEY12_FLAT_LIST
                    ;
                return `${key12List[sound.soundIndex]}${octave}`;
            }

            const name = getSoundFullName();
            cellJsxList.push(
                <_Cell key={j}
                    isEnable={isEnable}
                    isSelected={isSelected}
                    isOver={isSelected && selectCount > channelLimitNum}
                    onClick={() => {
                        table[i][j] = !isSelected;
                        if (pattern != null) {
                            const voicingStructs: BackingState.VoicParam[] = BackingUtil.buildVoicsFromTable(table);
                            pattern.channels = BackingUtil.getChannels(voicingStructs, pattern.channels.length);
                        }
                        dispatcher.backing.setState({ ...state, voicingTable: table, pattern });
                        dispatcher.backing.updateErrorState();
                    }}
                    onContextMenu={() => {
                        const soundfont = store.instruments.harmonyFont;
                        if (soundfont != null) {
                            soundfont.play(name, 0, { duration: 1 });
                        }
                    }}
                >
                    {name}
                </_Cell>
            );
        }
        rowJsxList.push(
            <_TableRow key={i}>
                {cellJsxList}
            </_TableRow>
        );
    }

    /** 構成音リストのJSX */
    const structJsxList = structList.map((sound, i) => {
        const key12List = !sound.isFlat ? TheoryUtil.KEY12_SHARP_LIST : TheoryUtil.KEY12_FLAT_LIST;

        /** 表示する構成音の関係性 */
        const getStructName = (): string => {
            if (sound.relation == null) {
                // オンコードの場合
                return 'On';
            } else if (sound.relation === 'p1') {
                // ルート音の場合
                return 'Root';
            }
            return sound.relation;
        }
        return (
            <_StructItem key={i}
                isOnChord={sound.relation == null}
            >{getStructName()} [{key12List[sound.soundIndex]}]</_StructItem>
        );
    });

    return (
        <_Wrap>
            {/* コードの基本情報 */}
            <_InfoDiv>
                <_InfoItem>
                    <_Label>Chord</_Label>
                    <_Value>{chordInfo.chordFullName}</_Value>
                </_InfoItem>
                <_InfoItem>
                    <_Label>Attr</_Label>
                    {/* <_Value>{symbol.attr}</_Value> */}
                    <_Value>{chordInfo.symbolAttr}</_Value>
                </_InfoItem>
            </_InfoDiv>
            <_EditorDiv>
                {/* 構成音の情報 */}
                <_StructDiv>
                    {structJsxList}
                </_StructDiv>
                {/* ボイシングのテーブル */}
                <_TableDiv>{rowJsxList}</_TableDiv>
            </_EditorDiv>
        </_Wrap>
    );
    // }, [table, channelLimitNum]);
}

export default VoicingChooser;

const TIP_WIDTH = 60;
const TIP_HEIGHT = 22;

const _Wrap = styled.div`
    display: inline-block;
    width: 600px;
`;

const _InfoDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 30px;
    background-color: #338f8f;
`;

const _InfoItem = styled.div`
    display: inline-block;
    width: 50%;
    height: 100%;
    /* background-color: #89dada; */
`;

const _Label = styled.div`
    display: inline-block;
    margin-top: 4px;
    margin-left: 5px;
    height: calc(100% - 8px);
    width: 80px;
    background-color: #69bcdd;
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #026c86;
    border: 1px solid #303030;
    border-radius: 2px;
    box-sizing: border-box;
    text-align: center;
`;

const _Value = styled.div`
    display: inline-block;
    margin-top: 4px;
    margin-left: 6px;
    height: calc(100% - 8px);
    /* background-color: #cccccc; */
    font-size: 18px;
    line-height: 18px;
    font-weight: 600;
    color: #ffffff;
`;

const _EditorDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: ${(TIP_HEIGHT + 1) * 6 + 4}px;
    background-color: #c09e05;
    border: solid 1px #888;
    border-radius: 5px;
    box-sizing: border-box;

    & *{
        vertical-align: top;
        height: 100%;
    }
`;

const _StructDiv = styled.div`
    display: inline-block;
    width: calc(100% - ${TIP_WIDTH * 8}px);
    /* width: 100px; */
    background-color: #8fb5bc;
`;

const _StructItem = styled.div<{
    isOnChord: boolean;
}>`
    display: inline-block;
    width: calc(100% - 2px);
    height: ${TIP_HEIGHT}px;
    margin-top: 1px;
    /* width: 100px; */
    /* background-color: #8fb5bc; */
    color: white;
    background: linear-gradient(to bottom, #10828a, #76d5db, #10828a);
    ${props => !props.isOnChord ? '' : css`
        /* color: #e00000; */
        background: linear-gradient(to bottom, #104f8a, #7699db, #104f8a);
    `}
    font-size: 16px;
    font-weight: 600;
    padding-left: 5px;
    box-sizing: border-box;
`;

const _TableDiv = styled.div`
    display: inline-block;
    background-color: #a7acd8;
`;

const _TableRow = styled.div`
    display: inline-block;
    width: ${TIP_WIDTH}px;
    height: 100%;
    /* margin-left: 1px; */
    background-color: #a7d8c4;
    padding-bottom: 2px;
    box-sizing: border-box;
`;

const _Cell = styled.div<{
    isEnable: boolean;
    isSelected: boolean;
    isOver: boolean;
}>`
    display: inline-block;
    width: calc(100% - 1px);
    height: ${TIP_HEIGHT}px;
    margin: 1px 0 0 1px;
    background-color: #409ca3;
    border: 1px solid #215e53;
    border-radius: 4px;
    color: #01004e;
    ${props => !props.isSelected ? '' : css`
        background-color: #e5e747;
    `}
    ${props => !props.isOver ? '' : css`
        background-color: #c40000;
        color: #ffffff;
    `}
    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}
    /* border: 1px solid #ffffff; */
    box-sizing: border-box;

    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    text-align: center;

    &:hover {
        background-color: #117dbb;
        ${props => !props.isSelected ? '' : css`
            background-color: #b9bb3f;
        `}
        ${props => !props.isOver ? '' : css`
            background-color: #bb3f3f;
        `}
    }
`;

