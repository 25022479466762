import styled, { css } from "styled-components";
import { TOTAL_HEADER_WIDTH } from "../../utils/systemConst";
import { ImUser } from 'react-icons/im'
import { MdOutlineLanguage } from 'react-icons/md'
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "./systemEntry";
import DataUtil from "../../utils/dataUtil";
import ConfirmDialog from "../dialog/confirmDialog";
import LoginFrame from "../dialog/login/loginFrame";
import AccountFrame from "../dialog/accountFrame";

const TotalHeader = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const loginProps = store.userEnv.login;

    const [userId, setUserId] = useState('');

    useEffect(() => {
        console.log(loginProps);
        if (loginProps != undefined) {
            setUserId('@' + loginProps.id);
        }
    }, [loginProps]);

    const authButtonJsx = loginProps == undefined ? (
        <_LoginButton onClick={() => {
            store.systemState.dialog = <LoginFrame />;
            dispatcher.system.setState(store.systemState);
            console.log(window.location.origin);
        }}>
            <div><ImUser /></div>
            Login / Sign up
        </_LoginButton>
    ) : (<>
        <_UserName
            isEntry={store.userEnv.login != null}
            onClick={() => {
                dispatcher.system.setDialog(<AccountFrame />);
            }}
        >{userId}</_UserName>
        <_LoginButton onClick={() => {
            store.userEnv.login = null;
            dispatcher.system.setUserEnv(store.userEnv);

            dispatcher.system.setDialog(<ConfirmDialog.Component
                message={'Logout.\nSee you next time!'}
            />);
        }}>
            <div><ImUser /></div>
            Logout
        </_LoginButton>
    </>);
    return (
        <_Wrap>
            <_Label>
                <_ACCENT>L</_ACCENT><_NORMAL>iCreSia</_NORMAL>
            </_Label>
            <_Menu>
                {authButtonJsx}
                <_Language>
                    <div><MdOutlineLanguage /></div>
                </_Language>
            </_Menu>
        </_Wrap>
    );
}

export default TotalHeader;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${TOTAL_HEADER_WIDTH}px;
    background: linear-gradient(to bottom, #ececec, #e2e2e2, #eeeeee);
    /* background-color: #c2c2c2; */
    box-shadow: 0 0 8px 0 #000b3a48;
`;

const _Label = styled.div`
    display: inline-block;
    margin: 2px 0 0 10px;
    width: 200px;
    height: calc(100% - 4px);
    /* background-color: #00000013; */
    font-size: 34px;
    font-weight: 600;
    line-height: 36px;
    text-shadow: 1px 2px 3px #8080806a;
`;

const _NORMAL = styled.span`
    color: #ffffff;
`;
const _ACCENT = styled.span`
    font-size: 34px;
    color: #da4242;
`;



const _Menu = styled.div`
    display: inline-block;
    /* width: 300px; */
    height: 100%;
    position: absolute;
    right: 30px;
    /* background-color: #ff00002b; */
`

const _MenuItem = styled.div`
    background-color: #c9c7c7;
    &:hover {
        background-color: #cf9bae;
    }
    transition: background-color 800ms;
`

const _LoginButton = styled(_MenuItem)`
    display: inline-block;
    /* width: 240px; */
    padding: 0 30px;
    margin: 5px 10px 0 0;
    height: calc(100% - 10px);
    border-radius: 20px;
    /* background: linear-gradient(to bottom, #e9baba, #d39d9d, #e9baba); */
    /* background-color: #adadad; */
    /* border: 1px solid #7e7e7e; */
    /* box-shadow: 0 0 8px 0 #000b3a48; */
    /* text-shadow: 1px 2px 3px #808080ba; */
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #ffffff;
    vertical-align: top;

    & div {
        display: inline-block;
        /* background-color: #0000007d; */
        margin: 2px 5px 0 0;
        vertical-align: top;
    }
`;

const _UserName = styled.div<{
    isEntry: boolean;
}>`
    display: inline-block;
    /* width: 240px; */
    margin: 5px 20px 0 0;
    height: calc(100% - 10px);
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #003a86;
    ${props => props.isEntry ? '' : css`
        color: #96000057;
    `}
    vertical-align: top;
`;

const _Language = styled(_MenuItem)`
    display: inline-block;
    width: ${TOTAL_HEADER_WIDTH - 6}px;
    height: ${TOTAL_HEADER_WIDTH - 6}px;
    margin: 3px 0 0 0;
    border-radius: 50%;
    /* background: linear-gradient(to bottom, #e9baba, #d39d9d, #e9baba); */
    /* background-color: #48aa9d; */
    /* border: 1px solid #7e7e7e; */
    /* box-shadow: 0 0 8px 0 #000b3a48; */
    /* text-shadow: 1px 2px 3px #808080ba; */
    /* text-align: center; */
    font-size: 28px;
    font-weight: 600;
    color: #ffffff;
    & div {
        display: inline-block;
        margin: 2px 0 0 5px;
        /* background-color: #0000007d; */
        vertical-align: top;
    }
`;