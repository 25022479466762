import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { TabbedPane } from "../../base/components/tab/tabbedPane";
import Styles, { _CSS_LABEL_MIDIUM } from "../../base/design/styles";
import { SCORE_TAB_MARGIN } from "../../utils/systemConst";
import { GlobalContext } from "../entry/systemEntry";
import BackingLibraryTab from "./backing/backingLibraryTab";


const CustomChannel = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    return (
        <_Wrap>
            {/* <DraggableDialog /> */}
            {/* <ExecutableButton labelText={'test'} size={'midium'} /> */}
            <TabbedPane
                tabElements={[
                    { name: 'Backing-Library', disable: true, cont: <BackingLibraryTab /> },
                    { name: 'Design', disable: true, cont: <div></div> }
                ]}
                activeNo={0}
                // selectTabIndex={(index: number) => dispatcher.score.setScoreTabIndex(index)}
            />
        </_Wrap>
    );
}
export default CustomChannel;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    margin: ${SCORE_TAB_MARGIN}px 0 0 ${SCORE_TAB_MARGIN}px;
    width: calc(100% - ${SCORE_TAB_MARGIN * 2}px);
    height: calc(100% - ${SCORE_TAB_MARGIN * 2}px);
    background-color: transparent;
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;

