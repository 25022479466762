import { useContext, useState, useEffect, useMemo, createContext, KeyboardEvent } from "react";
import styled, { css } from "styled-components";
import { TabbedPane } from "../../../base/components/tab/tabbedPane";
import BackingEditorFrame from "../../backing/backingEditorFrame";
import { GlobalContext } from "../../entry/systemEntry";
import BackingEditorInput from "../../score/edit/harmonize/input/backingEditorInput";
import AttrFormTab from "./form/attrFormTab";
import BaseFormTab from "./form/baseFormTab";
import MethFormTab from "./form/methFormTab";
import PattFormTab from "./form/pattFormTab";
import RootFormTab from "./form/rootFormTab";
import SustFormTab from "./form/sustFormTab";
import ThemaEditTabUtil from "./form/themaEditTabUtil";
import VoicFormTab from "./form/voicFormTab";
import HierarchyNavi from "./hierarchyNavi";
import ThemaUtil from "./themaUtil";
import TreeUtil from "./treeUtil";


export const ThemaTreeContext = createContext({} as ThemaEditTabUtil.ComponentArgs);

const BackingLibraryTab = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const [themaData, setThamaData] = useState(ThemaUtil.getInitialThema());
    // const [isDispEditor, setDispEditor] = useState(true);

    const [focusNode, setFocusNode] = useState<TreeUtil.ElementNode | null>(null);
    const [isBackingWnd, setBackingWnd] = useState(false);
    const [treeDisable, setTreeDisable] = useState(false);
    const [invalidate, setInvalidate] = useState<any>(null);

    // テーマファイルをデータノードに変換する
    const rootDataNode = useMemo(() => {
        const rootNode: TreeUtil.DataNode = {
            data: themaData,
            children: themaData.meths.map(type => {
                const getDefsChildren = () => {
                    switch (type.method) {
                        case 'sustain': {
                            return (type.defs as ThemaUtil.LevelAttr[]).map(attr => ({
                                data: attr,
                                children: attr.roots.map(root => ({
                                    data: root,
                                    children: root.voics.map(voic => ({
                                        data: voic,
                                        children: []
                                    }))
                                }))
                            }));
                        }
                        case 'pattern': {
                            return (type.defs as ThemaUtil.LevelSust[]).map(sust => ({
                                data: sust,
                                children: sust.patts.map(patt => ({
                                    data: patt,
                                    children: patt.attrs.map(attr => ({
                                        data: attr,
                                        children: attr.roots.map(roots => ({
                                            data: roots,
                                            children: roots.voics.map(voic => ({
                                                data: voic,
                                                children: []
                                            }))
                                        }))
                                    }))
                                }))
                            }));
                        }
                    }
                }
                return {
                    data: type,
                    children: getDefsChildren()
                }
            })
        }
        return rootNode;
    }, [themaData]);

    const activeNo = useMemo<number>(() => {
        if (focusNode != null) {
            switch (focusNode.depth) {
                case 0: {
                    return 0;
                }
                case 1: {
                    return 1;
                }
                case 2: {
                    const method = (focusNode.parent?.data as ThemaUtil.LevelMethod).method;
                    return method === 'sustain' ? 4 : 2;
                }
                case 3: {
                    const method = (focusNode.parent?.parent?.data as ThemaUtil.LevelMethod).method;
                    return method === 'sustain' ? 5 : 3;
                }
                case 4: {
                    const method = (focusNode.parent?.parent?.parent?.data as ThemaUtil.LevelMethod).method;
                    return method === 'sustain' ? 6 : 4;
                }
                case 5: {
                    return 5;
                }
                case 6: {
                    return 6;
                }
            }
        }
        return -1;
    }, [focusNode]);

    const tabInner = (tabIndex: number, contJsx: JSX.Element) => {
        return (
            activeNo === tabIndex ? <_TabContWrap>{contJsx}</_TabContWrap> : <></>
        )
    }
    // const tabProps: {

    // }[] = [];
    // const getTabContList = () => {
    //     tabProps.map((props) => {

    //         return {
    //             name: 'base',
    //             disable: true,
    //             cont: tabInner(0, <BaseFormTab />
    //             )
    //         };
    //     });
    // };
    // const tabContList = getTabContList();

    return (
        <_Wrap
            tabIndex={0}
            onKeyDown={(e: KeyboardEvent) => {
                BackingEditorInput.main(e, store, dispatcher);
            }}
        >
            <_TreeDiv>
                <_TreeHead>
                    <HierarchyNavi cur={focusNode}/>
                </_TreeHead>
                <_TreeBody>
                    <TreeUtil.Frame
                        rootDataNode={rootDataNode}
                        clickEvent={(node: TreeUtil.ElementNode) => {
                            setFocusNode(node);
                        }}
                        getLabelJsx={(node: TreeUtil.ElementNode) => {
                            // console.log(focusNode);
                            // console.log(node);
                            // const isActive = focusNode == node || (focusNode?.depth===0 && focusNode.data == rootDataNode.data)
                            return ThemaUtil.getLabelJsx(node, focusNode?.data == node.data)
                        }}
                        invalidate={invalidate}
                        focusNode={focusNode}
                        isDisable={treeDisable}
                    />
                </_TreeBody>
            </_TreeDiv>
            <_PropsDiv>
                <ThemaTreeContext.Provider value={{
                    node: focusNode as TreeUtil.ElementNode,
                    setFocusNode: setFocusNode,
                    invalidate: () => { setInvalidate({}) },
                    setBackingWnd,
                    dispatcher,
                    treeDisable,
                    setThamaData,
                    setTreeDisable
                }}>
                    <TabbedPane
                        tabElements={[
                            {
                                name: 'base', disable: true, cont: tabInner(0, <BaseFormTab
                                    themaList={store.systemState.themaList}
                                    addThema={(thema: ThemaUtil.LevelBase) => {
                                        const themaList = store.systemState.themaList;
                                        themaList.push(JSON.parse(JSON.stringify(thema)));
                                        dispatcher.system.setState({ ...store.systemState, themaList });
                                    }}
                                />)
                            },
                            { name: 'Method', disable: true, cont: tabInner(1, <MethFormTab />) },
                            { name: 'Sustain', disable: true, cont: tabInner(2, <SustFormTab />) },
                            { name: 'Pattern', disable: true, cont: tabInner(3, <PattFormTab />) },
                            { name: 'Attribute', disable: true, cont: tabInner(4, <AttrFormTab isPattern={focusNode?.depth === 4} />) },
                            { name: 'Root', disable: true, cont: tabInner(5, <RootFormTab />) },
                            { name: 'voicing', disable: true, cont: tabInner(6, <VoicFormTab isPattern={focusNode?.depth === 6} />) },
                        ]}
                        tabRecordNum={2}
                        activeNo={activeNo}
                        selectTabIndex={(index: number) => dispatcher.score.setScoreTabIndex(index)}
                    />
                </ThemaTreeContext.Provider>
            </_PropsDiv>
            <_Blind isVisible={isBackingWnd}>
                <_Contents>{!isBackingWnd ? <></> : <BackingEditorFrame />}</_Contents>
            </_Blind>
        </_Wrap >
    );
}
export default BackingLibraryTab;

const PROPS_DIV_WIDTH = 460;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    background-color: #c3d8dd;
    width: 100%;
    height: 100%;
`;

const _TreeDiv = styled.div`
    display: inline-block;
    position: relative;
    /* background-color: #d5d4d8; */
    width: calc(100% - ${PROPS_DIV_WIDTH}px);
    height: 100%;
    vertical-align: top;
`;

const _TreeHead = styled.div`
    display: inline-block;
    position: relative;
    /* background-color: #d5d4d8; */
    width: 100%;
    height: 40px;
`;

const _TreeBody = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
`;

const _PropsDiv = styled.div`
    display: inline-block;
    /* position: relative; */
    /* background-color: #532fc0; */
    width: ${PROPS_DIV_WIDTH}px;
    height: 100%;
    vertical-align: top;
    box-sizing: border-box;
    padding: 6px 6px 6px 0;
`;

const _TabContWrap = styled.div`
    display: inline-block;
    /* position: relative; */
    background-color: #c0c9c4;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px 0 0 2px;
`;

const _Blind = styled.div<{
    isVisible: boolean;
}>`
    display: inline-block;
    ${props => props.isVisible ? '' : css`
        display: none;
    `}
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background-color: #0012616c;
    width: 100%;
    height: 100%;
`;

const _Contents = styled.div<{
}>`
    display: inline-block;
    width: 630px;
    /* height: 400px; */
    background-color: #a3a3a3;
    /* opacity: 0.9; */
    overflow: hidden;
`;