import { useContext, useEffect, useMemo } from "react";
import styled, { css } from "styled-components";
import { DetailChord } from "../../../../../redux/store/score/scoreData";
import { SymbolDiagram } from "../../../../../redux/store/score/scoreState";
import TheoryUtil from "../../../../../utils/theoryUtil";
import { GlobalContext } from "../../../../entry/systemEntry";

type ChordDefBean = {
    root: TheoryUtil.DegreeProps;
    symbolKey: TheoryUtil.SymbolKey;
}

const ChordEditor = (props: {
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const detail = store.scoreData.chordList[state.focusIndex].detail as DetailChord;

    // 表示時に呼ばれる
    useEffect(() => {
    }, [detail.root, detail.symbolKey]);

    const getChord = (index: number, isFlat: boolean, symbolKey: TheoryUtil.SymbolKey): ChordDefBean => {
        return { root: { index, isFlat }, symbolKey };
    }

    const createRow = (label: JSX.Element, chordList: ChordDefBean[]): JSX.Element => {
        const listJsx: JSX.Element[] = [];
        listJsx.push(<_Header key={listJsx.length}>{label}</_Header>);
        chordList.forEach((chord) => {
            const rootName = TheoryUtil.getDegreeRoot(chord.root);
            const symbol = TheoryUtil.getSymbolFromKey(chord.symbolKey) as TheoryUtil.SymbolParams;
            const symbolName = symbol.name;
            listJsx.push(
                <_Item isActive={false} key={listJsx.length}>
                    {rootName + symbolName}
                </_Item>);
        });
        return <_Row>{listJsx}</_Row>;
    }

    return (
        <_Wrap>
            {createRow(<>triad</>, [
                getChord(0, false, 'major'),
                getChord(2, false, 'minor'),
                getChord(4, false, 'minor'),
                getChord(5, false, 'major'),
                getChord(7, false, 'major'),
                getChord(9, false, 'minor'),
                getChord(11, false, 'minor-minus5'),
            ])}
            {/* {createRow(<>Tetrad</>, [
                getChord(0, false, 'major-min7th'),
                getChord(2, false, 'minor-7th'),
                getChord(4, false, 'minor-7th'),
                getChord(5, false, 'major-maj7th'),
                getChord(7, false, 'major-min7th'),
                getChord(9, false, 'minor-7th'),
                getChord(11, false, 'minor7-minus5'),
            ])} */}
            {createRow(<>domm</>, [
                getChord(9, false, 'major-min7th'),
                getChord(11, false, 'major-min7th'),
                getChord(0, false, 'major-min7th'),
                getChord(2, false, 'major-min7th'),
                getChord(4, false, 'major-min7th'),
            ])}
            {createRow(<>domm*</>, [
                getChord(1, false, 'diminish'),
                getChord(3, false, 'diminish'),
                getChord(4, false, 'diminish'),
                getChord(6, false, 'diminish'),
                getChord(8, false, 'diminish'),
            ])}
        </_Wrap>
    );
}

export default ChordEditor;

const _Wrap = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 30;
    top: 100px;
    left: 0;
    width: 250px;
    /* height: 160px; */
    background-color: #a9cfd422;
    border: 2px solid #000000ec;
    box-sizing: border-box;
    text-align: center;
    opacity: 0.9;
    box-shadow: 0 4px 16px 4px #000b3a48;
    backdrop-filter: blur(6px);
`;

const _Row = styled.div`
    display: inline-block;
    width: 80px;
    /* height: 100%; */
    /* background-color: #e6e6e6; */
    margin: 1px;
    vertical-align: top;
`;


const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 20px;
    box-sizing: border-box;
    /* background-color: #78dab4;
    border: 1px solid #000000; */
    background: linear-gradient(to bottom, #7a9ea3, #78cfda, #7a9ea3);
    /* background: linear-gradient(to bottom, #a37a7a, #da7878, #a37a7a); */
    margin: 1px;

    font-size: 16px;
    font-weight: 600;
    color: #f3f3f3;
    line-height: 17px;
`;
const _Item = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    width: calc(100% - 2px);
    height: 20px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    ${props => !props.isActive ? '' : css`background-color: #ffef64;`}
    border: 1px solid #000000;
    margin: 1px;

    font-size: 16px;
    font-weight: 600;
    color: #000000;
    line-height: 16px;
`;