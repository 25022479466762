import { useContext } from "react";
import styled from "styled-components";
import { GlobalContext } from "../../../../entry/systemEntry";
import SideFormAlter from "./sideFormAlter";
import SideFormChord from "./sideFormChord";
import SideFormInit from "./sideFormInit";
import SideFormSection from "./sideFormSection";

const HarmSideForm = () => {

    const { store, dispatcher } = useContext(GlobalContext);
    const state = store.scoreState;
    const chordList = store.scoreData.chordList;

    if (state.focusIndex === -1 || state.focusIndex > chordList.length - 1) {
        return <></>;
    }

    const element = chordList[state.focusIndex];

    return (
        <_Wrap>
            {(() => {
                switch (element.type) {
                    case 'init': return <SideFormInit />;
                    case 'section': return <SideFormSection />;
                    case 'chord': return <SideFormChord />;
                    case 'alter': return <SideFormAlter />;
                }
            })()}
        </_Wrap>
    );
}

export default HarmSideForm;

const _Wrap = styled.div`
    display: inline-block;
    margin: 3px 0 0 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    text-align: left;
    background-color: #8a8a8a;
`;
