import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../entry/systemEntry";
import InstrumentSwitch from "./item/instrumentSwitch";
import QuantizeSwitch from "./item/quantizeSwitch";


import { GiViolin, GiGuitar, GiSaxophone, GiViola, GiTrumpet, GiClarinet, GiOcarina } from 'react-icons/gi'
import { BsPerson } from 'react-icons/bs'
import ControlButton from "./item/controlButton";

const MelodyHeader = () => {
    const { store, dispatcher } = useContext(GlobalContext);
    const state = store.melodyState;

    return (<>
        <_OperationDiv>
            <_QuantizeSelector>
                <QuantizeSwitch labelName={'32'} isDisable={false} isActive={state.quantize === 8} callback={() => {
                    state.quantize = 8;
                    dispatcher.melody.setState(state);
                }} />
                <QuantizeSwitch labelName={'16'} isDisable={false} isActive={state.quantize === 4} callback={() => {
                    state.quantize = 4;
                    dispatcher.melody.setState(state);
                }} />
                <QuantizeSwitch labelName={'8'} isDisable={false} isActive={[2, 6].includes(state.quantize)} callback={() => {
                    state.quantize = 2;
                    dispatcher.melody.setState(state);
                }} />
                <QuantizeSwitch labelName={'4'} isDisable={false} isActive={[1, 3].includes(state.quantize)} callback={() => {
                    state.quantize = 1;
                    dispatcher.melody.setState(state);
                }} />
                <QuantizeSwitch labelName={'/3'} isDisable={![1, 2, 3, 6].includes(state.quantize)} isActive={[3, 6].includes(state.quantize)} callback={() => {
                    if ([3, 6].includes(state.quantize)) {
                        state.quantize /= 3;
                        dispatcher.melody.setState(state);
                    } else {
                        state.quantize *= 3;
                        dispatcher.melody.setState(state);
                    }
                }} />
            </_QuantizeSelector>
            <_Control>
                {/* <ControlButton labelName={'Up'} isDisable={false} callback={() => {
                    const list = store.scoreData.notesList;
                    list.forEach(notes => {
                        notes.pitchIndex--;
                    });
                    dispatcher.melody.setNotesList(list.slice());
                }} />
                <ControlButton labelName={'Down'} isDisable={false} callback={() => {
                    const list = store.scoreData.notesList;
                    list.forEach(notes => {
                        notes.pitchIndex++;
                    });
                    dispatcher.melody.setNotesList(list.slice());
                }} /> */}
                {/* <ControlButton labelName={'Reset'} isDisable={false} callback={() => {
                    dispatcher.tune.setNotesList([]);
                }} /> */}
            </_Control>
        </_OperationDiv>
        <_InstrumentSelector>
            <InstrumentSwitch icon={<GiViolin />} labelName="violin" soundfontInfo={{ name: 'violin', gain: 9 }} />
            <InstrumentSwitch icon={<GiGuitar />} labelName="guitar" soundfontInfo={{ name: 'overdriven_guitar', gain: 4 }} />
            <InstrumentSwitch icon={<GiSaxophone />} labelName="saxphone" soundfontInfo={{ name: 'tenor_sax', gain: 3 }} />
            <InstrumentSwitch icon={<GiViola />} labelName="viola" soundfontInfo={{ name: 'viola', gain: 5 }} />
            <InstrumentSwitch icon={<GiTrumpet />} labelName="trumpet" soundfontInfo={{ name: 'trumpet', gain: 5 }} />
            <InstrumentSwitch icon={<GiClarinet />} labelName="clarinet" soundfontInfo={{ name: 'clarinet', gain: 4 }} />
            <InstrumentSwitch icon={<GiClarinet />} labelName="oboe" soundfontInfo={{ name: 'oboe', gain: 5 }} />
            <InstrumentSwitch icon={<GiClarinet />} labelName="flute" soundfontInfo={{ name: 'flute', gain: 5 }} />
            <InstrumentSwitch icon={<BsPerson />} labelName="choir" soundfontInfo={{ name: 'choir_aahs', gain: 6 }} />
            <InstrumentSwitch icon={<GiGuitar />} labelName="shamisen" soundfontInfo={{ name: 'shamisen', gain: 9 }} />
        </_InstrumentSelector>
    </>);
}

export default MelodyHeader;

const _OperationDiv = styled.div<{
}>`
    display: block;
    padding: 4px 4px 4px 0;
    background-color: #bbbbbb;
`;

const _QuantizeSelector = styled.div<{
}>`
    display: inline-block;
    /* margin-left: 20px; */
`;
const _Control = styled.div<{
}>`
    display: inline-block;
    margin-left: 20px;
`;

const _InstrumentSelector = styled.div<{
}>`
    display: inline-block;
    /* padding: 4px 4px 4px 0; */
    background-color: #e4e4e486;
`;

