import { on } from "stream";
import { Dispatcher } from "../../redux/dispatcher/dispatcher";
import { DetailChord } from "../../redux/store/score/scoreData";
import { Store } from "../../redux/store/store";
import BackingUtil from "../backingUtil";
import CacheUtil from "./cacheUtil";
import { getKeyIndexFromPitcher, getNormalizeLenFromNotes } from "../melodyUtil";
import TheoryUtil from "../theoryUtil";

namespace HarmonizeUtil {

    export const previewStart = (store: Store, dispatcher: Dispatcher) => {
        const state = store.scoreState;
        // const sampler = store.instruments.sampler;
        const cacheList = state.elementCacheList;
        const chordList = store.scoreData.chordList;
        const reserveTasks = store.reserveTasks;
        let firstTime = -1;
        const update = () => {
            // if (store.invalidate.harmonize) {
            //     store.invalidate.harmonize();
            // }
            dispatcher.score.setScoreState(state);
        }
        // const reserveTasks = store.reserveTasks;
        // const init = chordList[0].detail as DetailInit;
        const adjsut = 0;
        for (let i = state.focusIndex; i < chordList.length; i++) {
            const cache = cacheList[i];
            const chordCache = store.scoreState.chordCacheList[cache.chordBlockNo];
            const element = chordList[i];

            /**
             * 要素の切り替わり時の処理をタスクに追加する
             * @param callback 実行する処理
             */
            const addProgAction = (callback: () => void) => {
                reserveTasks.push(
                    setTimeout(() => {
                        callback();
                    }, cache.pastSecond - firstTime - adjsut)
                );
            }

            if (element.type === 'chord') {
                const detail = element.detail as DetailChord;
                // 開始時のノーツの位置（時間）を保存する。
                if (firstTime === -1) {
                    firstTime = cache.pastSecond;
                }

                // コードが指定されてい場合
                if (detail.root == null) {
                    addProgAction(()=> {
                        state.focusIndex = i;
                        state.distIndex = state.focusIndex;
                        update();
                    });
                }

                // バッキングエラーがない場合
                if (!chordCache.backingError) {
                    const backing = detail.backing;
                    const pitchList = BackingUtil.getPitchListFromVoicing(backing.voicingList, chordCache.structList);

                    // const chordTime = 60 / cache.bpm * len;

                    const minute = detail.minute.head + detail.minute.tail;
                    const chordTime = TheoryUtil.calcSustainMsec(
                        detail.beatLen,
                        minute,
                        cache.bpm,
                        cache.beatSignature
                    );

                    addProgAction(()=> {
                        BackingUtil.playBacking(store, reserveTasks, cache, backing, pitchList, chordTime);
                        state.focusIndex = i;
                        state.distIndex = state.focusIndex;
                        setTimeout(() => {
                            update();
                        }, 0);
                    });
                } else {
                    // バッキングエラーがある場合プレビューを止める
                    reserveTasks.push(
                        setTimeout(() => {
                            HarmonizeUtil.stopPreview(store, dispatcher, reserveTasks);
                        }, (cache.pastSecond - firstTime - adjsut))
                    );
                }
            }

            // 終端の要素を処理
            if (i === chordList.length - 1) {   
                addProgAction(()=> {
                    HarmonizeUtil.stopPreview(store, dispatcher, reserveTasks);
                });
            }
        }

        // メロディを再生
        if (!state.isSolo) {
            previewMelody(firstTime / 1000, store, dispatcher, reserveTasks);
        }

        state.isPreview = true;
        update();
    }

    const previewMelody = (start: number, store: Store, dispatcher: Dispatcher, reserveTasks: NodeJS.Timeout[]) => {

        const state = store.melodyState;
        const scoreState = store.scoreState;
        const data = store.scoreData;
        const instrument = store.instruments.melodyFont;

        const update = store.invalidate.melody as () => void;
        let firstTime = -1;
        for (let i = 0; i < data.notesList.length; i++) {
            const notes = data.notesList[i];
            // const beatProps = TheoryUtil.getBeatProps(init.beatSignature);
            // const adjustRate = 4 / beatProps.beatMemoriCount;
            // const pos = getNormalizeLenFromNotes(notes.pos) * adjustRate;
            // const len = getNormalizeLenFromNotes(notes.len) * adjustRate;
            const pos = getNormalizeLenFromNotes(notes.pos);
            const len = getNormalizeLenFromNotes(notes.len);
            const [posTime, lenTime] = CacheUtil.getTimeInfoFromNotesPos(pos, len, scoreState.tempoCacheList);

            if (posTime < start) continue;

            // 開始時のノーツの位置（時間）を保存する。
            if (firstTime === -1) {
                // ノーツ非選択時は先頭から流す
                if (state.focusIndex === -1) {
                    firstTime = 0;
                } else {
                    firstTime = posTime;
                }
            }

            const pitch = notes.pitchIndex;
            // const time = 60 / bpm * pos;

            const keyIndex = getKeyIndexFromPitcher(pitch);
            const soundName = TheoryUtil.KEY12_SHARP_LIST[keyIndex % 12];
            const octave = Math.floor(((59 - pitch) + 9) / 12) + 1;

            reserveTasks.push(
                setTimeout(() => {
                    // state.mouseState.curChord = searchCurChord(store, i);

                    // dispatcher.tune.setState(state);
                    dispatcher.melody.adjustScrollX();
                    if (instrument != null) {
                        instrument.stop();
                        instrument.play(soundName + octave, 0, { duration: lenTime });
                    }
                    setTimeout(() => {
                        state.focusIndex = i;
                        state.destIndex = state.focusIndex;
                        update();
                    }, 0);
                }, (posTime - start) * 1000)
            );

            // 終端ノーツの処理
            // if (i === data.notesList.length - 1) {
            //     reserveTasks.push(
            //         setTimeout(() => {
            //             stopPreview(store, dispatcher, reserveTasks);
            //         }, (posTime - start + lenTime) * 1000)
            //     );
            // }
        }
    }

    // ハーモニーのプレビューを停止する。
    export const stopPreview = (store: Store, dispatcher: Dispatcher, reserveTasks: NodeJS.Timeout[]) => {
        const state = store.scoreState;
        state.isPreview = false;
        // store.instruments.sampler.releaseAll();
        // state.focusIndex = state.lastFocus;
        reserveTasks.forEach(id => clearTimeout(id));
        dispatcher.system.setReserveTasks([]);
        dispatcher.score.setScoreState(state);

        store.instruments.harmonyFont?.stop();
    }

    // 選択中の要素のバッキングパターンを鳴らす
    export const previewCurHarmony = (store: Store, dispatcher: Dispatcher) => {

        const state = store.scoreState;
        const reserveTasks = store.reserveTasks;
        const cache = state.elementCacheList[state.focusIndex];
        const chordCache = store.scoreState.chordCacheList[cache.chordBlockNo];
        const curChord = store.scoreData.chordList[state.focusIndex];
        if (curChord != null) {

            const detail = curChord.detail as DetailChord;

            if (detail.root == null) return;

            // const rootIndex = (detail.root.index + cache.keyIndex) % 12;
            // const onIndex = detail.on == null ? -1 : ((detail.on.index + cache.keyIndex) % 12);
            // const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey);

            const backing = detail.backing;
            const pitchList = BackingUtil.getPitchListFromVoicing(backing.voicingList, chordCache.structList);
            // const chordTime = 60 / cache.bpm * len;

            const minute = detail.minute.head + detail.minute.tail;
            const chordTime = TheoryUtil.calcSustainMsec(
                detail.beatLen,
                minute,
                cache.bpm,
                cache.beatSignature
            );

            BackingUtil.playBacking(store, store.reserveTasks, cache, backing, pitchList, chordTime);

            reserveTasks.push(setTimeout(() => {
                state.isPreview = false;
                dispatcher.score.setScoreState(state);
            }, chordTime));
            state.isPreview = true;
            dispatcher.score.setScoreState(state);
        }
    }
}

export default HarmonizeUtil;
