import { useContext, useEffect, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import { ChordElement, DetailChord } from "../../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../../utils/theoryUtil";
import { GlobalContext } from "../../../../entry/systemEntry";


const MEMORI_WIDTH = 12;

const HarmMeasure = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const dataList = store.scoreData.chordList;
    const focusIndex = state.focusIndex;
    const cacheList = state.elementCacheList;

    const memoriJsxList = useMemo(() => {
        const list: JSX.Element[] = [];
        list.push(<_Margin key={list.length} />);
        state.measureCacheList.forEach((cache) => {

            let bean: { width: number, height: number, color: string } = { width: 1, height: 10, color: '#555' }
            switch (true) {
                case cache.rate === 4: bean = { width: 2, height: 22, color: '#747474' }; break;
                case cache.rate === 8: bean = { width: 1, height: 18, color: '#646464' }; break;
                case cache.rate === 16: bean = { width: 1, height: 10, color: '#7a7a7a' }; break;
            }
            list.push(
                <_Memori
                    key={list.length}
                    width={bean.width}
                    height={bean.height}
                    color={bean.color}
                >
                    {cache.barCount !== -1 ? <_Bar>{cache.barCount}</_Bar> : ''}
                    {/* {<_Bar>{i}</_Bar>} */}
                </_Memori>
            );
        });
        list.push(<_Margin key={list.length} />);
        return list;
    }, [state.beatCacheList]);

    const [beatJsxList, minuteJsxList] = useMemo(() => {
        const beatJsxList: JSX.Element[] = [];
        const minuteJsxList: JSX.Element[] = [];

        const isChordFocus = dataList[focusIndex].type === 'chord';
        const curChordIndex = cacheList[focusIndex].chordIndex;
        // list.push(<_Start key={list.length}/>);
        dataList.forEach((data, i) => {

            const cache = state.elementCacheList[i];
            if (data.type === 'chord' && Math.abs(cache.chordIndex - curChordIndex) <= 5) {

                const detail = data.detail as DetailChord;
                const sustain = detail.beatLen + detail.minute.head + detail.minute.tail;

                let label = '';
                let type: ItemType = 'ather';
                if (!isChordFocus) {
                    if (cache.chordIndex === curChordIndex) {
                        label = 'prev';
                        type = 'prev';
                    } else if (cache.chordIndex === curChordIndex + 1) {
                        label = 'next';
                        type = 'next';
                    }
                } else {
                    if (cache.chordIndex === curChordIndex) {
                        label = 'cur';
                        type = 'cur';
                    } else if (cache.chordIndex === curChordIndex - 1) {
                        label = 'prev';
                        type = 'prev';
                    } else if (cache.chordIndex === curChordIndex + 1) {
                        label = 'next';
                        type = 'next';
                    }
                }

                const beatProps = TheoryUtil.getBeatProps(cache.beatSignature);
                const minute = detail.minute.head + detail.minute.tail;
                const beatMemori = detail.beatLen * beatProps.beatMemoriCount;
                const posMemori = 16 + cache.curTotalMemori;
                beatJsxList.push(
                    <_SustainItem key={beatJsxList.length}
                        type={type}
                        pos={posMemori} sustain={beatMemori + minute}
                    >{label}</_SustainItem>
                );
                minuteJsxList.push(
                    <_BeatItem key={beatJsxList.length}
                        isActive={type === 'cur'}
                        pos={posMemori + detail.minute.head} sustain={beatMemori}
                    />
                );
            }
        });
        // list.push(<_Start key={list.length}/>);
        return [beatJsxList, minuteJsxList];
    }, [dataList.length, focusIndex, state.beatCacheList]);

    const scrollPos = cacheList[focusIndex].curTotalMemori * MEMORI_WIDTH;
    return (
        <_Wrap>
            <_ScrollFrame pos={scrollPos}>
                <_Blocks>
                    <_Beat>
                        {beatJsxList}
                    </_Beat>
                    <_Minute>
                        {minuteJsxList}
                    </_Minute>
                </_Blocks>
                <_Measure>
                    {memoriJsxList}
                </_Measure>
            </_ScrollFrame>
        </_Wrap>
    );
}

export default HarmMeasure;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    text-align: left;
    white-space: nowrap;  
`;

const _ScrollFrame = styled.div<{
    pos: number;
}>`
    display: inline-block;
    position: absolute;
    left: ${props => -props.pos}px;
    position: absolute;
    height: 100%;
    /* width: 100px;
    background-color: #000; */
    transition: left 0.5s;
`;

const _Blocks = styled.div`
    display: block;
    /* position: relative; */
    height: 40px;
    /* width: 2200px; */
    background-color: rgb(131, 131, 131);
`;

const _Beat = styled.div`
    display: block;
    position: relative;
    height: 30px;
`;

const _Minute = styled.div`
    display: block;
    position: relative;
    height: 10px;
`;

type ItemType = 'cur' | 'prev' | 'next' | 'ather';
const _SustainItem = styled.div<{
    pos: number;
    sustain: number;
    type: ItemType;
}>`
    display: inline-block;
    position: absolute;
    left: ${props => props.pos * MEMORI_WIDTH}px;
    width: ${props => props.sustain * MEMORI_WIDTH}px;
    height: 100%;

    font-size: 16px;
    font-weight: 600;
    color: white;
    text-align: center;
    background-color: rgb(136, 163, 133);
    ${props => {
        switch (props.type) {
            case 'cur': return css`
                background-color: rgb(218, 107, 134);
                /* color: #ffe551; */
            `;
            case 'prev': return css`
                opacity: 0.4;
                background-color: rgb(163, 177, 221);
            `;
            case 'next': return css`
                opacity: 0.4;
                background-color: rgb(163, 221, 171);
            `;
            case 'ather': return css`
                background-color: rgb(192, 192, 192);
            `;
        }
    }}
    border-left: solid 1px #000;
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
    box-sizing: border-box;
`;


const _BeatItem = styled.div<{
    isActive: boolean;
    pos: number;
    sustain: number;
}>`
    display: inline-block;
    position: absolute;
    left: ${props => props.pos * MEMORI_WIDTH}px;
    width: ${props => props.sustain * MEMORI_WIDTH}px;
    height: 100%;

    font-size: 16px;
    font-weight: 600;
    color: white;
    text-align: center;
    background-color: rgb(224, 224, 224);
    border-left: solid 1px #000;
    ${props => !props.isActive ? '' : css`
        /* background-color: rgb(233, 180, 228); */
        /* color: #ffe551; */
    `}
`;

const _Measure = styled.div`
    display: block;
    position: relative;
    height: 38px;
    background-color: rgb(48, 48, 48);
    border-top: solid 2px rgb(128, 128, 128);
    background: linear-gradient(to bottom, rgb(255, 255, 255), rgb(98, 97, 114));
`;


const _Margin = styled.div`
    display: inline-block;
    width: ${MEMORI_WIDTH * 16}px;
    height: 100%;
    background-color: #d9ebe7;
    box-sizing: border-box;
`;
const _Memori = styled.div<{
    width: number;
    height: number;
    color: string;
}>`
    display: inline-block;
    position: relative;
    width: ${MEMORI_WIDTH}px;
    height: ${props => props.height}px;
    border-left: solid ${props => props.width}px ${props => props.color};
    box-sizing: border-box;
`;

const _Bar = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    z-index: 10;
    left: -5px;
    top: 18px;
    font-size: 16px;
    font-weight: 600;
    color: #dd0000;
`;