import { useMemo } from "react";
import styled, { css } from "styled-components";
import ScoreCache from "../../../../../../redux/store/score/scoreCache";
import { DetailChord, Scale } from "../../../../../../redux/store/score/scoreData";
import BackingUtil from "../../../../../../utils/backingUtil";
import TheoryUtil from "../../../../../../utils/theoryUtil";

type TipLenType = 'short' | 'normal' | 'long';
type TipValueType = 0 | 2 | 1;

/**
 * コードブロックのコンポーネント
 * @param props 引数
 * @returns コンポーネント
 */
const ElementDetailChord = (props: {
    detail: DetailChord;
    isSustainMode: boolean;
    isCurrent: boolean;
    cache: ScoreCache.Element;
    chordCacheList: ScoreCache.Chord[];
    keyIndex: number;
    scale: Scale;
}) => {

    const minute = props.detail.minute;
    const symbol = TheoryUtil.getSymbolFromKey(props.detail.symbolKey) as TheoryUtil.SymbolParams;
    const symbolName = symbol.name;
    const chordBacking = props.detail.backing;
    const cache = props.cache;
    const chordCache = props.chordCacheList[cache.chordBlockNo];

    const backingError = chordCache != undefined ? chordCache.backingError : false;

    return useMemo(() => {

        // const keySwitch = store.scoreState.keySwitch;
        let degreeRoot: null | string = null;
        let degreeOn: null | string = null;
        let chordRoot: null | string = null;
        let chordOn: null | string = null;

        if (props.detail.root != null) {
            degreeRoot = TheoryUtil.getDegreeRoot({
                index: props.detail.root.index,
                isFlat: props.detail.root.isFlat
            });
            chordRoot = TheoryUtil.getRootName({
                index: props.detail.root.index,
                isFlat: props.detail.root.isFlat
            }, props.keyIndex);

            if (props.detail.on != null) {
                degreeOn = TheoryUtil.getDegreeRoot({
                    index: props.detail.on.index,
                    isFlat: props.detail.on.isFlat
                });
                chordOn = TheoryUtil.getRootName({
                    index: props.detail.on.index,
                    isFlat: props.detail.root.isFlat
                }, props.keyIndex);
            }
        }
        const diatonicList = TheoryUtil.getDiatonicDegreeList(props.scale);
        const isDiatonic = diatonicList.includes(degreeRoot + symbolName);

        return <_Wrap>
            <_Degree
                // isSwitch={keySwitch.sustainMode && props.isCurrent}
                isSwitch={props.isSustainMode}
                scaleRelation={degreeRoot != null && isDiatonic ? 'diatonic' : 'none'}
                isOnChordScale={props.detail.on != null && [0, 2, 4, 5, 7, 9, 11].includes(props.detail.on.index)}
            >
                {degreeRoot == null ? 'none' : (<>
                    <_DegreeRoot>{degreeRoot}{symbolName}{degreeOn == null ? '' : `/${degreeOn}`}</_DegreeRoot>
                    {/* <_DegreeSymbol>{symbolName}</_DegreeSymbol>
                    <_DegreeOnChord>{degreeOn == null ? '' : `/${degreeOn}`}</_DegreeOnChord> */}
                </>)}
            </_Degree>
            {/* <_Chord>
                {chordRoot == null ? '-' : (<>
                    {chordRoot}{symbolName}{chordOn == null ? '' : `/${chordOn}`}
                </>)}
            </_Chord> */}
            <_Tip isSwitch={props.isSustainMode}>
                {(() => {
                    const jsxList: JSX.Element[] = [];
                    for (let i = 0; i < props.detail.beatLen; i++) {
                        let lenType: TipLenType = 'normal';
                        let valueType: TipValueType = 0;
                        if ([0, props.detail.beatLen - 1].includes(i)) {
                            const target = i === 0 ? minute.head : minute.tail;
                            if (target !== 0) {
                                lenType = target > 0 ? 'long' : 'short';
                                valueType = Math.abs(target) as TipValueType;
                            }
                        }
                        jsxList.push(
                            <_TipItem
                                key={i}
                                lenType={lenType}
                                valueType={valueType}
                            />
                        );
                    }
                    return jsxList;
                })()}
            </_Tip>
            <_Backing>
                {backingError ? <_Error /> : <>
                    {chordBacking.voicingList.length === 0 ? '' : <_Voicing />}
                    {chordBacking.voicingList.length === 0 ? '' : chordBacking.pattern == null ? '' : <_Pattern />}
                </>}
            </_Backing>
        </_Wrap>
    }
        , [
            props.scale,
            props.isCurrent, props.detail.beatLen, props.detail.root?.index,
            props.detail.on?.index,
            props.detail.symbolKey,
            props.isSustainMode,
            props.keyIndex,
            props.detail.minute.head,
            props.detail.minute.tail,
            props.detail.backing,
            backingError
        ]);
}

export default ElementDetailChord;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    /* border: 1px solid #c5c5cc; */
    /* padding: 4px; */
    box-sizing: border-box;
    /* background-color: #00000013; */
`;


const _DegreeRoot = styled.div``;
const _DegreeSymbol = styled.div``;
const _DegreeOnChord = styled.div``;
const _Degree = styled.div<{
    isSwitch: boolean;
    scaleRelation: TheoryUtil.ScaleRelation;
    isOnChordScale: boolean;
}>`
    display: block;
    /* width: 100%; */
    height: 25px;
    /* background-color: #f700d616; */
    ${props => !props.isSwitch ? '' : css`background-color: #0004f729;`}
    font-size: 18px;
    /* color: #4e516394; */
    text-align: center;

    & * {
        display: inline-block;
    }

    & ${_DegreeRoot} {
        color: #4e5163cc;
        ${props =>
        props.scaleRelation === 'diatonic' ? css`color: #4e516394;` :
            props.scaleRelation === 'secondaly' ? css`color: #00116e93;` :
                css`color: #70000093;`
    }
        font-weight: 600;
    }
    & ${_DegreeSymbol} {
        color: #4e5163cc;
        ${props =>
        props.scaleRelation === 'diatonic' ? css`color: #4e516394;` :
            props.scaleRelation === 'secondaly' ? css`color: #00116e93;` :
                css`color: #70000093;`
    }
    }
    & ${_DegreeOnChord} {
        color: #4e5163cc;
        ${props => props.isOnChordScale ? '' : css`color: #70000093;`}
        font-weight: 600;
    }
`;

const _Chord = styled.div`
    display: inline-block;
    width: 100%;
    height: 22px;
    /* background-color: #d37979b2; */
    font-size: 14px;
    /* font-weight: 600; */
    color: #9c5f74;
    text-align: center;
`;

const _Tip = styled.div<{
    isSwitch: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 18px;
    ${props => !props.isSwitch ? '' : css`background-color: #0004f729;`}
    /* background-color: #d8ccccb1; */
    text-align: center;
`;

const _TipItem = styled.div<{
    lenType: TipLenType;
    valueType: TipValueType;
}>`
    display: inline-block;
    width: 8px;
    ${props => props.lenType === 'short' ? css`
        width: 4px;
    ` : props.lenType === 'long' ? css`
        width: 12px;
    ` : ''}
    height: calc(100% - 4px);
    margin: 2px 2px 0 2px;
    background-color: #e0e0e088;
    ${props => props.valueType === 2 ? css`
        background-color: #db828287;
    ` : props.valueType === 1 ? css`
        background-color: #9394dd87;
    ` : ''}
    /* background: linear-gradient(to bottom, #9b9b9bc3, #85858514); */
    border: solid 1px #0000004e;
    /* border-radius: 2px; */
    box-sizing: border-box;
`;

const _Backing = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 6px;
    /* background-color: #00a048; */
    text-align: center;
`;

const _Error = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    margin-top: 2px;
    height: 4px;
    border-radius: 4px;
    background-color: #af3838;
`;

const _Voicing = styled.div<{
}>`
    display: inline-block;
    width: 40%;
    margin: 2px 2px 0 0;
    height: 4px;
    border-radius: 4px 0 0 4px;
    border: solid 1px #0000004e;
    background-color: #acb6ca;
`;

const _Pattern = styled.div<{
}>`
    display: inline-block;
    width: 40%;
    margin: 2px 0 0 2px;
    height: 4px;
    border-radius: 0 4px 4px 0;
    border: solid 1px #0000004e;
    background-color: #accab3;
`;