import { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../../base/design/styles";
import { BeatSignature } from "../../../../redux/store/score/scoreData";
import { GlobalContext } from "../../../entry/systemEntry";
import ProgTemplateUtil from "./progTemplateUtil";
import ProgUnitSearcher from "./progUnitSearcher";

const ProgTemplateFrame = (props: {
    /** 拍子フィルターの値 */
    beat: '' | BeatSignature;
    /** 出力リストに進行ユニットを挿入するコールバック関数 */
    applyProgTemplate: (template: ProgTemplateUtil.TemplateBean) => void;
}) => {
    const { store, dispatcher } = useContext(GlobalContext);

    const [focusIndex, setFocusIndex] = useState(-1);

    const templateList = ProgTemplateUtil.search({
        beat: props.beat as BeatSignature
    });

    const templateJsxList = useMemo(() => {
        return templateList.map((template, i) => {
            return (
                <_Item key={i}
                    isFocus={i === focusIndex}
                    onClick={() => {
                        setFocusIndex(i);
                        props.applyProgTemplate(templateList[i]);
                    }}
                >{template.name}</_Item>
            );
        });
    }, [templateList, focusIndex]);

    return (
        <_Frame>
            {templateJsxList}
        </_Frame>
    );
}

export default ProgTemplateFrame;


const _Frame = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
`;

const _Item = styled.div<{
    isFocus: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM}
    display: inline-block;
    width: 100%;
    background-color: #00000010;
    ${props => !props.isFocus ? '' : css`
        background-color: #f6ff0062;
    `}
    text-align: left;
    margin: 2px 0 0 0;
    padding: 0 0 0 5px;
    color: #eb0000;
`;
