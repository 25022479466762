import { BeatSignature } from "../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../utils/theoryUtil";
import ProgUtil from "./progUtil";

/**
 * コード進行のサンプルを検索するユーティリティ
 */
namespace ProgUnitSearcher {

    export type ChordItemBean = {
        root: TheoryUtil.DegreeProps;
        symbolKey: TheoryUtil.SymbolKey;
        beatLen: number;
    }

    export type UnitBean = {
        condition: UnitCondition;
        chordList: ChordItemBean[];
    }

    /**
     * ダイアトニックスケールの連番でコードを指定する
     * @param scaleIndex スケールの連番
     * @param beatLen 拍
     */
    const getDiatonicChord = (scaleIndex: number, beatLen: number): ChordItemBean => {
        const chord = TheoryUtil.DIATONIC_MAJOR_SCALE_LIST[scaleIndex];
        return { root: { index: chord.root.index, isFlat: false }, symbolKey: chord.symbolKey, beatLen };
    }

    export type UnitCondition = {
        beat: BeatSignature;
        step: number;
        firstChord: ProgUtil.DegreeFilter;
        secondChord: ProgUtil.DegreeFilter;
    }

    /**
     * コード進行を検索する
     * @param condition 
     * @returns フィルターしたリスト
     */
    export const search = (condition: UnitCondition) => {
        return UNIT_LIST.filter((unit) => (
            (unit.condition.beat === condition.beat) &&
            (condition.step === 0 || unit.condition.step === condition.step) &&
            (condition.firstChord === '' || unit.condition.firstChord === condition.firstChord) &&
            (condition.secondChord === '' || unit.condition.secondChord === condition.secondChord)
        ));
    }

    export const parseChord = (source: string): ChordItemBean[] => {
        let baseBeat = -1;
        const keyList = source.split(' ');
        let hasError = false;
        const chordList: ChordItemBean[] = [];
        keyList.some((key, i) => {
            const head = key.charAt(0);
            if (head === '@') {
                const newBeat = Number(key.charAt(1));
                baseBeat = newBeat;
            } else if (['1', '2', '3', '4', '5', '6', '7'].includes(head)) {
                const diatonicNo = Number(head) - 1;

                let chord: null | ProgUnitSearcher.ChordItemBean = null;
                const diatonicProps = TheoryUtil.DIATONIC_MAJOR_SCALE_LIST[diatonicNo];
                if (key.length === 1) {
                    chord = {
                        root: diatonicProps.root, symbolKey: diatonicProps.symbolKey, beatLen: baseBeat
                    }
                } else {
                    const adjustKey = key.charAt(1);
                    const adjust = adjustKey === '#' ? 1 : adjustKey === 'b' ? -1 : 0;
                    const shortNameKey = key.substring(2);
                    const symbol = getSymbolFromShortName(shortNameKey);
                    if (symbol != undefined) {
                        const root = { ...diatonicProps.root };
                        root.index += adjust;
                        root.isFlat = adjust === -1;
                        chord = {
                            root, symbolKey: symbol.key, beatLen: baseBeat
                        }
                    }
                }

                if (chord == null) {
                    hasError = true;
                    return true;
                } else {
                    chordList.push(chord);
                }
            } else {
                hasError = true;
                return true;
            }

        });
        return chordList;
    }

    const getSymbolFromShortName = (shortName: string): undefined | TheoryUtil.SymbolParams => {
        return TheoryUtil.SYMBOL_LIST.find(symbol => {
            if ('*' + symbol.name === shortName) return symbol;
        });
    }

    const UNIT_LIST: UnitBean[] = [
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅰ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 1 5 6 3 4 1 2 5')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅰ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 1 5 6 3 4 3 2 5')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅰ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 1 5 6 3 4 1 2 5 1')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅰ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 1 5 6 5 4 1 2 5')
        },
        {
            condition: { beat: "4/4", step: 4, firstChord: 'Ⅰ', secondChord: 'Ⅴ' },
            chordList: parseChord('@4 1 5 6 4 1 5 6 4')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅰ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 1 5 7b* 6n*7 2 1#*dim 2 5')
        },
        {
            condition: { beat: "4/4", step: 4, firstChord: 'Ⅰ', secondChord: 'Ⅳ' },
            chordList: parseChord('@4 1 4 5 6 2 5')
        },
        {
            condition: { beat: "4/4", step: 4, firstChord: 'Ⅰ', secondChord: 'Ⅵm' },
            chordList: parseChord('@4 1 6 2 5 1 6 2 5')
        },
        {
            condition: { beat: "4/4", step: 4, firstChord: 'Ⅰ', secondChord: 'Ⅵm' },
            chordList: parseChord('@4 1 6 4 5 1 6 4 5')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅳ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 4 5 3 6 4 5 3 6')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅳ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 4n*maj7 5 3n*m7 6 4n*maj7 5 3n*m7 6')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅳ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 4n*maj7 5 3n*m7 6 2n*m7 3n*7 6 6n*7')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅳ', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 2n*m7 5 3n*m7 6 2n*m7 3n*7 6n*sus4 6')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅵm', secondChord: 'Ⅳ' },
            chordList: parseChord('@2 6 4 5 1 6 4 5 1')
        },
        {
            condition: { beat: "4/4", step: 4, firstChord: 'Ⅵm', secondChord: 'Ⅳ' },
            chordList: parseChord('@4 6 4 5 1 6 4 5 1')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅵm', secondChord: 'Ⅳ' },
            chordList: parseChord('@2 6 4 1 5 6 4 1 5')
        },
        {
            condition: { beat: "4/4", step: 4, firstChord: 'Ⅵm', secondChord: 'Ⅳ' },
            chordList: parseChord('@4 6 4 1 5 6 4 1 5')
        },
        {
            condition: { beat: "4/4", step: 2, firstChord: 'Ⅵm', secondChord: 'Ⅴ' },
            chordList: parseChord('@2 6 5 4 3n*m7 2n*m7 6 2#*dim 3n*7')
        },
    ];
}

export default ProgUnitSearcher;