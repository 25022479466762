import { useContext, useEffect, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import ScoreCache from "../../../../redux/store/score/scoreCache";
import { ChordElement, DetailChord, DetailInit } from "../../../../redux/store/score/scoreData";
import MelodyUtil, { getNormalizeLenFromNotes } from "../../../../utils/melodyUtil";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";
import MelodyTimelineMouseOperation from "./util/melodyTimelineMouseOperation";

const MelodyTimeline = (props: {
    barLength: number;
    beatWidth: number;
    curChord: ChordElement | null;
    timelineLength: number;
    mouseMeasurePos: number;
    update: () => void;
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const measureRef = useRef<null | HTMLDivElement>(null);
    const chordRef = useRef<null | HTMLDivElement>(null);

    const state = store.scoreState;
    const update = props.update;

    const chordList = store.scoreData.chordList;
    const barLength = props.barLength;
    const beatWidth = props.beatWidth;
    const curChord = props.curChord;
    const timelineLength = props.timelineLength;
    const mouseMeasurePos = props.mouseMeasurePos;
    const rate = 1 / store.melodyState.quantize;
    const init = store.scoreData.chordList[0].detail as DetailInit;

    useEffect(() => {
        store.refs.melody.measure = measureRef.current;
        store.refs.melody.chord = chordRef.current;
    }, []);

    const measureJsxList = useMemo(() => {
        const jsxList: JSX.Element[] = [];
        state.measureCacheList.forEach(cache => {

            let bean: { width: number, height: number, color: string } = { width: 1, height: 10, color: '#555' }
            switch (true) {
                case cache.rate === 4: bean = { width: 2, height: 22, color: '#747474' }; break;
                case cache.rate === 8: bean = { width: 1, height: 18, color: '#646464' }; break;
                case cache.rate === 16: bean = { width: 1, height: 10, color: '#7a7a7a' }; break;
            }
            // 表示する小節数
            const barCount = cache.barCount;

            jsxList.push(
                <_Memori
                    key={jsxList.length}
                    beatWidth={beatWidth}
                    width={bean.width}
                    height={bean.height}
                    color={bean.color}
                >
                    {barCount !== -1 ? <_Bar>{barCount}</_Bar> : ''}
                </_Memori>
            );
        });
        return jsxList;
    }, [state.beatCacheList, barLength]);

    const chordJsxList: JSX.Element[] = useMemo(() => {
        const jsxList: JSX.Element[] = [];
        if (state.elementCacheList.length === 0) {
            return jsxList;
        }
        let left = 0;
        chordList.forEach((element, i) => {
            if (element.type === 'chord') {
                // console.log(state.elementCacheList.length);
                const detail = element.detail as DetailChord;
                const cache = state.elementCacheList[i];
                const minute = detail.minute.head + detail.minute.tail;
                const beatProps = TheoryUtil.getBeatProps(cache.beatSignature);
                const minuteLen = minute * 0.25;
                const adjustRate = beatProps.beatMemoriCount / 4;
                const width = beatWidth * (detail.beatLen * adjustRate + minuteLen);

                if (chordRef.current != null) {
                    const scroll = chordRef.current.scrollLeft;
                    if (left + width - scroll >= 0 ||
                        left - scroll <= chordRef.current.clientWidth) {
                        const symbolNmae = TheoryUtil.getSymbolFromKey(detail.symbolKey)?.name;
                        const degreeName = detail.root == null ? '-' :
                            TheoryUtil.getDegreeRoot(detail.root) + symbolNmae;
                        const chordName = detail.root == null ? '-' :
                            TheoryUtil.getRootName(detail.root, init.keyIndex) + symbolNmae;
                        jsxList.push(
                            <_ChordItem key={jsxList.length}
                                left={left}
                                width={width}
                                isActive={element == curChord}
                                onClick={() => {
                                    state.isMelody = false;
                                    state.focusIndex = state.distIndex = i;
                                    dispatcher.score.setScoreState(state);
                                }}
                                onContextMenu={() => {
                                    MelodyUtil.previewCurHarmony(store, dispatcher);
                                }}
                            >
                                <_DegreeName>{degreeName}</_DegreeName><_ChordName> ({chordName})</_ChordName>
                            </_ChordItem>
                        );
                    }
                }
                left += width;
            }
        });
        return jsxList;
    }, [chordList, curChord]);

    return (<>
        <_Chord ref={chordRef}>
            <_ChordWrap width={timelineLength}>
                {chordJsxList}
            </_ChordWrap>
        </_Chord>
        <_MeasureWrap ref={measureRef}>
            <_MeasureInner
                onMouseDown={e => MelodyTimelineMouseOperation.mouseDownEvent(e, store, dispatcher, timelineLength, update)}
                onMouseMove={e => MelodyTimelineMouseOperation.mouseMoveEvent(e, store, dispatcher, timelineLength, update)}
                onMouseUp={e => MelodyTimelineMouseOperation.mouseUpEvent(e, store, dispatcher, timelineLength, update)}
                onMouseLeave={() => {
                    const mouse = store.melodyState.timelineMouse;
                    mouse.pos = -1;
                    mouse.lock = -1;
                    update();
                }}
            >
                {measureJsxList}
                <_MousePos
                    left={mouseMeasurePos * beatWidth * rate}
                    width={beatWidth * rate}
                />
            </_MeasureInner>
        </_MeasureWrap>
    </>);
}

const getExtendMeasure = (measureList: ScoreCache.Measure[]): ScoreCache.Measure[] => {
    const list: ScoreCache.Measure[] = [];

    return list;
}

export default MelodyTimeline;

const _MeasureWrap = styled.div`
    display: inline-block;
    position: relative;
    background: linear-gradient(to bottom, #ffffff, #808080);
    width: 100%;
    height: 40px;
    text-align: left;
    border-top: solid 2px #000;
    white-space: nowrap;
    overflow: hidden;
`;

const _MeasureInner = styled.div`
    display: inline-block;
    position: absolute;
    /* width: 100%; */
    left: 0;
    top: 0;
    width: 10000px;
    height: 100%;
    &:hover {
        background-color: #ff00b347;
    }
`;


const _MousePos = styled.div<{
    left: number;
    width: number;
}>`
    display: inline-block;
    position: absolute;
    z-index: 10;
    left: ${props => props.left}px;
    top: 0px;
    width: ${props => props.width}px;
    height: 100%;
    background-color: #ffff006c;
`;

const _Chord = styled.div`
    display: inline-block;
    position: relative;
    background-color: #c0c0c0;
    width: 100%;
    height: calc(100% - 40px);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
`;

const _ChordWrap = styled.div<{
    width: number;
}>`
    width: ${props => props.width}px;
    height: 100%;
    overflow: hidden;
`;

const _ChordItem = styled.div<{
    left: number;
    width: number;
    isActive: boolean;
}>`
    display: inline-block;
    position: absolute;
    left: ${props => props.left}px;
    width: ${props => props.width - 1}px;
    height: calc(100% - 2px);
    box-sizing: border-box;
    border: 1px solid #646464;
    border-left: 4px solid #940000;
    background-color: #ffffff22;
    ${props => !props.isActive ? '' : css`background-color: #ffce2c9b;`}
    margin: 1px 0 0 1px;
    
    font-size: 18px;
    font-weight: 600;
    text-align: center;

    &:hover {
        background-color: #fffb0037;
    }
`;

const _DegreeName = styled.span`
    color: #d80000;
`;
const _ChordName = styled.span`
    color: #0000007d;
`;

const _Scale = styled.div<{
    isHead: boolean;
    beatWidth: number;
}>`
    display: inline-block;
    position: relative;
    pointer-events: none;
    /* background-color: #ffffffbe; */
    width: ${props => props.beatWidth / 4}px;
    vertical-align: bottom;
    margin-top: 25px;
    height: ${props => props.isHead ? '15px' : '10px'};
    box-sizing: border-box;
    border-left: solid ${props => props.isHead ? '2px' : '1px'} #000;
`;

const _Memori = styled.div<{
    width: number;
    height: number;
    color: string;
    beatWidth: number;
}>`
    display: inline-block;
    position: relative;
    width: ${props => props.beatWidth / 4}px;
    height: ${props => props.height}px;
    border-left: solid ${props => props.width}px ${props => props.color};
    box-sizing: border-box;
    vertical-align: top;
`;

const _Bar = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    z-index: 10;
    left: -5px;
    top: 18px;
    font-size: 16px;
    font-weight: 600;
    color: #dd0000;
`;