import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import Styles from "../../base/design/styles";
import BackingState from "../../redux/store/score/backing/backingState";
import { ChordBacking, DetailChord } from "../../redux/store/score/scoreData";
import BackingUtil from "../../utils/backingUtil";
import LibraryUtil from "../../utils/LibraryUtil";
import TheoryUtil from "../../utils/theoryUtil";
import { GlobalContext } from "../entry/systemEntry";

namespace PresetSelectorFrame {

    export type CachePorps = {
        field: Field;
        presetList: LibraryUtil.PresetItemProps[];
        applyAction: () => void;
        invalidate: () => void;
    };

    type TargetProps = {
        index: number;
        condition: LibraryUtil.Condition;
    }

    type Field = {
        listIndex: number;
    }
    /**
     * プリセットセレクタ画面
     * @returns コンポーネント
     */
    export const Component = () => {

        const { store, dispatcher } = useContext(GlobalContext);

        const backing = store.backingState as BackingState.Editor;

        const [field, setField] = useState<Field>({
            listIndex: 0,
        });

        const chordList = store.scoreData.chordList;
        const state = store.scoreState;
        const [minIndex, maxIndex] = state.focusIndex < state.distIndex ? [state.focusIndex, state.distIndex] : [state.distIndex, state.focusIndex];

        const systemState = store.systemState;
        const themaList = systemState.themaList;
        const thema = themaList[systemState.activeThemaIndex];

        useEffect(() => {
            console.log('preset start');

            return () => {
                console.log('preset end');

                // キャッシュを削除
                dispatcher.system.setState({
                    ...store.systemState,
                    cache: null
                });
            }
        }, []);

        useEffect(() => {
            // キーボード操作のためのキャッシュを作成
            const inputCache: CachePorps = {
                field,
                presetList,
                applyAction,
                invalidate,
            };
            dispatcher.system.setState({
                ...store.systemState,
                cache: inputCache
            });
        }, []);

        const invalidate = () => {
            setField({ ...field });
        };

        /**
         * プレビュー再生を行う
         */
        const testBacking = (index: number) => {
            console.log(backing.isPreview);
            if (!backing.isPreview) {
                const applyItem = presetList[field.listIndex].applyList[index];
                const chordBacking = applyItem.backing as ChordBacking;
                const symbol = TheoryUtil.getSymbolFromKey(applyItem.condition.symbolKey) as TheoryUtil.SymbolParams;
                backing.chordInfo = {
                    ...backing.chordInfo,
                    beatLen: applyItem.condition.beatLen,
                    minute: {
                        head: applyItem.condition.head,
                        tail: applyItem.condition.tail,
                    },
                    // structList: symbol.structs,
                    // init: {

                    // }
                }
                const voicingTable = BackingUtil.getInitVoicingTable(chordBacking.voicingList, symbol.structs.length);
                const pattern = BackingUtil.getEditorPatternFromChordBacking(voicingTable, chordBacking.pattern);
                dispatcher.backing.setState({ ...backing, voicingTable, pattern });
                dispatcher.backing.testBacking();
            } else {
                BackingUtil.stopPreview(store, backing, dispatcher, store.reserveTasks);
            }
        };

        const [targetList, presetList]: [TargetProps[], LibraryUtil.PresetItemProps[]] = useMemo(() => {

            const targetList: TargetProps[] = [];
            const conditionList: LibraryUtil.Condition[] = [];
            chordList.forEach((element, i) => {

                if (i >= minIndex && i <= maxIndex && element.type === 'chord') {
                    const chord = element.detail as DetailChord;
                    if (chord.root == null) return 1;

                    const cache = state.elementCacheList[i];
                    // console.log(`degreeIndex: ${chord.root.index}, scaleIndex: ${cache.keyIndex}`);
                    const rootIndex = (chord.root.index + cache.keyIndex) % 12;

                    const condition: LibraryUtil.Condition = {
                        beatLen: chord.beatLen,
                        head: chord.minute.head,
                        tail: chord.minute.tail,
                        rootIndex,
                        symbolKey: chord.symbolKey,
                    };
                    // 同じ条件がリストに存在しない場合のみ追加する
                    if (conditionList.find(con => {
                        return con.beatLen === condition.beatLen && con.head === condition.head && con.tail === condition.tail &&
                            con.rootIndex === condition.rootIndex && con.symbolKey === condition.symbolKey;
                    }) == undefined) {
                        conditionList.push(condition);
                    }
                    targetList.push({
                        index: i,
                        condition
                    });
                }
            });

            return [targetList, LibraryUtil.getPresetList(thema, conditionList)];
        }, []);


        const [presetJsxList, applyJsxList, rate]: [JSX.Element[], JSX.Element[], number] = useMemo(() => {
            if (presetList.length === 0) return [[], [], 0];
            const focusPreset = presetList[field.listIndex];
            return [
                presetList.map((preset, i) => {

                    return (
                        <_PresetItem key={i}
                            isFocus={field.listIndex === i}
                            onClick={() => {
                                field.listIndex = i;
                                invalidate();
                            }}
                        ><_Preset>{preset.dispName}</_Preset> <_Method>[{preset.method}]</_Method></_PresetItem>
                    );
                }),
                focusPreset.applyList.map((target, i) => {
                    const con = target.condition;
                    const sust = `[${con.beatLen}* ${con.tail},${con.tail}]`;
                    const chordName = TheoryUtil.KEY12_SHARP_LIST[con.rootIndex] + TheoryUtil.getSymbolFromKey(con.symbolKey)?.name;
                    const isMatch = target.backing != null;
                    return (
                        <_ApplyItem
                            key={i}
                            isMatch={isMatch}
                            onClick={() => {
                                if (isMatch) {
                                    testBacking(i);
                                }
                            }}
                        ><_Result isMatch={isMatch}>{isMatch ? 'OK' : 'NG'}</_Result> {sust} {chordName}</_ApplyItem>
                    );
                }),
                focusPreset.applyList.filter(apply => {
                    return apply.backing != null;
                }).length / focusPreset.applyList.length
            ];
        }, [field.listIndex]);

        const applyAction = useCallback(() => {

            const applyList = presetList[field.listIndex].applyList;

            targetList.forEach((target) => {
                const chordElement = chordList[target.index].detail as DetailChord;
                const apply = applyList.find(apply => {
                    return LibraryUtil.matchCondition(apply.condition, target.condition);
                });
                if (apply != undefined && apply.backing != null) {
                    chordElement.backing = apply.backing;
                }
            });

            dispatcher.score.closeLibraryWnd();
        }, [field.listIndex]);

        return (
            <_Wrap>
                <_BodyDiv>
                    {presetList.length === 0 ? (
                        <_Message>Preset does not exist...</_Message>
                    ) : (<>
                        <_ListDiv>
                            <_Header>Preset</_Header>
                            {presetJsxList}
                        </_ListDiv>
                        <_ListDiv>
                            <_Header>Apply</_Header>
                            <_Sum>Matching Rate: <_Rate>{(rate * 100).toFixed(1)}%</_Rate></_Sum>
                            {applyJsxList}
                        </_ListDiv>
                    </>)}
                </_BodyDiv>
                <_FooterDiv>
                    {/* 選択を反映せずに閉じる */}
                    <_Button isEnable={true} onClick={() => {
                        dispatcher.score.closeLibraryWnd();
                    }}>Cancel</_Button>
                    {/* 選択を反映して閉じる */}
                    <_Button
                        isEnable={presetList.length > 0}
                        onClick={applyAction}
                    >Apply</_Button>
                </_FooterDiv>
            </_Wrap>
        )
    }
}
export default PresetSelectorFrame;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 630px;
    height: 400px;
    background-color: #d4d5ca;
    border: 1px solid #121212;
    box-sizing: border-box;
`;
const FOOTER_WIDTH = 30;
const _BodyDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - ${FOOTER_WIDTH}px);
    /* background-color: #4d5e53; */
`;
const _FooterDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${FOOTER_WIDTH}px;
    background-color: #c7c7c7;
    text-align: right;
`;


const _ListDiv = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    /* background-color: #4d5e53; */
    /* border: 1px solid #121212; */
    box-sizing: border-box;
`;

const _Header = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    ${Styles.CSS_LABEL_SMALL}
    background-color: #3c4a99c3;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    color: #ffffff;
    text-align: center;
`;

const _Item = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    ${Styles.CSS_LABEL_SMALL}
    background-color: #d1dad4c8;
    box-sizing: border-box;
    border: 1px solid #676767;
    color: #000786;
    text-align: left;
    padding-left: 4px;
`;

const _PresetItem = styled(_Item) <{
    isFocus: boolean;
}>`
    ${props => !props.isFocus ? '' : css`
        background-color: #e6dd5bc5;
    `}
`;

const _Method = styled.span`
    color: #00054b86;
`;
const _Preset = styled.span`
    color: #b90000;
`;

const _ApplyItem = styled(_Item) <{
    isMatch: boolean;
}>`
    background-color: #79b98f;
    ${props => !props.isMatch ? '' : css`
        background-color: #7eda5a;
        &:hover {
            background-color: #abeb92da;
        }
    `}

    
`;
const _Result = styled.span<{
    isMatch: boolean;
}>`
    ${props => !props.isMatch ? '' : css`
        color: #e61426;
    `}
`;

const _Sum = styled.div<{
}>`
    display: inline-block;
    position: relative;
    ${Styles.CSS_LABEL_SMALL}
    width: 100%;
    /* height: 32px; */
    background-color: #eaecca;
    box-sizing: border-box;
    border: 1px solid #676767;
    color: #6e6f91;
    text-align: left;
    padding-left: 4px;
`;
const _Rate = styled.span<{
}>`
    color: #be003f;
`;


const _Button = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100px;
    height: 26px;
    margin: 2px 2px 0 0;
    font-size: 20px;
    color: white;
    font-weight: 600;
    background-color: #0051ff21;
    border: solid 1px #555;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 20px;
    text-align: center;

    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}

    &:hover {
        background-color: #ff008039;
    }
`;

const _Message = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    ${Styles.CSS_LABEL_MIDIUM}
    /* background-color: #d1dad4c8; */
    color: #a10000;
    text-align: left;
    padding-left: 4px;
`;