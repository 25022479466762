

/**
 * データの履歴を管理するクラス
 */
class DataHistory {

    cur: number;
    list: string[];

    constructor() {
        this.cur = -1;
        this.list = [];
    }


    add(data: string) {
        // console.log(`add start ★ length: ${this.list.length}, cur: ${this.cur}`);
        // if (this.list[this.cur] === data) {
        //     console.log('same...');
        //     return;
        // }
        if (this.cur > -1) {
            this.list.splice(0, this.cur + 1);
            this.cur = -1;
            console.log('restert');
        }
        this.list.unshift(data);
        // console.log(`add end ★ length: ${this.list.length}, cur: ${this.cur}`);
    }

    undo(): null | string {
        let ret = null;
        if (this.cur < this.list.length - 1) {
            this.cur++;
            ret = this.list[this.cur];
        }
        return ret;
    }

    redo(): null | string {
        let ret = null;
        if (this.cur > 0) {
            this.cur--;
            ret = this.list[this.cur];
        }
        return ret;
    }
}

export default DataHistory;
