import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { Store } from "../../../../../redux/store/store";
import { getNormalizeLenFromNotes, getNotesLenFromQuantize, playSoundfont } from "../../../../../utils/melodyUtil";

class MelodyTimelineMouseOperation {

    static mouseDownEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        store: Store, dispatcher: Dispatcher, timelineLength: number, update: Function) => {

        if (e.button !== 0) return;

        const refs = store.refs.melody;
        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {
            const rect = refs.measure.getBoundingClientRect();
            const x = e.clientX - rect.left;

            const state = store.melodyState;
            const mouse = state.timelineMouse;

            mouse.lock = x;
            update();
        }
    }

    static mouseMoveEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        store: Store, dispatcher: Dispatcher, timelineLength: number, update: Function) => {

        // if (e.button !== 0) return;

        const refs = store.refs.melody;
        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {

            const rect = refs.measure.getBoundingClientRect();
            const x = e.clientX - rect.left;

            const state = store.melodyState;
            const mouse = state.timelineMouse;

            if (mouse.pos !== x) {
                // console.log(`★ move=== x: ${x}, pox: ${mouse.pos}, lock: ${mouse.lock}, width: ${mouse.width}`);
                mouse.pos = x;
                update();
            }
        }
    }

    static mouseUpEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        store: Store, dispatcher: Dispatcher, timelineLength: number, update: Function) => {

        if (e.button !== 0) return;

        const refs = store.refs.melody;
        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {
            const rect = refs.measure.getBoundingClientRect();
            const x = e.clientX - rect.left;

            const state = store.melodyState;
            const mouse = state.timelineMouse;

            // update();

            const lock = mouse.lock + refs.measure.scrollLeft;
            const pos = mouse.pos + refs.measure.scrollLeft;
            const notesList = store.scoreData.notesList;
            let start = -1;
            let end = -1;
            for (let i = 0; i < notesList.length; i++) {
                const notes = notesList[i];
                const left = getNormalizeLenFromNotes(notes.pos) * state.beatWidth;
                const right = left + getNormalizeLenFromNotes(notes.len) * state.beatWidth;
                const [head, tail] = lock < pos ? [lock, pos] : [pos, lock];
                if (head <= left && tail >= right) {
                    if (start === -1) start = i;
                } else {
                    if (start !== -1) {
                        end = i - 1;
                        break;
                    }
                }
            };
            if (start !== -1 && end === -1) {
                end = notesList.length - 1;
            }
            state.focusIndex = start;
            state.destIndex = end;
            mouse.lock = -1;
            // console.log(`★ up=== focus: ${state.focusIndex}, dist: ${state.distIndex}`);
            dispatcher.melody.setState(state);
            // const [head, tail] = mouse.lock < x ? [mouse.lock, x] : [x, mouse.lock];
            // const width = tail - head;
            // if (width > 5) {
            //     mouse.width = width;
            //     mouse.lock = -1;
            //     mouse.pos = head;
            //     update();
            //     console.log(`★ up=== pox: ${mouse.pos}, lock: ${mouse.lock}, width: ${mouse.width}`);
            // }
        }

    }
}

export default MelodyTimelineMouseOperation;