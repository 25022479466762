import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../../entry/systemEntry";

const HarmClipboard = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    return (
        <_Wrap>
            <_Label>clipboard</_Label>
            <_TypeDiv>
                <_ModeItem isActive={state.cloneBacking != null}>Backing</_ModeItem>
                <_ModeItem isActive={state.copiedElementList != null}>Elements</_ModeItem>
            </_TypeDiv>
        </_Wrap>
    );
}

export default HarmClipboard;

const _Wrap = styled.div`
    display: inline-block;
    height: 100%;
    margin-left: 20px;
    /* background-color: red; */
    & *{
        vertical-align: top;
    }
`;
const _Label = styled.div`
    display: inline-block;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #979797;
    margin-left: 4px;
    font-style: italic;
    line-height: 38px;
    /* background-color: yellow; */
`;

const _TypeDiv = styled.div`
    display: inline-block;
    height: 100%;
    margin-left: 10px;
    /* background-color: yellow; */
`;

const _ModeItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    /* width: 300px; */
    width: 120px;
    height: calc(100% - 8px);
    /* background-color: #e0ff6f55; */
    ${props => !props.isActive ? '' : css`
        background-color: #a8dbb3;
    `}
    margin: 4px 0 0 4px;

    font-size: 18px;
    font-weight: 600;
    color: #8f8f8f;
    border-radius: 2px;
    border: 1px solid #969696;
    box-sizing: border-box;
    text-align: center;
`;