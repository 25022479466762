import { useContext, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../../base/design/styles";
import { DetailChord, DetailFixed, DetailSection, FixedItem } from "../../../../redux/store/score/scoreData";
import DummyUtil from "../../../../utils/dummyUtil";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";
import progBackingUtil from "./progBackingUtil";
import ProgStyle from "./progStyle";
import ProgUtil from "./progUtil";

/**
 * コード進行の出力画面のコンポーネント
 * @param props ブロックリスト
 * @returns コンポーネント
 */
const ProgOutputFrame = (props: {
    outputUnits: ProgUtil.UnitProps[];
    updateOutputUnits: () => void;
}) => {
    // const { store, dispatcher } = useContext(GlobalContext);

    const [
        /** セクションカテゴリのoptionリスト */
        secCatOptionJsxList,
        /** セクション連番のoptionリスト */
        secNoOptionJsxList,
    ] = useMemo(() => {
        const catList = DummyUtil.SECTION_CATEGORY.map((val, i) => {
            return (
                <option key={i} value={val}>{val}</option>
            );
        });
        const noList = DummyUtil.SECTION_NO.map((val, i) => {
            return (
                <option key={i} value={val}>{val}</option>
            );
        });
        return [catList, noList];
    }, []);

    const backingSampleJsxList = progBackingUtil.BACKING_SAMPLE_LIST.map((sample, i) => {
        return (
            <option key={i} value={i}>{sample.name}</option>
        );
    });

    const blockJsxList = props.outputUnits.map((block, i) => {
        const isFixed = block.type === 'fixed';
        const getInner = () => {
            if (isFixed) {
                return <_FixedItem>{(block.detail as FixedItem)}</_FixedItem>;
            } else {
                const detail = block.detail as ProgUtil.SectionProps;
                const chordList = detail.chordList;
                const getChordListJsx = () => {
                    return chordList.map((chord, j) => {
                        const getBeatTipsJsx = () => {
                            const jsxList: JSX.Element[] = [];
                            for (let k = 0; k < chord.beatLen; k++) {
                                jsxList.push(<_BeatTip key={k} />);
                            }
                            return jsxList;
                        }
                        let chordName = 'none';
                        if (chord.root !== null) {
                            const degreeRoot = TheoryUtil.getDegreeRoot({
                                index: chord.root.index,
                                isFlat: chord.root.isFlat
                            });
                            const symbolName = TheoryUtil.getSymbolFromKey(chord.symbolKey)?.name;
                            chordName = degreeRoot + symbolName;
                        }
                        return (
                            <_ChordItem key={j}>
                                <_ChordNameLabel>{chordName}</_ChordNameLabel>
                                <_ChordBeatDiv>{getBeatTipsJsx()}</_ChordBeatDiv>
                            </_ChordItem>
                        );
                    });
                }
                return (<>
                    <_Record>
                        <_BackingLabel>Section</_BackingLabel>
                        <_Combobox isEnable={true} width={180} value={detail.sectionName} onChange={(e) => {
                            detail.sectionName = e.target.value;
                            props.updateOutputUnits();
                        }}>{secCatOptionJsxList}</_Combobox>
                        <_Combobox isEnable={true} width={60} value={detail.sectionNo} onChange={(e) => {
                            detail.sectionNo = e.target.value;
                            props.updateOutputUnits();
                        }}>{secNoOptionJsxList}</_Combobox>
                    </_Record>
                    <_Record>
                        {/* <_SectionItem>{labelName}</_SectionItem> */}
                        <_BackingLabel>Backing</_BackingLabel>
                        <_Combobox
                            isEnable={true} width={180}
                            value={detail.backingNo}
                            onChange={(e) => {
                                detail.backingNo = Number(e.target.value);
                                props.updateOutputUnits();
                            }}>{backingSampleJsxList}</_Combobox>
                    </_Record>
                    <_ChordListDiv>{getChordListJsx()}</_ChordListDiv>
                </>);
            }
        };
        return (
            <_Block key={i} isFixed={isFixed}>{getInner()}</_Block>
        );
    });
    return (
        <_Frame>
            {blockJsxList}
        </_Frame>
    );
}

export default ProgOutputFrame;


const _Frame = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #1959A8;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        box-shadow: 0 0 4px #aaa inset;
    }
`;

const _Block = styled.div<{
    isFixed: boolean;
}>`
    display: inline-block;
    width: calc(100% - 10px);
    background-color: #e8e4c5;
    margin: 5px 0 0 5px;
    box-sizing: border-box;
    /* border: 1px solid #7e7e7ee8; */
    text-align: left;
    ${props => !props.isFixed ? '' : css`
        text-align: left;
    `}
`;

const _FixedItem = styled.div<{
}>`
    ${Styles.CSS_LABEL_SMALL}
    width: 200px;
    background-color: #b14272;
    border-radius: 2px 15px 15px 2px;
    color: white;
    margin: 5px 0 5px 5px;
`;

const _SectionItem = styled.div<{
}>`
    ${Styles.CSS_LABEL_SMALL}
    width: 120px;
    background-color: #4297b1;
    color: white;
    border-radius: 2px;
    margin: 5px 0 0 5px;
`;

const RECORD_HEIGHT = 22;
const _Record = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${RECORD_HEIGHT}px;
    /* background-color: #5b6e69; */
    margin: 2px 0 0 0;
    text-align: left;
    /* padding: 5px 0 0 0; */
`;
const _BackingLabel = styled.div<{
}>`
    ${Styles.CSS_LABEL_SMALL}
    width: 90px;
    background-color: #95b6d1;
    color: white;
    border-radius: 8px;
    margin: 0 0 0 5px;
`;

export const _Combobox = styled.select<{
    isEnable: boolean;
    width: number;
}>`
    ${Styles.CSS_LABEL_SMALL}
    font-weight: inherit;
    text-align: left;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    width: ${props => props.width}px;
    margin: 0 0 0 5px;
    box-sizing: border-box;
`;

const _ChordListDiv = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - 10px);
    /* height: 100px; */
    padding: 0 5px 5px 0;
    background-color: #c2d6dd1e;
    border: solid 2px #ab4343;
    border-radius: 8px;
    box-sizing: border-box;
    margin: 5px 0 5px 5px;
    text-align: left;
`;

const _ChordItem = styled.div<{
}>`
    display: inline-block;
    height: 45px;
    background-color: #676767;
    color: white;
    border-radius: 2px;
    margin: 5px 0 0 5px;
`;

const _ChordNameLabel = styled.div<{
}>`
    ${Styles.CSS_LABEL_SMALL}
    padding: 0 20px;
    /* background-color: #b38282; */
    color: white;
`;

const _ChordBeatDiv = styled.div<{
}>`
    display: block;
    height: 25px;
    min-width: 100%;
    /* background-color: #c5adad; */
    text-align: center;
`;

const _BeatTip = styled.div<{
}>`
    display: inline-block;
    width: 8px;
    height: calc(100% - 12px);
    margin: 6px 2px 0 2px;
    background-color: #ddd796;
    border-radius: 2px;
`;
