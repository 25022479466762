import { KeyboardEvent } from "react";
import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { DetailChord } from "../../../../../redux/store/score/scoreData";
import { Store } from "../../../../../redux/store/store";
import HarmonizeUtil from "../../../../../utils/score/harmonizeUtil";
import BackingEditorInput from "./backingEditorInput";
import { NoteBasicInput } from "./noteBasicInput";
import PresetSelectorInput from "./presetSelectorInput";
import ThemaSelectorInput from "./themaSelectorInput";

export const NoteKeyDown = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher) => {

    const config = store.userEnv.keyConfig.note;
    const keySwitch = store.scoreState.keySwitch;
    // alert(e.key);
    // alert(config.nextElement);

    const data = store.scoreData;
    const state = store.scoreState;
    const curType = data.chordList[state.focusIndex].type;
    const isElement = curType !== 'init'


    // プレビュー再生時
    if (state.isPreview) {
        switch (e.key) {
            case ' ': {
                HarmonizeUtil.stopPreview(store, dispatcher, store.reserveTasks);
            }
                break;
        }
        return;
    } else {
        switch (store.scoreState.hideMenu) {
            case 'none': {
                if (isElement) {
                    if (keySwitch.control) {
                        switch (e.key) {
                            case 'c': {
                                const element = data.chordList[state.focusIndex];
                                if (element.type === "chord") {
                                    const detail = element.detail as DetailChord;
                                    if (detail.root != null) {
                                        dispatcher.score.cloneBacking();
                                    }
                                }
                            }
                                break;
                            case 'x': {
                                dispatcher.score.copyElementList();
                            }
                                break;
                            case 'v': {
                                if (state.copiedElementList != null) {
                                    dispatcher.score.pasteElementList();
                                } else if (state.cloneBacking != null) {

                                    const element = data.chordList[state.focusIndex];
                                    if (element.type === "chord") {
                                        const detail = element.detail as DetailChord;
                                        if (detail.root != null) {
                                            dispatcher.score.pastBacking();
                                        }
                                    }
                                }
                            }
                                break;
                            case 's': {
                                dispatcher.score.saveFile();
                                // DataUtil.saveFile(JSON.stringify(data), state);
                            }
                                break;
                        }
                    } else if (keySwitch.distMode) {
                        switch (e.key) {
                            case 'ArrowLeft': {
                                dispatcher.score.moveDist(false);
                            }
                                break;
                            case 'ArrowRight': {
                                dispatcher.score.moveDist(true);
                            }
                                break;
                        }
                    } else if (keySwitch.sustainMode) {
                        switch (e.key) {
                            case config.decRoot: {
                                dispatcher.score.incrementDegreeRoot(false);
                            }
                                break;
                            case config.incRoot: {
                                dispatcher.score.incrementDegreeRoot(true);
                            }
                                break;
                            case config.decBeat: {
                                dispatcher.score.incrementBeatLen(false);
                            }
                                break;
                            case config.incBeat: {
                                dispatcher.score.incrementBeatLen(true);
                            }
                                break;
                        }
                    } else if (keySwitch.minHeadMode) {
                        switch (e.key) {
                            case 'ArrowLeft': {
                                dispatcher.score.incrementMinuteValRelation(false, 1);
                            }
                                break;
                            case 'ArrowRight': {
                                dispatcher.score.incrementMinuteValRelation(false, -1);
                            }
                                break;
                        }
                    } else if (keySwitch.minTailMode) {
                        switch (e.key) {
                            case 'ArrowLeft': {
                                dispatcher.score.incrementMinuteValRelation(true, -1);
                            }
                                break;
                            case 'ArrowRight': {
                                dispatcher.score.incrementMinuteValRelation(true, 1);
                            }
                                break;
                        }
                    } else if (keySwitch.chordMode) {
                        // switch (e.key) {
                        //     case 'ArrowUp': {
                        //         dispatcher.symbol.toNext(false);
                        //     }
                        //         break;
                        //     case 'ArrowDown': {
                        //         dispatcher.symbol.toNext(true);
                        //     }
                        //         break;
                        //     case 'ArrowLeft': {
                        //         dispatcher.symbol.toBase();
                        //     }
                        //         break;
                        //     case 'ArrowRight': {
                        //         dispatcher.symbol.toDerive();
                        //     }
                        //         break;
                        // }
                    } else if (keySwitch.symbolMode) {
                        switch (e.key) {
                            case 'ArrowUp': {
                                dispatcher.symbol.toNext(false);
                            }
                                break;
                            case 'ArrowDown': {
                                dispatcher.symbol.toNext(true);
                            }
                                break;
                            case 'ArrowLeft': {
                                dispatcher.symbol.toBase();
                            }
                                break;
                            case 'ArrowRight': {
                                dispatcher.symbol.toDerive();
                            }
                                break;
                        }
                    } else {
                        NoteBasicInput(e, store, dispatcher);
                    }
                }
            } break;
            case 'backing': {
                BackingEditorInput.main(e, store, dispatcher);
            } break;
            case 'library': {
                ThemaSelectorInput.main(e, store, dispatcher);
            } break;
            case 'preset': {
                PresetSelectorInput.main(e, store, dispatcher);
            }
        }
    }
}

export const NoteKeySwitch = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher, isPress: boolean) => {

    const state = store.scoreState;
    if (!state.isPreview) {
        // if (state.hideMenu === 'none') {
        const config = store.userEnv.keyConfig.note;
        const keySwitch = store.scoreState.keySwitch;
        // alert(e.key);
        // alnfig.nextElement);
        switch (e.key) {
            case 'Control': {
                keySwitch.control = isPress;
                dispatcher.score.setKeySwitch(keySwitch);
            }
                break;
            case config.distMode: {
                if (!keySwitch.control) {
                    keySwitch.distMode = isPress;
                    dispatcher.score.setKeySwitch(keySwitch);
                }
            }
                break;
            case config.edit1: {
                if (!(keySwitch.control || keySwitch.distMode)) {
                    keySwitch.sustainMode = isPress;
                    dispatcher.score.setKeySwitch(keySwitch);
                }
            }
                break;
            case 'v': {
                if (!(keySwitch.control || keySwitch.distMode || keySwitch.sustainMode)) {
                    keySwitch.chordMode = isPress;
                    dispatcher.score.setKeySwitch(keySwitch);
                }
            }
                break;
            case config.edit2: {
                if (!(keySwitch.control || keySwitch.distMode || keySwitch.sustainMode || keySwitch.chordMode)) {
                    keySwitch.symbolMode = isPress;
                    dispatcher.score.setKeySwitch(keySwitch);
                }
            }
                break;
            case 'd': {
                if (!(keySwitch.control || keySwitch.distMode || keySwitch.sustainMode || keySwitch.chordMode || keySwitch.symbolMode)) {
                    keySwitch.minHeadMode = isPress;
                    dispatcher.score.setKeySwitch(keySwitch);
                }
            }
                break;
            case 'g': {
                if (!(keySwitch.control || keySwitch.distMode || keySwitch.sustainMode || keySwitch.chordMode || keySwitch.symbolMode || keySwitch.minHeadMode)) {
                    keySwitch.minTailMode = isPress;
                    dispatcher.score.setKeySwitch(keySwitch);
                }
            }
                break;
        }
        // }
    }
    if (isPress) {
        NoteKeyDown(e, store, dispatcher);
    }

}