import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import Styles from "../../../../base/design/styles";
import DataUtil from "../../../../utils/dataUtil";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const BaseFormTab = (props: {
    themaList: ThemaUtil.LevelBase[];
    addThema: (thema: ThemaUtil.LevelBase) => void;
}) => {
    const { node, invalidate, setThamaData } = useContext(ThemaTreeContext);
    const data = node.data as ThemaUtil.LevelBase;
    const [themaName, setThemaName] = useState(data.themaName);

    const [errorList, setErrorList] = useState<string[]>([]);

    useEffect(() => {
        const list: string[] = [];
        if (themaName === '') {
            list.push('name is empty.');
        }
        setErrorList(list);
    }, [themaName]);

    return (<>
        <ThemaEditTabUtil.ButtonDiv buttons={[
            {
                name: 'Export',
                isEnable: true, callback: () => {
                    const options = {
                        types: [
                            {
                                accept: {
                                    'text/plain': ['.lcrt'],
                                },
                            },
                        ],
                    };

                    window.showSaveFilePicker(options).then((handle) => {
                        (async () => {
                            //ファイルへ書き込むための FileSystemWritableFileStream を作成
                            const writable = await handle.createWritable();
                            //テキストデータの書き込み
                            const text = DataUtil.gZip(JSON.stringify(data));
                            await writable.write(text);
                            //ファイルを閉じる
                            await writable.close();
                        })();
                    }).catch(() => {
                        console.log('キャンセルされました');
                    });

                }
            },
            {
                name: 'Import',
                isEnable: true, callback: () => {
                    (async () => {
                        const [fileHandle] = await window.showOpenFilePicker();
                        const file = await fileHandle.getFile();
                        const fileContents = await file.text();
                        const text = DataUtil.unZip(fileContents);
                        const themaData = JSON.parse(text);
                        setThamaData(themaData);
                        node.data = themaData;
                        console.log(JSON.stringify(node.data));
                        invalidate();
                    })();
                }
            }
        ]} />
        {/* <ThemaEditTabUtil.ButtonDiv buttons={[
            {
                name: 'Clear',
                isEnable: true, callback: () => {
                    data.meths[0].defs = [];
                    data.meths[1].defs = [];
                    invalidate();
                }
            }
        ]} /> */}
        <ThemaEditTabUtil.InputTextForm titleLabel="name" value={themaName} setValue={(value: string) => {
            setThemaName(value);
        }} />
        <ThemaEditTabUtil.BorderPanel
            title="selft operatioon"
            innerJsx={<>
                <ThemaEditTabUtil.TextAreaView value={errorList.join('\n')} height={50} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: errorList.length === 0,
                        callback: () => {
                            data.themaName = themaName;
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="install"
            innerJsx={<>
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Install',
                        isEnable: !props.themaList.map(thema => thema.themaName).includes(data.themaName),
                        callback: () => {
                            props.addThema(data);
                        }
                    }
                ]} />
                <ThemaEditTabUtil.ListManager
                    height={140}
                    records={props.themaList.map((thema, i) => {
                        return {
                            label: thema.themaName, buttons: [
                                {
                                    label: '↓', width: 30,
                                    isEnable: i < props.themaList.length - 1,
                                    callback: () => {
                                        const cache = props.themaList[i];
                                        props.themaList[i] = props.themaList[i + 1];
                                        props.themaList[i + 1] = cache;
                                        invalidate();
                                    },
                                },
                                {
                                    label: '↑', width: 30,
                                    isEnable: i > 0,
                                    callback: () => {
                                        const cache = props.themaList[i];
                                        props.themaList[i] = props.themaList[i - 1];
                                        props.themaList[i - 1] = cache;
                                        invalidate();
                                    },
                                },
                                {
                                    label: 'Load', width: 50, isEnable: false, callback: () => {

                                    },
                                },
                                {
                                    label: 'Delete', width: 60, callback: () => {
                                        props.themaList.splice(i, 1);
                                        invalidate();
                                    },
                                },
                            ]
                        };
                    })}
                />
            </>}
        />
    </>);
}

export default BaseFormTab;

