import { useContext, useMemo } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../../utils/backingUtil";
import { NOTES_1BAR_WIDTH, PATTERN_BODY_HEIGHT, PATTERN_FOOTER_HEIGHT, PATTERN_HEADER_HEIGHT } from "../../../../utils/systemConst";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";

const PatternTable = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const chordInfo = state.chordInfo;
    const pattern = state.pattern as BackingState.Pattern;
    const channelCount = pattern.channels.length;
    // const layer = pattern.layers[pattern.layerIndex];
    const curLayer = pattern.layers[pattern.layerIndex];

    const update = () => dispatcher.backing.setState(state);

    const beatProps = TheoryUtil.getBeatProps(chordInfo.init.beatSignature);
    const adjustRate = beatProps.beatMemoriCount === 4 ? 1 : (3 / 2);

    const layerJsxList = useMemo(() => {

        const layerJsxList: JSX.Element[][] = Array(2);

        pattern.layers.forEach((layer, j) => {

            const jsxLsit: JSX.Element[] = [];
            for (let i = 0; i < channelCount; i++) {
                const notesJsxLsit: JSX.Element[] = [];

                layer.noteDivList.forEach((note, k) => {
                    // データ上は下から数えるため、表示のループは逆回転する
                    const channelIndex = (channelCount - 1) - i;
                    const isActive = pattern.layerIndex === j && pattern.lenIndex === k;
                    const cell = layer.table[channelIndex][k];
                    const setNotesStatus = (status: BackingState.NoteStatus | null) => layer.table[channelIndex][k] = status;

                    const isUse = cell != null;
                    // 前面にあるレイヤーのみエラー判定をする
                    const isError = pattern.layerIndex !== j ? false :
                        isUse && BackingUtil.checkOverlapBackLayer(pattern.layers, j, channelIndex, k);
                    const isEdit = isActive && pattern.channelIndex === i;
                    let rate = note.rate * adjustRate;
                    if (note.isDot) rate /= 1.5;
                    if (note.div3 !== 0) rate /= note.div3 / 3;

                    let viewSignal: BackingState.NoteSignal | null = null;
                    if (cell != null) {
                        viewSignal = cell.signal;
                        if (k > 0) {
                            const prevCell = layer.table[channelIndex][k - 1];
                            if (prevCell != null && prevCell.signal === 't') {
                                viewSignal = 't';
                            }
                        }
                    }

                    const hasStaccato = () => {
                        const search = layer.table.find((record) => {
                            const cell = record[k];
                            if (cell != null && cell.signal === 's') return cell;
                        });
                        return search != undefined;
                    }
                    notesJsxLsit.push(
                        <_Notes key={k} rate={rate} isActive={isActive} isEdit={isEdit} isBack={pattern.layerIndex !== j}>
                            <_State
                                // isUse={isUse}
                                isError={isError}
                                signal={viewSignal}
                                isBack={pattern.layerIndex !== j}
                                // クリック
                                onClick={() => {
                                    if (cell == null) {
                                        // 同列にスタッカートがある場合、スタッカートを設定
                                        const signal = !hasStaccato() ? 'n' : 's';
                                        setNotesStatus({ signal, timing: 0, velAdj: 0 });
                                    } else {
                                        setNotesStatus(null);
                                    }
                                    curLayer.table = curLayer.table.slice();
                                    update();
                                    dispatcher.backing.updateErrorState();
                                }}
                                // 右クリック
                                onContextMenu={() => {
                                    pattern.channelIndex = i;
                                    pattern.lenIndex = k;
                                    update();
                                }}
                            />
                        </_Notes>
                    );
                });
                jsxLsit.push(
                    <_Record key={i} isEven={i % 2 == 0} isFront={true}>
                        {notesJsxLsit}
                    </_Record>
                );
            }
            layerJsxList[j] = jsxLsit;
        });
        return layerJsxList;
    }, [
        pattern.channels, pattern.layerIndex, pattern.lenIndex,
        curLayer.noteDivList, curLayer.table, pattern.channelIndex
    ]);

    // 選択中のレイヤーを表に表示する
    const jsx = pattern.layerIndex === 0 ? layerJsxList : layerJsxList.reverse();
    return (<>
        <_Layer zIndex={2}>{jsx[1]}</_Layer>
        <_Layer zIndex={1}>{jsx[0]}</_Layer>
    </>);
}

export default PatternTable;

const _Layer = styled.div<{
    zIndex: number;
}>`
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* background-color: #000000f; */
`;

const _Record = styled.div<{
    isEven: boolean;
    isFront: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 20px;
    overflow: hidden;
    white-space: nowrap;
    ${props => !props.isFront ? '' : css<{
    isEven: boolean;
}>`
        ${props => !props.isEven ? css`
            background-color: #3a3a502f;
        ` : css`
            background-color: #42425542;
        `}
    `}
`;

const _Notes = styled.div<{
    rate: number;
    isActive: boolean;
    isEdit: boolean;
    isBack: boolean;
}>`
    display: inline-block;
    width: ${props => NOTES_1BAR_WIDTH / props.rate - 1}px;
    height: calc(100% - 1px);
    margin: 1px 0 0 1px;
    ${props => props.isBack ? '' : css<{
    isActive: boolean;
}>`
        background-color: #00000013;
        ${props => !props.isActive ? '' : css`
            background-color: #9b9b9b1d;
        `}
    `}
    ${props => !props.isEdit ? '' : css`
        border: 2px solid #d80000ba;
    `}
    box-sizing: border-box;

    &:hover {
        background-color: #82f7ff57;
    }
`;

const _State = styled.div<{
    // isUse: boolean;
    isError: boolean;
    signal: null | BackingState.NoteSignal;
    isBack: boolean;
}>`
    display: inline-block;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    margin: 1px 0 0 1px;
    background-color: #a7a7a754;
    ${props => props.signal == null ? '' : css<{
    isBack: boolean;
    isError: boolean;
    signal: null | BackingState.NoteSignal;
}>`
        /* background-color: #f8fc029b; */
        background: linear-gradient(to bottom, #9b9e00, #f8fc02, #9b9e00);
        ${props => props.signal !== 's' ? '' : css`
            border-radius: 8px;
            background: linear-gradient(to bottom, #009e0d, #02fc49, #009e0d);
        `}
        ${props => props.signal !== 't' ? '' : css`
            height: calc(100% - 8px);
            margin: 4px 0 0 1px;
            background: linear-gradient(to bottom, #9e4c00, #fc6a02, #9e4c00);
        `}
        // エラーあり
        ${props => !props.isError ? '' : css`
            opacity: 0.5;
        `}
        // 裏面のレイヤー
        ${props => !props.isBack ? '' : css`
            background: linear-gradient(to bottom, #9e0000, #fc0202, #9e0000);
            opacity: 0.5;
        `}
    `}
    box-sizing: border-box;
    &:hover {
        background-color: #ffffff63;
        ${props => props.signal == null ? '' : css`
            background-color: #fdff719b;
        `}
    }
`;