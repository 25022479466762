import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Styles, { _CSS_LABEL_MIDIUM } from "../../base/design/styles";
import DataUtil from "../../utils/dataUtil";
import { GlobalContext } from "../entry/systemEntry";


const ScoreInfoTab = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const [userId, setUserId] = useState('-');

    const directScore = store.systemState.directScore;

    const [owner, name] = directScore == null ? ['-', '-'] : [directScore.owner, directScore.name];

    return (<_Wrap>
        <_Left>
            <_Recored>
                <_TitleLabel>Score Data</_TitleLabel>
            </_Recored>
            <_ListInfoDiv isEnable={true}>
                <_Recored>
                    <_ItemnLabel>owner</_ItemnLabel>
                    <_ItemnValue>{owner}</_ItemnValue>
                </_Recored>
                <_Recored>
                    <_ItemnLabel>name</_ItemnLabel>
                    <_ItemnValue>{name}</_ItemnValue>
                </_Recored>
                <_Button isEnable={true} onClick={() => {
                    saveFile(JSON.stringify(store.scoreData));
                }}>Download</_Button>
            </_ListInfoDiv>
        </_Left>
    </_Wrap>);
}

export default ScoreInfoTab;

const saveFile = (plainData: string) => {
    const options = {
        types: [
            {
                accept: {
                    'text/plain': ['.lcrs'],
                },
            },
        ],
    };
    window.showSaveFilePicker(options).then((handle) => {
        (async () => {
            //ファイルへ書き込むための FileSystemWritableFileStream を作成
            const writable = await handle.createWritable();
            //テキストデータの書き込み
            const text = DataUtil.gZip(plainData);
            await writable.write(text);
            //ファイルを閉じる
            await writable.close();
        })();
    }).catch(() => {
        console.log('キャンセルされました');
    });
}

const _Wrap = styled.div`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 0 4px;
    background-color: #ccc;
`;

const _Left = styled.div`
    display: inline-block;
    width: 50%;
    height: 100%;
    background-color: #bbcac7;
`;

const _ListInfoDiv = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: 400px;
    margin: 4px 0 4px 4px;
    background-color: #acacac;
    border: solid 1px #1b00b3;
    ${props => props.isEnable ? '' : css`
        opacity: 0.3;
        pointer-events: none;
    `}
    border-radius: 4px;
    box-sizing: border-box;
    color: #060053;
`;

const _TitleLabel = styled.div`
    display: inline-block;
    height: 40px;
    font-size: 30px;
    font-weight: 600;
    color: #1c2c95;
    /* background-color: #3f80cf57; */
    padding: 0 10px;
`;
const _Recored = styled.div`
    display: inline-block;
    width: 100%;
    height: 35px;
    /* background-color: #3fcf7b56; */
    padding: 4px 0 0 10px;
`;

const _ItemnLabel = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: 140px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #08106e;
    border: solid 1px #1b00b3;
    box-sizing: border-box;
    /* background-color: #85a2c5; */
    background: linear-gradient(to bottom, #8a98a7, #a3bbd2, #8a98a7);
    border-radius: 4px;
    text-align: center;
`;

const _ItemnValue = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 30px;
    user-select: text;
    /* font-weight: 600; */
    color: #ffffff;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #808080;
    text-align: left;
`;

const _ItemnForm = styled.input`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 24px;
    /* font-weight: 600; */
    color: #1a1a1a;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #e9e9e9;
    text-align: left;
`;

const _ButtonsDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 40px;
    background-color: #888888;
    box-sizing: border-box;
    text-align: left;
`;

const _Button = styled.div<{
    isEnable: boolean;
}>`
    ${_CSS_LABEL_MIDIUM}
    /* width: 160px; */
    padding: 0 40px;
    /* line-height: 25px; */
    box-sizing: border-box;
    background-color: #a8a8a8;
    background: linear-gradient(to bottom, #787878, #b4b4b4, #787878);
    border: 1px solid #1a2b3f;
    color: #1a2b3f;
    border-radius: 4px;
    margin: 5px 0 0 4px;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    &:hover {
        /* background-color: #98bfc2; */
    background: linear-gradient(to bottom, #989898, #b4b4b4, #989898);
    }
`;