import { useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { GlobalContext } from "../entry/systemEntry";


const GloballDialog = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const dialog = store.systemState.dialog;

    if (dialog == null) return <></>;
    return (<>
        <_Blind onClick={() => {
            store.systemState.dialog = null;
            dispatcher.system.setState(store.systemState);
        }} />
        {dialog}
    </>);
}

export default GloballDialog;

const _Blind = styled.div<{
}>`
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000329a2;
    z-index: 10;
    text-align: center;
`;
