import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../../base/design/styles";
import { BeatSignature, DetailChord, DetailFixed, DetailInit, DetailSection, ScoreData } from "../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";
import ProgCustomFrame from "./progCustomFrame";
import ProgOutputFrame from "./progOutputFrame";
import ProgUnitSearcher from "./progUnitSearcher";
import ProgStyle from "./progStyle";
import ProgTemplateFrame from "./progTemplateFrame";
import ProgUtil from "./progUtil";
import ProgTemplateUtil from "./progTemplateUtil";

/**
 * コード進行を構成して譜面のベースを作成するダイアログ
 * @param props 引数
 * @returns コンポーネント
 */
const ChordProgDialog = (props: {
    setScoreData: (scoreData: ScoreData) => void;
}) => {
    const { store, dispatcher } = useContext(GlobalContext);

    /** 生成モード（ブランク、カスタム、テンプレート） */
    const [buildMode, setBuildType] = useState<ProgUtil.BuildType>('blank');
    /** 出力する進行ユニットのリスト */
    const [outputUnits, setOutputUnits] = useState(ProgUtil.getBlankBlockList());
    /** 拍子フィルター */
    const [beatFilter, setBeatFilter] = useState<'' | BeatSignature>('');

    /**
     * Closeボタン押下時の処理
     */
    const closeAction = () => {
        store.systemState.dialog = null;
        dispatcher.system.setState(store.systemState);
    };

    /**
     * Startボタン押下時の処理
     */
    const startAction = () => {
        const scoreData = ProgUtil.buildScoreData(outputUnits);
        props.setScoreData(scoreData);
        closeAction();
        // Editタブに遷移
        store.systemState.scoreTabIndex = 1;
        dispatcher.system.setState(store.systemState);
    };

    const getSwitchContJsx = () => {
        switch (buildMode) {
            case 'blank': return <></>;
            case 'custom': return <ProgCustomFrame
                beat={beatFilter}
                unitNum={outputUnits.length - 2}
                reset={() => { setOutputUnits(ProgUtil.getInitialBlockList()) }}
                insertProgUnit={insertProgUnit}
            />;
            case 'template': return <ProgTemplateFrame
                beat={beatFilter}
                applyProgTemplate={applyProgTemplate}
            />;
        }
    };

    /** 拍フィルターのオプションリストJSX */
    const beatOptionJsxList = useMemo(() => {
        if (buildMode === 'blank') {
            return [(
                <option key={0} value={''}></option>
            )];
        } else {
            return [''].concat(TheoryUtil.BEAT_DEF_LIST).map((val, i) => {
                return (
                    <option key={i} value={val}>{val}</option>
                );
            });
        }
    }, [buildMode]);

    const beatChangeAction = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setBeatFilter(e.target.value as BeatSignature);
    }

    /** リストから選択中のコード進行ブロックを出力に追加する */
    const insertProgUnit = (sectionName: string, sectionNo: string, chordList: ProgUnitSearcher.ChordItemBean[]) => {
        const detail: ProgUtil.SectionProps = {
            sectionName, sectionNo,
            chordList: chordList.map((chord) => {
                const chordDetail = ProgUtil.getDefaultChord();
                chordDetail.beatLen = chord.beatLen;
                chordDetail.symbolKey = chord.symbolKey;
                chordDetail.root = chord.root;
                return chordDetail;
            }),
            backingNo: 1
        }
        const block: ProgUtil.UnitProps = { type: 'section', detail }
        outputUnits.splice(outputUnits.length - 1, 0, block);
        setOutputUnits(outputUnits.slice());
    }

    const applyProgTemplate = (templateBean: ProgTemplateUtil.TemplateBean) => {
        outputUnits.splice(0);
        ProgUtil.getInitialBlockList().forEach(progUnit => {
            outputUnits.push(progUnit);
        });
        templateBean.unitList.forEach((unit) => {
            insertProgUnit(unit.sectionName, '', unit.chordList);
        });
    }

    /**
     * 切り替え式ボタンのJSXを返すクロージャ
     * @param labelName ラベル名
     * @param clickEvent 押下時の処理
     * @param isActive 選択中かどうか
     * @returns JSX
     */
    const createSwitchableButton = (
        labelName: string,
        isActive: boolean,
        clickEvent: () => void
    ) => {
        return (
            <_SwitchableButton isActive={isActive}
                onClick={clickEvent}
            >{labelName}</_SwitchableButton>
        );
    }

    const beatComboboxEnable = outputUnits.length <= 2;

    // Blankモードの時は空白を表示
    const distBeatFilter = buildMode === 'blank' ? '' : beatFilter;
    return (
        <_Frame >
            <_Header>
                {createSwitchableButton('Blank', buildMode === 'blank', () => {
                    setOutputUnits(ProgUtil.getBlankBlockList());
                    setBuildType('blank');
                })}
                {createSwitchableButton('Custom', buildMode === 'custom', () => {
                    setOutputUnits(ProgUtil.getInitialBlockList());
                    setBuildType('custom');
                })}
                {createSwitchableButton('Template', buildMode === 'template', () => {
                    setOutputUnits(ProgUtil.getInitialBlockList());
                    setBuildType('template');
                })}
            </_Header>
            <_MainDiv>
                <_LeftDiv isEnable={buildMode !== 'blank'}>
                    <_BeatFormDiv>
                        <ProgStyle._TitleLabel>Beat</ProgStyle._TitleLabel>
                        <ProgStyle._Combobox value={distBeatFilter} isEnable={beatComboboxEnable} width={150}
                            onChange={(e) => {
                                beatChangeAction(e);
                            }}>{beatOptionJsxList}</ProgStyle._Combobox>
                    </_BeatFormDiv>
                    {/* モードによって切り替えるコンテンツ（カスタムorテンプレート） */}
                    <_SwitchDiv>{getSwitchContJsx()}</_SwitchDiv>
                </_LeftDiv>
                <_RightDiv>
                    <ProgOutputFrame
                        outputUnits={outputUnits}
                        updateOutputUnits={() => { setOutputUnits(outputUnits.slice()) }}
                    />
                </_RightDiv>
            </_MainDiv>
            <_Footer>
                {/* ダイアログを閉じる */}
                <_ExecutableButton isEnable={true} onClick={closeAction}>Cancel</_ExecutableButton>
                {/* 譜面を生成 */}
                <_ExecutableButton isEnable={outputUnits.length >= 3} onClick={startAction}>Start</_ExecutableButton>
            </_Footer>
        </_Frame>
    );
}

export default ChordProgDialog;

const HEADER_HEIGHT = 50;
const FOOTER_HEIGHT = 50;
const BEAT_HEIGHT = 40;

const _Frame = styled.div<{
}>`
    display: inline-block;
    position: absolute;
    left: 50px;
    top: 50px;
    width: 1024px;
    height: 768px;
    z-index: 11;
    /* top: 100px;
    left: 100px; */
    /* width: calc(100% - 200px);
    height: calc(100% - 200px); */
    background-color: #929292;
    border: solid 2px #404250;
    border-radius: 8px;
    /* border: 1px solid #dadada; */
`;

/**
 * ヘッダー
 */
const _Header = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    background-color: #7d8d97;
    text-align: center;
`;

/**
 * メインコンポーネント
 */
const _MainDiv = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: calc(100% - ${HEADER_HEIGHT + FOOTER_HEIGHT}px);
    /* background-color: #7d8d97; */
    text-align: left;
`;

/**
 * 左側のコンポーネント
 */
const _LeftDiv = styled.div<{
    isEnable: boolean;
}>`
   display: inline-block;
   vertical-align: top;
   width: calc(50% - 6px);
   height: calc(100% - 8px);
   background-color: #bdced8;
   text-align: left;
   margin: 4px 2px 4px 4px;
   ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
`;

/**
 * 拍選択
 */
const _BeatFormDiv = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${BEAT_HEIGHT}px;
    /* background-color: #b4c7ba; */
    padding: 5px 0 0 5px;
    box-sizing: border-box;
`;

const _BeatSelect = styled.select<{
}>`
    display: inline-block;
    height: 30px;
    font-size: ${Styles.FONT_MIDIUM}px;
    text-align: left;
    color: black;
    vertical-align: top;
    line-height: 24px;
    width: 210px;
    box-sizing: border-box;
    margin: 0 0 0 5px;
`;


/**
 * テンプレートとカスタムの切り替えコンテンツ表示
 */
const _SwitchDiv = styled.div<{
}>`
    display: inline-block;
    background-color: #cedbd7;
    border: 1px solid #696969;
    box-sizing: border-box;
    margin: 5px 0 0 5px;
    width: calc(100% - 10px);
    height: calc(100% - ${BEAT_HEIGHT + 10}px);
`;

/**
 * 右側のコンポーネント
 */
const _RightDiv = styled.div<{
}>`
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 6px);
    height: calc(100% - 8px);
    background-color: #bdced8;
    text-align: left;
    margin: 4px 4px 4px 2px;
`;

/**
 * フッター
 */
const _Footer = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    background-color: #d1d1d1;
    text-align: right;
    & *{
        margin: 10px 5px 0 0;
    }
`;

/**
 * スイッチボタン
 */
const _SwitchableButton = styled.div<{
    isActive: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    background-color: #5083c5;
    ${props => !props.isActive ? '' : css`
        background-color: #c5b950;
    `};
    width: 200px;
    margin: 10px 5px 0 0;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #375176;
    box-sizing: border-box;
    &:hover {
        background-color: #77a7e6;
    ${props => !props.isActive ? '' : css`
            background-color: #c5b950;
    `};
}
`;

/**
 * 実行ボタン
 */
const _ExecutableButton = styled.div<{
    isEnable: boolean;
}>`
    ${Styles.CSS_LABEL_MIDIUM};
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE};
    background-color: #5c9253;
    width: 130px;
    text-align: center;
    border-radius: 4px;
    border: solid 1px #2d5625;
    box-sizing: border-box;
    &:hover {
        background-color: #7eaf75;
    }
`;