import { BeatSignature } from "../../../../redux/store/score/scoreData";
import ProgUnitSearcher from "./progUnitSearcher";

/**
 * コード進行のテンプレートを検索するユーティリティ
 */
namespace ProgTemplateUtil {


    export type TemplateBean = {
        name: string;
        condition: TemplateCondition;
        unitList: UnitItemBean[];
    }

    export type UnitItemBean = {
        sectionName: string;
        chordList: ProgUnitSearcher.ChordItemBean[];
    }

    export type TemplateCondition = {
        beat: BeatSignature;
    }

    /**
     * テンプレートを検索する
     * @param condition 
     * @returns フィルターしたリスト
     */
    export const search = (condition: TemplateCondition) => {
        console.log(JSON.stringify(condition));
        return TEMPLATE_LIST.filter((template) => (
            (template.condition.beat === condition.beat)
        ));
    }
    
    const TEMPLATE_LIST: TemplateBean[] = [
        {
            name: 'major minimum 01 [Ⅰ→Ⅳ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@4 1 4 5 @2 1 5 @4 6 4 5 1')
                }
            ]
        },
        {
            name: 'major minimum 02 [Ⅰ→Ⅴ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@2 1 5 6 3 4 1 2 5 1 5 3n*7 6 2 3 4n*m 1')
                }
            ]
        },
        {
            name: 'minor minimum 01 [Ⅵ→Ⅴ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@2 6 5 4 3n*m7 2n*m7 6 7n*7 3n*7 6')
                }
            ]
        },
        {
            name: 'minor minimum 02 [Ⅱm→Ⅴ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@2 2n*m7 5 1 6 2n*m7 3n*7 6 6n*7 2n*m7 5 3n*m7 6 2n*m7 3n*7 6n*sus4 6')
                }
            ]
        },
        {
            name: 'major short 01 [Ⅰ→Ⅴ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@2 1 5 6 3n*m7 4 1 2n*m7 5 1 5 6 3 4 1 2n*7 3n*7')
                },
                {
                    sectionName: 'B',
                    chordList: ProgUnitSearcher.parseChord('@4 4n*maj7 5 3n*m7 6 4 5 1n*sus4 1')
                }
            ]
        },
        {
            name: 'major short 02 [Ⅳ→Ⅴ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@4 4n*maj7 5 3n*m7 6 2n*m7 5 1 @2 5n*m 1n*7 @4 4n*maj7 5 @2 1 5 @4 6 2n*m7 5 6n*sus4 6n*')
                }
            ]
        },
        {
            name: 'major short 03 [Ⅰ→Ⅵm～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@4 1 1 6 6 4 4 5 5 1 1 6 6 4 4 5 5')
                },
                {
                    sectionName: 'B',
                    chordList: ProgUnitSearcher.parseChord('@4 4n*maj7 5 3n*m7 6 4 5 1n*sus4 1')
                }
            ]
        },
        {
            name: 'minor short 01 [Ⅵ→Ⅳ～]',
            condition: { beat: '4/4' },
            unitList: [
                {
                    sectionName: 'A',
                    chordList: ProgUnitSearcher.parseChord('@2 6 4 5 1 6 4 5 1 6 4 5 1 2 3n*m7 4n*maj7 3n*7')
                },
                {
                    sectionName: 'B',
                    chordList: ProgUnitSearcher.parseChord('@4 4 5 3 6 2 3 6n*sus4 6')
                }
            ]
        },
    ];
}

export default ProgTemplateUtil;