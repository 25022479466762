import { useMemo } from "react";
import styled from "styled-components";
import Styles from "../../../base/design/styles";
import TheoryUtil from "../../../utils/theoryUtil";
import ThemaUtil from "./themaUtil";
import TreeUtil from "./treeUtil";

const HierarchyNavi = (props: {
    cur: TreeUtil.ElementNode | null;
}) => {

    const jsx = useMemo(() => {
        if (props.cur == null) return <></>;
        const list: JSX.Element[] = [];

        const connect = (isCreaet: boolean, addInfo: () => void) => {
            list.push(<span key={list.length}>{` > `}</span>);
            if (isCreaet) list.push(<_Create key={list.length}>Create...</_Create>);
            else {
                addInfo();
            }
        }

        /**
         * 基本情報のJSXを追加する
         * @param data データ
         */
        const addBaseInfo = (data: ThemaUtil.LevelBase) => {
            list.push(<_Base key={list.length}>[{data.themaName}]</_Base>);
        }
        /**
         * メソッド情報のJSXを追加する
         * @param data データ
         */
        const addMethInfo = (data: ThemaUtil.LevelMethod) => {
            connect(data == null, () => {
                list.push(<_Meth key={list.length}>[{data.method}]</_Meth>);
            });
        }
        /**
         * 拍情報のJSXを追加する
         * @param data データ
         */
        const addSustInfo = (data: ThemaUtil.LevelSust) => {
            connect(data == null, () => {
                list.push(<_Sust key={list.length}>[{data.beat}* ({data.head},{data.tail})]</_Sust>);
            });

        }
        /**
         * パターン情報のJSXを追加する
         * @param data データ
         */
        const addPattInfo = (data: ThemaUtil.LevelPatt) => {
            connect(data == null, () => {
                list.push(<_Patt key={list.length}>{data.key}: [{data.pattName} channel: {data.source.channelSize}]</_Patt>);
            });
        }
        /**
         * 属性情報のJSXを追加する
         * @param data データ
         */
        const addAttrInfo = (data: ThemaUtil.LevelAttr) => {
            connect(data == null, () => {
                const symbolNameList = data.symbols.map(symbol => (
                    `[${TheoryUtil.getSymbolFromKey(symbol)?.name}]`
                ));
                list.push(<_Attr key={list.length}>@{data.attr}: {symbolNameList.join(', ')}</_Attr>);
            });
        }
        /**
         * ルート情報のJSXを追加する
         * @param data データ
         */
        const addRootInfo = (data: ThemaUtil.LevelRoot) => {
            connect(data ==null, () => {
                const nameList = TheoryUtil.KEY12_SHARP_LIST;
                list.push(<_Root key={list.length}>[{nameList[data.minIdx]}～{nameList[data.maxIdx]}]</_Root>);
            });
        }
        /**
         * ボイシング情報のJSXを追加する
         * @param data データ
         */
        const addVoicInfo = (data: ThemaUtil.LevelVoic) => {
            connect(data == null, () => {
                list.push(<_Voic key={list.length}>[{data.source.map(v => {
                    return `${v.octave}-${v.struct}`;
                }).join(', ')}]</_Voic>);
            });
        }

        // 選択されたノードに応じて、階層構造を表示する
        switch (props.cur.depth) {
            case 0: {
                addBaseInfo(props.cur.data as ThemaUtil.LevelBase);
            } break;
            case 1: {
                addBaseInfo(props.cur.parent?.data as ThemaUtil.LevelBase);
                addMethInfo(props.cur.data as ThemaUtil.LevelMethod);
            } break;
            case 2: {
                addBaseInfo(props.cur.parent?.parent?.data as ThemaUtil.LevelBase);

                const methData = props.cur.parent?.data as ThemaUtil.LevelMethod;
                addMethInfo(methData);
                switch (methData.method) {
                    case 'sustain': {
                        addAttrInfo(props.cur.data as ThemaUtil.LevelAttr);
                    } break;
                    case 'pattern': {
                        addSustInfo(props.cur.data as ThemaUtil.LevelSust);
                    } break;
                }
            } break;
            case 3: {
                addBaseInfo(props.cur.parent?.parent?.parent?.data as ThemaUtil.LevelBase);

                const methData = props.cur.parent?.parent?.data as ThemaUtil.LevelMethod;
                addMethInfo(methData);
                switch (methData.method) {
                    case 'sustain': {
                        addAttrInfo(props.cur.parent?.data as ThemaUtil.LevelAttr);
                        addRootInfo(props.cur.data as ThemaUtil.LevelRoot);
                    } break;
                    case 'pattern': {
                        addSustInfo(props.cur.parent?.data as ThemaUtil.LevelSust);
                        addPattInfo(props.cur.data as ThemaUtil.LevelPatt);
                    } break;
                }
            } break;
            case 4: {
                addBaseInfo(props.cur.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelBase);

                const methData = props.cur.parent?.parent?.parent?.data as ThemaUtil.LevelMethod;
                addMethInfo(methData);
                switch (methData.method) {
                    case 'sustain': {
                        addAttrInfo(props.cur.parent?.parent?.data as ThemaUtil.LevelAttr);
                        addRootInfo(props.cur.parent?.data as ThemaUtil.LevelRoot);
                        addVoicInfo(props.cur.data as ThemaUtil.LevelVoic);
                    } break;
                    case 'pattern': {
                        addSustInfo(props.cur.parent?.parent?.data as ThemaUtil.LevelSust);
                        addPattInfo(props.cur.parent?.data as ThemaUtil.LevelPatt);
                        addAttrInfo(props.cur.data as ThemaUtil.LevelAttr);
                    } break;
                }
            } break;
            case 5: {
                addBaseInfo(props.cur.parent?.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelBase);

                const methData = props.cur.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelMethod;
                addMethInfo(methData);
                switch (methData.method) {
                    case 'sustain': {

                    } break;
                    case 'pattern': {
                        addSustInfo(props.cur.parent?.parent?.parent?.data as ThemaUtil.LevelSust);
                        addPattInfo(props.cur.parent?.parent?.data as ThemaUtil.LevelPatt);
                        addAttrInfo(props.cur.parent?.data as ThemaUtil.LevelAttr);
                        addRootInfo(props.cur.data as ThemaUtil.LevelRoot);
                    } break;
                }
            } break;
            case 6: {
                addBaseInfo(props.cur.parent?.parent?.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelBase);

                const methData = props.cur.parent?.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelMethod;
                addMethInfo(methData);
                switch (methData.method) {
                    case 'sustain': {

                    } break;
                    case 'pattern': {
                        addSustInfo(props.cur.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelSust);
                        addPattInfo(props.cur.parent?.parent?.parent?.data as ThemaUtil.LevelPatt);
                        addAttrInfo(props.cur.parent?.parent?.data as ThemaUtil.LevelAttr);
                        addRootInfo(props.cur.parent?.data as ThemaUtil.LevelRoot);
                        addVoicInfo(props.cur.data as ThemaUtil.LevelVoic);
                    } break;
                }
            } break;
        }
        return list;
    }, [props.cur]);

    return (
        <_Wrap>
            <_TextField>{jsx}</_TextField>
        </_Wrap>
    );
};

export default HierarchyNavi;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    background-color: #eeeeee3b;
    border: 1px solid #402cad;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 0 4px;
    box-sizing: border-box;
    overflow: hidden;
    /* text-align: right; */
`;

const _TextField = styled.div`
    ${Styles.CSS_LABEL_MIDIUM}
    margin: 0 0 0 4px;
    color: #0000005a;
    font-weight: 400;
`;
const _Create = styled.span`
    color: #e69d00;
`;

const _Base = styled.span`
    color: #0c6900;
`;

const _Meth = styled.span`
    color: #23237e;
`;

const _Sust = styled.span`
    color: #7e5723;
`;
const _Patt = styled.span`
    color: #7e7223;
`;
const _Attr = styled.span`
    color: #7e2323;
`;
const _Root = styled.span`
    color: #79237e;
`;
const _Voic = styled.span`
    color: #237c7e;
`;