import { useContext } from "react";
import styled, { css } from "styled-components";
import { DetailChord, DetailInit, DetailSection, Scale, BeatSignature } from "../../../../../redux/store/score/scoreData";
import CacheUtil from "../../../../../utils/score/cacheUtil";
import TheoryUtil from "../../../../../utils/theoryUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import { SideFormStyles } from "./sideFromStyles";


/**
 * イニットブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const SideFormInit = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const data = store.scoreData;
    const detail = data.chordList[0].detail as DetailInit;

    const update = () => {
        data.chordList = data.chordList.slice();
        dispatcher.score.setScoreData(data);
        // キャッシュの更新
        CacheUtil.updateHarmonizeCache(store, dispatcher);
    }

    const scaleItemJsxList: JSX.Element[] = TheoryUtil.SCALE_DEF_LIST.map((item, i) => {
        return (
            <_ScaleItem key={i} isActive={detail.scale === item} onClick={() => {
                detail.scale = item as Scale;
                // data.chordList = data.chordList.slice();
                update();
            }}>
                {item}
            </_ScaleItem>
        )
    });

    const keyList = detail.scale === 'major' ? TheoryUtil.KEY12_MAJOR_SCALE_LIST : TheoryUtil.KEY12_MINOR_SCALE_LIST;
    const keyItemJsxList: JSX.Element[] = keyList.map((item, i) => {
        const scale = detail.scale;
        return (
            <_KeyItem key={i} isActive={detail.keyIndex === i} onClick={() => {
                detail.keyIndex = i;
                // data.chordList = data.chordList.slice();
                update();
            }}>
                {`${item} ${scale}`}
            </_KeyItem>
        )
    });

    const rateItemJsxList: JSX.Element[] = TheoryUtil.BEAT_DEF_LIST.map((item, i) => {
        return (
            <_RateItem key={i} isActive={detail.beatSignature === item} onClick={() => {
                detail.beatSignature = item as BeatSignature;
                // data.chordList = data.chordList.slice();
                update();
            }}>
                {item}
            </_RateItem>
        )
    });


    return (
        <_Wrap>
            <_LabelTitle>Scale</_LabelTitle>
            <_ScaleWrap>{scaleItemJsxList}</_ScaleWrap>
            <_LabelTitle>Key</_LabelTitle>
            <_KeyWrap>{keyItemJsxList}</_KeyWrap>
            <_LabelTitle>Tempo</_LabelTitle>
            {SideFormStyles.createNumberForm(detail.bpm, true, (e) => {
                detail.bpm = e.currentTarget.valueAsNumber;
                // data.chordList = data.chordList.slice();
                update();
            }, 30, 220, 1)}
            <_LabelTitle>Rate</_LabelTitle>
            <_RateWrap>{rateItemJsxList}</_RateWrap>
        </_Wrap>
    );
}

export default SideFormInit;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    background-color: #9b9eb8;
`;

const _LabelTitle = styled.div`
    ${SideFormStyles.___CSS_TITLE}
    margin-top: 4px;
`;

const _ScaleWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 24px;
    /* margin: 5px 0 0 0; */
    text-align: left;
    /* background-color: #ececec; */
`;

const _ScaleItem = styled.div<{
    isActive: boolean;
}>`
    ${SideFormStyles.___CSS_FORM}
    border: solid 1px #fff;
    color: #fff;
    width: 50%;
    margin: 1px 0 0 0;

    background-color: #61b47d;
    ${props => !props.isActive ? '' : css`
        background-color: #da909082;
    `}
`;


const _KeyWrap = styled.div`
    display: inline-block;
    width: 100%;
    /* height: 24px; */
    /* margin: 5px 0 0 0; */
    text-align: left;
    /* background-color: #ececec; */
`;

const _KeyItem = styled.div<{
    isActive: boolean;
}>`
    ${SideFormStyles.___CSS_FORM}
    border: solid 1px #fff;
    color: #fff;
    width: 100%;
    margin: 1px 0 0 0;
    
    /* margin: 5px 0 0 0; */
    background-color: #61b47d;
    ${props => !props.isActive ? '' : css`
        background-color: #da909082;
    `}
`;


const _TempoWrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 24px;
    /* margin: 5px 0 0 0; */
    text-align: left;
    background-color: #ececec;

    & * {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
    }
    & input:nth-child(1) {
        background-color: #dddddd;
        width: 60px;
        height: calc(100% - 4px);
        margin: 2px 0 0 0;
        border: 1px solid #9b9b9b;
        border-radius: 4px;
    }
    & input:nth-child(2) {
        margin-left: 5px;
        background-color: #f00;
        width: calc(100% - 70px);
        height: 100%;
    }
`;


const _RateWrap = styled.div`
    display: inline-block;
    width: 100%;
    /* height: 24px; */
    /* margin: 5px 0 0 0; */
    text-align: left;
    /* background-color: #ececec; */
`;

const _RateItem = styled.div<{
    isActive: boolean;
}>`
    ${SideFormStyles.___CSS_FORM}
    width: 100%;
    border: solid 1px #fff;
    color: #fff;
    margin: 1px 0 0 0;
    /* margin: 5px 0 0 0; */
    background-color: #61b47d;
    ${props => !props.isActive ? '' : css`
        background-color: #da909082;
    `}

`;