import { useContext } from "react";
import styled, { css } from "styled-components";
import TheoryUtil from "../../../../utils/theoryUtil";
import { PATTERN_BODY_HEIGHT, PATTERN_FOOTER_HEIGHT, PATTERN_HEADER_HEIGHT } from "../../../../utils/systemConst";
import { GlobalContext } from "../../../entry/systemEntry";
import ChannelItem from "./channelItem";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { _CSS_LABEL_MIDIUM } from "../../../../base/design/styles";

const PatternChannel = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const chordInfo = state.chordInfo;
    // const symbol = TheoryUtil.getSymbolFromKey(chordInfo.symbolKey);
    // const chordRoot = (chordInfo.root.index + chordInfo.init.keyIndex) % 12;

    const channelJsxList: JSX.Element[] = [];
    const layerJsxList: JSX.Element[] = [];

    if (state.pattern == null) return <></>;

    const pattern = state.pattern as BackingState.Pattern;
    const channels = pattern.channels;
    channels.forEach((channel, i) => {
        let name = '-';
        if (channel.octave !== -1) {
            const structInfo = chordInfo.structList[channel.structIndex];
            const soundIndex = structInfo.soundIndex;
            const octave = channel.octave + structInfo.adjustOctave;
            const keyList = !structInfo.isFlat ? TheoryUtil.KEY12_SHARP_LIST : TheoryUtil.KEY12_FLAT_LIST;
            name = keyList[soundIndex % 12] + octave;
        }
        channelJsxList.push(
            <ChannelItem key={i}
                soundfont={store.instruments.harmonyFont}
                index={i}
                soundFullName={name}
            />
        );
    });

    pattern.layers.forEach((layer, i) => {
        const labelName = i === 0 ? 'L-HAND' : 'R-HAND';
        layerJsxList.push(
            <_LayerItem key={i} isActive={pattern.layerIndex === i}
                onClick={() => {
                    if (i !== pattern.layerIndex) {
                        dispatcher.backing.switchLayer();
                    }
                }}
            >{labelName}</_LayerItem>
        );
    });

    return (<>
        <_Header>
            {layerJsxList}
        </_Header>
        <_Body>
            {channelJsxList}
        </_Body>
        <_Footer>
            <_ModeItem isActive={pattern.mode === 'velocity'}
                onClick={() => {
                    pattern.mode = 'velocity';
                    dispatcher.backing.setState(state);
                }}
            >Velocity</_ModeItem>
            <_ModeItem isActive={pattern.mode === 'pedal'}
                onClick={() => {
                    pattern.mode = 'pedal';
                    dispatcher.backing.setState(state);
                }}
            >Pedal</_ModeItem>
        </_Footer>
    </>);
}

export default PatternChannel;

const _Header = styled.div`
    /* display: inline-block; */
    width: 100%;
    height: ${PATTERN_HEADER_HEIGHT}px;
    background-color: #929292;
`;

const _LayerItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    height: 22px;
    width: calc(100% - 4px);
    margin: 2px 0 0 2px;
    box-sizing: border-box;
    text-align: center;
    
    font-size: 14px;
    font-weight: 600;
    color: #3b3b3b;
    line-height: 20px;
    background-color: #b8b8b8;
    border: 1px solid #7a7a7a;
    border-radius: 2px;

    ${proops => !proops.isActive ? '' : css`
        background-color: #dad600;
        color: #c40000;
    `}

    &:hover {
        background-color: #d4d4d4;
    }
`;

const _Body = styled.div`
/* display: inline-block; */
    width: 100%;
    height: ${PATTERN_BODY_HEIGHT}px;
    background-color: #97d3cec8;
`;
const _Footer = styled.div`
/* display: inline-block; */
    width: 100%;
    height: ${PATTERN_FOOTER_HEIGHT}px;
    background-color: #898997;
`;


const _ModeItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    height: 22px;
    width: calc(100% - 4px);
    margin: 2px 0 0 2px;
    box-sizing: border-box;
    text-align: center;
    
    font-size: 14px;
    font-weight: 600;
    color: #3b3b3b;
    line-height: 20px;
    background-color: #b8b8b8;
    border: 1px solid #7a7a7a;
    border-radius: 2px;

    ${proops => !proops.isActive ? '' : css`
        background-color: #4fe419;
        color: #c40000;
    `}

    &:hover {
        background-color: #d4d4d4;
    }
`;