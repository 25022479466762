import { useContext, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ExecutableButton } from "../../../base/components/button/executableButton";
import Styles, { _CSS_LABEL_MIDIUM } from "../../../base/design/styles";
import { ChordBacking, DetailChord, DetailFixed, DetailInit, DetailSection, ScoreData } from "../../../redux/store/score/scoreData";
import CacheUtil from "../../../utils/score/cacheUtil";
import DataUtil from "../../../utils/dataUtil";
import HarmonizeUtil from "../../../utils/score/harmonizeUtil";
import TheoryUtil from "../../../utils/theoryUtil";
import { GlobalContext } from "../../entry/systemEntry";
import ChordProgDialog from "./chordProg/chordProgDialog";
import ScoreBuilder from "./scoreBuilder";

type ShareType = '' | 'url' | 'free' | 'dl';

const ManageTab = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const [file, setFile] = useState<null | File>(null);

    const fileHandle = store.scoreState.fileHandle;
    const scoreData = store.scoreData;
    const tabIndex = store.systemState.scoreTabIndex;

    const loginProps = store.userEnv.login;

    const [cloudScoreList, setCloudScoreList] = useState<null | {
        seq: number, name: string, hash: string, url: string, share: string
    }[]>(null);
    const [focusIndex, setFocusIndex] = useState(-1);
    const [cloudScoreName, setCloudScoreName] = useState('-');
    const [cloudScoreShare, setCloudScoreShare] = useState<ShareType>('');

    useEffect(() => {
        loadCloudScore();
    }, [loginProps]);

    const loadCloudScore = () => {
        if (loginProps != undefined) {
            DataUtil.findUserScoreList(loginProps.seq).then((resList) => {
                setCloudScoreList(resList.map((res) => {
                    const seq = res['seq'];
                    const hash = DataUtil.getHashedFromScoreSeq(seq);
                    const url = DataUtil.getPreviewURL(seq);
                    return { seq, name: res['dispnam'], hash, url, share: res['share'] };
                }));
                console.log('load');
            });
        }
    }

    const [fileInfo, scoreInfo]: [{
        name: string;
        size: string;
        modified: string;
    }, {
        key: string;
        tempo: string;
        rate: string;
        section: string;
        chord: string;
        bar: string;
        time: string;
        notes: string;
    }] = useMemo(() => {
        let name = '-';
        let size = '-';
        let modified = '-';
        let key = '-';
        let tempo = '-';
        let rate = '-';
        let section = '-';
        let chord = '-';
        let bar = '-';
        let time = '-';
        let notes = '-';
        if (tabIndex === 0) {
            if (file != null) {
                name = file.name;
                size = `${file.size.toString()} byte`;
                modified = DataUtil.getStringFromDate(new Date(file.lastModified));
            }
            if (scoreData.chordList.length > 0) {
                const init = scoreData.chordList[0].detail as DetailInit;
                key = `${TheoryUtil.KEY12_MAJOR_SCALE_LIST[init.keyIndex]} ${init.scale}`;
                tempo = init.bpm.toString();
                rate = init.beatSignature;
                let sectionNum = 0;
                let chordNum = 0;
                scoreData.chordList.forEach(chord => {
                    switch (chord.type) {
                        case 'section': sectionNum++; break;
                        case 'chord': chordNum++; break;
                    }
                });
                section = sectionNum.toString();
                chord = chordNum.toString();

                const cacheList = store.scoreState.elementCacheList;
                if (cacheList.length > 0) {
                    const last = cacheList[cacheList.length - 1];
                    time = `${last.pastSecond / 1000}s`;
                    bar = `${last.bar} (${last.surplusBeat})`;
                }
                notes = scoreData.notesList.length.toString();
            }
        }
        return [{
            name, size, modified
        }, {
            key, tempo, rate, section, chord, bar, time, notes
        }]
    }, [tabIndex, scoreData, file]);

    useEffect(() => {
        if (tabIndex === 0 && fileHandle != null) {
            fileHandle.getFile().then(file => {
                setFile(file);
            });
        }
    }, [tabIndex]);

    const setScoreData = ((data: ScoreData) => {
        store.scoreState.focusIndex = 2;
        store.scoreState.distIndex = store.scoreState.focusIndex;
        dispatcher.score.setScoreState({ ...store.scoreState });
        // dispatcher.score.setScoreTabIndex(1);
        dispatcher.score.setScoreData(data);
        // キャッシュの更新
        store.scoreData = data;
        CacheUtil.updateHarmonizeCache(store, dispatcher);
    });

    /**
     * ラベルアイテムを生成する
     * @param labelName ラベル名
     * @param labelValue 値
     * @returns JSX
     */
    const createLabelRecord = (labelName: string, labelValue: string) => {
        return (
            <_Recored>
                <_ItemnLabel>{labelName}</_ItemnLabel>
                <_ItemnValue>{labelValue}</_ItemnValue>
            </_Recored>
        );
    }
    /**
     * テキストアイテムを生成する
     * @param labelName ラベル名
     * @param textValue 値
     * @returns JSX
     */
    const createTextRecord = (labelName: string, textValue: string, changeAction: (e: React.ChangeEvent<HTMLInputElement>) => void) => {
        return (
            <_Recored>
                <_ItemnLabel>{labelName}</_ItemnLabel>
                <_ItemnInput
                    type="text" value={textValue}
                    isEnable={focusIndex !== -1}
                    onChange={changeAction}
                />
            </_Recored>
        );
    }

    const searchLocalFileAction = () => {
        (async () => {
            const [fileHandle] = await window.showOpenFilePicker();
            const file = await fileHandle.getFile();
            const fileContents = await file.text();
            // console.log(fileContents);
            store.scoreState.fileHandle = fileHandle;
            setFile(file);
            const text = DataUtil.unZip(fileContents);
            // const text = fileContents;
            console.log(text);
            setScoreData(JSON.parse(text));
        })();
    }

    const fileDisconnectAction = () => {
        store.scoreState.fileHandle = null;
        dispatcher.score.setScoreState({ ...store.scoreState });
        // キャッシュの更新
        store.scoreData = scoreData;
        CacheUtil.updateHarmonizeCache(store, dispatcher);
        setFile(null);
    };

    const isDataConnect = fileHandle != null;


    const cloudScoreListJsx = loginProps == undefined ? <></> : cloudScoreList == null ? (
        <_Message>Now loading your cloud score...</_Message>
    ) : cloudScoreList.map((score, i) => {

        return <_CloudItem key={i} isActive={i === focusIndex} onClick={() => {
            setFocusIndex(i);
            const cur = cloudScoreList[i];
            if (cur != null) {
                setCloudScoreName(cur.name);
            }
        }}><_CName>{score.name}</_CName><_CHash> [{score.hash}]</_CHash></_CloudItem>
    });


    const uploadActioon = () => {
        setCloudScoreList(null);
        if (loginProps != undefined && cloudScoreList != null) {
            const newScoreName = `new [${loginProps.id}] score`;
            DataUtil.sendQueryRequestToAPI('update', `INSERT INTO scoretbl(user_seq, dispnam, data)
                VALUES(${loginProps.seq}, '${newScoreName}', '${DataUtil.gZip(JSON.stringify(scoreData))}')
            `).then(() => {
                setFocusIndex(cloudScoreList.length);
                setCloudScoreName(newScoreName);
                loadCloudScore();
            });
        }
    }

    const overwriteActioon = () => {
        setCloudScoreList(null);
        if (curCloudScore != null) {
            DataUtil.sendQueryRequestToAPI('update', `
                UPDATE scoretbl SET
                dispnam = '${cloudScoreName}',
                share = '${cloudScoreShare}',
                lastmod = datetime('now', 'localtime'),
                data = '${DataUtil.gZip(JSON.stringify(scoreData))}'
                WHERE seq = ${curCloudScore.seq}
            `).then(() => {
                loadCloudScore();
            })
        }
    }
    const deleteActioon = () => {
        setCloudScoreList(null);
        if (curCloudScore != null) {
            DataUtil.sendQueryRequestToAPI('update', `DELETE FROM scoretbl WHERE seq = ${curCloudScore.seq}
            `).then(() => {
                setFocusIndex(-1);
                loadCloudScore();
            })
        }
    }

    const downloadAction = () => {
        if (curCloudScore != undefined) {
            DataUtil.findScore(curCloudScore.seq).then((resList) => {
                if (resList.length > 0) {
                    const source = resList[0]['data'];
                    const text = DataUtil.unZip(source);
                    setScoreData(JSON.parse(text));
                }
            });
        }
    }
    const curCloudScore = cloudScoreList == null ? null : cloudScoreList[focusIndex];
    const uploadEnable = scoreData.chordList.length > 0 && focusIndex === -1;
    const isExistEditingData = scoreData.chordList.length > 0;

    const isSelectedScore = focusIndex !== -1;
    return (
        <_Wrap>
            <_ButtonsDiv>
                <_Button isEnable={!isDataConnect && !isExistEditingData} onClick={() => {
                    store.systemState.dialog = <ChordProgDialog setScoreData={setScoreData} />;
                    dispatcher.system.setState(store.systemState);
                    // window.history.replaceState('','','/');
                }}>new score</_Button>
            </_ButtonsDiv>
            <_MainDiv>
                <_Left>
                    <_CloudDiv isEnable={true}>

                        <_ButtonsDiv>
                            <_Button isEnable={loginProps != undefined && uploadEnable} onClick={uploadActioon}>upload</_Button>
                            <_Button isEnable={loginProps != undefined && isSelectedScore} onClick={() => {
                                setFocusIndex(-1);
                            }}>release</_Button>
                        </_ButtonsDiv>
                        <_ListFrame isEnable={loginProps != undefined}>
                            {cloudScoreListJsx}
                        </_ListFrame>
                        <_ButtonsDiv>
                            <_Button isEnable={loginProps != undefined && isSelectedScore && isExistEditingData} onClick={overwriteActioon}>overwrite</_Button>
                            <_Button isEnable={loginProps != undefined && isSelectedScore && !isExistEditingData} onClick={downloadAction}>download</_Button>
                            <_Button isEnable={loginProps != undefined && isSelectedScore} onClick={deleteActioon}>delete</_Button>
                        </_ButtonsDiv>
                    </_CloudDiv>
                </_Left>
                <_Right>
                    {/* <_Source value={source} onChange={e => setSource(e.target.value)} /> */}

                    <_ButtonsDiv>
                        <_Button isEnable={!isDataConnect && !isExistEditingData} onClick={searchLocalFileAction}>load file</_Button>
                        <_Button isEnable={isDataConnect} onClick={fileDisconnectAction}>disconnect</_Button>
                        <_Button isEnable={isExistEditingData} onClick={() => {
                            dispatcher.score.clearCache();
                            if (isDataConnect) {
                                fileDisconnectAction();
                            }
                            dispatcher.score.setScoreData({ notesList: [], chordList: [] });
                        }}>clear</_Button>
                    </_ButtonsDiv>
                    <_DataInfoDiv isEnable={isDataConnect}>
                        <_TitleLabel>Local</_TitleLabel>
                        {createLabelRecord('file', fileInfo.name)}
                        {createLabelRecord('size', fileInfo.size)}
                        {createLabelRecord('last', fileInfo.modified)}
                        {/* {createLabelRecord('size', fileInfo.size)}
                        {createLabelRecord('modified', fileInfo.modified)} */}
                    </_DataInfoDiv>
                    <_DataInfoDiv isEnable={focusIndex !== -1}>
                        <_TitleLabel>Cloud</_TitleLabel>
                        {createTextRecord('name', curCloudScore == null ? '-' : cloudScoreName, (e) => {
                            setCloudScoreName(e.target.value);
                        })}
                        <_Recored>
                            <_ItemnLabel>url</_ItemnLabel>
                            <_ItemnURL onContextMenu={() => {
                                navigator.clipboard.writeText(curCloudScore == null ? '-' : curCloudScore.url).then(() => {
                                    alert('Copied clipboad!');
                                });
                            }}>{curCloudScore == null ? '-' : curCloudScore.url}</_ItemnURL>
                        </_Recored>
                        {/* {createLabelRecord('url', curCloudScore == null ? '-' : curCloudScore.url)} */}
                        <_Recored>
                            <_ItemnLabel>share</_ItemnLabel>
                            <_ItemnCombobox
                                isEnable={focusIndex !== -1}
                                value={cloudScoreShare}
                                onChange={(e) => {
                                    setCloudScoreShare(e.target.value as ShareType);
                                }}
                            >
                                <option value={'url'}>URL only</option>
                                <option value={'free'}>Home publish</option>
                                <option value={'dl'}>Download OK</option>
                            </_ItemnCombobox>
                        </_Recored>
                    </_DataInfoDiv>
                    <_ScoreInfoDiv isEnable={isExistEditingData}>
                        <_TitleLabel>SCORE</_TitleLabel>
                        {createLabelRecord('key', scoreInfo.key)}
                        {createLabelRecord('tempo', scoreInfo.tempo)}
                        {createLabelRecord('rate', scoreInfo.rate)}
                        {createLabelRecord('section', scoreInfo.section)}
                        {createLabelRecord('chord', scoreInfo.chord)}
                        {createLabelRecord('bar', scoreInfo.bar)}
                        {createLabelRecord('time', scoreInfo.time)}
                        {createLabelRecord('notes', scoreInfo.notes)}
                    </_ScoreInfoDiv>
                </_Right>
            </_MainDiv>
        </_Wrap >
    );
}

export default ManageTab;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #7e7e7e;
    text-align: left;
`;

const _MainDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - 40px);
`;

const _Left = styled.div`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    /* background-color: #8a6b6b; */
    text-align: left;
    vertical-align: top;
`;

const _Right = styled.div`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    /* background-color: #969696; */
    text-align: left;
    vertical-align: top;
`;

const _Link = styled.div`
    
    display: inline-block;
    width: calc(100% - 50px);
    height: 50px;
    margin: 20px 25px;
    border: 2px solid #cacaca;
    /* background: linear-gradient(to bottom, #585858, #a7a7a7, #585858); */
    background-color: #000;
    color: #ccc;
    font-size: 30px;
    font-weight: 600;
    text-align: left;
    box-sizing: border-box;
    padding-left: 20px;
    border-radius: 6px;

    &:hover {
        background-color: #616161;
    }
`;

// const _Source = styled.textarea`
//     display: inline-block;
//     width: calc(100% - 8px);
//     margin: 4px 0 0 4px;
//     height: calc(100% - 44px);
//     /* background-color: #7e7e7e; */
//     text-align: left;
//     font-size: 20px;
//     resize: none;
//     box-sizing: border-box;
//     color: #060053;
// `;

const _InfoDiv = styled.div`
    display: inline-block;
    position: relative;
    width: calc(100% - 8px);
    margin: 4px 0 4px 4px;
    height: calc(100% - 48px);
    background-color: #ffffff;
    box-sizing: border-box;
    color: #060053;
`;

const DATA_INFO_HEIGHT = 180;

const _DataInfoDiv = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: ${DATA_INFO_HEIGHT}px;
    margin: 4px 0 4px 4px;
    background-color: #9da5af;
    border: solid 1px #1b00b3;
    ${props => props.isEnable ? '' : css`
        opacity: 0.3;
    `}
    border-radius: 4px;
    box-sizing: border-box;
    color: #060053;
`;

const _ScoreInfoDiv = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - ${DATA_INFO_HEIGHT * 2 + 40 + 20}px);
    margin: 0 0 4px 4px;
    background-color: #acacac;
    border: solid 1px #1b00b3;
    ${props => props.isEnable ? '' : css`
        opacity: 0.3;
    `}
    border-radius: 4px;
    box-sizing: border-box;
    color: #060053;
    overflow: hidden;
`;

const _CloudDiv = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 4px 4px;
    background-color: #acacac;
    border: solid 1px #1b00b3;
    ${props => props.isEnable ? '' : css`
        opacity: 0.3;
    `}
    border-radius: 4px;
    box-sizing: border-box;
    color: #060053;
    overflow: hidden;
`;

const _TitleLabel = styled.div`
    display: inline-block;
    height: 40px;
    font-size: 30px;
    font-weight: 600;
    color: #1c2c95;
    /* background-color: #3f80cf57; */
    padding: 0 10px;
`;
const _Recored = styled.div`
    display: inline-block;
    width: 100%;
    height: 35px;
    /* background-color: #3fcf7b56; */
    padding: 4px 0 0 10px;
`;
const _ItemnLabel = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: 140px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #08106e;
    border: solid 1px #1b00b3;
    box-sizing: border-box;
    /* background-color: #85a2c5; */
    background: linear-gradient(to bottom, #8a98a7, #a3bbd2, #8a98a7);
    border-radius: 4px;
    text-align: center;
    vertical-align: top;
`;
const _ItemnValue = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #ffffff;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #808080;
    text-align: left;
    vertical-align: top;
    user-select: text;
    white-space: nowrap;
    overflow: hidden;
`;
const _ItemnURL = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 18px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #01f52a;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #808080;
    text-align: left;
    vertical-align: top;
    user-select: text;
    white-space: nowrap;
    overflow: hidden;
`;

const _ItemnInput = styled.input<{
    isEnable: boolean;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #1f1f1f;
    border: solid 1px #000;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
`;

const _ItemnCombobox = styled.select<{
    isEnable: boolean;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #1f1f1f;
    border: solid 1px #000;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
`;

const _ButtonsDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 40px;
    /* background-color: #888888; */
    box-sizing: border-box;
    text-align: left;
    white-space: nowrap;
`;

const _Button = styled.div<{
    isEnable: boolean;
}>`
    ${_CSS_LABEL_MIDIUM}
    /* width: 160px; */
    padding: 0 40px;
    /* line-height: 25px; */
    box-sizing: border-box;
    background-color: #a8a8a8;
    background: linear-gradient(to bottom, #787878, #b4b4b4, #787878);
    border: 1px solid #1a2b3f;
    color: #1a2b3f;
    border-radius: 4px;
    margin: 5px 0 0 4px;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    &:hover {
        /* background-color: #98bfc2; */
    background: linear-gradient(to bottom, #989898, #b4b4b4, #989898);
    }
`;


const _ListFrame = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - 92px);
    margin: 4px 0 0 4px;
    background-color: #d3d3c5;
    border: 1px solid #e60000;
    border-radius: 4px;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : css`
        opacity: 0.3;
        pointer-events: none;
    `}
`;

const _Message = styled.div<{
}>`
    display: inline-block;
    color: #d60000;
    font-size: 24px;
    /* font-weight: 600; */
    /* background-color: white; */
    margin: 0 0 0 4px;
`;

const _CloudItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    width: 100%;
    font-size: 28px;
    font-weight: 600;
    border-bottom: 1px solid #888888;
    /* background-color: white; */
    background: linear-gradient(to bottom, transparent, #b6b6b684);
    ${props => !props.isActive ? '' : css`
        background: linear-gradient(to bottom, transparent, #fdff8484);
    `}
    margin: 2px 0 0 0;
    padding: 0 0 0 4px;
    box-sizing: border-box;
    padding-left: 4px;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
        background: linear-gradient(to bottom, transparent, #ffffff5c);
        ${props => !props.isActive ? '' : css`
            background: linear-gradient(to bottom, transparent, #feffcd99);
        `}
    }
`;
const _CName = styled.span` color: #00532c; `;
const _CHash = styled.span` color: #b9000042; `;