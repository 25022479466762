
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { TabbedPane } from "../../base/components/tab/tabbedPane";
import Styles from "../../base/design/styles";
import BackingState from "../../redux/store/score/backing/backingState";
import { ChordBacking } from "../../redux/store/score/scoreData";
import BackingUtil from "../../utils/backingUtil";
import { GlobalContext } from "../entry/systemEntry";
import PatternFrame from "./pattern/patternFrame";
import VoicingChooser from "./voicing/voicingChooser";

/**
 * バッキングエディタ画面
 * @returns コンポーネント
 */
const BackingEditorFrame = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;

    const [source, setSource] = useState('');

    useEffect(() => {
        console.log('backing start');
    }, []);

    const syncSource = () => {
        const chordBacking = {
            voicingList: BackingUtil.getVoicingListFromTable(state.voicingTable),
            pattern: state.pattern == null ? null : BackingUtil.buildPatternProps(state.pattern)
        }
        setSource(JSON.stringify(chordBacking));
    };

    return (
        <_Frame>
            <_LabelFrame>
                <_Label
                    isActive={state.tabIndex === 0}
                    onClick={() => {
                        dispatcher.backing.setState({ ...state, tabIndex: 0 });
                    }}
                ><div>Editor</div></_Label>
                <_Label
                    isActive={state.tabIndex === 1}
                    onClick={() => {
                        syncSource();
                        dispatcher.backing.setState({ ...state, tabIndex: 1 });
                    }}
                ><div>Source</div></_Label>
            </_LabelFrame>
            <_Wrap>
                {state.tabIndex === 0 ? (<>
                    <_PrevireBrind isUse={state.isPreview} />
                    {/* ボイシングエディタ部分 */}
                    <VoicingChooser />
                    {/* パターンエディタ部分 */}
                    <PatternFrame />
                    {/* フッター部分 */}
                    <_FooterDiv>
                        {/* プレビュー再生 */}
                        <_Button isEnable={!state.hasError} onClick={() => {

                            if (!state.isPreview) {
                                dispatcher.backing.testBacking();
                            } else {
                                BackingUtil.stopPreview(store, state, dispatcher, store.reserveTasks);
                            }
                        }}>{!state.isPreview ? 'Test' : 'Stop'}</_Button>
                        {/* 編集を取り消しして閉じる */}
                        <_Button isEnable={!state.isPreview} onClick={() => {
                            state.closeAction(state, false);
                        }}>Cancel</_Button>
                        {/* 編集を反映して閉じる */}
                        <_Button isEnable={!state.isPreview} onClick={() => {
                            state.closeAction(state, true);
                        }}>Update</_Button>
                    </_FooterDiv>
                </>) : (<>
                    <_SourceFrame>
                        <_TextArea value={source} onChange={(e) => {
                            setSource(e.target.value);
                        }} />
                    </_SourceFrame>
                    {/* フッター部分 */}
                    <_FooterDiv>
                        <_Button isEnable={true} onClick={() => {
                            syncSource();
                        }}>Reset</_Button>
                        <_Button isEnable={true} onClick={() => {
                            try {
                                const chordBacking = JSON.parse(source) as ChordBacking;
                                const voicingTable = BackingUtil.getInitVoicingTable(chordBacking.voicingList, state.chordInfo.structList.length);
                                const pattern = BackingUtil.getEditorPatternFromChordBacking(voicingTable, chordBacking.pattern);
                                dispatcher.backing.setState({ ...state, voicingTable, pattern, tabIndex: 0 });
                            } catch {
                                alert('error');
                                dispatcher.backing.setState({ ...state, tabIndex: 0 });
                            }
                        }}>Aplly</_Button>
                    </_FooterDiv>
                </>)}
            </_Wrap>
        </_Frame>
    )
}

export default BackingEditorFrame;

const _Frame = styled.div`
    display: inline-block;
    position: relative;
    width: 630px;
    background-color: #a1a1a1;
`;

const _LabelFrame = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 30px;
    padding: 2px 0 0 2px;
    box-sizing: border-box;
    vertical-align: top;
    /* background-color: #f17379; */
`;
const _Label = styled.div<{
    isActive: boolean;
}>`
    display: block;
    width: 25px;
    height: 100px;
    background-color: #99afb3;
    ${props => !props.isActive ? '' : css`
        background-color: #b8dae0;
    `}
    border-left: 1px solid #5a5a5a;
    border-top: 1px solid #5a5a5a;
    border-bottom: 1px solid #5a5a5a;
    margin: 2px 0 0 2px;
    border-radius: 8px 0 0 8px;
    font-size: 16px;
    font-weight: 600;
    color: #5a5a5a;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    
    & div {
        width: 100px;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-100%);
        /* background-color: #fff; */
    }

    &:hover {
        background-color: #b2bcbe;
        ${props => !props.isActive ? '' : css`
            background-color: #b8dae0;
        `}
    }
`;
const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 600px;
    /* background-color: #bd4646; */
`;

const _FooterDiv = styled.div`
    display: inline-block;
    height: 30px;
    width: 100%;
    /* background-color: #bd4646; */
    text-align: right;
`;

const _Button = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100px;
    height: 26px;
    margin: 2px 2px 0 0;
    font-size: 20px;
    color: white;
    font-weight: 600;
    background-color: #0051ff21;
    border: solid 1px #555;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 20px;
    text-align: center;

    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}

    &:hover {
        background-color: #ff008039;
    }
`;

const _PrevireBrind = styled.div<{
    isUse: boolean;
}>`
    position: absolute;
    display: ${props => props.isUse ? 'block' : 'none'};
    z-index: 80;
    width: 100%;
    height: calc(100% - 30px);
    background-color: #7a270028;
`;

const _SourceFrame = styled.div`
    display: inline-block;
    position: relative;
    width: 600px;
    height: 400px;
    background-color: #b4aeae;
`;

const _TextArea = styled.textarea`
    display: inline-block;
    font-size: ${Styles.FONT_SMALL}px;
    color: #0a0038;
    background-color: #b7bcd3;
    resize: none;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    box-sizing: border-box;
    margin: 4px 0 0 4px;
    outline: none;
`;