import { useContext } from "react";
import styled, { css } from "styled-components";
import ScoreCache from "../../../../../../redux/store/score/scoreCache";
import { DetailAlter } from "../../../../../../redux/store/score/scoreData";
import TheoryUtil from "../../../../../../utils/theoryUtil";

const CalcAlter = (props: {
    detail: DetailAlter;
    prevCache: ScoreCache.Element;
    curCache: ScoreCache.Element;
}) => {

    const getType = () => {
        switch (props.detail.alterType) {
            case 'modulate': return 'Key';
            case 'tempo': return 'Tempo';
            case 'beat': return 'Beat';
        }
    }
    const getDetail = () => {
        switch (props.detail.alterType) {
            case 'modulate':{
                const prevKey = TheoryUtil.KEY12_SHARP_LIST[props.prevCache.keyIndex] + (props.prevCache.scale === 'major' ? '' : 'm');
                const curKey = TheoryUtil.KEY12_SHARP_LIST[props.curCache.keyIndex] + (props.curCache.scale === 'major' ? '' : 'm');
                return `${prevKey}→${curKey}`;
            }
            case 'tempo':{
                const prevTempo = props.prevCache.bpm;
                const curTempo = props.curCache.bpm;
                return `${prevTempo}→${curTempo}`;
            }
            case 'beat':{
                const prevBeat = props.prevCache.beatSignature;
                const curBeat = props.curCache.beatSignature;
                return `${prevBeat}→${curBeat}`;
            }
        }
    }
    return (
        <_Wrap>
            <_Type>{getType()}</_Type>
            <_Detail>{getDetail()}</_Detail>
        </_Wrap>
    );
}

export default CalcAlter;

const _Wrap = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 50px;
    /* background-color: #ff000011; */
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    text-align: center;
    box-sizing: border-box;
`;

const _Label = styled.div`
    display: block;
    position: relative;
    /* min-width: 80px; */
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 16px;
    /* background-color: #00000042; */
`;

const _Type = styled(_Label)`
    color: #0b00a8cf;
`;

const _Detail = styled(_Label)`
    color: #050505cf;
`;