import { posix } from "path";
import { KeyboardEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { TUNE_HEADER_WIDTH, TUNE_PITCH_HEIGHT, TUNE_PITCH_WIDTH, TUNE_TIMELINE_WIDTH } from "../../../../utils/systemConst";
import { GlobalContext } from "../../../entry/systemEntry";
import MelodyTimeline from "./melodyTimeline";
import MelodyPitchList from "./melodyPitchList";
import MelodyHeader from "./melodyHeader";
import { getKeyIndexFromPitcher, getMinimumLenUnit, getNormalizeLenFromNotes, PITCH_MAX, playSoundfont } from "../../../../utils/melodyUtil";
import MelodyMouseOperation from "./util/melodyMouseOperation";
import { Side } from "../../../../redux/store/score/melodyState";
import MelodyInput from "../harmonize/input/melodyInput";
import { _CSS_DISABLE } from "../../../../base/design/styles";
import CacheUtil from "../../../../utils/score/cacheUtil";
import TheoryUtil from "../../../../utils/theoryUtil";


const MelodyFrame = (props: {
    isActive: boolean;
}) => {
    const { store, dispatcher } = useContext(GlobalContext);

    const scoreState = store.scoreState;
    const state = store.melodyState;

    const wrapRef = useRef<null | HTMLDivElement>(null);
    const tableRef = useRef<null | HTMLDivElement>(null);
    const pitchRef = useRef<null | HTMLDivElement>(null);

    const [invalidate, setInvalidate] = useState<{}>({});
    const [invalidate2, setInvalidate2] = useState<{}>({});
    const update = () => setInvalidate({});

    const mouse = state.tableMouse;
    const isPut = mouse.isPut;
    const notesState = mouse.notesState;
    const curChord = mouse.curChord;
    const mouseMeasurePos = mouse.mouseMeasurePos;
    const mousePitchIndex = mouse.mousePitchIndex;
    const width = mouse.width;
    const lock = mouse.lock;
    const refs = store.refs.melody;

    const barCount = 150;

    const timelineLength = barCount * state.beatWidth * 4;
    const notesList = store.scoreData.notesList;
    const rate = 1 / store.melodyState.quantize;

    // const rate = 4 / state.quantize;

    const isTune = store.scoreState.isMelody;

    const tableWidth = tableRef.current == null ? 0 : tableRef.current.scrollLeft + tableRef.current.clientWidth;

    useEffect(() => {
        dispatcher.system.setRefs({
            ...store.refs, melody: {
                ...store.refs.melody,
                table: tableRef.current,
                pitch: pitchRef.current
            }
        });

        dispatcher.system.setInvalidate({
            ...store.invalidate, melody: update
        });
    }, []);

    useEffect(() => {
        if (isTune && wrapRef.current != null) {
            wrapRef.current.focus();
        }
    }, [isTune]);

    /**
     * 画面上部のメモリJSXリスト
     * 拍子のキャッシュから作成する
     */
    const measureJsxList = useMemo(() => {
        const list: JSX.Element[] = [];
        scoreState.measureCacheList.forEach((cache, i) => {

            list.push(
                <_Scale key={i} isHead={cache.rate === 4} beatWidth={state.beatWidth} />
            );
        });
        return list;
    }, [scoreState.beatCacheList, barCount]);

    // const init = store.scoreData.chordList[0].detail as DetailInit;

    /**
     * スケール区間のJSXリスト
     * 背景色のラインを表現
     * 転調でキーが変わる度に追加される
     */
    const scaleDivJsxList = useMemo(() => {
        const divList: JSX.Element[] = [];
        // console.log('**' + scoreState.scaleCacheList.length);
        scoreState.scaleCacheList.forEach((scaleInfo, i) => {
            const scaleIndexList = scaleInfo.scale === 'major' ? TheoryUtil.MAJOR_SCALE_INTERVALS : TheoryUtil.MINOR_SCALE_INTERVALS;
            const scaleIndexies = scaleIndexList.map(value => (scaleInfo.keyIndex + value) % 12);
            const scaleJsxList: JSX.Element[] = [];
            for (let j = 0; j < PITCH_MAX; j++) {
                const keyIndex = getKeyIndexFromPitcher(j);
                scaleJsxList.push(
                    <_ScaleLine key={j}
                        isScale={scaleIndexies.includes(keyIndex)}
                        isBase={keyIndex === scaleIndexies[0]}
                    />
                );
            }

            divList.push(
                <_ScaleList key={i} width={state.beatWidth / 4 * scaleInfo.memoriCnt} isEven={i % 2 === 0}>
                    {scaleJsxList}
                </_ScaleList>
            );
        });
        return divList;
    }, [scoreState.scaleCacheList]);

    /**
     * 配置するノーツのJSXリスト
     */
    const notesJsxList = useMemo(() => {
        const list: JSX.Element[] = [];
        // notesList.forEach((notes, i) => {
        // let count = 0;
        if (scoreState.scaleCacheList.length === 0) return list;//
        for (let i = 0; i < notesList.length; i++) {
            const notes = notesList[i];
            const normalLeft = getNormalizeLenFromNotes(notes.pos);
            const normalWidth = getNormalizeLenFromNotes(notes.len);
            if (tableRef.current != null) {
                const scroll = tableRef.current.scrollLeft;
                const notesLeft = normalLeft * state.beatWidth;
                const notesRight = notesLeft + normalWidth * state.beatWidth;
                if (
                    // !state.isPreview &&
                    (notesRight - scroll < 0 || notesLeft - scroll > tableRef.current.clientWidth)
                ) {
                    continue;
                }
            }
            const scaleInfo = CacheUtil.getKeyScaleFromNotesPos(normalLeft, scoreState.scaleCacheList);
            // count++;
            const [focusStart, focusEnd] = state.focusIndex < state.destIndex ? [state.focusIndex, state.destIndex] : [state.destIndex, state.focusIndex];
            const isActive = focusStart <= i && focusEnd >= i;
            const scaleIndexList = scaleInfo.scale === 'major' ? TheoryUtil.MAJOR_SCALE_INTERVALS : TheoryUtil.MINOR_SCALE_INTERVALS;
            const scaleIndexies = scaleIndexList.map(value => (scaleInfo.keyIndex + value) % 12);
            const keyIndex = getKeyIndexFromPitcher(notes.pitchIndex);
            const isScale = scaleIndexies.includes(keyIndex);

            // クオンタイズ
            const unitPos = getMinimumLenUnit(notes.pos);
            const unitLen = getMinimumLenUnit(notes.len);
            list.push(
                <_NotesWrap key={i}
                    left={normalLeft * state.beatWidth}
                    width={normalWidth * state.beatWidth}
                    side={notesState.side}
                    isActive={isActive}
                    isScale={state.keySwitch.scaleMode}
                    isResize={state.keySwitch.resize}
                    isMove={state.keySwitch.move}
                    isOctave={state.keySwitch.octave}
                    isPreview={state.isPreview}
                    onContextMenu={() => {
                        dispatcher.melody.removeNotes();
                    }}
                >
                    <_Notes
                        y={notes.pitchIndex * TUNE_PITCH_HEIGHT}
                        lenType={unitPos > unitLen ? unitPos : unitLen}
                        isScale={isScale}
                        isActive={isActive}
                        isPreview={state.isPreview}
                    >
                        {/* {notes.posX} */}
                    </_Notes>
                </_NotesWrap>
            );
        }
        // console.log('cnt: ' + count);
        // });
        return list;
    }, [notesList, notesList.length, state.focusIndex, state.destIndex, notesState, state.isPreview,
        state.keySwitch.scaleMode, state.keySwitch.octave, state.keySwitch.resize, state.keySwitch.move, refs.table?.scrollLeft]);

    /**
     * タイムラインを横にスクロールした際の処理
     */
    const sideScrollAction = () => {

        if (refs.table != null && refs.measure != null && refs.chord != null && refs.pitch != null) {
            const scrollLeft = refs.table.scrollLeft;
            const scrollTop = refs.table.scrollTop;
            refs.measure.scrollTo({ left: scrollLeft });
            refs.chord.scrollTo({ left: scrollLeft });
            refs.pitch.scrollTo({ top: scrollTop });
        }
        update();
    };

    /**
     * マウスポインタがエリア外に出た際の処理
     */
    const mouseLeaveAction = () => {
        console.log('leave!');
        if (mouse.notesState.index !== -1) {
            mouse.notesState.index = -1;
            mouse.notesState.isResize = false;
            mouse.notesState.side = 'normal';
        }
        mouse.mouseMeasurePos = -1;
        mouse.mousePitchIndex = -1;
        update();
    };

    return (
        <_Wrap
            tabIndex={999}
            ref={wrapRef}
            isActive={props.isActive}
            onKeyDown={(e: KeyboardEvent) => MelodyInput.keySwitchEvent(e, store, dispatcher, true)}
            onKeyUp={(e: KeyboardEvent) => MelodyInput.keySwitchEvent(e, store, dispatcher, false)}
        >
            <_Header isEnable={!state.isPreview}>
                <MelodyHeader />
            </_Header>
            <_Body>
                {(() => {
                    const mouse = state.timelineMouse;
                    if (mouse.lock === -1) {
                        return mouse.pos === -1 ? <></> :
                            <_MouseLinePos left={mouse.pos} />
                    } else {
                        const [head, tail] = mouse.lock < mouse.pos ? [mouse.lock, mouse.pos] : [mouse.pos, mouse.lock];
                        const width = tail - head;
                        return <_MouseRange left={head} width={width} />
                    }
                })()}
                {

                }
                <_Timeline>
                    <MelodyTimeline
                        barLength={barCount}
                        beatWidth={state.beatWidth}
                        curChord={curChord}
                        timelineLength={timelineLength}
                        mouseMeasurePos={mouseMeasurePos}
                        update={() => setInvalidate2({})}
                    />
                </_Timeline>
                <_Pitch ref={pitchRef}>
                    <MelodyPitchList mousePitchIndex={mousePitchIndex} curChord={curChord} />
                </_Pitch>
                <_TableWrap ref={tableRef} onScroll={sideScrollAction} >
                    <_TableMeasure
                        width={timelineLength}
                    >
                        {measureJsxList}
                    </_TableMeasure>
                    <_TablePitch
                        width={timelineLength}
                    >
                        {scaleDivJsxList}
                    </_TablePitch>
                    <_TableNotes
                        width={timelineLength}
                        onMouseDown={e => MelodyMouseOperation.mouseDownEvent(e, store, dispatcher, timelineLength, update)}
                        onMouseMove={e => MelodyMouseOperation.mouseMoveEvent(e, store, dispatcher, timelineLength, update)}
                        onMouseUp={e => MelodyMouseOperation.mouseUpEvent(e, store, dispatcher, timelineLength, update)}
                        onMouseLeave={mouseLeaveAction}
                    >
                        {!isPut ? <></> : <_Test x={lock.x} y={lock.y} width={width}
                            lenType={width % state.beatWidth === 0 ? 4 : (width % (state.beatWidth / 2) === 0 ? 8 : 16)}
                        />}
                        {notesJsxList}
                    </_TableNotes>
                    <_PrevireBrind
                        isUse={state.isPreview}
                        width={timelineLength}
                    />
                </_TableWrap>
            </_Body>
        </_Wrap>
    );
}

export default MelodyFrame;

const _Wrap = styled.div<{
    isActive: boolean;
}>`
    display: ${props => !props.isActive ? 'none' : 'inline-block'};
    width: 100%;
    height: 100%;
    outline: none;
`;

const editAnimation = keyframes`
  0%,100% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
`;

const _Test = styled.div<{
    x: number;
    y: number;
    width: number;
    lenType: number;
}>`
    display: inline-block;
    position: absolute;
    left: ${props => props.x}px;
    top: ${props => props.y}px;
    width: ${props => props.width}px;
    height: ${props => {
        switch (props.lenType) {
            case 4: return TUNE_PITCH_HEIGHT;
            case 8: return TUNE_PITCH_HEIGHT - 6;
            case 16: return TUNE_PITCH_HEIGHT - 12;
            default: return TUNE_PITCH_HEIGHT - 14;
        }
    }}px;
    background: linear-gradient(to bottom, #dff85230, #fffb00, #dff85230);
    border: 1px solid #fbffd9;
    
    animation: ${editAnimation} .8s linear infinite;
`;

const _Header = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: ${TUNE_HEADER_WIDTH}px;
    background-color: #b3b3b3;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
`;
const _Body = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - ${TUNE_HEADER_WIDTH}px);
    background-color: #464646;
`;

const _MouseLine = css`
    display: inline-block;
    position: absolute;
    pointer-events: none;
    z-index: 99;
    top: ${TUNE_TIMELINE_WIDTH - 38}px;
    height: calc(100% - 42px);
    width: 3px;
`;
const _MouseLinePos = styled.div<{
    left: number;
}>`
    ${_MouseLine}
    left: ${props => TUNE_PITCH_WIDTH + props.left}px;
    background-color: #15ff00;
`;
const _MouseLineLock = styled.div<{
    left: number;
}>`
    ${_MouseLine}
    left: ${props => TUNE_PITCH_WIDTH + props.left}px;
    background-color: #fbff00;
`;

const _MouseRange = styled.div<{
    left: number;
    width: number;
}>`
    display: inline-block;
    position: absolute;
    pointer-events: none;
    z-index: 99;
    top: ${TUNE_TIMELINE_WIDTH - 38}px;
    height: calc(100% - 42px);
    left: ${props => TUNE_PITCH_WIDTH + props.left}px;
    width: ${props => props.width}px;
    background-color: #ffffff8b;
`;

const _Timeline = styled.div`
    display: inline-block;
    margin-left: ${TUNE_PITCH_WIDTH}px;
    width: calc(100% - ${TUNE_PITCH_WIDTH}px - 10px);
    height: ${TUNE_TIMELINE_WIDTH}px;
    background-color: #534b81;
    overflow-y: hidden;
`;

const _Pitch = styled.div`
    display: inline-block;
    width: ${TUNE_PITCH_WIDTH}px;
    height: calc(100% - ${TUNE_TIMELINE_WIDTH}px - 10px);
    background-color: #464545;
    vertical-align: top;
    overflow-y: hidden;
`;

const _TableWrap = styled.div`
    display: inline-block;
    position: relative;
    width: calc(100% - ${TUNE_PITCH_WIDTH}px);
    height: calc(100% - ${TUNE_TIMELINE_WIDTH}px);
    background-color: #bdbdbd;
    vertical-align: top;
    overflow: scroll;
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #1959A8;
    }
    &::-webkit-scrollbar-track {
        border-radius: 5px;
        box-shadow: 0 0 4px #aaa inset;
    }
`;

const _TableMeasure = styled.div<{
    width: number;
}>`
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: ${props => props.width}px;
    height: ${TUNE_PITCH_HEIGHT * 60}px;
    white-space: nowrap;
`;

const _TablePitch = styled.div<{
    width: number;
}>`
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 6; */
    width: ${props => props.width}px;
    height: ${TUNE_PITCH_HEIGHT * 60}px;
    /* pointer-events: none; */
    /* background-color: #70ff7058; */
`;

const _ScaleList = styled.div<{
    width: number;
    isEven: boolean;
}>`
    display: inline-block;
    position: relative;
    top: 0;
    left: 0;
    /* z-index: 6; */
    width: ${props => props.width}px;
    height: 100%;
    /* pointer-events: none; */
    background-color: #70ff7021;
    ${props => !props.isEven ? '' : css`
        background-color: #708aff20;
    `}
`;

// 音階を区別するバックの横線
const _ScaleLine = styled.div<{
    isScale: boolean;
    isBase: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: ${TUNE_PITCH_HEIGHT - 1}px;
    margin: 1px 0 0 0;
    background-color: #00000024;
    /* border-bottom: 1px solid #00000018; */
    ${props => !props.isScale ? '' : css`
        background-color: #d1d1d11f;
    `}
    ${props => !props.isBase ? '' : css`
        background-color: #e0e0e0;
    `}
`;

const _TableNotes = styled.div<{
    width: number;
}>`
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    width: ${props => props.width}px;
    height: ${TUNE_PITCH_HEIGHT * 60}px;
    cursor: crosshair;
`;


const _Scale = styled.div<{
    isHead: boolean;
    beatWidth: number;
}>`
    display: inline-block;
    /* background-color: #ffffffbe; */
    width: ${props => props.beatWidth / 4}px;
    vertical-align: top;
    height: ${TUNE_PITCH_HEIGHT * 60}px;
    box-sizing: border-box;
    border-left: solid ${props => props.isHead ? '2px' : '1px'} #757575;
`;


const _NotesWrap = styled.div<{
    left: number;
    width: number;
    isActive: boolean;
    isResize: boolean;
    isMove: boolean;
    isOctave: boolean;
    isScale: boolean;
    isPreview: boolean;
    side: Side;
}>`
    display: inline-block;
    position: absolute;
    z-index: 9;
    left: ${props => props.left}px;
    top: 0;
    width: ${props => props.width}px;
    height: 100%;
    cursor: default;
    background-color: #2727275a;
    box-sizing: border-box;

    // アクティブ時
    ${props => !props.isActive ? '' : css`
        background-color: #00a6cf52;
    `}
    // スケールモードON
    ${props => !(props.isActive && props.isScale) ? '' : css`
        background-color: #00cf4552;
    `}
    // オクターブモードON
    ${props => !(props.isActive && props.isOctave) ? '' : css`
        background-color: #ffa9a952;
    `}
    /* border-left: 1px solid #ff0000b2; */
    ${props => props.isActive ? props.side === 'left' ? css`
        border-left: 3px solid #0026ffb1;
        cursor : col-resize;
        background-color: #1900ff52;
    ` : props.side === 'right' ? css`
        border-right: 3px solid #0026ffb1;
        cursor : col-resize;
        background-color: #ff00006e;
    ` : '' : ''}

    // キーボードによるリサイズモードON
    ${props => !(props.isActive && props.isResize) ? '' : css`
        border-right: 3px solid #0026ffb1;
        background-color: #ff00006e;
    `}
    // キーボードによる移動モードON
    ${props => !(props.isActive && props.isMove) ? '' : css`
        border-left: 3px solid #0026ffb1;
        background-color: #1900ff52;
    `}
    // プレビューモード
    ${props => !(props.isActive && props.isPreview) ? '' : css`
        background-color: #fffb0040;
    `}
`;
const _Notes = styled.div<{
    y: number;
    lenType: number;
    isScale: boolean;
    isActive: boolean;
    isPreview: boolean;
}>`
    display: inline-block;
    position: absolute;
    z-index: 10;
    top: ${props => props.y}px;
    width: 100%;
    height: ${props => TUNE_PITCH_HEIGHT - (props.lenType === 1 ? 0 : props.lenType === 2 ? 6 : props.lenType === 4 ? 12 : 14)}px;
    /* background-color: red; */
    /* background: linear-gradient(to bottom, ${props => {
        switch (props.lenType) {
            case 4: return '#f8525232, #ff0000, #f8525232';
            case 8: return '#5d52f831, #0400ff, #5d52f831';
            case 16: return '#52f85a31, #00ff15, #52f85a31';
        }
    }}); */
    border-radius: 15px 0 0 15px;
    background: linear-gradient(to bottom, #f8525232, #ff0000, #f8525232);
    ${props => props.isScale ? '' : css`    
       background: linear-gradient(to bottom, #f8b65231, #ffae00, #f8b65231);
    `}
    opacity: 0.5;
    ${props => props.isPreview && !props.isActive ? '' : css`    
       opacity: 1;
    `}
    /* border: 1px solid #000; */
    box-sizing: border-box;
    font-size: 12px;
    color: #000000;
    box-shadow: 0 0 22px 0 #0000007d;
    ${props => !(props.isPreview && props.isActive) ? '' : css`
        box-shadow: 0 0 32px 0 #ffffff92;
    `}
    &:hover {
        opacity: 0.8;
    }
`;

const _PrevireBrind = styled.div<{
    width: number;
    isUse: boolean;
}>`
    position: absolute;
    display: ${props => props.isUse ? 'block' : 'none'};
    z-index: 80;
    width: ${props => props.width}px;
    height: ${TUNE_PITCH_HEIGHT * 60}px;
    background-color: #7a270028;
`;

// const _Scroll = styled.div<{
//     left: number;
// }>`
//     position: absolute;
//     left: ${props => props.left}px;
//     top: 0px;
//     z-index: 20;
//     width: 4px;
//     height: ${TUNE_PITCH_HEIGHT * 60}px;
//     background-color: #00f;
// `;