import { useEffect, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import { ChordElement, DetailAlter, DetailChord, DetailFixed, DetailInit, DetailSection, TypeDetail } from "../../../../../redux/store/score/scoreData";
import { ELEMENT_FOOT_WIDTH, ELEMENT_HEAD_WIDTH, ELEMENT_HEIGHT, ELEMENT_OPERATIONS_WIDTH, ELEMENT_POS_INFO_WIDTH, ELEMENT_SIDE_PADDING, FIRST_CHORD_INDEX } from "../../../../../utils/systemConst";
import SymbolEditor from "../tool/symbolEditor";
import CalcChord from "./calc/calcChord";
import CalcSection from "./calc/calcSection";
import ElementDetailAlter from "./form/elementDetailAlter";
import ElementDetailChord from "./form/elementDetailChord";
import ElementFixedBorder from "./elementFixedBorder";
import ElementDetailInit from "./form/elementDetailInit";
import ElementDetailSection from "./form/elementDetailSection";
import CalcAlter from "./calc/calcAlter";
import TheoryUtil from "../../../../../utils/theoryUtil";
import { ScoreState } from "../../../../../redux/store/score/scoreState";
import ChordEditor from "../tool/chordEditor";

const ScoreElement = (props: {
    index: number;
    element: ChordElement;
    state: ScoreState;
    setState: (state: ScoreState) => void;
}) => {

    const wrapRef = useRef({} as HTMLDivElement);
    const bodyRef = useRef({} as HTMLDivElement);

    const state = props.state;
    const keySwitch = state.keySwitch;
    const isCurrent = state.focusIndex == props.index;
    // const chordList = store.scoreData.chordList;
    // const element = chordList[props.index];
    const element = props.element;
    const cache = state.elementCacheList[props.index];
    const distHead = state.focusIndex < state.distIndex ? state.focusIndex : state.distIndex;
    const distTail = state.focusIndex > state.distIndex ? state.focusIndex : state.distIndex;
    const isFocus = props.index >= distHead && props.index <= distTail;
    const isRange = isFocus && distHead != distTail;
    const isSameSection = isFocus || state.elementCacheList[state.focusIndex].sectionNo === cache.sectionNo;

    useEffect(() => {
        cache.reffers.wrap = wrapRef.current;
        cache.reffers.body = bodyRef.current;
        props.setState(state);
    }, [state.elementCacheList.length]);

    const elementJson = JSON.stringify(element);

    const isError = () => {
        let ret = false;
        if (element.type === 'chord') {
            const chordCache = state.chordCacheList[cache.chordBlockNo];
            ret = chordCache.backingError;
        }
        return ret;
    }

    return useMemo(() => {
        // console.log(`pos: ${props.index}`);
        const chordDetail = element.type !== 'chord' ? null : element.detail as DetailChord;
        const isInactive = !(isSameSection || element.type === 'init');
        return (
            <_Wrap
                ref={wrapRef}
                isSelectable={element.type !== 'fixed'}
                isActive={isFocus}
                isInactive={isInactive}
                isAdditional={['chord', 'section', 'alter', 'init'].includes(element.type)}
            >
                <_Head>
                    {element.type !== 'chord' ? <></> : (
                        <_CurKey>{TheoryUtil.getDisplayKeyScaleName(cache.keyIndex, cache.scale)}</_CurKey>
                    )}
                </_Head>

                {/* 要素の入力フォーム */}
                {['init', 'chord', 'section', 'alter'].includes(element.type) ? (
                    <_Form
                        ref={bodyRef}
                        isActive={isFocus}
                        isRange={isRange}
                        isInactive={isInactive}
                        type={element.type}
                        isAnytime={['fixed', 'init'].includes(element.type) || state.focusIndex === 0}
                        onClick={() => {
                            if (element.type === 'fixed') {
                                return;
                            }
                            if (!state.keySwitch.distMode) {
                                state.focusIndex = state.distIndex = props.index;
                            } else {
                                state.distIndex = props.index;
                            }
                            props.setState(state);
                        }}
                    >{(() => {
                        switch (element.type) {
                            case 'init': {
                                const detail = element.detail as DetailInit;
                                return (<ElementDetailInit detail={detail} />);
                            }
                            case 'section': {
                                const detail = element.detail as DetailSection;
                                return (<ElementDetailSection detail={detail} />);
                            }
                            case 'chord': {
                                const detail = element.detail as DetailChord;
                                return (<ElementDetailChord
                                    detail={detail}
                                    isCurrent={isFocus}
                                    cache={cache}
                                    chordCacheList={state.chordCacheList}
                                    keyIndex={cache.keyIndex}
                                    isSustainMode={isFocus && state.keySwitch.sustainMode}
                                    scale={cache.scale}
                                />);
                            }
                            case 'alter': {
                                const detail = element.detail as DetailAlter;
                                return (<ElementDetailAlter detail={detail} />);
                            }
                        }
                    })()}
                    </_Form>
                ) : (<ElementFixedBorder detail={element.detail as DetailFixed} />)}

                {/* 入力フォーム下部の計算によって出力される情報 */}
                {
                    element.type === 'chord' ? (<CalcChord index={props.index} detail={chordDetail as DetailChord} />) :
                        element.type === 'section' ? (<CalcSection index={props.index} />) :
                            element.type === 'alter' ? (
                                <CalcAlter
                                    detail={element.detail as DetailAlter}
                                    prevCache={state.elementCacheList[props.index - 1]}
                                    curCache={cache}
                                />
                            ) :
                                <></>
                }
                <>{!(isCurrent && chordDetail != null && state.keySwitch.chordMode) ? '' :
                    <ChordEditor />
                }</>
                <>{!(isCurrent && chordDetail != null && chordDetail.root != null && state.keySwitch.symbolMode) ? '' :
                    <SymbolEditor />
                }</>
                {['fixed', 'init'].includes(element.type) ? <></> : (
                    <_Operations>
                        <_OperationItem>-</_OperationItem>
                        <_OperationItem
                        // onClick={() => dispatcher.score.addChordElement()}
                        >+</_OperationItem>
                    </_Operations>
                )}
            </_Wrap>
        );
    }, [
        elementJson, isSameSection, isRange, isFocus,
        keySwitch.chordMode, keySwitch.symbolMode, keySwitch.sustainMode,
        isError(), cache.keyIndex, cache.scale
    ]);
}

export default ScoreElement;

const _NextLine = styled.div`
    display: block;
    width: 1px;
    height: 10px;
    background-color: #ad0c0c;
`;

const _Form = styled.div<{
    isActive: boolean;
    isRange: boolean;
    isInactive: boolean;
    //常に表示させるかどうか
    isAnytime: boolean;
    type: TypeDetail;
}>`
    display: inline-block;
    /* position: relative; */
    min-width: 80px;
    height: calc(100% - ${ELEMENT_HEAD_WIDTH + ELEMENT_FOOT_WIDTH}px);
    /* border: solid 1px #00000049; */
    ${props => props.type !== 'section' ? '' : css`
        border: solid 1px #df8484;
    ` }
    ${props => props.type !== 'init' ? '' : css`
        border: solid 1px #44b168;
        height: 85px;
    ` }
    ${props => props.type !== 'alter' ? '' : css`
        border: solid 1px #604fc0;
    ` }

    opacity: 0.4;
    border-radius: 4px;
    ${props => props.isInactive ? '' : css`
        /* background-color: #ebf1e2; */
        opacity: 1;
    ` }
    ${props => !props.isAnytime ? '' : css`
        opacity: 1;
    ` }
    box-sizing: border-box;
    padding: 4px;
    transition: background-color 100ms;
    ${props => !props.isActive ? '' : css`
        background-color: #e4c9df;
        border: solid 1px #00000049;
        box-shadow: 0 0 8px 0 #000b3a48;
    `}
    ${props => !props.isRange ? '' : css`
        background-color: #80d4e2;
        box-shadow: 0 0 8px 0 #000b3a48;
    `}
`;

const _Operations = styled.div``;
const _OperationItem = styled.div``;

const _Wrap = styled.div<{
    // 選択可能か
    isSelectable: boolean;
    isActive: boolean;
    isInactive: boolean;
    isAdditional: boolean;
}>`
    display: inline-block;
    position: relative;
    height: ${ELEMENT_HEIGHT}px;
    padding: 0 ${ELEMENT_SIDE_PADDING}px;
    box-sizing: border-box;
    /* margin: 10px 5px 5px 5px; */
    border-bottom: 1px solid #47438678;
    margin-bottom: 4px;
    vertical-align: top;
    /* width: 1px; */
    transition: width 500ms, opacity 300ms;
    /* border: 1px solid #00000015; */
    ${props => props.isInactive || !props.isAdditional ? '' : css`
        /* background-color: #e8ff9549; */
        background: linear-gradient(to bottom, transparent,transparent,transparent, #ffffff9e);
    `}

    & ${_Operations} {
        display: none;
        position: absolute;
        z-index: 5;
        width: ${ELEMENT_OPERATIONS_WIDTH}px;
        height: 44px;
        /* background-color: #0000ff11; */
        top: ${35}px;
        right: -${ELEMENT_OPERATIONS_WIDTH / 2}px;

        /* background-color: #0400d84e; */
        & ${_OperationItem} {
            display: inline-block;
            width: ${ELEMENT_OPERATIONS_WIDTH}px;
            height: ${ELEMENT_OPERATIONS_WIDTH}px;
            background-color: #ebebeb6e;
            border: 1px solid #818181;
            margin: 1px 0;
            font-size: 15px;
            font-weight: 600;
            text-align: center;
            color: #818181;
            box-sizing: border-box;
            line-height: 15px;

            &:hover {
                background-color: #fffb0b9d
            }
        }
    }

    &:hover {
        ${props => !props.isSelectable ? '' : css<{
    isActive: boolean;
}>`
            & ${_Form} {
                ${props => props.isActive ? '' : css`background-color: #ffffff9b;`}
                opacity: 1;
            }
            & ${_Operations} {
                display: inline-block;
            }
        `}
    }
`;

const _CurKey = styled.div``;
const _Head = styled.div`
    display: inline-block;
    /* position: relative; */
    width: 100%;
    height: ${ELEMENT_HEAD_WIDTH}px;
    /* background-color: #eca8813b; */

    & ${_CurKey} {
        display: inline-block;
        position: absolute;
        top: -2px;
        left: 10px;
        width: 50px;
        font-size: 12px;
        color: #ca0000;
        /* background-color: #fff; */
    }
`;