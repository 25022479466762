import { useContext } from "react";
import styled, { css } from "styled-components";
import { BeatSignature, DetailAlter as DetailAlter, AlterType, ModulateRelation, TempoRelation } from "../../../../../redux/store/score/scoreData";
import CacheUtil from "../../../../../utils/score/cacheUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import { SideFormStyles } from "./sideFromStyles";

/**
 * アルターブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const SideFormAlter = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const cur = store.scoreData.chordList[state.focusIndex];
    const detail = cur.detail as DetailAlter;

    const type = detail.alterType;

    const update = () => {
        dispatcher.score.setScoreData(store.scoreData);
        // キャッシュの更新
        CacheUtil.updateHarmonizeCache(store, dispatcher);
    }

    const createAlertTypeItem = (value: AlterType | '-', isActive: boolean): JSX.Element => {
        const type = value === '-' ? null : value;
        return <_Form isActive={isActive}
            onClick={() => {
                //値が変わった時のみ処理を実行
                if (detail.alterType !== type) {
                    detail.alterType = type;
                    // 全ての項目を初期化
                    detail.modRel = null;
                    detail.dommVal = 0;
                    detail.keyAdj = 0;
                    detail.tempoRel = null;
                    detail.tempoDiff = 0;
                    detail.tempoRate = 100;
                    detail.tempoAbs = 100;
                    detail.beatSignature = '4/4';
                    update();
                }
            }}> {value}</_Form >;
    }

    const createModulateRelationItem = (value: ModulateRelation | '-'): JSX.Element => {
        const rel = value === '-' ? null : value;
        return <_Form isActive={detail.alterType === 'modulate' && rel == detail.modRel}
            onClick={() => {
                if (detail.modRel !== rel) {
                    detail.modRel = rel;
                    detail.dommVal = 0;
                    detail.keyAdj = 0;
                    update();
                }
            }}>{value}</_Form>;
    }

    const createTempoRelationItem = (value: TempoRelation | '-'): JSX.Element => {
        const rel = value === '-' ? null : value;
        return <_Form isActive={detail.alterType === 'tempo' && rel == detail.tempoRel}
            onClick={() => {
                if (detail.tempoRel !== rel) {
                    detail.tempoRel = rel;
                    detail.tempoDiff = 0;
                    detail.tempoRate = 100;
                    detail.tempoAbs = 100;
                    update();
                }
            }}>{value}</_Form>;
    }

    const createBeatItem = (value: BeatSignature): JSX.Element => {
        return <_Form isActive={detail.alterType === 'beat' && detail.beatSignature === value}
            onClick={() => {
                if (detail.beatSignature !== value) {
                    detail.beatSignature = value;
                    update();
                }
            }}>{value}</_Form>;
    }

    const donmmItemJsxList = <_ParamDiv isEnable={detail.modRel === 'domm'}>{[-3, -2, -1, 0, 1, 2, 3].map((value, i) => {
        const sign = value > 0 ? '+' : '';
        return <_ParamItem key={i} isActive={detail.alterType === 'modulate' && detail.modRel === 'domm' && value == detail.dommVal}
            onClick={() => {
                detail.dommVal = value;
                update();
            }}>{`${sign}${value}`}</_ParamItem>
    })}</_ParamDiv>;

    const keyAdjItemJsxList = <_ParamDiv isEnable={detail.modRel === 'key'}>{[-3, -2, -1, 0, 1, 2, 3].map((value, i) => {
        const sign = value > 0 ? '+' : '';
        return <_ParamItem key={i} isActive={detail.alterType === 'modulate' && detail.modRel === 'key' && value == detail.keyAdj}
            onClick={() => {
                detail.keyAdj = value;
                update();
            }}>{`${sign}${value}`}</_ParamItem>;
    })}</_ParamDiv>;

    return (
        <_Wrap>
            <_Group isEnable={true}>
                <_CategoryLabel>Type</_CategoryLabel>
                {createAlertTypeItem('-', type == null)}
                {createAlertTypeItem('modulate', type === 'modulate')}
                {createAlertTypeItem('tempo', type === 'tempo')}
                {createAlertTypeItem('beat', type === 'beat')}
            </_Group>
            <_Group isEnable={detail.alterType === 'modulate'}>
                <_CategoryLabel>Modulate</_CategoryLabel>
                {createModulateRelationItem('-')}
                {createModulateRelationItem('domm')}
                {donmmItemJsxList}
                {createModulateRelationItem('parallel')}
                {createModulateRelationItem('relative')}
                {createModulateRelationItem('key')}
                {keyAdjItemJsxList}
            </_Group>
            <_Group isEnable={detail.alterType === 'tempo'}>
                <_CategoryLabel>Tempo</_CategoryLabel>
                {createTempoRelationItem('-')}
                {createTempoRelationItem('diff')}
                {SideFormStyles.createNumberForm(detail.tempoDiff,
                    detail.alterType === 'tempo' && detail.tempoRel === 'diff',
                    (e) => {
                        detail.tempoDiff = e.currentTarget.valueAsNumber;
                        update();
                    }, -100, 100, 1)}
                {createTempoRelationItem('rate')}
                {SideFormStyles.createNumberForm(detail.tempoRate,
                    detail.alterType === 'tempo' && detail.tempoRel === 'rate',
                    (e) => {
                        detail.tempoRate = e.currentTarget.valueAsNumber;
                        update();
                    }, 10, 200, 1)}
                {createTempoRelationItem('abs')}
                {SideFormStyles.createNumberForm(detail.tempoAbs,
                    detail.alterType === 'tempo' && detail.tempoRel === 'abs',
                    (e) => {
                        detail.tempoAbs = e.currentTarget.valueAsNumber;
                        update();
                    }, 10, 200, 1)}
            </_Group>
            <_Group isEnable={detail.alterType === 'beat'}>
                <_CategoryLabel>Beat</_CategoryLabel>
                {createBeatItem('2/4')}
                {createBeatItem('4/4')}
                {createBeatItem('3/4')}
                {createBeatItem('6/8')}
                {createBeatItem('9/8')}
                {createBeatItem('12/8')}
            </_Group>
        </_Wrap>
    );
}

export default SideFormAlter;

const _Wrap = styled.div`
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: left;
        background-color: #b3b5c5;
        `;

const _Group = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    margin: 0 0 0 4px;
    ${props => props.isEnable ? '' : css`
    pointer-events: none;
    opacity: 0.5;
`}
        `;

const _CategoryLabel = styled.div`
    ${SideFormStyles.___CSS_TITLE}
    margin-top: 4px;
`;

const _Form = styled.div<{
    isActive: boolean;
}>`
        ${SideFormStyles.___CSS_FORM}
        margin-top: 1px;
        width: 100%;
        background-color: #c1cdd4;
        ${props => !props.isActive ? '' : css`
        background-color: #e0c83e;
    `}
        `;

const _ParamDiv = styled.div<{
    isEnable: boolean;
}>`
        margin: 1px;
        background-color: #070707;
        ${props => props.isEnable ? '' : css`
        pointer-events: none;
        opacity: 0.5;
    `}
        `;
const _ParamItem = styled.div<{
    isActive: boolean;
}>`
        ${SideFormStyles.___CSS_FORM}
        width: 29px;
        background-color: #dddddd;
        ${props => !props.isActive ? '' : css`
        background-color: #e0c83e;
    `}
        `;


const _ValueForm = styled.div`
        display: inline-block;
        width: 100%;
        height: 24px;
        /* margin: 5px 0 0 0; */
        text-align: left;
        background-color: #ececec;

        & * {
            display: inline-block;
            box-sizing: border-box;
            vertical-align: top;
    }
        & input:nth-child(1) {
            background-color: #dddddd;
            width: 60px;
            height: calc(100% - 4px);
            margin: 2px 0 0 0;
            border: 1px solid #9b9b9b;
            border-radius: 4px;
    }
        & input:nth-child(2) {
            margin-left: 5px;
            background-color: #f00;
            width: calc(100% - 70px);
            height: 100%;
    }
        `;