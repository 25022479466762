import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import Styles from "../../base/design/styles";
import BackingState from "../../redux/store/score/backing/backingState";
import { BackingPatternProps, DetailChord, VoicingProps } from "../../redux/store/score/scoreData";
import BackingUtil from "../../utils/backingUtil";
import LibraryUtil from "../../utils/LibraryUtil";
import TheoryUtil from "../../utils/theoryUtil";
import ThemaUtil from "../custom/backing/themaUtil";
import { GlobalContext } from "../entry/systemEntry";

namespace ThemaSelectorFrame {

    export type CachePorps = {
        patternList: LibraryUtil.PattItemProps[];
        voicingList: LibraryUtil.VoicItemProps[];
        field: Field;
        invalidate: () => void;
        // updateBacking: () => void;
        testBacking: () => void;
        isPermitTest: () => boolean;
        isPreview: boolean;
    };

    type Field = {
        focusSide: number;
        pattIndex: number;
        voicIndex: number;
    }

    /**
     * ライブラリセレクタ画面
     * @returns コンポーネント
     */
    export const Component = () => {

        const { store, dispatcher } = useContext(GlobalContext);

        const [field, setField] = useState<Field>({
            focusSide: 0,
            pattIndex: 0,
            voicIndex: 0
        });

        const invalidate = () => {
            setField({ ...field });
        };

        const chordList = store.scoreData.chordList;
        const systemState = store.systemState;
        const focusIndex = store.scoreState.focusIndex;
        const chord = chordList[focusIndex].detail as DetailChord;
        const state = store.scoreState;
        const cache = state.elementCacheList[state.focusIndex];
        const chordCache = state.chordCacheList[cache.chordBlockNo];
        const structList = chordCache.structList;

        const degreeRootIndex = chord.root?.index as number;
        const rootIndex = (degreeRootIndex + cache.keyIndex) % 12;

        const backing = store.backingState as BackingState.Editor;
        const themaList = systemState.themaList;
        const thema = themaList[systemState.activeThemaIndex];

        useEffect(() => {
            console.log('thema start');
            console.log(thema);

            return () => {
                console.log('thema end');

                // キャッシュを削除
                dispatcher.system.setState({
                    ...store.systemState,
                    cache: null
                });
            }
        }, []);

        useEffect(() => {
            // キーボード操作のためのキャッシュを作成
            const inputCache: CachePorps = {
                patternList,
                voicingList,
                field,
                invalidate,
                // updateBacking,
                testBacking,
                isPermitTest,
                isPreview: false
            };
            dispatcher.system.setState({
                ...store.systemState,
                cache: inputCache
            });
        }, []);


        useEffect(() => {
            updateBacking();
            const cache = store.systemState.cache as CachePorps;
            if (cache != null) {
                // cache.updateBacking = updateBacking;
                cache.testBacking = testBacking;
                cache.isPermitTest = isPermitTest;
                cache.isPreview = backing.isPreview;
            }
        }, [field, backing.isPreview]);

        /**
         * バッキングの状態を更新する
         */
        const updateBacking = () => {
            if (isPermitTest()) {
                const patternProps = patternList[field.pattIndex].source;
                const voicingProps = voicingList.filter(voic=>{
                    return LibraryUtil.acceptVoic(voic, patternList, field.pattIndex);
                })[field.voicIndex].source;
                const voicingTable = BackingUtil.getInitVoicingTable(voicingProps, structList.length);
                const pattern = BackingUtil.getEditorPatternFromChordBacking(voicingTable, patternProps);
                dispatcher.backing.setState({ ...backing, voicingTable, pattern });
            } else {
                dispatcher.backing.setState({ ...backing, voicingTable: [], pattern: null });
            }
        }

        /**
         * プレビュー再生を行う
         */
        const testBacking = useCallback(() => {
            console.log('test');
            if (!backing.isPreview) {
                dispatcher.backing.testBacking();
            } else {
                BackingUtil.stopPreview(store, backing, dispatcher, store.reserveTasks);
            }
        }, [store]);

        /**
         * プレビュー再生が可能な状態か判断する
         * @returns プレビュー再生が可能か
         */
        const isPermitTest = () => {
            return field.voicIndex > 0;
        }

        const [patternList, voicingList] = useMemo(() => {

            const patternList: LibraryUtil.PattItemProps[] = [];
            const voicingList: LibraryUtil.VoicItemProps[] = [];

            // 空のボイシングを先頭に追加
            voicingList.push({ pattIdx: -1, dispName: 'none', source: [] });

            //検索条件
            const condition: LibraryUtil.Condition = {
                symbolKey: chord.symbolKey,
                rootIndex,
                beatLen: chord.beatLen,
                head: chord.minute.head,
                tail: chord.minute.tail
            };

            // シンプルバッキング
            const sustMet = thema.meths[0];
            LibraryUtil.searchSustMeth(sustMet, condition, null, (voic: ThemaUtil.LevelVoic) => {
                const dispName = voic.source.map(v => `[${v.octave}-${v.struct}]`).join(',');
                voicingList.push({
                    pattIdx: patternList.length,
                    dispName,
                    source: voic.source
                });
            });
            patternList.push({
                dispName: 'none',
                source: null,
                key: -1,
                refer: -1
            });

            // パターンバッキング
            const pattMet = thema.meths[1];
            LibraryUtil.searchPattMeth(pattMet, condition, null, (patt: ThemaUtil.LevelPatt) => {
                patternList.push({
                    dispName: patt.pattName,
                    source: patt.source,
                    key: patt.key,
                    refer: patt.refer
                });
            }, (voic: ThemaUtil.LevelVoic) => {
                // const dispName = voic.source.map(v => `[${v.octave}-${v.struct}]`).join(',');
                const soundList = voic.source.map(v => {
                    const struct = structList[v.struct];
                    return TheoryUtil.KEY12_SHARP_LIST[struct.soundIndex] + (v.octave + struct.adjustOctave);
                });
                voicingList.push({
                    pattIdx: patternList.length,
                    dispName: soundList.join('-'),
                    source: voic.source
                });
            }, () => { });
            return [patternList, voicingList];
        }, []);

        const [pattJsxList, voicJsxList]: [JSX.Element[], JSX.Element[]] = useMemo(() => {
            return [
                // パターンのJSXリスト
                patternList.map((patt, i) => {
                    return (
                        <_Item key={i}
                            isFocus={field.pattIndex === i}
                            isNone={i === 0}
                            onClick={() => {
                                field.focusSide = 0;
                                field.pattIndex = i;
                                // パターンが変わったらボイシングの選択をリセット
                                if (field.pattIndex !== i) {
                                    field.voicIndex = 0;
                                }
                                invalidate();
                                updateBacking();
                            }}
                        >{patt.dispName}</_Item>
                    )
                }),
                // ボイシングのJSXリスト
                voicingList.filter((voic) => {
                    return LibraryUtil.acceptVoic(voic, patternList, field.pattIndex);
                }).map((voic, i): JSX.Element => {
                    const soundList = voic.source.map((v, i) => {
                        const struct = structList[v.struct];
                        const fullName = TheoryUtil.KEY12_SHARP_LIST[struct.soundIndex] + (v.octave + struct.adjustOctave);
                        return (<span key={i}>
                            <_Struct
                                interval={LibraryUtil.getDistinctionFromRelate(struct)}
                            >{fullName}</_Struct>{i < voic.source.length - 1 ? '-' : ''}
                        </span>);
                    });
                    const dispLabel: JSX.Element = i === 0 ? <>none</> : <>{soundList}</>;
                    return (
                        <_Item key={i}
                            isFocus={field.voicIndex === i}
                            isNone={i === 0}
                            onClick={() => {
                                field.focusSide = 1;
                                field.voicIndex = i;
                                invalidate();
                                updateBacking();
                            }}
                        >{dispLabel}</_Item>
                    )
                })
            ];
        }, [field.pattIndex, field.voicIndex]);

        return (
            <_Wrap>
                <_PrevireBrind isUse={backing.isPreview} />
                <_BodyDiv>
                    <_ListDiv isFocus={field.focusSide === 0}>
                        <_Header>Pattern</_Header>
                        {pattJsxList}
                    </_ListDiv>
                    <_ListDiv isFocus={field.focusSide === 1}>
                        <_Header>Voicing</_Header>
                        {voicJsxList}
                    </_ListDiv>
                </_BodyDiv>
                <_FooterDiv>
                    {/* プレビュー再生 */}
                    <_Button isEnable={isPermitTest()} onClick={testBacking}>
                        {!backing?.isPreview ? 'Test' : 'Stop'}
                    </_Button>
                    {/* 選択を反映せずに閉じる */}
                    <_Button isEnable={!backing.isPreview} onClick={() => {
                        dispatcher.score.closeLibraryWnd();
                    }}>Cancel</_Button>
                    {/* 選択を反映して閉じる */}
                    <_Button isEnable={!backing.isPreview} onClick={() => {
                        const pattern = patternList[field.pattIndex];
                        const voicing = voicingList.filter(voic=>{
                            return LibraryUtil.acceptVoic(voic, patternList, field.pattIndex);
                        })[field.voicIndex];
                        dispatcher.thema.mappingSelectorToScore(voicing.source, pattern.source);
                        dispatcher.score.closeLibraryWnd();
                    }}>Apply</_Button>
                </_FooterDiv>
            </_Wrap>
        )
    }
}
export default ThemaSelectorFrame;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    width: 630px;
    height: 400px;
    background-color: #d4d5ca;
    border: 1px solid #121212;
    box-sizing: border-box;
`;
const FOOTER_WIDTH = 30;
const _BodyDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(100% - ${FOOTER_WIDTH}px);
    /* background-color: #4d5e53; */
`;
const _FooterDiv = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;
    height: ${FOOTER_WIDTH}px;
    background-color: #c7c7c7;
    text-align: right;
`;


const _ListDiv = styled.div<{
    isFocus: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 50%;
    height: 100%;
    /* background-color: #4d5e53; */
    /* border: 1px solid #121212; */
    ${props => !props.isFocus ? '' : css`
        background-color: #ffffff45;
        /* border: 1px solid #eb0000; */
    `}
    box-sizing: border-box;
`;

const _Header = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    ${Styles.CSS_LABEL_SMALL}
    background-color: #3c4a99c3;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    color: #ffffff;
    text-align: center;
`;
const _Item = styled.div<{
    isFocus: boolean;
    isNone: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    ${Styles.CSS_LABEL_SMALL}
    background-color: #d1dad4c8;
    ${props => !props.isFocus ? '' : css`
        background-color: #e6dd5bc5;
    `}
    box-sizing: border-box;
    border: 1px solid #676767;
    color: #000786;
    ${props => !props.isNone ? '' : css`
        color: #850000;
    `}
    text-align: left;
    padding-left: 4px;
`;
const _Struct = styled.span<{
    interval: number;
}>`
    color: ${props => {
        switch (props.interval) {
            case 1: return '#960000';
            case 3: return '#000877';
            case 5: return '#007a06';
            case 7: return '#742e00';
            case 9: return '#005e5e';
        }
    }};
`;


const _Button = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100px;
    height: 26px;
    margin: 2px 2px 0 0;
    font-size: 20px;
    color: white;
    font-weight: 600;
    background-color: #0051ff21;
    border: solid 1px #555;
    border-radius: 4px;
    box-sizing: border-box;
    line-height: 20px;
    text-align: center;

    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}

    &:hover {
        background-color: #ff008039;
    }
`;

const _PrevireBrind = styled.div<{
    isUse: boolean;
}>`
    position: absolute;
    display: ${props => props.isUse ? 'block' : 'none'};
    z-index: 80;
    width: 100%;
    height: calc(100% - 30px);
    background-color: #7a270028;
`;