import styled, { css } from "styled-components";
import BackingState from "../../../redux/store/score/backing/backingState";
import { BackingPatternProps, VoicingProps } from "../../../redux/store/score/scoreData";
import TheoryUtil from "../../../utils/theoryUtil";
import TreeUtil from "./treeUtil";

namespace ThemaUtil {

    export type Method = 'sustain' | 'pattern';

    export type LevelAttr = {
        attr: TheoryUtil.SymbolAttribute;
        symbols: TheoryUtil.SymbolKey[];
        roots: LevelRoot[];
    }
    export type LevelRoot = {
        minIdx: number;
        maxIdx: number;
        voics: LevelVoic[];
    }
    export type PresetUse = {
        presKey: number;
        depPatt: number;
    }
    export type LevelVoic = {
        source: VoicingProps[];
        preUsies: PresetUse[];
    }

    export type LevelPatt = {
        key: number;
        pattName: string;
        source: BackingPatternProps;
        refer: number;
        attrs: LevelAttr[];
    }
    export type LevelSust = {
        beat: number;
        head: number;
        tail: number;
        patts: LevelPatt[];
    }

    export type Prenam = {
        seq: number;
        name: string;
    }
    export type LevelMethod = {
        method: Method;
        prenams: Prenam[];
        defs: LevelAttr[] | LevelSust[];
    }

    export type LevelBase = {
        themaName: string;
        meths: LevelMethod[];
    }

    export const getLabelJsx = (node: TreeUtil.ElementNode, isActive: boolean) => {
        let innderJsx = <></>;

        const setBaseJsx = () => {
            const data = node.data as ThemaUtil.LevelBase;
            innderJsx = (<>
                <_TypeLabel type='base'>Base</_TypeLabel>
                <_Label>{data.themaName}</_Label>
            </>);
        }
        const setMethJsx = () => {
            const data = node.data as ThemaUtil.LevelMethod;
            innderJsx = (<>
                <_TypeLabel type='method'>Method</_TypeLabel>
                <_Label>{data.method}</_Label>
            </>);
        }
        const setAttrJsx = () => {
            const getMainLabel = () => {
                if (node.data == null) {
                    return <_Label><_Create>Create...</_Create></_Label>
                } else {
                    const data = node.data as ThemaUtil.LevelAttr;
                    const list = data.symbols.map(symbol => (
                        `[${TheoryUtil.getSymbolFromKey(symbol)?.name}]`
                    ));
                    return <_Label>@{data.attr}: <_C_Symbol>{list.join(', ')}</_C_Symbol></_Label>;
                }
            };
            innderJsx = (<>
                <_TypeLabel type='attr'>Attribute</_TypeLabel>
                {getMainLabel()}
            </>);
        }
        const setSustJsx = () => {
            const getMainLabel = () => {
                if (node.data == null) {
                    return <_Label><_Create>Create...</_Create></_Label>
                } else {
                    const data = node.data as ThemaUtil.LevelSust;
                    return <_Label>[{data.beat}* ({data.head},{data.tail})]</_Label>
                }
            };
            innderJsx = (<>
                <_TypeLabel type='sustain'>Sustain</_TypeLabel>
                {getMainLabel()}
            </>);
        }
        const setRootJsx = () => {
            const getMainLabel = () => {
                if (node.data == null) {
                    return <_Label><_Create>Create...</_Create></_Label>
                } else {
                    const data = node.data as ThemaUtil.LevelRoot;
                    const nameList = TheoryUtil.KEY12_SHARP_LIST;
                    return <_Label>[{nameList[data.minIdx]}～{nameList[data.maxIdx]}]</_Label>;
                }
            };
            innderJsx = (<>
                <_TypeLabel type='root'>Root</_TypeLabel>
                {getMainLabel()}
            </>);
        }
        const setPattJsx = () => {
            const getMainLabel = () => {
                if (node.data == null) {
                    return <_Label><_Create>Create...</_Create></_Label>
                } else {
                    const data = node.data as ThemaUtil.LevelPatt;
                    const refers = (node.parent?.data as LevelSust).patts.map(patt => patt.refer);
                    // 参照されていた場合masterを表示
                    const master = !refers.includes(data.key) ? '' : ' master';
                    const depend = data.refer === -1 ? '' : ` depend: ${data.refer}`;
                    return (<_Label>
                        <_P_Key>{data.key}</_P_Key>: [{data.pattName} <_P_Channel> channel: {data.source.channelSize}</_P_Channel>]
                        <_P_Master>{master}</_P_Master>
                        <_P_Depend>{depend}</_P_Depend>
                    </_Label>);
                }
            };
            innderJsx = (<>
                <_TypeLabel type='pattern'>Pattern</_TypeLabel>
                {getMainLabel()}
            </>);
        }
        const setVoicJsx = () => {
            const getMainLabel = () => {
                if (node.data == null) {
                    return <_Label><_Create>Create...</_Create></_Label>
                } else {
                    const data = node.data as ThemaUtil.LevelVoic;
                    return <_Label>[{data.source.map(v => {
                        return `${v.octave}-${v.struct}`;
                    }).join(', ')}]</_Label>
                }
            };
            innderJsx = (<>
                <_TypeLabel type='voicing'>Voicing</_TypeLabel>
                {getMainLabel()}
            </>);
        }
        switch (node.depth) {
            case 0: setBaseJsx(); break;
            case 1: setMethJsx(); break;
            case 2: {
                const method = (node.parent?.data as ThemaUtil.LevelMethod).method;
                switch (method) {
                    case 'sustain': setAttrJsx(); break;
                    case 'pattern': setSustJsx(); break;
                }
            } break;
            case 3: {
                const method = (node.parent?.parent?.data as ThemaUtil.LevelMethod).method;
                switch (method) {
                    case 'sustain': setRootJsx(); break;
                    case 'pattern': setPattJsx(); break;
                }
            } break;
            case 4: {
                const method = (node.parent?.parent?.parent?.data as ThemaUtil.LevelMethod).method;
                switch (method) {
                    case 'sustain': setVoicJsx(); break;
                    case 'pattern': setAttrJsx(); break;
                }
            } break;
            case 5: {
                const method = (node.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelMethod).method;
                switch (method) {
                    case 'pattern': setRootJsx(); break;
                }
            } break;
            case 6: {
                const method = (node.parent?.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelMethod).method;
                switch (method) {
                    case 'pattern': setVoicJsx(); break;
                }
            } break;
        }
        return <_NodeFrame isActive={isActive}>{innderJsx}</_NodeFrame>
    }

    export const getInitialThema = (): LevelBase => {
        return {
            themaName: 'test',
            meths: [
                {
                    method: 'sustain',
                    prenams: [],
                    defs: [
                    ]
                },
                {
                    method: 'pattern',
                    prenams: [],
                    defs: [
                    ]
                }
            ]
        };
    }
}

export default ThemaUtil;


const _CSS_FONT = css`
    font-size: 24px;
    font-weight: 600;
    height: calc(100% - 8px);
    margin-top: 4px;
`;

const _TypeLabel = styled.div<{
    type: 'base' | 'method' | 'pattern' | 'sustain' | 'attr' | 'root' | 'voicing'
}>`
    display: inline-block;
    position: relative;
    ${_CSS_FONT}
    color: #660000;
    background-color: ${props => {
        switch (props.type) {
            case 'base': return '#6fa480';
            case 'method': return '#7779a2';
            case 'sustain': return '#c7a67e';
            case 'pattern': return '#9b77a2';
            case 'attr': return '#b67575';
            case 'root': return '#a2a177';
            case 'voicing': return '#779ea2';
        }
    }};
    padding: 0 15px;
    border-radius: 5px;
`;
const _Label = styled.div`
    display: inline-block;
    position: relative;
    ${_CSS_FONT}
    color: #6d1717;
    background-color: #ffffff49;
    padding: 0 10px;
    margin-left: 5px;
`;
const _C_Symbol = styled.span`
    color: #959595;
`;
const _P_Master = styled.span`
    color: #d40101;
`;
const _P_Depend = styled.span`
    color: #0300cc;
`;
const _Create = styled.span`
    color: #fff9c2;
`;
const _P_Channel = styled.span`
    color: #d3701381;
`;
const _P_Key = styled.span`
    color: #868686;
`;

const _NodeFrame = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    padding: 0 5px;
    height: 100%;
    ${props => !props.isActive ? '' : css`
        background-color: #fffb0077;
    `}
`;