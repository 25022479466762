import { useContext, useMemo } from "react";
import styled, { css } from "styled-components";
import { ChordElement, DetailChord, DetailInit } from "../../../../redux/store/score/scoreData";
import { TUNE_PITCH_HEIGHT } from "../../../../utils/systemConst";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";


const MelodyPitchList = (props: {
    mousePitchIndex: number;
    curChord: ChordElement | null;
}) => {
    const { store, dispatcher } = useContext(GlobalContext);
    const init = store.scoreData.chordList[0].detail as DetailInit;

    // 左端の音程リストのJSXリスト
    const pitchItemJsxList = useMemo(() => {
        const structIndexList: number[] = [];
        if (props.curChord != null) {
            const detail = props.curChord.detail as DetailChord;
            const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey);
            const root = detail.root;
            if (root != null && symbol != null) {
                symbol.structs.forEach((struct) => {
                    structIndexList.push((root.index + init.keyIndex + TheoryUtil.IntervalRelationProps[struct]) % 12);
                });
            }
        }

        const list: JSX.Element[] = [];
        for (let i = 0; i < 60; i++) {
            const keyIndex = ((59 - i) + 9) % 12;
            const octave = Math.floor(((59 - i) + 9) / 12) + 1;
            const soundName = TheoryUtil.KEY12_SHARP_LIST[keyIndex % 12];
            const octaveName = getOctaveName(octave);
            list.push(
                <_PitchItem key={i}
                    isScale={[0, 2, 4, 5, 7, 9, 11].includes(keyIndex)}
                    isStruct={structIndexList.includes(keyIndex)}
                    isActive={i === props.mousePitchIndex}
                    onClick={() => {
                        const instrument = store.instruments.melodyFont;
                        if (instrument != null) {
                            instrument.stop();
                            instrument.play(soundName + octave, 0, { duration: 1 });
                        }
                    }}
                >
                    {/* {octaveName} */}
                    {soundName}
                </_PitchItem>
            );
        }
        return list;
    }, [props.mousePitchIndex, props.curChord]);

    return (<>
        <_OctaveWrap>
            <_OctaveItem isActive={false}><div>hihi</div></_OctaveItem>
            <_OctaveItem isActive={false}><div>hi</div></_OctaveItem>
            <_OctaveItem isActive={false}><div>mid2</div></_OctaveItem>
            <_OctaveItem isActive={false}><div>mid1</div></_OctaveItem>
            <_OctaveItem isActive={false}><div>low</div></_OctaveItem>
        </_OctaveWrap>
        <_PitchWrap>
            {pitchItemJsxList}
        </_PitchWrap>
    </>);
};

export default MelodyPitchList;

const getOctaveName = (num: number) => {
    switch (num) {
        case 0: return 'low';
        case 1: return 'mid1';
        case 2: return 'mid2';
        case 3: return 'hi';
        case 4: return 'hihi';
        default: return '';
    }
}

const _OctaveWrap = styled.div`
    display: inline-block;
    width: 30px;
    height: 100%;
    vertical-align: top;
`;

const _PitchWrap = styled.div`
    display: inline-block;
    width: calc(100% - 30px);
    height: 100%;
    vertical-align: top;
`;

const _OctaveItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    border: 1px solid black;
    width: 100%;
    height: 360px;
    background-color: #8f8f8f;
    ${props => !props.isActive ? '' : css`background-color: #ffc40089;`}
    box-sizing: border-box;

    font-size: 18px;
    color: #dfdfdf;
    font-weight: 600;
    & div {
        width: 360px;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-50%);
        /* background-color: #fff; */
    }
`;

const _PitchItem = styled.div<{
    isScale: boolean;
    isActive: boolean;
    isStruct: boolean;
}>`
    display: inline-block;
    width: calc(100% - 20px);
    border: 1px solid black;
    ${props => !props.isScale ? '' : css`
        width: 100%;
    `}
    height: ${TUNE_PITCH_HEIGHT}px;
    background-color: #adadad;
    ${props => !props.isStruct ? '' : css`background-color: #e25d5d;`}
    ${props => !props.isActive ? '' : css<{
        isStruct: boolean;
    }>`
        background-color: #cece60;
        ${props => !props.isStruct ? '' : css`background-color: #e28c45;`}
    `}
    box-sizing: border-box;

    padding-left: 5px;
    line-height: 28px;
    font-size: 18px;
    color: #3b3b3b;
    font-weight: 600;
`;
