import { useContext } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { NOTES_1BAR_WIDTH, PATTERN_BODY_HEIGHT, PATTERN_FOOTER_HEIGHT, PATTERN_HEADER_HEIGHT } from "../../../../utils/systemConst";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";

const PatternVelocity = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const chordInfo = state.chordInfo;

    const itemJsxList: JSX.Element[] = [];
    const pattern = state.pattern as BackingState.Pattern;
    const layer = pattern.layers[pattern.layerIndex];
    
    const beatProps = TheoryUtil.getBeatProps(chordInfo.init.beatSignature);
    const adjustRate = beatProps.beatMemoriCount === 4 ? 1 : (3/2);

    layer.velocityList.forEach((vel, i) => {
        const isActive = pattern.lenIndex === i;

        const note = layer.noteDivList[i];
        let rate = note.rate * adjustRate;
        if (note.isDot) rate /= 1.5;
        if (note.div3 !== 0) rate /= note.div3 / 3;
        itemJsxList.push(
            <_Item key={i} rate={rate} isActive={isActive} >
                <_Value value={vel} />
            </_Item>
        );
    });

    return (<_Wrap>
        {itemJsxList}
    </_Wrap>);
}

export default PatternVelocity;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
`;


const _Item = styled.div<{
    rate: number;
    isActive: boolean;
}>`
    display: inline-block;
    position: relative;
    width: ${props => NOTES_1BAR_WIDTH / props.rate - 1}px;
    height: calc(100% - 4px);
    margin: 2px 0 0 1px;
    background-color: #001763ac;
    ${props => !props.isActive ? '' : css`
        background-color: #173494ac;
    `}
    box-sizing: border-box;
    /* text-align: center; */
`;

const _Value = styled.div<{
    value: number;
}>`
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: calc(50% - 6px);
    width: 10px;
    height: ${props => props.value * 10}%;
    /* background-color: #5cceddaf; */
    background: linear-gradient(to right, #00949e, #26dcf4, #00949e);
`;
