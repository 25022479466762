import { themaColorNormal } from "../../base/design/thema/themaColorNormal"
import { getDefaultSampler } from "../../utils/instrumentsUtil"
import { RefsCache as RefsCache } from "./refsCache"
import { Instruments } from "./instrument"
import { ScoreData } from "./score/scoreData"
import { ScoreState } from "./score/scoreState"
import { SystemState } from "./systemState"
import { MelodyState } from "./score/melodyState"
import { getDefaultKeyConfig, UserEnv } from "./userEnv"
import { Invalidate } from "./invalidate"
import DataHistory from "./state/dataHistory"
import BackingState from "./score/backing/backingState"
import LibraryUtil from "../../utils/LibraryUtil"

/**
 * システムで使用するステート群
 */
export type Store = {
    userEnv: UserEnv;
    systemState: SystemState;
    scoreState: ScoreState;
    backingState: null | BackingState.Editor;
    melodyState: MelodyState;
    scoreData: ScoreData;
    instruments: Instruments;
    refs: RefsCache;
    reserveTasks: NodeJS.Timeout[];
    invalidate: Invalidate;
}

export const initialStore: Store = {
    userEnv: {
        keyConfig: getDefaultKeyConfig(),
        colorProps: themaColorNormal,
        login: null
    },
    systemState: {
        directScore: null,
        isSplash: true,
        mainChannelIndex: 0,
        scoreTabIndex: 0,
        editable: true,
        dialog: null,
        themaList: LibraryUtil.getDefaultLibs(),
        activeThemaIndex: -1,
        cache: null
    },
    backingState: null,
    scoreState: {
        fileHandle: null,
        isMelody: false,
        initialBeat: 4,
        focusIndex: -1,
        distIndex: -1,
        elementCacheList: [],
        chordCacheList: [],
        sectionCacheList: [],
        scaleCacheList: [],
        tempoCacheList: [],
        beatCacheList: [],
        measureCacheList: [],
        keySwitch: {
            distMode: false,
            sustainMode: false,
            chordMode: false,
            symbolMode: false,
            control: false,
            minHeadMode: false,
            minTailMode: false
        },
        symbolMng: null,
        hideMenu: 'none',
        isPreview: false,
        isSolo: false,
        cloneBacking: null,
        copiedElementList: null,
        backingDataHistory: new DataHistory(),
    },
    melodyState: {
        focusIndex: -1,
        destIndex: -1,
        lastFocus: -1,
        quantize: 1,
        beatWidth: 120,
        tableMouse: {
            isPut: false,
            lock: { x: 0, y: 0 },
            width: 0,
            prevRight: 0,
            nextLeft: 0,
            notesState: { index: -1, side: 'normal', isResize: false, isMove: false },
            curChord: null,
            mousePitchIndex: -1,
            mouseMeasurePos: -1
        },
        timelineMouse: {
            pos: -1,
            isPut: false,
            lock: -1,
            width: 0,
        },
        soundfontInfo: {
            name: 'violin',
            gain: 9
        },
        keySwitch: {
            scaleMode: false,
            octave: false,
            resize: false,
            move: false,
            shift: false,
            control: false,
        },
        isPreview: false,
        isSolo: false,
        cloneNotesList: [],

        dataHistory: new DataHistory(),
        notesCacheList: []
    },
    scoreData: {
        chordList: [],
        notesList: []
    },
    instruments: {
        // sampler: getDefaultSampler(),
        harmonyFont: null,
        melodyFont: null
    },

    refs: {
        harmonize: {
            note: {} as HTMLDivElement
        },
        melody: {
            table: null,
            measure: null,
            chord: null,
            pitch: null,
        }
    },
    reserveTasks: [],
    invalidate: {
        harmoniizeInvalidate: {},
        harmonize: null,
        melody: null
    },
}