import { useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../entry/systemEntry";
import HarmonizeTab from "./harmonize/harmonizeFrame";
import Soundfont from 'soundfont-player';
import MelodyFrame from "./melody/melodyFrame";
import Styles, { _CSS_DISABLE, _CSS_LABEL_MIDIUM } from "../../../base/design/styles";
import MelodyUtil, { } from "../../../utils/melodyUtil";
import HarmonizeUtil from "../../../utils/score/harmonizeUtil";

const EditTab = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const melody = store.melodyState;

    const isDirect = store.systemState.directScore != undefined;

    useEffect(() => {
        const info = melody.soundfontInfo;
        if (store.instruments.melodyFont == null && store.instruments.harmonyFont == null) {
            const instruments = store.instruments;
            Soundfont.instrument(new AudioContext(), info.name,
                { gain: info.gain, release: 0.5 }).then((melodyFont) => {
                    // dispatcher.melody.setMelodyFont(soundFontPlayer);

                    instruments.melodyFont = melodyFont;
                    // acoustic_guitar_steel
                    //bright_acoustic_piano
                    //electric_grand_piano
                    Soundfont.instrument(new AudioContext(), 'electric_grand_piano',
                        { gain: 2, release: 0.5 }).then((harmonyFont) => {
                            // dispatcher.score.setHarmonyFont(soundFontPlayer);

                            instruments.harmonyFont = harmonyFont;
                            dispatcher.score.setInstruments(instruments);
                        });
                });
        }

    }, []);

    const switchMode = () => {
        state.isMelody = !state.isMelody;
        dispatcher.score.setScoreState(state);
    };

    if (store.scoreData.chordList.length === 0) {
        return <></>;
    }

    const isPreview = !state.isMelody ? state.isPreview : store.melodyState.isPreview;
    const isSolo = !state.isMelody ? state.isSolo : melody.isSolo;
    const preview = !state.isMelody ? () => {

        if (!state.isPreview) {
            HarmonizeUtil.previewStart(store, dispatcher);
        } else {
            HarmonizeUtil.stopPreview(store, dispatcher, store.reserveTasks);
        }
    } : () => {

        if (!melody.isPreview) {
            dispatcher.melody.previewStart();
        } else {
            MelodyUtil.stopPreview(store, dispatcher, store.reserveTasks);
        }
    }
    /** アンサンブルの切替 */
    const toggleEnsamble = !state.isMelody ? () => {
        state.isSolo = !state.isSolo;
        dispatcher.score.setScoreState(state);
    } : () => {
        melody.isSolo = !melody.isSolo;
        dispatcher.melody.setState(melody);
    }

    const playJsx = <_SwitchInner isActive={isPreview}>Play</_SwitchInner>;
    const stopJsx = <_SwitchInner isActive={!isPreview}>Pause</_SwitchInner>;
    const ensambleJsx = <_SwitchInner isActive={!isSolo}>Emsamble</_SwitchInner>;
    const soloJsx = <_SwitchInner isActive={isSolo}>Solo</_SwitchInner>;

    return (
        <_Wrap>
            <_Header isEnable={state.hideMenu === 'none'}>
                <_Mode isEnable={!isPreview}>
                    <_ModeItem isActive={!state.isMelody} onClick={() => {
                        if (state.isMelody) switchMode();
                    }}>Harmony</_ModeItem>
                    <_ModeItem isActive={state.isMelody} onClick={() => {
                        if (!state.isMelody) switchMode();
                    }}>Melody</_ModeItem>
                </_Mode>
                <_Buttons>
                    <_Switch isEnable={true} onClick={preview}>{playJsx} / {stopJsx}</_Switch>
                    <_Switch isEnable={!state.isPreview} onClick={toggleEnsamble}>{ensambleJsx} / {soloJsx}</_Switch>
                    <_Button isEnable={!isDirect} onClick={dispatcher.score.saveFile}>Save</_Button>
                </_Buttons>
            </_Header>
            <_Body
                tabIndex={999}
                onKeyDown={(e) => {
                    const isPermit = !isPreview && state.hideMenu === 'none';
                    if (isPermit) {
                        switch (e.key) {
                            case 'r': switchMode(); break;
                        }
                    }
                }}>
                <_Main>
                    <HarmonizeTab isActive={!state.isMelody} />
                    <MelodyFrame isActive={state.isMelody} />
                </_Main>
            </_Body>
        </_Wrap>
    );
}
export default EditTab;

const _Wrap = styled.div`
    width: 100%;
    height: 100%;
    outline: none;
`;

const _Header = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 35px;
    background-color: #ececec;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
`;

const _Mode = styled.div<{
    isEnable: boolean
}>`
    display: inline-block;
    /* width: 300px; */
    height: 100%;
    text-align: center;
    margin-right: 20px;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
`;
const _ModeItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    /* width: 300px; */
    width: 120px;
    height: calc(100% - 8px);
    /* background-color: #e0ff6f55; */
    ${props => !props.isActive ? '' : css`background-color: #e2c36dc0;`}
    margin: 4px 0 0 4px;

    font-size: 18px;
    font-weight: 600;
    color: #505050;
    border-radius: 5px;
    border: 1px solid #969696;
    box-sizing: border-box;
    &:hover {
        background-color: #e0ff6f55;
        ${props => !props.isActive ? '' : css`background-color: #e2c36dc0;`}
    }
`;
const _Buttons = styled.div`
    display: inline-block;
    /* width: 300px; */
    height: 100%;
    /* background-color: #e0ff6f55; */

    & * {
        margin: 3px 0 0 5px;
    }
`;

const _Button = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    /* width: 300px; */
    width: 150px;
    height: calc(100% - 8px);
    background-color: #cae4e3;
    margin: 4px 0 0 24px;
    text-align: center;

    font-size: 18px;
    font-weight: 600;
    color: #000000cf;
    border-radius: 5px;
    border: 1px solid #969696;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
    &:hover {
        background-color: #dceceb
    }
`;

const _Switch = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    /* width: 300px; */
    width: 220px;
    height: calc(100% - 8px);
    background-color: #e1e4ca;
    margin: 4px 0 0 4px;
    text-align: center;

    font-size: 18px;
    font-weight: 600;
    color: #000000cf;
    border-radius: 5px;
    border: 1px solid #969696;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : _CSS_DISABLE}
    &:hover {
        background-color: #eff3cf
    }
`;

const _SwitchInner = styled.span<{
    isActive: boolean;
}>`
    color: #00109e6e;
    ${props => !props.isActive ? '' : css`color: #db0000;`}
`;

const _Body = styled.div`
    display: inline-block;
    width: 100%;
    height: calc(100% - 35px);
    background-color: #c50000;
    outline: none;
`;

const _Main = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #dddddd;
    vertical-align: top;
`;

