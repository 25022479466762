import styled, { css } from "styled-components";

export namespace SideFormStyles {

    export const ___CSS_FORM = css`
        display: inline-block;
        /* width: 100px; */
        height: 25px;
        background-color: #bdbdbd;
        border: 1px solid #000;
        border-radius: 2px;
        box-sizing: border-box;
        color: #303030;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        text-align: center;
    `;

    export const ___CSS_TITLE = css`
        display: inline-block;
        width: 100%;
        height: 26px;
        /* background-color: #f0f0f0; */
        background: linear-gradient(to bottom, #707070, #b3b3b3, #707070);
        border: 1px solid #222222;
        font-size: 20px;
        color: #ffffff;
        font-weight: 600;
        line-height: 20px;
        box-sizing: border-box;
        /* border: 1px solid #c50000; */
        text-align: center;
    `;

    export const createNumberForm = (value: number, isEnable: boolean,
         onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
         min: number, max:number, step:number) => {

        return (
            <_NumberForm isEnable={isEnable}>
                <input
                    type="number" min={min} max={max} step={step}
                    value={value}
                    onChange={onChange}
                />
                <input
                    type="range" min={min} max={max} step={step}
                    value={value}
                    onChange={onChange}
                />
            </_NumberForm>
        );
    }

    const _NumberForm = styled.div<{
        isEnable: boolean;
    }>`
    display: inline-block;
    width: 100%;
    height: 24px;
    /* margin: 5px 0 0 0; */
    text-align: left;
    ${props => props.isEnable ? '' : css`
        pointer-events: none;
        opacity: 0.5;
    `}
    background-color: #ececec;

    & * {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: top;
    }
    & input:nth-child(1) {
        background-color: #dddddd;
        width: 55px;
        height: calc(100% - 4px);
        margin: 2px 0 0 5px;
        border: 1px solid #9b9b9b;
        border-radius: 4px;
    }
    & input:nth-child(2) {
        margin-left: 5px;
        background-color: #f00;
        width: calc(100% - 70px);
        height: 100%;
    }
    `;
}