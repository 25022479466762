import { useContext, useState, useMemo } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../../entry/systemEntry";

const HarmBackingLibrary = () => {

    const { store,dispatcher } = useContext(GlobalContext);

    const systemState = store.systemState;
    const list = store.systemState.themaList;

    return (
        <_Wrap>
            <_Label>backing-library</_Label>
            <_Combobox
                value={systemState.activeThemaIndex}
                onChange={(e) => {
                    systemState.activeThemaIndex = Number(e.target.value);
                    dispatcher.system.setState(systemState);
                }}
            >{[<option key={-1} value={-1}>{''}</option>].concat(list.map((thema, i) => {
                const name = thema.themaName;
                return <option key={i} value={i}>{name}</option>
            }))}</_Combobox>
        </_Wrap>
    );
}

export default HarmBackingLibrary;

const _Wrap = styled.div`
    display: inline-block;
    height: 100%;
    margin-left: 20px;
    /* background-color: red; */
    & *{
        vertical-align: top;
    }
`;
const _Label = styled.div`
    display: inline-block;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #979797;
    margin-left: 4px;
    font-style: italic;
    line-height: 38px;
    /* vertical-align: top; */
    /* background-color: yellow; */
`;

const _Combobox = styled.select<{
}>`
    display: inline-block;
    width: 320px;
    height: calc(100% - 8px);
    margin: 4px 0 0 8px;
    font-size: 18px;
    /* line-height: 32px; */
    color: #1100d0;
    border: solid 1px #000;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
`;

