import { useContext, useEffect } from "react";
import styled from "styled-components";
import { ExecutableButton } from "../../base/components/button/executableButton";
import { ChannelPane } from "../../base/components/tab/channelPane";
import { TabbedPane } from "../../base/components/tab/tabbedPane";
import { MAIN_FRAME_MARGIN } from "../../utils/systemConst";
import { GlobalContext } from "./systemEntry";
import { AiFillHome } from 'react-icons/ai';
import { CgOptions, CgPiano } from 'react-icons/cg';
import { GiMusicalScore } from 'react-icons/gi';
import { ImPlay } from 'react-icons/im';
import { VscSettingsGear } from 'react-icons/vsc';
import ScoreChannel from "../score/scoreChannel";
import SettingChannel from "../setting/settingChannel";
import PerformChannel from "../perform/performChannel";
import HomeChannel from "../home/homeChannel";
import PreviewChannel from "../preview/previewChannel";
import { HashRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import DataUtil from "../../utils/dataUtil";
import { ScoreData } from "../../redux/store/score/scoreData";
import CacheUtil from "../../utils/score/cacheUtil";
import CustomChannel from "../custom/customChannel";
import DirectView from "./directView";
import DirectLogin from "./directLogin";

const MainFrame = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    return (
        <HashRouter basename={process.env.PUBLIC_URL}>
            <RouterManage />
        </HashRouter>
    );
}

export default MainFrame;

const RouterManage = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const query = new URLSearchParams(useLocation().search);
    const str = query.get('v') as string;

    const isDirect = store.systemState.directScore != null;
    return (
        <Switch>
            <Route path="/" exact>
                <_Wrap>
                    {/* <DraggableDialog /> */}
                    {/* <ExecutableButton labelText={'test'} size={'midium'} /> */}
                    <ChannelPane
                        tabElements={[
                            { name: 'Home', icon: <AiFillHome />, isEnable: !isDirect, cont: <HomeChannel /> },
                            { name: 'Perform', icon: <CgPiano />, isEnable: false, cont: <PerformChannel /> },
                            { name: 'Score', icon: <GiMusicalScore />, isEnable: !isDirect, cont: <ScoreChannel /> },
                            { name: 'Custom', icon: <VscSettingsGear />, isEnable: !isDirect, cont: <CustomChannel /> },
                            { name: 'Setting', icon: <CgOptions />, isEnable: false, cont: <SettingChannel /> },
                            { name: 'Preview', icon: <ImPlay />, isEnable: isDirect, cont: !isDirect ? <></> : <PreviewChannel /> }
                        ]}
                        activeNo={store.systemState.mainChannelIndex}
                        selectTabIndex={(index: number) => dispatcher.system.setMainTabIndex(index)}
                    />
                </_Wrap>
            </Route>
            <Route path="/test" exact >
                <_Wrap>
                </_Wrap>
            </Route>
            <Route path="/preview" exact >
                <DirectView hashKey={str} />
            </Route>
            <Route path="/user" exact >
                <DirectLogin hashKey={str} />
            </Route>
        </Switch>
    );
}



const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    margin: ${MAIN_FRAME_MARGIN}px 0 0 ${MAIN_FRAME_MARGIN}px;
    width: calc(100% - ${MAIN_FRAME_MARGIN * 2}px);
    height: calc(100% - ${MAIN_FRAME_MARGIN * 2}px);
    background-color: transparent;
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;