import { useContext, useEffect, useMemo, useState } from "react";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { BackingPatternProps, ChordBacking } from "../../../../redux/store/score/scoreData";
import BackingUtil from "../../../../utils/backingUtil";
import TheoryUtil from "../../../../utils/theoryUtil";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const PattFormTab = () => {

    const { node, invalidate, setFocusNode, dispatcher, setBackingWnd, setTreeDisable } = useContext(ThemaTreeContext);

    const parentNode = node.parent as TreeUtil.ElementNode;
    const parentData = parentNode.data as ThemaUtil.LevelSust;
    const sustData = node.parent?.data as ThemaUtil.LevelSust;
    const metData = node.parent?.parent?.data as ThemaUtil.LevelMethod;

    const [pattName, setPattName] = useState('');
    // const [key, setKey] = useState('');
    const [refer, setRefer] = useState('');
    const [rootIndex, setRootIndex] = useState(0);
    const [symbolKey, setSymbolKsy] = useState<TheoryUtil.SymbolKey>('major');
    const [source, setSource] = useState<string>('');

    const [errorList, setErrorList] = useState<string[]>([]);

    // ツリー選択時の初期化
    useEffect(() => {
        if (node.data == null) {
            setPattName('');
            // setKey('');
            setRefer('');
            setRootIndex(0);
            setSymbolKsy('major');
            setSource('');
        } else {
            const data = node.data as ThemaUtil.LevelPatt;
            setPattName(data.pattName);
            // setKey(data.key);
            setRefer(data.refer === -1 ? '' : data.refer.toString());
            setRootIndex(0);
            setSymbolKsy('major');
            setSource(JSON.stringify(data.source));
        }
    }, [node]);

    const key = useMemo(() => {

        if (node.data == null) {
            return parentData.patts.map(patt => patt.key).reduce((prev, cur) => {
                return prev < cur ? cur : prev;
            }, -1) + 1;
        } else {
            const data = node.data as ThemaUtil.LevelPatt;
            return data.key;
        }
    }, [node]);

    useEffect(() => {
        const list: string[] = [];
        if (pattName === '') {
            list.push('name is empty.');
        }
        if (source === '') {
            list.push('source is empty.');
        }
        setErrorList(list);
    }, [pattName, key, refer, source]);

    /** ディペンドされているパターンの有無 */
    const hasDepend = useMemo(()=> {
        let result = false;
        if(node.data != null) {
            const data = node.data as ThemaUtil.LevelPatt;
            return parentData.patts.find(patt=> {
                return patt.refer === data.key;
            }) != undefined;
        }
        return result;
    }, [node]);

    /** ディペンドを許可するパターンのキーリスト */
    const permitDependKeys: number[] = useMemo(() => {
        if (source === '') return [];
        const channelSize = (JSON.parse(source) as BackingPatternProps).channelSize;
        return sustData.patts.filter(patt => {
            const useDepend = patt.refer !== -1;
            const matchChannel = patt.source.channelSize === channelSize;
            return patt.key !== key && matchChannel && !useDepend;
        }).map(patt => {
            return patt.key;
        });
    }, [node, source]);

    /** チャンネルサイズを取得 */
    const getChannelSize = () => {
        if (source === '') return '-';
        else {
            const pattern = JSON.parse(source) as BackingPatternProps;
            return pattern.channelSize;
        }
    }

    const getInitialPattern = () => ({
        channelSize: 1,
        layers: [
            {
                divs: [{ rate: 4, div3: 0, isDot: false }],
                notesList: [],
                vels: [5]
            },
            {
                divs: [{ rate: 4, div3: 0, isDot: false }],
                notesList: [],
                vels: [5]
            }
        ]
    });

    /** 子要素の有無 */
    const hasChild = () => {
        if (node.data != null) {
            const data = node.data as ThemaUtil.LevelPatt;
            return data.attrs.length >= 1;
        }
        return false;
    }

    return (<>
        <ThemaEditTabUtil.LabelTextItem
            titleLabel="key" value={key.toString()}
        />
        <ThemaEditTabUtil.InputTextForm titleLabel="name" value={pattName} setValue={(value: string) => {
            setPattName(value);
        }} />
        <ThemaEditTabUtil.BorderPanel
            title="source"
            isEnable={true}
            innerJsx={<>
                <ThemaEditTabUtil.InputComboboxForm titleLabel="root" value={rootIndex.toString()} setValue={(value: string) => {
                    setRootIndex(Number.parseInt(value));
                }} list={TheoryUtil.KEY12_SHARP_LIST.map((key12, idx) => (
                    { value: idx.toString(), labelText: key12 }
                ))} />
                <ThemaEditTabUtil.InputComboboxForm titleLabel="symbol" value={symbolKey} setValue={(value: string) => {
                    setSymbolKsy(value as TheoryUtil.SymbolKey);
                }} list={TheoryUtil.SYMBOL_LIST.map((symbol) => (
                    { value: symbol.key, labelText: `${symbol.key}: [${symbol.name}]` }
                ))} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Edit',
                        isEnable: true, callback: () => {
                            const pattern = source === '' ? getInitialPattern() : JSON.parse(source);
                            const chordBacking: ChordBacking = {
                                pattern,
                                voicingList: []
                            }
                            dispatcher.thema.openBackingEditor(
                                sustData.beat, sustData.head, sustData.tail, symbolKey, rootIndex, chordBacking,
                                (editor: BackingState.Editor) => {
                                    if (editor.pattern != null) {
                                        const chordPattern = BackingUtil.buildPatternProps(editor.pattern);
                                        setSource(JSON.stringify(chordPattern));
                                    }
                                },
                                () => {
                                    setBackingWnd(false);
                                }
                            );
                            setBackingWnd(true);
                        }
                    },
                ]} />
                <ThemaEditTabUtil.TextAreaView value={source} />
                <ThemaEditTabUtil.LabelTextItem
                    titleLabel="channel"
                    value={(() => {
                        return getChannelSize()
                    })().toString()}
                />
            </>}
        />
        <ThemaEditTabUtil.InputComboboxForm
            titleLabel="depend" value={refer}
            // ディペンドされていないかつ、子要素が存在しない場合のみ活性化
            isEnable={!hasDepend && !hasChild()}
            setValue={(value: string) => {
                setRefer(value);
            }} list={[''].concat(permitDependKeys.map(p => p.toString())).map(key => (
                { value: key, labelText: key }
            ))} />
        <ThemaEditTabUtil.BorderPanel
            title="selft operatioon"
            innerJsx={<>
                <ThemaEditTabUtil.TextAreaView value={errorList.join('\n')} height={50} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: errorList.length === 0,
                        callback: () => {
                            if (node.data == null) {
                                const pattData: ThemaUtil.LevelPatt = {
                                    pattName,
                                    source: JSON.parse(source),
                                    key,
                                    refer: refer === '' ? -1 : Number(refer),
                                    attrs: []
                                };
                                node.data = pattData;
                                parentData.patts.push(pattData);
                                setTreeDisable(false);
                            } else {
                                const data = node.data as ThemaUtil.LevelPatt;
                                data.pattName = pattName;
                                data.source = JSON.parse(source);
                                data.key = key;
                                data.refer = refer === '' ? -1 : Number(refer);
                                invalidate();
                            }
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            setFocusNode(parentNode);
                            const selfIndex = TreeUtil.getChildIndex(node);
                            if(node.data != null) {
                                const data = node.data as ThemaUtil.LevelPatt;
                                // 他のパターンからディペンドされていた場合、解除する
                                parentData.patts.forEach(patt=> {
                                    if(patt.refer === data.key) patt.refer = -1;
                                });
                            }
                            parentNode.children.splice(selfIndex, 1);
                            parentData.patts.splice(selfIndex, 1);
                            setTreeDisable(false);
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="children operatioon"
            isEnable={node.data != null && refer === ''}
            innerJsx={<>
                <ThemaEditTabUtil.LabelTextItem titleLabel="Child" value={'Attribute Element'} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: true,
                        callback: () => {
                            // const attrData: ThemaUtil.LevelAttr = {
                            //     attr: 'triad',
                            //     symbols: [],
                            //     roots: []
                            // };
                            // data.attrs.push(attrData);
                            const newNode = TreeUtil.createNode(null, node);
                            node.children.push(newNode);
                            setFocusNode(newNode);
                            setTreeDisable(true);
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true,
                        callback: () => {
                            const data = node.data as ThemaUtil.LevelPatt;
                            node.children = [];
                            data.attrs = [];
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
    </>);
}

export default PattFormTab;
