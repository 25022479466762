import { useContext } from "react";
import styled, { css } from "styled-components";
import { GlobalContext } from "../../../entry/systemEntry";


const HarmSections = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const sections = state.sectionCacheList;

    if (state.focusIndex === -1 || state.focusIndex > state.elementCacheList.length) {
        return <></>;
    }

    const curSectionNo = state.elementCacheList[state.focusIndex].sectionNo;

    const list: JSX.Element[] = [];
    sections.forEach((section, i) => {
        const barSum = Math.floor(section.beatSum / 4);
        const barRem = section.beatSum % 4;
        const isActive = i === curSectionNo;
        list.push(
            <_Item
                key={i}
                isActive={isActive}
                onClick={() => {
                    state.focusIndex = section.rangeIndexStart;
                    state.distIndex = state.focusIndex;
                    dispatcher.score.setScoreState(state);
                }}
            >
                <_Label>{section.labelName}</_Label>
                <_Total>{barSum}{barRem === 0 ? '' : ` (${barRem})`}</_Total>
            </_Item>
        );
    });

    return (<>
        {list}
    </>);
}

export default HarmSections;


const _Label = styled.div``;
const _Total = styled.div``;
const _Item = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    margin: 4px 4px 0 4px;
    width: calc(100% - 8px);
    height: 46px;
    background-color: ${props => props.isActive ? css`
        #97cce0dd;
        box-shadow: 0 0 8px 0 #000b3a48;
    ` : css`
        #c4c4c42b;
    `} ;
    border: 1px solid #929292;
    /* border-radius: 20px 20px 20px 20px; */
    border-radius: 2px;

    text-align: center;

    & ${_Label} {
        display: inline-block;
        width: 100%;
        height: 25px;
        font-size: 18px;
        /* font-weight: 600; */
        color: #4d4d4d;
        /* background-color: #0000003a; */
        line-height: 22px;
    }
    & ${_Total} {
        display: inline-block;
        width: 100%;
        height: 21px;
        font-size: 14px;
        font-weight: 600;
        color: #003eb3;
        /* background-color: #adadad39; */
        line-height: 20px;
    }
`;