import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import TheoryUtil from "../../../../utils/theoryUtil";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const RootFormTab = () => {
    const { node, setFocusNode, invalidate, setTreeDisable } = useContext(ThemaTreeContext);

    const parentNode = node.parent as TreeUtil.ElementNode;
    const parentData = parentNode.data as ThemaUtil.LevelAttr;
    // const data = node.data as ThemaUtil.LevelRoot;

    const [minIdx, setMinIdx] = useState<string>('');
    const [maxIdx, setMaxIdx] = useState<string>('');

    const [errorList, setErrorList] = useState<string[]>([]);

    useEffect(() => {
        if (node.data == null) {
            setMinIdx('');
            setMaxIdx('');
        } else{
            const data = node.data as ThemaUtil.LevelRoot;
            setMinIdx(data.minIdx.toString());
            setMaxIdx(data.maxIdx.toString());
        }
    }, [node]);

    useEffect(() => {
        const list: string[] = [];
        if (minIdx === '' || maxIdx === '') {
            list.push('min or max form is null.');
            // return;
        } else if (Number(minIdx) > Number(maxIdx)) {
            list.push('min is upper than max.');
        }
        setErrorList(list);
    }, [minIdx, maxIdx]);

    return (<>
        <ThemaEditTabUtil.InputComboboxForm titleLabel="minIdx" value={minIdx.toString()} setValue={(value: string) => {
            setMinIdx(value);
        }} list={ThemaEditTabUtil.createBlankCatList(TheoryUtil.KEY12_SHARP_LIST.map((key12, idx) => (
            { value: idx.toString(), labelText: key12 }
        )))} />
        <ThemaEditTabUtil.InputComboboxForm titleLabel="maxIdx" value={maxIdx.toString()} setValue={(value: string) => {
            setMaxIdx(value);
        }} list={ThemaEditTabUtil.createBlankCatList(TheoryUtil.KEY12_SHARP_LIST.map((key12, idx) => (
            { value: idx.toString(), labelText: key12 }
        )))} />
        <ThemaEditTabUtil.BorderPanel
            title="selft operatioon"
            innerJsx={<>
                <ThemaEditTabUtil.TextAreaView value={errorList.join('\n')} height={50} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: errorList.length === 0,
                        callback: () => {
                            if (node.data == null) {
                                const rootData: ThemaUtil.LevelRoot = {
                                    minIdx: Number(minIdx),
                                    maxIdx: Number(maxIdx),
                                    voics: []
                                };
                                node.data = rootData;
                                parentData.roots.push(rootData);
                                setTreeDisable(false);
                            } else {
                                const data = node.data as ThemaUtil.LevelRoot;
                                data.minIdx = Number(minIdx);
                                data.maxIdx = Number(maxIdx);
                                invalidate();
                            }
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            setFocusNode(parentNode);
                            const selfIndex = TreeUtil.getChildIndex(node);
                            parentNode.children.splice(selfIndex, 1);
                            parentData.roots.splice(selfIndex, 1);
                            setTreeDisable(false);
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="children operatioon"
            isEnable={node.data != null}
            innerJsx={<>
                <ThemaEditTabUtil.LabelTextItem titleLabel="Child" value={'Voicing Element'} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: true,
                        callback: () => {
                            // const voicData: ThemaUtil.LevelVoic = {
                            //     source: [],
                            //     presets: []
                            // };
                            // data.voics.push(voicData);
                            const newNode = TreeUtil.createNode(null, node);
                            node.children.push(newNode);
                            setFocusNode(newNode);
                            setTreeDisable(true);
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true,
                        callback: () => {
                            const data = node.data as ThemaUtil.LevelRoot;
                            node.children = [];
                            data.voics = [];
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
    </>);
}

export default RootFormTab;
