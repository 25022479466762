import { useAuth0 } from "@auth0/auth0-react";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Styles, { _CSS_LABEL_MIDIUM } from "../../base/design/styles";
import DataUtil from "../../utils/dataUtil";
import { HOME_USER_DIV_HEIGHT } from "../../utils/systemConst";
import { GlobalContext } from "../entry/systemEntry";
import ReleaseNote from "./releaseNote";

type scoreProps = {
    dispnam: string;
    seq: number;
    owner: string;
    lastmod: string;
}

const HomeChannel = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const [userId, setUserId] = useState('-');
    const [userCount, setUserCount] = useState(0);

    const [scoreList, setScoreList] = useState<scoreProps[]>([]);

    const loginProps = store.userEnv.login;

    useEffect(() => {
        if (store.systemState.directScore == null) {
            countUsers().then(cnt => {
                setUserCount(24 + cnt);
                findAllScore().then((resList) => {
                    setScoreList(resList as scoreProps[]);
                });
            });
        }
    }, []);

    useEffect(() => {
        if (!loginProps) {
            setUserId('');
        } else {
            setUserId(loginProps.id);
        }
    }, [loginProps]);

    const scoreListJsx = scoreList.map((score, i) => {
        return (
            <_CloudItem key={i} onClick={() => {
                window.open(DataUtil.getPreviewURL(score.seq), '_blank');
            }}>
                <_Owner>{`${score.owner}`}</_Owner>
                <_CName>{`${score.dispnam}`}</_CName><_CDate>{score.lastmod}</_CDate>
            </_CloudItem>
        );
    });

    return (<_Wrap>
        <_Left>
            <_MessageRecord>{userCount} users have created accounts.</_MessageRecord>
            <ReleaseNote />
        </_Left>
        <_Right>
            <_MessageRecord>{scoreList.length} licresia scores have been published.</_MessageRecord>
            <_ListFrame>{scoreListJsx}</_ListFrame>
        </_Right>
    </_Wrap>);
}

export default HomeChannel;

export const countUsers = async () => {
    const response = await DataUtil.sendQueryRequestToAPI('select', `SELECT count(*) cnt FROM user_tbl`);
    const results = await response.json();
    return (results as any[])[0]['cnt'] as number;
};

export const findAllScore = async () => {
    const response = await DataUtil.sendQueryRequestToAPI('select', `
        SELECT seq, (select id from user_tbl where seq = score.user_seq) as owner, dispnam, lastmod
        FROM scoretbl score
        WHERE share <> 'url'
        ORDER BY lastmod desc
    `);
    const results = await response.json();
    return results as any[];
};

const _Wrap = styled.div`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    margin: 4px 0 0 4px;
    background-color: #ccc;
`;

const _Left = styled.div`
    display: inline-block;
    width: 50%;
    height: 100%;
    background-color: #bbcac7;
`;


const _MessageRecord = styled.div`
    display: inline-block;
    width: 100%;
    height: 40px;
    font-size: 22px;
    font-weight: 600;
    color: #cd0202;
    background-color: #ffffff3d;
    padding: 0 0 0 10px;
    box-sizing: border-box;
`;

const _ListInfoDiv = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: ${HOME_USER_DIV_HEIGHT}px;
    margin: 4px 0 4px 4px;
    background-color: #acacac;
    border: solid 1px #1b00b3;
    ${props => props.isEnable ? '' : css`
        opacity: 0.3;
        pointer-events: none;
    `}
    border-radius: 4px;
    box-sizing: border-box;
    color: #060053;
`;

const _TitleLabel = styled.div`
    display: inline-block;
    height: 40px;
    font-size: 30px;
    font-weight: 600;
    color: #1c2c95;
    /* background-color: #3f80cf57; */
    padding: 0 10px;
`;
const _Recored = styled.div`
    display: inline-block;
    width: 100%;
    height: 35px;
    /* background-color: #3fcf7b56; */
    padding: 4px 0 0 10px;
`;

const _ItemnLabel = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: 140px;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    color: #08106e;
    border: solid 1px #1b00b3;
    box-sizing: border-box;
    /* background-color: #85a2c5; */
    background: linear-gradient(to bottom, #8a98a7, #a3bbd2, #8a98a7);
    border-radius: 4px;
    text-align: center;
`;

const _ItemnValue = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 30px;
    user-select: text;
    /* font-weight: 600; */
    color: #ffffff;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #808080;
    text-align: left;
`;

const _ItemnForm = styled.input`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 170px);
    margin-left: 10px;
    font-size: 24px;
    line-height: 24px;
    /* font-weight: 600; */
    color: #1a1a1a;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #e9e9e9;
    text-align: left;
`;

const _ButtonsDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 40px;
    /* background-color: #888888; */
    box-sizing: border-box;
    text-align: left;
`;

const _Button = styled.div<{
    isEnable: boolean;
}>`
    ${_CSS_LABEL_MIDIUM}
    /* width: 160px; */
    padding: 0 40px;
    /* line-height: 25px; */
    box-sizing: border-box;
    background-color: #a8a8a8;
    background: linear-gradient(to bottom, #787878, #b4b4b4, #787878);
    border: 1px solid #1a2b3f;
    color: #1a2b3f;
    border-radius: 4px;
    margin: 5px 0 0 4px;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    &:hover {
        /* background-color: #98bfc2; */
    background: linear-gradient(to bottom, #989898, #b4b4b4, #989898);
    }
`;



const _Right = styled.div`
    display: inline-block;
    width: 50%;
    height: 100%;
    background-color: #bbbfca;
    vertical-align: top;
`;
const _ListFrame = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - 42px);
    margin: 4px 0 0 4px;
    background-color: #d3d3c5;
    border: 1px solid #e60000;
    border-radius: 4px;
    box-sizing: border-box;
    overflow-y: auto;
`;
const _CloudItem = styled.div<{
}>`
    display: inline-block;
    position: relative;
    width: 100%;
    /* height: 60px; */
    border-bottom: 1px solid #888888;
    /* background-color: white; */
    background: linear-gradient(to bottom, transparent, #b6b6b684);
    margin: 2px 0 0 0;
    padding: 0 0 0 4px;
    box-sizing: border-box;
    /* padding-left: 4px; */
    &:hover {
        background: linear-gradient(to bottom, transparent, #ffffff5c);
    }
`;

const _Owner = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 20px;
    line-height: 21px;
    font-size: 18px;
    font-weight: 600;
    color: #c9494f;
    overflow: hidden;
    white-space: nowrap;
`;
const _CName = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - 250px);
    height: 35px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #47417c;
    /* background-color: #f8f8e8; */
    overflow: hidden;
    white-space: nowrap;
`;
const _CDate = styled.div<{
}>`
    display: inline-block;
    width: 250px;
    height: 35px;
    font-size: 24px;
    line-height: 36px;
    font-weight: 600;
    color: #417c49;
    /* background-color: #f8f8e8; */
    overflow: hidden;
    white-space: nowrap;
`;