import SystemConst from "./systemConst";

namespace CanvasUtil {

    /**
     * Canvasに任意の描画をし、画像のURLを取得する
     * @param width 幅
     * @param height 高さ
     * @param draw 描画
     * @returns 画像のURL
     */
    export const createURL = (width: number, height: number, draw: (ctx: CanvasRenderingContext2D) => void) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
        canvas.width = width;
        canvas.height = height;
        draw(ctx);
        return canvas.toDataURL();
    }

    export const getLogoURL = () => {
        return CanvasUtil.createURL(350, 80, (ctx) => {
            // ctx.fillStyle = '#ffffff';
            // ctx.fillRect(0, 0, 350, 80);

            ctx.shadowColor = "#555";
            ctx.shadowOffsetX = 2;
            ctx.shadowOffsetY = 1;
            ctx.shadowBlur = 5;
            ctx.font = '55pt Arial';
            ctx.fillStyle = '#db0000';
            ctx.fillText('L', 30, 70);
            const adjust = ctx.measureText('L').width;
            ctx.fillStyle = '#ffffff';
            ctx.fillText('iCreSia', 30 + adjust, 70);
            ctx.save();
        });
    }

    export const getAuthTextURL = (text: string) => {
        const WIDTH = SystemConst.AUTH_TEXT_WIDTH;
        const SIZE = SystemConst.AUTH_TEXT_WIDTH / 6;
        return createURL(WIDTH, SystemConst.AUTH_TEXT_HEIGHT, (ctx) => {
            ctx.rotate(1 * Math.PI / 180);
            for (let i = 0; i < text.length; i++) {
                const char = text.charAt(i);

                const fontRand = Math.random() * 60;
                const alphaRand = Math.random() * 60;
                ctx.font = `${20 + fontRand}pt Arial`;
                ctx.fillStyle = "rgba(" + [40, 0, 100, 0.05 + 0.01 * alphaRand] + ")";
                ctx.fillText(char, i * SIZE, 60);
            }
        });
    }
}

export default CanvasUtil;