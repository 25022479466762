import { useContext } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { NOTES_1BAR_WIDTH, PATTERN_BODY_HEIGHT, PATTERN_FOOTER_HEIGHT, PATTERN_HEADER_HEIGHT } from "../../../../utils/systemConst";
import TheoryUtil from "../../../../utils/theoryUtil";
import { GlobalContext } from "../../../entry/systemEntry";

const PatternLength = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const chordInfo = state.chordInfo;

    const itemJsxList: JSX.Element[] = [];
    const pattern = state.pattern as BackingState.Pattern;
    const layer = pattern.layers[pattern.layerIndex];
    const update = () => dispatcher.backing.setState(state);
    
    const beatProps = TheoryUtil.getBeatProps(chordInfo.init.beatSignature);
    const adjustRate = beatProps.beatMemoriCount === 4 ? 1 : (3/2);

    layer.noteDivList.forEach((note, i) => {
        const isActive = pattern.lenIndex === i;
        const isEdit = isActive && pattern.channelIndex !== -1;
        let rate = note.rate;
        if (note.isDot) rate /= 1.5;
        if (note.div3 !== 0) rate /= note.div3 / 3;
        itemJsxList.push(
            <_Item key={i} rate={rate * adjustRate} isActive={isActive} isEdit={isEdit}
                onClick={() => {
                    pattern.channelIndex = -1;
                    pattern.lenIndex = i;
                    update();
                }}
            >
                {note.rate}{!note.isDot ? '' : '.'}{note.div3 === 0 ? '' : '/'}
            </_Item>
        );
    });

    return (
        <_Wrap>
            {itemJsxList}
        </_Wrap>
    );
}

export default PatternLength;

const _Wrap = styled.div<{
}>`
    display: inline-block;
    width: 100%;
    height: 100%;
    white-space: nowrap;
`;


const _Item = styled.div<{
    rate: number;
    isActive: boolean;
    isEdit: boolean;
}>`
    display: inline-block;
    width: ${props => NOTES_1BAR_WIDTH / props.rate - 1}px;
    height: calc(100% - 4px);
    margin: 2px 0 0 1px;
    background-color: #1616beb0;
    ${props => !props.isActive ? '' : css`
        background-color: #6363eeaf;
    `}
    ${props => !props.isEdit ? '' : css`
        background-color: #b40000af;
    `}
    box-sizing: border-box;

    font-size: 14px;
    font-weight: 600;
    color: #ffffffba;
    text-align: center;
`;

