import { Sampler } from "tone";
import { ChordBacking, DetailChord, VoicingProps } from "../redux/store/score/scoreData";
import Soundfont from 'soundfont-player';
import TheoryUtil from "./theoryUtil";

// export const getDefaultSampler = () => {
//     return new Sampler({
//         "C2": "https://dl.dropbox.com/s/9v1e4vb2y49kvxe/KEPSREC036.mp3?dl=0",
//         "C3": "https://dl.dropbox.com/s/qjkoqqdkqwtnctj/KEPSREC048.mp3?dl=0",
//         "C4": "https://dl.dropbox.com/s/9fgqyu7hsj8fncc/KEPSREC060.mp3?dl=0",
//         "C5": "https://dl.dropbox.com/s/0v042zfk67q4a8d/KEPSREC072.mp3?dl=0",
//         "C6": "https://dl.dropbox.com/s/3u2c7qsmhnse575/KEPSREC084.mp3?dl=0",
//     }).toDestination();
// }
export const getDefaultSampler = () => {
    return new Sampler({
        "C2": "https://dl.dropbox.com/s/ebta7izw1zdphki/KEPSREC036.mp3?dl=0",
        "D2": "https://dl.dropbox.com/s/d8bel1c9d1utk25/KEPSREC038.mp3?dl=0",
        "E2": "https://dl.dropbox.com/s/qe48401jgb2cewo/KEPSREC040.mp3?dl=0",
        "F2": "https://dl.dropbox.com/s/3s7sc8btjob8gru/KEPSREC041.mp3?dl=0",
        "G2": "https://dl.dropbox.com/s/zzxvr0p4j37n9i0/KEPSREC043.mp3?dl=0",
        "A2": "https://dl.dropbox.com/s/vcrs90e06djj7sx/KEPSREC045.mp3?dl=0",
        "B2": "https://dl.dropbox.com/s/yvkrz0ngkijjngq/KEPSREC047.mp3?dl=0",
        "C3": "https://dl.dropbox.com/s/qjkoqqdkqwtnctj/KEPSREC048.mp3?dl=0",
        "D3": "https://dl.dropbox.com/s/jjyithbiwmxfn5m/KEPSREC050.mp3?dl=0",
        "E3": "https://dl.dropbox.com/s/2s4p06g2gw2lcf7/KEPSREC052.mp3?dl=0",
        "F3": "https://dl.dropbox.com/s/7mo0mm2bbc3im60/KEPSREC053.mp3?dl=0",
        "G3": "https://dl.dropbox.com/s/qqrrlwogz0orihq/KEPSREC055.mp3?dl=0",
        "A3": "https://dl.dropbox.com/s/o6oycmolcn7rhbs/KEPSREC057.mp3?dl=0",
        "B3": "https://dl.dropbox.com/s/3s05r18hsd19t6e/KEPSREC059.mp3?dl=0",
        "C4": "https://dl.dropbox.com/s/9fgqyu7hsj8fncc/KEPSREC060.mp3?dl=0",
        "D4": "https://dl.dropbox.com/s/ja1d04dgy9v98x9/KEPSREC062.mp3?dl=0",
        "E4": "https://dl.dropbox.com/s/y2379pix35gto7u/KEPSREC064.mp3?dl=0",
        "F4": "https://dl.dropbox.com/s/h1sq8yikmdfvzqc/KEPSREC065.mp3?dl=0",
        "G4": "https://dl.dropbox.com/s/hvzvcrm1emfum0d/KEPSREC067.mp3?dl=0",
        "A4": "https://dl.dropbox.com/s/vy56xbt7w78o41o/KEPSREC069.mp3?dl=0",
        "B4": "https://dl.dropbox.com/s/e9kv77pym1wtujc/KEPSREC071.mp3?dl=0",
        "C5": "https://dl.dropbox.com/s/0v042zfk67q4a8d/KEPSREC072.mp3?dl=0",
        "C6": "https://dl.dropbox.com/s/3u2c7qsmhnse575/KEPSREC084.mp3?dl=0",
    }).toDestination();
}


export const playChordSampler = (sampler: Sampler, detail: DetailChord, bpm: number) => {

    sampler.releaseAll();
    if (detail.root == null) return;

    const rootIndex = detail.root.index;
    const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;

    const channelList = detail.backing.voicingList.map((item) => {
        const interval = TheoryUtil.IntervalRelationProps[symbol.structs[item.struct]];
        return 12 * item.octave + rootIndex + interval;
    });

    channelList.forEach((index, i) => {
        const soundName = TheoryUtil.KEY12_SHARP_LIST[index % 12];
        const octave = Math.floor(index / 12);
        const soundFullName = soundName + octave;

        sampler.triggerAttack(soundFullName, '+0', 0.5);
    });

    setTimeout(() => {
        // sampler.triggerRelease('C3');
        sampler.releaseAll();
    }, 2000);
}

export const playChordSoundfont = (soundfont: Soundfont.Player, detail: DetailChord) => {

    soundfont.stop();
    if (detail.root == null) return;

    const rootIndex = detail.root.index;
    const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;

    const channelList = detail.backing.voicingList.map((item) => {
        const interval = TheoryUtil.IntervalRelationProps[symbol.structs[item.struct]];
        return 12 * item.octave + rootIndex + interval;
    });

    channelList.forEach((index) => {
        const soundName = TheoryUtil.KEY12_SHARP_LIST[index % 12];
        const octave = Math.floor(index / 12);
        const soundFullName = soundName + octave;

        soundfont.play(soundFullName, 0, { duration: 1 });
    });
}


export const getDefaultBacking = (attr: TheoryUtil.SymbolAttribute) => {
    let voicingList: VoicingProps[] = [];
    switch (attr) {
        case 'triad': voicingList = [
            { struct: 0, octave: 3 },
            { struct: 1, octave: 3 },
            { struct: 2, octave: 3 },
            // { struct: 0, octave: 3 },
            // { struct: 2, octave: 3 },
            // { struct: 0, octave: 4 },
            // { struct: 1, octave: 4 },
            // { struct: 2, octave: 4 },
            // { struct: 0, octave: 5 },
        ]; break;
        case 'seventh': voicingList = [
            { struct: 0, octave: 3 },
            { struct: 1, octave: 3 },
            { struct: 2, octave: 3 },
            { struct: 3, octave: 3 },
            // { struct: 0, octave: 3 },
            // { struct: 2, octave: 3 },
            // { struct: 0, octave: 4 },
            // { struct: 1, octave: 4 },
            // { struct: 2, octave: 4 },
            // { struct: 3, octave: 4 },
        ]; break;
        case 'add9th': voicingList = [
            { struct: 0, octave: 3 },
            { struct: 1, octave: 3 },
            { struct: 2, octave: 3 },
            { struct: 3, octave: 3 },
            // { struct: 0, octave: 3 },
            // { struct: 2, octave: 3 },
            // { struct: 3, octave: 3 },
            // { struct: 0, octave: 4 },
            // { struct: 1, octave: 4 },
            // { struct: 3, octave: 4 },
        ]; break;
    }
    const backing: ChordBacking = {
        voicingList,
        pattern: null,
        // hasError: false
    };
    return backing;
}