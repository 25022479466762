import { ChordElement, DetailChord, DetailInit, MelodyLength, ScoreData } from "../redux/store/score/scoreData";
import { Store } from "../redux/store/store";
import TheoryUtil from "./theoryUtil";
import { Dispatcher } from "../redux/dispatcher/dispatcher";
import BackingUtil from "./backingUtil";
import CacheUtil from "./score/cacheUtil";

export const PITCH_MAX = 60;

export const getKeyIndexFromPitcher = (index: number) => {
    return (PITCH_MAX - 1 - index + 9) % 12;
}

export const getNormalizeLenFromNotes = (notes: MelodyLength) => {
    let total = 0;
    total += (notes.len4 ?? 0);
    total += (notes.len8 ?? 0) / 2;
    total += (notes.len16 ?? 0) / 4;
    total += (notes.len32 ?? 0) / 8;
    total += (notes.len4div3 ?? 0) / 3;
    total += (notes.len8div3 ?? 0) / 6;
    return total;
}

export const getAdditionNotsLen = (notes1: MelodyLength, notes2: MelodyLength) => {
    const ml: MelodyLength = {};
    const len4 = (notes1.len4 ?? 0) + (notes2.len4 ?? 0);
    if (len4 > 0) ml.len4 = len4;
    const len8 = (notes1.len8 ?? 0) + (notes2.len8 ?? 0);
    if (len8 > 0) ml.len8 = len8;
    const len16 = (notes1.len16 ?? 0) + (notes2.len16 ?? 0);
    if (len16 > 0) ml.len16 = len16;
    const len32 = (notes1.len32 ?? 0) + (notes2.len32 ?? 0);
    if (len32 > 0) ml.len32 = len32;
    const len4div3 = (notes1.len4div3 ?? 0) + (notes2.len4div3 ?? 0);
    if (len4div3 > 0) ml.len4 = len4div3;
    const len8div3 = (notes1.len8div3 ?? 0) + (notes2.len8div3 ?? 0);
    if (len8div3 > 0) ml.len8div3 = len8div3;
    return getSortetdNotesLen(ml);
}

const getSortetdNotesLen = (base: MelodyLength) => {
    let len4 = base.len4 ?? 0;
    let len8 = base.len8 ?? 0;
    let len16 = base.len16 ?? 0;
    let len32 = base.len32 ?? 0;
    let len4div3 = base.len4div3 ?? 0;
    let len8div3 = base.len8div3 ?? 0;

    const ml: MelodyLength = {};

    const len32Cnt2 = Math.floor(len32 / 2);
    len32 -= len32Cnt2 * 2;
    len16 += len32Cnt2;

    const len8div3Cnt3 = Math.floor(len8div3 / 3);
    len8div3 -= len8div3Cnt3 * 3;
    len8 += len8div3Cnt3;

    const len8div3Cnt2 = Math.floor(len8div3 / 2);
    len8div3 -= len8div3Cnt2 * 2;
    len4div3 += len8div3Cnt2;

    const len4div3Cnt3 = Math.floor(len4div3 / 3);
    len4div3 -= len4div3Cnt3 * 3;
    len4 += len4div3Cnt3;

    const len16Cnt2 = Math.floor(len16 / 2);
    len16 -= len16Cnt2 * 2;
    len8 += len16Cnt2;

    const len8Cnt2 = Math.floor(len8 / 2);
    len8 -= len8Cnt2 * 2;
    len4 += len8Cnt2;

    if (len4 > 0) ml.len4 = len4;
    if (len8 > 0) ml.len8 = len8;
    if (len16 > 0) ml.len16 = len16;
    if (len32 > 0) ml.len32 = len32;
    if (len4div3 > 0) ml.len4div3 = len4div3;
    if (len8div3 > 0) ml.len8div3 = len8div3;

    return ml;
}

export const getNotesLenFromQuantize = (quantize: number, count: number): MelodyLength => {

    let len4 = 0;
    let len8 = 0;
    let len16 = 0;
    let len32 = 0;
    let len4div3 = 0;
    let len8div3 = 0;

    switch (quantize) {
        case 1: len4 = count; break;
        case 2: len8 = count; break;
        case 3: len4div3 = count; break;
        case 4: len16 = count; break;
        case 8: len32 = count; break;
        case 6: len8div3 = count; break;
    }

    const ml: MelodyLength = {};
    if (len4 > 0) ml.len4 = len4;
    if (len8 > 0) ml.len8 = len8;
    if (len16 > 0) ml.len16 = len16;
    if (len32 > 0) ml.len32 = len32;
    if (len4div3 > 0) ml.len4div3 = len4div3;
    if (len8div3 > 0) ml.len8div3 = len8div3;
    return getSortetdNotesLen(ml);
}
// export const getNotesLenFromNormal = (normal: number): MelodyLength => {
//     // let normal = a;
//     let len4 = 0;
//     let len8 = 0;
//     let len16 = 0;
//     let len4div3 = 0;
//     let len8div3 = 0;

//     normal *= 12;

//     len4 = Math.floor(normal / 12);
//     normal -= len4 * 12;
//     if (normal - Math.floor(normal / 12 * 3) !== 0) {
//         len8 = Math.floor(normal / 12 * 2);
//         normal -= len8 * 12 / 2;
//     }
//     len4div3 = Math.floor(normal / 12 * 3);
//     normal -= len4div3 * 12 / 3;
//     if (normal - Math.floor(normal / 12 * 6) !== 0) {
//         len16 = Math.floor(normal / 12 * 4);
//         normal -= len16 * 12 / 4;
//     }
//     len8div3 = Math.floor(normal / 12 * 6);
//     return {
//         len4, len8, len16, len4div3, len8div3
//     };
// }

export const getMinimumLenUnit = (notes: MelodyLength): number => {

    if ((notes.len32 ?? 0) > 0) return 8;
    else if ((notes.len8div3 ?? 0) > 0) return 6;
    else if ((notes.len16 ?? 0) > 0) return 4;
    else if ((notes.len4div3 ?? 0) > 0) return 3;
    else if ((notes.len8 ?? 0) > 0) return 2;
    else if ((notes.len4 ?? 0) > 0) return 1;
    else return 0;
}

export const playSoundfont = (i: number, store: Store) => {
    const keyIndex = ((59 - i) + 9) % 12;
    const octave = Math.floor(((59 - i) + 9) / 12) + 1;
    const soundName = TheoryUtil.KEY12_SHARP_LIST[keyIndex % 12];
    const instrument = store.instruments.melodyFont;
    if (instrument != null) {
        instrument.stop();
        instrument.play(soundName + octave, 0, { duration: 0.5 });
    }
}

export const playHarmony = (soundFullName: string, store: Store, duration: number, gain: number) => {
    const instrument = store.instruments.harmonyFont;
    if (instrument != null) {
        instrument.play(soundFullName, 0, { gain, duration });
    } else {
        // alert('null');
    }
}

export const stopPiano = (store: Store) => {
    const instrument = store.instruments.harmonyFont;
    if (instrument != null) {
        instrument.stop();
    }
}

export const searchCurChord = (store: Store, focusIndex: number) => {
    const state = store.melodyState;
    const chordList = store.scoreData.chordList;
    const x = getNormalizeLenFromNotes(store.scoreData.notesList[focusIndex].pos) * state.beatWidth;
    let left = 0;
    const searchedChord = chordList.find((element) => {
        if (element.type === 'chord') {
            const detail = element.detail as DetailChord;
            const width = detail.beatLen * state.beatWidth;
            if (x >= left && x < left + width) {
                return element;
            }
            left += width;
        }
    });
    return searchedChord == undefined ? null : searchedChord;
}

namespace MelodyUtil {

    export const stopPreview = (store: Store, dispatcher: Dispatcher, reserveTasks: NodeJS.Timeout[]) => {

        const state = store.melodyState;
        state.isPreview = false;
        // state.focusIndex = state.lastFocus;
        // state.distIndex = state.focusIndex;
        reserveTasks.forEach(id => clearInterval(id));
        // store.instruments.sampler.releaseAll();
        dispatcher.melody.setState(state);
        dispatcher.melody.adjustScrollX();
    }

    /**
     * プレビュー再生を開始する
     * @param store ストア
     * @param dispatcher ディスパッチャー
     */
    export const previewStart = (store: Store, dispatcher: Dispatcher) => {

        const state = store.melodyState;
        const scoreState = store.scoreState;
        const data = store.scoreData;
        const reserveTasks: NodeJS.Timeout[] = [];
        const instrument = store.instruments.melodyFont;

        // 開始するフォーカス（非選択時は最初から）
        const start = state.focusIndex === -1 ? 0 : state.focusIndex;

        // プレビュー開始時のフォーカスを保持する
        state.lastFocus = state.focusIndex;
        state.isPreview = true;
        const update = store.invalidate.melody as () => void;
        let firstTime = -1;
        for (let i = start; i < data.notesList.length; i++) {
            const notes = data.notesList[i];
            // const beatProps = TheoryUtil.getBeatProps(init.beatSignature);
            // const adjustRate = 4 / beatProps.beatMemoriCount;
            // const pos = getNormalizeLenFromNotes(notes.pos) * adjustRate;
            // const len = getNormalizeLenFromNotes(notes.len) * adjustRate;
            const pos = getNormalizeLenFromNotes(notes.pos);
            const len = getNormalizeLenFromNotes(notes.len);
            const [posTime, lenTime] = CacheUtil.getTimeInfoFromNotesPos(pos, len, scoreState.tempoCacheList);
            const pitch = notes.pitchIndex;
            // const time = 60 / bpm * pos;

            // 開始時のノーツの位置（時間）を保存する。
            if (firstTime === -1) {
                // ノーツ非選択時は先頭から流す
                if (state.focusIndex === -1) {
                    firstTime = 0;
                } else {
                    firstTime = posTime;
                }
            }

            const keyIndex = getKeyIndexFromPitcher(pitch);
            const soundName = TheoryUtil.KEY12_SHARP_LIST[keyIndex % 12];
            const octave = Math.floor(((59 - pitch) + 9) / 12) + 1;

            reserveTasks.push(
                setTimeout(() => {
                    // state.mouseState.curChord = searchCurChord(store, i);

                    // dispatcher.tune.setState(state);
                    if (instrument != null) {
                        instrument.stop();
                        instrument.play(soundName + octave, 0, { duration: lenTime });
                    }
                    setTimeout(() => {
                        state.focusIndex = i;
                        state.destIndex = state.focusIndex;
                        update();
                        dispatcher.melody.adjustScrollX();
                        // dispatcher.melody.setState(state);
                    }, 0);
                }, (posTime - firstTime) * 1000)
            );

            // 終端ノーツの処理
            if (i === data.notesList.length - 1) {
                reserveTasks.push(
                    setTimeout(() => {
                        stopPreview(store, dispatcher, reserveTasks);
                    }, (posTime - firstTime + lenTime) * 1000)
                );
            }
        }

        // ハーモニーを再生
        if (!state.isSolo) {
            previewHarmony(firstTime * 1000, store, dispatcher, reserveTasks);
        }


        dispatcher.system.setReserveTasks(reserveTasks);
        dispatcher.melody.setState(state);
    }

    const previewHarmony = (start: number, store: Store, dispatcher: Dispatcher, reserveTasks: NodeJS.Timeout[]) => {
        const state = store.scoreState;
        const update = store.invalidate.melody as () => void;
        // const sampler = store.instruments.sampler;
        const cacheList = state.elementCacheList;
        const chordList = store.scoreData.chordList;
        // const reserveTasks = store.reserveTasks;
        // const init = chordList[0].detail as DetailInit;
        const adjsut = 0;
        for (let i = 3; i < chordList.length; i++) {
            const cache = cacheList[i];
            const chordCache = store.scoreState.chordCacheList[cache.chordBlockNo];
            const element = chordList[i];
            if (element.type === 'chord') {
                if (cache.pastSecond < start) continue;

                const detail = element.detail as DetailChord;

                if (detail.root == null) return;

                // const rootIndex = (detail.root.index + cache.keyIndex) % 12;
                // const onIndex = detail.on == null ? -1 : ((detail.on.index + cache.keyIndex) % 12);
                // const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey);

                const backing = detail.backing;
                const pitchList = BackingUtil.getPitchListFromVoicing(backing.voicingList, chordCache.structList);

                // const chordTime = 60 / cache.bpm * len;
                const minute = detail.minute.head + detail.minute.tail;
                const chordTime = TheoryUtil.calcSustainMsec(
                    detail.beatLen,
                    minute,
                    cache.bpm,
                    cache.beatSignature
                );
                reserveTasks.push(
                    setTimeout(() => {
                        // sampler.releaseAll();
                        // stopPiano(store);

                        // const pickingDelay = 60 / init.bpm * 0.1 * 1000;
                        // const div = pickingDelay / pitchList.length;

                        // pitchList.forEach((index, j) => {
                        //     const soundName = KEY12_SHARP_LIST[index % 12];
                        //     const octave = Math.floor(index / 12);
                        //     const soundFullName = soundName + octave;
                        //     playPiano(soundFullName, store, chordTime);
                        // });

                        BackingUtil.playBacking(store, reserveTasks, cache, backing, pitchList, chordTime);

                        setTimeout(() => {
                            store.melodyState.tableMouse.curChord = element;
                            update();
                            // dispatcher.melody.setState(store.melodyState);
                        }, 0);
                    }, cache.pastSecond - start - adjsut)
                );
            }
        }
    }

    export const previewCurHarmony = (store: Store, dispatcher: Dispatcher) => {

        const state = store.melodyState;
        const chordList = store.scoreData.chordList;
        const cacheList = store.scoreState.elementCacheList;
        const cache = cacheList[3];
        const chordCache = store.scoreState.chordCacheList[cache.chordBlockNo];
        const init = chordList[0].detail as DetailInit;
        const curChord = state.tableMouse.curChord;
        const reserveTasks = store.reserveTasks;
        if (curChord != null) {

            const detail = curChord.detail as DetailChord;

            if (detail.root == null) return;

            // const rootIndex = (detail.root.index + init.keyIndex) % 12;
            // const onIndex = detail.on == null ? -1 : ((detail.on.index + init.keyIndex) % 12);
            // const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey);

            const backing = detail.backing;
            const pitchList = BackingUtil.getPitchListFromVoicing(backing.voicingList, chordCache.structList);
            // const chordTime = 60 / init.bpm * len;
            const minute = detail.minute.head + detail.minute.tail;
            const chordTime = TheoryUtil.calcSustainMsec(
                detail.beatLen,
                minute,
                init.bpm,
                init.beatSignature
            );

            BackingUtil.playBacking(store, reserveTasks, init, backing, pitchList, chordTime);

            reserveTasks.push(setTimeout(() => {
                state.isPreview = false;
                dispatcher.melody.setState(state);
            }, chordTime));
            state.isPreview = true;
            dispatcher.melody.setState(state);

        }
    }

    export type Quantize = 32 | 16| 8 | 4;

    export const isEnableQuantize = (quantize: Quantize, notes: MelodyLength) => {

    }
}

export default MelodyUtil;