import { useContext } from "react";
import { Stream } from "stream";
import styled, { css } from "styled-components";
import { DetailChord } from "../../../../redux/store/score/scoreData";
import LibraryUtil from "../../../../utils/LibraryUtil";
import BackingEditorFrame from "../../../backing/backingEditorFrame";
import { GlobalContext } from "../../../entry/systemEntry";
import PresetSelectorFrame from "../../../thema/presetSelectorFrame";
import ThemaSelectorFrame from "../../../thema/themaSelectorFrame";


const HarmHideFrame = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const isOpen = state.hideMenu !== 'none';

    const enableState = LibraryUtil.getEnableState(store);

    return (<>
        <_Blind isOpen={isOpen} onClick={() => {
            dispatcher.backing.closeBackingEditor();
        }} />
        <_Wrap>
            <_Contents isOpen={isOpen}>
                {(() => {
                    switch (state.hideMenu) {
                        case 'none': return <></>;
                        case 'backing': return <BackingEditorFrame />
                        case 'library': return <ThemaSelectorFrame.Component />
                        case 'preset': return <PresetSelectorFrame.Component />
                    }
                })()}
                {/* {store.backingState == null ? <></> : <BackingEditorFrame />} */}
            </_Contents>
            <_LabelFrame>
                <_Label
                    isEnable={enableState.isChordElement}
                    isVisible={['backing', 'none'].includes(state.hideMenu)}
                    onClick={() => {
                        if (state.hideMenu === 'none') {
                            dispatcher.backing.openBackingEditor();
                        } else {
                            dispatcher.backing.closeBackingEditor();
                        }
                    }}>
                    <div>Backing</div>
                </_Label>
                <_Label
                    // 複数選択していない場合
                    isEnable={
                        enableState.isUseLib &&
                        enableState.isChordElement &&
                        !enableState.isRangeSelect
                    }
                    isVisible={['library', 'none'].includes(state.hideMenu)}
                    onClick={() => {
                        if (state.hideMenu === 'none') {
                            dispatcher.score.openLibraryWnd(false);
                        } else {
                            dispatcher.score.closeLibraryWnd();
                        }
                    }}>
                    <div>Library</div>
                </_Label>
                <_Label
                    // 複数選択している場合
                    isEnable={enableState.isRangeSelect && enableState.isUseLib}
                    isVisible={['preset', 'none'].includes(state.hideMenu)}
                    onClick={() => {
                        if (state.hideMenu === 'none') {
                            dispatcher.score.openLibraryWnd(true);
                        } else {
                            dispatcher.score.closeLibraryWnd();
                        }
                    }}>
                    <div>Preset</div>
                </_Label>
            </_LabelFrame>
        </_Wrap>
    </>);
}

export default HarmHideFrame;

const _Blind = styled.div<{
    isOpen: boolean;
}>`
    display: ${props => props.isOpen ? 'inline-block' : 'none'};
    position: absolute;
    z-index: 7;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #01004216;
    backdrop-filter: blur(6px);
`;

const _Wrap = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 8;
    left: 0;
    top: 0;
    /* width: 525px; */

    & * {
        vertical-align: top;
    }
`;

const _Contents = styled.div<{
    isOpen: boolean;
}>`
    display: inline-block;
    width: ${props => props.isOpen ? '630px' : 0};
    /* height: 400px; */
    background-color: #a3a3a3;
    opacity: 0.9;
    transition: width 0.1s;
    overflow: hidden;
`;

const _LabelFrame = styled.div<{
}>`
    display: inline-block;
    position: relative;
`;
const _Label = styled.div<{
    isEnable: boolean;
    isVisible: boolean;
}>`
    display: block;
    width: 25px;
    height: 110px;
    margin-top: 4px;
    background-color: #e4e4e4;
    border-top: 1px solid #5a5a5a;
    border-right: 1px solid #5a5a5a;
    border-bottom: 1px solid #5a5a5a;
    border-radius: 0 10px 10px 0;
    font-size: 16px;
    font-weight: 600;
    color: #5a5a5a;
    text-align: center;
    line-height: 16px;
    box-sizing: border-box;
    ${props => props.isEnable ? '' : css`
        opacity: 0.5;
        pointer-events: none;
    `}
    ${props => props.isVisible ? '' : css`
        opacity: 0;
    `}
    
    & div {
        width: 110px;
        transform-origin: top left;
        transform: rotate(-90deg) translateX(-100%);
        /* background-color: #fff; */
    }

    &:hover {
        background-color: #d1e5eb;
    }
`;