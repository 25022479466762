import { useState, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import Styles from "../../../../base/design/styles";
import TheoryUtil from "../../../../utils/theoryUtil";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const AttrFormTab = (props: {
    isPattern: boolean;
}) => {
    const { node, invalidate, setFocusNode, setTreeDisable } = useContext(ThemaTreeContext);

    const parentNode = node.parent as TreeUtil.ElementNode;

    const [attr, setAttr] = useState<'' | TheoryUtil.SymbolAttribute>('');
    const [symbols, setSymbols] = useState<TheoryUtil.SymbolKey[]>([]);

    const [errorList, setErrorList] = useState<string[]>([]);

    useEffect(() => {
        if (node.data == null) {
            setAttr('');
            setSymbols([]);
        } else {
            const data = node.data as ThemaUtil.LevelAttr;
            setAttr(data.attr);
            setSymbols(data.symbols.slice());
        }
    }, [node]);

    useEffect(() => {
        const list: string[] = [];
        if (attr === '' || symbols.length === 0) {
            list.push('target is none.');
        }
        setErrorList(list);
    }, [attr, symbols]);

    const targetSymbolList = attr === '' ? [] : TheoryUtil.getSymbolsFromAttr(attr);
    return (<>
        <ThemaEditTabUtil.InputComboboxForm titleLabel="attribute" value={attr} setValue={(value: string) => {
            setAttr(value as TheoryUtil.SymbolAttribute);
            setSymbols([]);
        }} list={ThemaEditTabUtil.createBlankCatList(TheoryUtil.SymbolAttributeList.map(item => ({ value: item, labelText: item })))} />
        <_ListDiv>
            {targetSymbolList.map((symbol, i) => (
                <_SymbolItem key={i}
                    isActive={symbols.includes(symbol.key)}
                    onClick={() => {
                        if (!symbols.includes(symbol.key)) symbols.push(symbol.key);
                        else symbols.splice(symbols.indexOf(symbol.key), 1);
                        setSymbols(symbols.slice());
                    }}
                ><_SymbolKey>{symbol.key}</_SymbolKey> [{symbol.name}]</_SymbolItem>
            ))}
        </_ListDiv>
        <ThemaEditTabUtil.BorderPanel
            title="selft element"
            innerJsx={<>
                <ThemaEditTabUtil.TextAreaView value={errorList.join('\n')} height={50} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: errorList.length === 0,
                        callback: () => {
                            if (node.data == null) {
                                const attrData: ThemaUtil.LevelAttr = {
                                    attr: attr as TheoryUtil.SymbolAttribute,
                                    symbols: symbols.slice(),
                                    roots: []
                                };
                                node.data = attrData;
                                if (!props.isPattern) {
                                    const parentData = parentNode.data as ThemaUtil.LevelMethod;
                                    (parentData.defs as ThemaUtil.LevelAttr[]).push(attrData);
                                } else {
                                    const parentData = parentNode.data as ThemaUtil.LevelPatt;
                                    parentData.attrs.push(attrData);
                                }
                                setTreeDisable(false);
                            } else {
                                const data = node.data as ThemaUtil.LevelAttr;
                                data.attr = attr as TheoryUtil.SymbolAttribute;
                                data.symbols = symbols;
                                invalidate();
                            }
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            setFocusNode(parentNode);
                            const selfIndex = TreeUtil.getChildIndex(node);
                            parentNode.children.splice(selfIndex, 1);
                            if (!props.isPattern) {
                                const parentData = parentNode.data as ThemaUtil.LevelMethod;
                                (parentData.defs as ThemaUtil.LevelAttr[]).splice(selfIndex, 1);
                            } else {
                                const parentData = parentNode.data as ThemaUtil.LevelPatt;
                                parentData.attrs.splice(selfIndex, 1);
                            }
                            setTreeDisable(false);
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="children element"
            isEnable={node.data != null}
            innerJsx={<>
                <ThemaEditTabUtil.LabelTextItem titleLabel="Child" value={'Root Element'} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: true, callback: () => {
                            // const rootData: ThemaUtil.LevelRoot = {
                            //     minIdx: 0,
                            //     maxIdx: 11,
                            //     voics: []
                            // };
                            // data.roots.push(rootData);
                            const newNode = TreeUtil.createNode(null, node);
                            node.children.push(newNode);
                            setFocusNode(newNode);
                            setTreeDisable(true);
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            const data = node.data as ThemaUtil.LevelAttr;
                            node.children = [];
                            data.roots = [];
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
    </>);
}

export default AttrFormTab;


const _ListDiv = styled.div`
    display: inline-block;
    background-color: #e3ecee;
    width: calc(100% - 8px);
    min-height: 50px;
    margin: 4px 0 0 4px;
    box-sizing: border-box;
    border: 1px solid #26242c;
    padding: 0 4px 4px 0;
`;

const _SymbolItem = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    background-color: #a2bcc2;
    ${props => !props.isActive ? '' : css`
        background-color: #dee058;
    `}
    ${Styles.CSS_LABEL_SMALL}
    color: #4f59b3;
    padding: 0 10px;
    margin: 4px 0 0 4px;
    box-sizing: border-box;
    border: 1px solid #697ab3;
    border-radius: 5px;
    &:hover {
        background-color: #c2d5da;
        ${props => !props.isActive ? '' : css`
            background-color:#c2c461;
        `}
    }
`;

const _SymbolKey = styled.span`
    color: #d65d5d;
`;