import { useContext } from "react";
import styled, { css } from "styled-components";
import { ELEMENT_POS_INFO_WIDTH } from "../../../../../../utils/systemConst";
import { GlobalContext } from "../../../../../entry/systemEntry";

const CalcSection = (props: {
    index: number;
}) => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.scoreState;
    const sectionNo = state.elementCacheList[props.index].sectionNo;
    const curSection = state.sectionCacheList[sectionNo];

    const barSum = Math.floor(curSection.beatSum / 4);
    const barRem = curSection.beatSum % 4;

    return (
        <_Wrap>
            <_Len>{barSum}{barRem === 0 ? '' : ` (${barRem})`}</_Len>
            <_Border />
        </_Wrap>
    );
}

export default CalcSection;



const _Wrap = styled.div`
    display: inline-block;
    position: absolute;
    z-index: 4;
    width: 100%;
    height: 50px;
    /* background-color: #ff000011; */
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    text-align: center;
    box-sizing: border-box;
`;

const _Len = styled.div`
    display: block;
    position: relative;
    /* min-width: 80px; */
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    color: #474386cf;
    text-align: center;
    line-height: 16px;
    /* background-color: #00000042; */
`;

const _Border = styled.div`
    display: inline-block;
    position: relative;
    width: 8px;
    height: calc(100% - 16px);
    background-color: #db0000cf;
    border-radius: 4px 4px 0 0;
`;
