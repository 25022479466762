import styled, { css } from "styled-components";
import Styles, { _CSS_LABEL_MIDIUM, _CSS_LABEL_SMALL } from "../../../../base/design/styles";
import { Dispatcher } from "../../../../redux/dispatcher/dispatcher";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";

namespace ThemaEditTabUtil {

    export type ComponentArgs = {
        node: TreeUtil.ElementNode;
        setFocusNode: (node: TreeUtil.ElementNode) => void;
        invalidate: () => void;
        setThamaData: React.Dispatch<React.SetStateAction<ThemaUtil.LevelBase>>;
        setBackingWnd: (flg: boolean) => void;
        dispatcher: Dispatcher;
        treeDisable: boolean;
        setTreeDisable: (treeDisable: boolean) => void;
    }

    export const InputTextForm = (props: {
        titleLabel: string;
        value: string;
        isEnable?: boolean;
        setValue: (value: string) => void;
    }) => {
        return (
            <_Record isEnable={props.isEnable ?? true}>
                <_ItemnLabel>{props.titleLabel}</_ItemnLabel>
                <_ItemnInput type="text" value={props.value} onChange={(e) => {
                    props.setValue(e.target.value);
                }} />
            </_Record>
        );
    }

    export type ComboboxItemProps = { value: string, labelText: string };
    export const createBlankCatList = (list: ComboboxItemProps[]) => {
        return [{ value: '', labelText: '' }].concat(list);
    }
    export const InputComboboxForm = (props: {
        titleLabel: string;
        value: string;
        isEnable?: boolean;
        setValue: (value: string) => void;
        list: ComboboxItemProps[];
    }) => {
        return (
            <_Record isEnable={props.isEnable ?? true}>
                <_ItemnLabel>{props.titleLabel}</_ItemnLabel>
                <_ItemnCombobox value={props.value} onChange={(e) => {
                    props.setValue(e.target.value);
                }} >{props.list.map((item, i) => {
                    return <option key={i} value={item.value}>{item.labelText}</option>
                })}</_ItemnCombobox>
            </_Record>
        );
    }

    export const LabelTextItem = (props: {
        titleLabel: string;
        value: string;
    }) => {
        return (
            <_Record isEnable={true}>
                <_ItemnLabel>{props.titleLabel}</_ItemnLabel>
                <_ItemnInput readOnly type="text" value={props.value} />
            </_Record>
        );
    }

    export const TextAreaView = (props: {
        value: string;
        height?: number;
    }) => {
        const height = props.height ?? 150;
        return (
            <_TextArea
                readOnly
                value={props.value}
                height={height}
            />
        );
    }

    export const BorderPanel = (props: {
        title: string;
        isEnable?: boolean;
        innerJsx: JSX.Element;
    }) => {
        // const title = props.title ?? 'title pl';
        return (<>
            <_BorderText>{props.title}</_BorderText>
            <_BorderPanel isEnable={props.isEnable ?? true}>{props.innerJsx}</_BorderPanel>
        </>);
    }

    export type ButtonProps = {
        name: string;
        isEnable?: boolean;
        callback: () => void;
    }
    export const ButtonDiv = (props: {
        buttons: ButtonProps[]
    }) => {
        return (
            <_Record isEnable={true}>
                {props.buttons.map((button, i) => {
                    return (
                        <_Button
                            key={i}
                            isEnable={button.isEnable ?? true}
                            onClick={button.callback}
                        >{button.name}</_Button>
                    );
                })}
            </_Record>
        );
    }

    export type ListButtonProps = {
        label: string;
        width: number;
        isEnable?: boolean;
        callback: () => void;
    }

    export const ListManager = (props: {
        height: number;
        records: {
            label: string;
            buttons: ListButtonProps[];
        }[]
    }) => {
        return (
            <_ListRecordFrame height={props.height}>
                {props.records.map((record, i) => {
                    const dim = record.buttons.map(button => button.width).reduce((a, b) => a + b + 2);
                    const buttonJsxList = record.buttons.map((button, i) => {
                        return <_ListRecordButton
                            key={i}
                            width={button.width}
                            isEnable={button.isEnable ?? true}
                            onClick={button.callback}
                        >{button.label}</_ListRecordButton>
                    });
                    return (
                        <_ListRecordItem key={i}>
                            <_ListRecordLabel dim={dim + 2}>{record.label}</_ListRecordLabel>{buttonJsxList}
                        </_ListRecordItem>
                    );
                })}
            </_ListRecordFrame>
        );
    }
}

export default ThemaEditTabUtil;

const _Record = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    width: 100%;
    height: 40px;
    /* background-color: #b3b4c4; */
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
`;

const _ItemnLabel = styled.div`
    display: inline-block;
    height: calc(100% - 4px);
    width: 140px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    color: #08106e;
    border: solid 1px #1b00b3;
    box-sizing: border-box;
    /* background-color: #85a2c5; */
    background: linear-gradient(to bottom, #8a98a7, #a3bbd2, #8a98a7);
    border-radius: 4px;
    text-align: center;
    vertical-align: top;
    margin-left: 4px;
    margin-top: 2px;
`;

const _ItemnInput = styled.input<{
    readOnly?: boolean;
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 150px);
    margin-top: 2px;
    margin-left: 4px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #1f1f1f;
    border: solid 1px #000;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
    ${props => !props.readOnly ? '' : css`
        color: #020075;
        border: none;
        outline: none;
        background-color: transparent;
    `}
`;

const _ItemnCombobox = styled.select<{
}>`
    display: inline-block;
    height: calc(100% - 4px);
    width: calc(100% - 150px);
    margin-top: 2px;
    margin-left: 4px;
    font-size: 24px;
    line-height: 32px;
    /* font-weight: 600; */
    color: #1f1f1f;
    border: solid 1px #000;
    padding: 0 5px;
    box-sizing: border-box;
    background-color: #dbdbdb;
    text-align: left;
    vertical-align: top;
    user-select: text;
`;

const _ButtonsDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 40px;
    /* background-color: #888888; */
    box-sizing: border-box;
    text-align: left;
    white-space: nowrap;
`;

const _Button = styled.div<{
    isEnable: boolean;
}>`
    ${_CSS_LABEL_MIDIUM}
    /* width: 160px; */
    padding: 0 40px;
    /* line-height: 25px; */
    box-sizing: border-box;
    background-color: #a8a8a8;
    background: linear-gradient(to bottom, #787878, #b4b4b4, #787878);
    border: 1px solid #1a2b3f;
    color: #1a2b3f;
    border-radius: 4px;
    margin-left: 4px;
    margin-top: 2px;
    margin: 5px 0 0 4px;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    &:hover {
        /* background-color: #98bfc2; */
    background: linear-gradient(to bottom, #989898, #b4b4b4, #989898);
    }
`;


const _BorderText = styled.div<{
}>`
    ${_CSS_LABEL_SMALL}
    color: #990000;
    /* background-color: #0a0a0a40; */
    font-style: italic;
    margin: 4px 0 0 8px;
`;
const _BorderPanel = styled.div<{
    isEnable: boolean;
}>`
    display: inline-block;
    margin: 0 0 0 6px;
    padding: 4px;
    width: calc(100% - 12px);
    background-color: #d4d6bc;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: left;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
`;

const _TextArea = styled.textarea<{
    height: number;
}>`
    display: inline-block;
    font-size: ${Styles.FONT_SMALL}px;
    color: #0a0038;
    background-color: #b7bcd3;
    resize: none;
    width: calc(100% - 8px);
    height: ${props => props.height}px;
    box-sizing: border-box;
    margin: 0 0 0 4px;
    outline: none;
`;

const _ListRecordFrame = styled.div<{
    height: number;
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: ${props => props.height}px;
    margin: 4px 0 0 4px;
    border: solid 2px #850000;
    background-color: #bcbcbc;
    box-sizing: border-box;
    padding: 0 0 2px 0;
    overflow-y: auto;
`;

const _ListRecordItem = styled.div`
    display: inline-block;
    width: 100%;
    height: 30px;
    ${Styles.CSS_LABEL_SMALL};
    margin: 2px 0 0 0;
    background-color: #fff7dc;
    text-align: left;
    box-sizing: border-box;
    padding-left: 4px;
`;

const _ListRecordLabel = styled.div<{
    dim: number;
}>`
    display: inline-block;
    width: calc(100% - ${props => props.dim}px);
    height: 100%;
    color: #9c1e1e;
    text-align: left;
`;

const _ListRecordButton = styled.div<{
    width: number;
    isEnable: boolean;
}>`
    display: inline-block;
    width: ${props => props.width}px;
    height: 100%;
    color: #110549;
    text-align: center;
    background-color: #93bec3;
    border-radius: 4px;
    border: solid 1px #274154;
    box-sizing: border-box;
    margin: 0 1px;
    ${props => props.isEnable ? '' : Styles.CSS_BUTTON_DISABLE}
    &:hover {
        background-color: #b7d6d9;
    }
`;