
import { useContext } from "react";
import styled, { css } from "styled-components";
import BackingState from "../../../redux/store/score/backing/backingState";
import BackingUtil from "../../../utils/backingUtil";
import { PATTERN_FRAME_HEIGHT, PATTERN_OPERATION_HEIGHT as PATTERN_STATUS_HEIGHT } from "../../../utils/systemConst";
import { GlobalContext } from "../../entry/systemEntry";
import PatternBuilder from "./builder/patternBuilder";
import PatternChannel from "./channel/patternChannel";
import PatternOptions from "./patternOptions";

/**
 * バッキングエディタ画面のパターン部分
 * @returns コンポーネント
 */
const PatternFrame = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    const state = store.backingState as BackingState.Editor;
    const chordInfo = state.chordInfo;

    const isUsePattern = state.pattern != null;

    const update = () => {
        dispatcher.backing.setState(state);
    }

    /**
     * USEスイッチ押下時の処理
     */
    const useSwitchAction = () => {
        if (!isUsePattern) {
            const table = state.voicingTable as boolean[][];
            const voicingStructs = BackingUtil.buildVoicsFromTable(table);
            const channels = BackingUtil.getChannels(voicingStructs, 1);
            state.pattern = {
                layerIndex: 0,
                lenIndex: 0,
                channelIndex: -1,
                channels,
                isUsePedal: false,
                mode: "velocity",
                pedalActs: [null],
                layers: [dispatcher.backing.getInitialLayer(), dispatcher.backing.getInitialLayer()],
            } as BackingState.Pattern;
            update();
        } else {
            state.pattern = null;
            update();
        }
    }

    return (
        <_Wrap>
            {/* ヘッダー部分（パターン利用スイッチ） */}
            <_Header>
                <_UseSwitch>
                    <_Label>Pattern</_Label>
                    <_Button
                        isActive={isUsePattern}
                        onClick={useSwitchAction}
                    >Use</_Button>
                </_UseSwitch>
            </_Header>
            <_Main isActive={isUsePattern}>
                {!isUsePattern ? <></> : (<>
                    <_StatusDiv><PatternOptions /></_StatusDiv>
                    <_MainDiv>
                        <_ChannelDiv>
                            <PatternChannel />
                        </_ChannelDiv>
                        <_BuilderDiv>
                            <PatternBuilder />
                        </_BuilderDiv>
                    </_MainDiv>
                </>)}
            </_Main>
        </_Wrap>
    )
}

export default PatternFrame;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    /* height: 300px; */
    background-color: #001279;

    & *{
        /* display: inline-block;
        width: 100%; */
    }
`;

const _Header = styled.div`
    height: 30px;
    background-color: #a5d1c3;
`;


const _Label = styled.div``;
const _UseSwitch = styled.div`
    display: inline-block;
    width: 200px;
    height: 100%;
    /* background-color: #7986d1; */

    & * {
        display: inline-block;
        height: calc(100% - 4px);
        margin-top: 2px;
        box-sizing: border-box;        
    }

    & ${_Label} {
        width: 80px;
        font-size: 20px;
        color: #f1fffb;
        font-weight: 600;
        /* background-color: #00000028; */
        margin: 2px 2px 0 2px;
        line-height: 22px;
        text-align: center;
    }
`;

const _Button = styled.div<{
    isActive: boolean;
}>`
    width: 100px;
    font-size: 20px;
    color: white;
    font-weight: 600;
    background-color: #ff008023;
    border: solid 1px #555;
    border-radius: 4px;
    line-height: 20px;
    text-align: center;

    ${props => !props.isActive ? '' : css`
        /* background-color: #b1dfc979; */
        color: #e00000;
    `}

    &:hover {
        background-color: #ff008039;
    }
`;

const _Main = styled.div<{
    isActive: boolean;
}>`
    display: inline-block;
    width: 100%;
    overflow: hidden;
    height: ${PATTERN_FRAME_HEIGHT}px;
    ${props => props.isActive ? '' : css`
        height: 0;
    `}
    transition: height 100ms;
`;

const _StatusDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: ${PATTERN_STATUS_HEIGHT}px;
    background-color: #8d98d4;
    text-align: left;
`;

const _MainDiv = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #d48d8d;

    /* & *{
        display: inline-block;
        height: 100%;
    } */
`;

const _ChannelDiv = styled.div`
    display: inline-block;
    width: 100px;
    background-color: #ff0000;
    vertical-align: top;
`;

const _BuilderDiv = styled.div`
    display: inline-block;
    width: calc(100% - 100px);
    background-color: #76af90;
    vertical-align: top;
`;