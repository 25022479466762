
import { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

const Splash = (props: {
    remove: () => void
}) => {
    const [isSwitch, setSwitch] = useState<boolean>(false);
    const [timerId, setTimerId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        setTimerId(setTimeout(() => {
            setSwitch(true);
        }, 3000));
    }, []);
    useEffect(() => {
        if (isSwitch) {
            setTimeout(() => {
                props.remove();
            }, 500);
        }
    }, [isSwitch]);

    return (

        <_Blind isSwitch={isSwitch} onClick={(e) => {
            clearTimeout(timerId as NodeJS.Timeout);
            setSwitch(true);
        }}>
            <_Frame>
                <_Title>
                    <span>L</span>iCreSia
                </_Title>
                <_NowLoading>Now Loading...</_NowLoading>
                <_Copylight>Copyright © 2022 Licresia All Rights Reserved.</_Copylight>
            </_Frame>
        </_Blind>
    );
}

export default Splash;

const _Blind = styled.div<{
    isSwitch: boolean;
}>`
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: #000f3a86;
    opacity: ${props => props.isSwitch ? '0' : '1'};
    transition: opacity 500ms;
`;
const _Frame = styled.div<{
}>`
    display: inline-block;
    position: relative;
    left: calc(50% - 450px);
    top: calc(50% - 300px);
    z-index: 11;
    width: 900px;
    height: 600px;
    background: radial-gradient(#868686, #ffffff);
    border-radius: 8px;
    border: solid 2px #cfcfcf;
    box-sizing: border-box;
    transition: opacity 500ms;
`;


const fadeIn = keyframes`
  0%, 100% {
    opacity: 0.4;
  }
  50% {
    opacity: 1;
  }
`;

const _Title = styled.div`
    display: inline-block;
    position: relative;
    margin-top: 10%;
    width: 100%;
    /* height: 400px; */
    /* background-color: #0003b69e; */
    background: linear-gradient(to bottom, #99999913, #ffffff13,#ffffff13,#ffffff13, #99999913);
    color: white;
    font-size: 130px;
    font-weight: 600;
    text-align: center;
    text-shadow: 1px 2px 22px #0000003d;
    /* line-height: 350px; */
    animation: ${fadeIn} 3s linear infinite;
    /* -webkit-box-reflect:below 1px; */
    -webkit-box-reflect: below 1px -webkit-gradient(
      linear,left top,left bottom,
      from(transparent),
      color-stop(0.6, transparent),
      to(#f80000));
    & span {
        color: red;
        font-size: 160px;
    }
`;

const _NowLoading = styled.div`
    display: inline-block;
    position: absolute;
    bottom: 80px;
    width: 100%;
    left: 20px;
    color: #ffffff;
    font-size: 42px;
    font-weight: 600;
    text-align: center;
`;

const _Copylight = styled.div`
    display: inline-block;
    position: absolute;
    bottom: 5px;
    left: 20px;
    color: #636363;
    font-size: 22px;
    font-weight: 600;
    text-align: left;
`;