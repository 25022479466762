import { useContext } from "react";
import styled from "styled-components";
import { TabbedPane } from "../../base/components/tab/tabbedPane";
import { SCORE_TAB_MARGIN } from "../../utils/systemConst";
import { GlobalContext } from "../entry/systemEntry";
import EditTab from "../score/edit/editTab";
import ScoreInfoTab from "./scoreInfoTab";

const PreviewChannel = () => {

    const { store, dispatcher } = useContext(GlobalContext);

    return (
        <_Wrap>
            <TabbedPane
                tabElements={[
                    { name: 'Info', disable: false, cont: <ScoreInfoTab /> },
                    { name: 'View', disable: false, cont: <EditTab /> },
                ]}
                activeNo={store.systemState.scoreTabIndex}
            />
        </_Wrap>
    );
}

export default PreviewChannel;

const _Wrap = styled.div`
    display: inline-block;
    position: relative;
    margin: ${SCORE_TAB_MARGIN}px 0 0 ${SCORE_TAB_MARGIN}px;
    width: calc(100% - ${SCORE_TAB_MARGIN * 2}px);
    height: calc(100% - ${SCORE_TAB_MARGIN * 2}px);
    background-color: transparent;
    /* background-color: #c3d8dd6f; */
    /* border: solid 1px black;
    border-radius: 5px; */
    /* box-shadow: 0 2px 14px 4px #000b3a48; */
`;