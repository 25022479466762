
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Styles from "../../base/design/styles";
import DataUtil from "../../utils/dataUtil";
import { HOME_USER_DIV_HEIGHT } from "../../utils/systemConst";
import { GlobalContext } from "../entry/systemEntry";


type NotePorp = {
    version: string;
    date: string;
    note: string;
}

const ReleaseNote = () => {
    const { store, dispatcher } = useContext(GlobalContext);

    const [jsx, setJsx] = useState<JSX.Element[]>([]);
    useEffect(() => {
        fetch('assets/sample.lcrt')
            .then(response => response.text()) // (2) レスポンスデータを取得
            .then(data => { // (3)レスポンスデータを処理

                const thema = JSON.parse(DataUtil.unZip(data));
                const state = store.systemState;
                state.themaList.push(thema);
                dispatcher.system.setState(state);
            });
        fetch('assets/release_note.json')
            .then(response => response.json()) // (2) レスポンスデータを取得
            .then(json => { // (3)レスポンスデータを処理

                const list = json as NotePorp[];

                setJsx(list.map((item, i) => {
                    const record = item.note.split('\n').map((r, i) => <span key={i}>{r}<br /></span>);
                    return (
                        <_Item key={i}>
                            <_Header>v{item.version} ({item.date})</_Header>
                            <_Detail>{record}</_Detail>
                        </_Item>
                    );
                }));
            });
    }, []);

    return (<_ListFrame>{jsx}</_ListFrame>);
}

export default ReleaseNote;

const _ListFrame = styled.div<{
}>`
    display: inline-block;
    width: calc(100% - 8px);
    height: calc(100% - ${HOME_USER_DIV_HEIGHT + 90}px);
    margin: 4px 0 0 4px;
    background-color: #d3d3c5;
    border: 1px solid #e60000;
    border-radius: 4px;
    box-sizing: border-box;
    overflow-y: auto;
`;

const _Item = styled.div`
    display: inline-block;
    width: calc(100% - 8px);
    margin: 4px 0 0 4px;
    background-color: #bfcace;
    border: 1px solid #9b9b9b;
    border-radius: 6px;
`;

const _Header = styled.div`
    display: inline-block;
    width: calc(100% - 8px);
    height: 20px;
    font-size: ${Styles.FONT_SMALL}px;
    margin: 2px 0 0 4px;
    font-weight: 600;
    /* background-color: #8bdbf8; */
    color: #494975;
`;

const _Detail = styled.div`
    display: inline-block;
    width: calc(100% - 8px);
    font-size: ${Styles.FONT_SMALL}px;
    margin: 2px 0 0 4px;
    /* background-color: #dafce4; */
    color: #754949;
`;