import { ColorProps } from "../../base/design/colorProps";

export type UserEnv = {
    login: LoginProps | null;
    keyConfig: KeyConfig;
    colorProps: ColorProps;
}

export type LoginProps = {
    seq: number;
    id: string;
    password: string;
    email: string;
}

export type KeyConfig = {
    note: {
        left: string;
        right: string;
        up: string;
        down: string;
        addChord: string;
        addSection: string;
        addChange: string;
        deleteCur: string;
        distMode: string;
        edit1: string;
        incRoot: string;
        decRoot: string;
        incBeat: string;
        decBeat: string;
        edit2: string;
    }
}

export const getDefaultKeyConfig = (): KeyConfig => {
    return {
        note: {
            left: 'ArrowLeft',
            right: 'ArrowRight',
            up: 'ArrowUp',
            down: 'ArrowDown',
            addChord: 'a',
            addSection: 's',
            addChange: 'm',
            deleteCur: 'Delete',
            distMode: 'Shift',
            edit1: 'f',
            edit2: 'c',
            incRoot: 'ArrowUp',
            decRoot: 'ArrowDown',
            incBeat: 'ArrowRight',
            decBeat: 'ArrowLeft',
        }
    };
}