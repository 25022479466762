import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ChordBacking } from "../../../../redux/store/score/scoreData";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const SustFormTab = (props: {
}) => {
    const { node, invalidate, setFocusNode, setTreeDisable } = useContext(ThemaTreeContext);
    const data = node.data as ThemaUtil.LevelSust;

    const parentNode = node.parent as TreeUtil.ElementNode;
    const parentData = parentNode.data as ThemaUtil.LevelMethod;

    const [beat, setBeat] = useState('');
    const [head, setHead] = useState('');
    const [tail, setTail] = useState('');

    const [errorList, setErrorList] = useState<string[]>([]);

    useEffect(() => {
        if (node.data == null) {
            setBeat('');
            setHead('0');
            setTail('0');
        } else {
            const data = node.data as ThemaUtil.LevelSust;
            setBeat(data.beat.toString());
            setHead(data.head.toString());
            setTail(data.tail.toString());
        }
    }, [node]);

    useEffect(() => {
        const list: string[] = [];
        if (beat === '') {
            list.push('beat is empty.');
        }
        if (head === '') {
            list.push('head is empty.');
        }
        if (tail === '') {
            list.push('tail is empty.');
        }
        setErrorList(list);
    }, [beat, head, tail]);

    return (<>

        <ThemaEditTabUtil.InputComboboxForm titleLabel="beat" value={beat.toString()}
            list={['', 1, 2, 3, 4].map(v => ({ value: v.toString(), labelText: v.toString() }))}
            setValue={(value: string) => {
                setBeat(value);
            }} />
        <ThemaEditTabUtil.InputComboboxForm titleLabel="head" value={head.toString()}
            list={['', -2, -1, 0, 1, 2].map(v => ({ value: v.toString(), labelText: v.toString() }))}
            setValue={(value: string) => {
                setHead(value);
            }} />
        <ThemaEditTabUtil.InputComboboxForm titleLabel="tail" value={tail.toString()}
            list={['', -2, -1, 0, 1, 2].map(v => ({ value: v.toString(), labelText: v.toString() }))}
            setValue={(value: string) => {
                setTail(value);
            }} />
        <ThemaEditTabUtil.BorderPanel
            title="selft element"
            innerJsx={<>
                <ThemaEditTabUtil.TextAreaView value={errorList.join('\n')} height={50} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: errorList.length === 0,
                        callback: () => {
                            if (node.data == null) {
                                const sustData: ThemaUtil.LevelSust = {
                                    beat: Number(beat),
                                    head: Number(head),
                                    tail: Number(tail),
                                    patts: []
                                };
                                node.data = sustData;
                                (parentData.defs as ThemaUtil.LevelSust[]).push(sustData);
                                setTreeDisable(false);
                            } else {
                                const data = node.data as ThemaUtil.LevelSust;
                                data.beat = Number(beat);
                                data.head = Number(head);
                                data.tail = Number(tail);
                                invalidate();
                            }
                            invalidate();
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            setFocusNode(parentNode);
                            const selfIndex = TreeUtil.getChildIndex(node);
                            parentNode.children.splice(selfIndex, 1);
                            parentData.defs.splice(selfIndex, 1);
                            setTreeDisable(false);
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="children element"
            isEnable={node.data != null}
            innerJsx={<>
                <ThemaEditTabUtil.LabelTextItem titleLabel="Child" value={'Pattern Element'} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: true, callback: () => {
                            const newNode = TreeUtil.createNode(null, node);
                            node.children.push(newNode);
                            setFocusNode(newNode);
                            setTreeDisable(true);
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            node.children = [];
                            data.patts = [];
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
    </>);
}

export default SustFormTab;

