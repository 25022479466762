import { useContext, useMemo } from "react";
import styled, { css } from "styled-components";
import { DetailChord, DetailSection } from "../../../../../redux/store/score/scoreData";
import DummyUtil from "../../../../../utils/dummyUtil";
import { GlobalContext } from "../../../../entry/systemEntry";
import { SideFormStyles } from "./sideFromStyles";


/**
 * セクションブロック選択時のサイド要素エディタ
 * @param props 引数
 * @returns コンポーネント
 */
const SideFormSection = (props: {
}) => {

    const { store, dispatcher } = useContext(GlobalContext);
    const state = store.scoreState;
    const cur = store.scoreData.chordList[state.focusIndex];
    const detail = cur.detail as DetailSection;

    const setLabelName = (value: string) => {
        detail.labelName = value;
        store.scoreData.chordList = store.scoreData.chordList.slice();
        dispatcher.score.setScoreData(store.scoreData);
    }

    const templateItemJsxList: JSX.Element[] = useMemo(() => {
        const jsxList: JSX.Element[] = [];
        DummyUtil.SECTION_CATEGORY.forEach((item) => {
            const isActive = detail.labelName.indexOf(item) === 0;
            jsxList.push(
                <_TemplateItem key={jsxList.length} isActive={isActive}
                    onClick={() => {
                        setLabelName(item);
                    }}
                >
                    {item}
                </_TemplateItem>
            );
        });
        return jsxList;
    }, [detail.labelName]);

    const numberItemJsxList: JSX.Element[] = useMemo(() => {
        const jsxList: JSX.Element[] = [];
        const splitList = detail.labelName.split(' ');
        DummyUtil.SECTION_NO.forEach((item) => {
            const isActive = splitList[splitList.length - 1] === item.toString();
            jsxList.push(
                <_NumberItem key={jsxList.length} isActive={isActive}
                    onClick={() => {
                        setLabelName(`${splitList[0]} ${item}`);
                    }}
                >
                    {item}
                </_NumberItem>
            );
        });
        return jsxList;
    }, [detail.labelName]);

    return (
        <_Wrap>
            <_DirectText type="text"
                value={detail.labelName}
                onChange={(e) => {
                    setLabelName(e.target.value);
                }}
            />
            <_SelectDiv>
                <_TemplateFrame>{templateItemJsxList}</_TemplateFrame>
                <_NumberFrame>{numberItemJsxList}</_NumberFrame>
            </_SelectDiv>
        </_Wrap>
    );
}

export default SideFormSection;

const _Wrap = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    /* background-color: #b89b9b; */
`;

const _DirectText = styled.input`
    display: inline-block;
    width: 100%;
    height: 30px;
    text-align: left;
    background-color: #bbbbbb;
    font-size: 22px;
    font-weight: 600;
    color: #a30000;
    box-sizing: border-box;
    outline: none;
    border: 1px solid black;
    border-radius: 4px;
`;



const _ItemBase = styled.div<{
}>`
    ${SideFormStyles.___CSS_FORM}
    width: 100%;
    vertical-align: top;
`;

const _SelectDiv = styled.div`
    display: inline-block;
    width: 100%;
    padding: 2px;
    box-sizing: border-box;
    background-color: #acacac;
`;

const _TemplateFrame = styled.div`
    display: inline-block;
    width: calc(60% - 4px);
    margin: 0 2px 0 2px;
    text-align: center;
    background-color: #dac3d8;
    vertical-align: top;
`;

const _NumberFrame = styled.div`
    display: inline-block;
    width: calc(40% - 2px);
    margin: 0 2px 0 0;
    text-align: center;
    background-color: #dac3d8;
    vertical-align: top;
`;

const _TemplateItem = styled(_ItemBase) <{
    isActive: boolean;
}>`
    /* background-color: #6182b4; */
    ${props => !props.isActive ? '' : css`
        background-color: #ad993fe6;
    `}
`;

const _NumberItem = styled(_ItemBase) <{
    isActive: boolean;
}>`
    /* background-color: #61b4b0; */
    ${props => !props.isActive ? '' : css`
        background-color: #ad993fe6;
    `}
`;
