import { useContext, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import BackingState from "../../../../redux/store/score/backing/backingState";
import { ChordBacking, VoicingProps } from "../../../../redux/store/score/scoreData";
import BackingUtil from "../../../../utils/backingUtil";
import TheoryUtil from "../../../../utils/theoryUtil";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const VoicFormTab = (props: {
    isPattern: boolean;
}) => {
    const { node, invalidate, dispatcher, setBackingWnd, setFocusNode, setTreeDisable } = useContext(ThemaTreeContext);
    // const data = node.data as ThemaUtil.LevelVoic;
    const methData = !props.isPattern ?
        // method sustain
        node.parent?.parent?.parent?.data as ThemaUtil.LevelMethod :
        // method pattern
        node.parent?.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelMethod;
    const sustData = !props.isPattern ? null : node.parent?.parent?.parent?.parent?.data as ThemaUtil.LevelSust;
    const pattData = !props.isPattern ? null : node.parent?.parent?.parent?.data as ThemaUtil.LevelPatt;
    const attrData = node.parent?.parent?.data as ThemaUtil.LevelAttr;
    const rootData = node.parent?.data as ThemaUtil.LevelRoot;

    // if(props.isPattern) console.log(data.source); 
    const [rootIndex, setRootIndex] = useState(0);
    const [symbolKey, setSymbolKey] = useState<TheoryUtil.SymbolKey>('major');
    const [source, setSource] = useState('');
    const [depPattKey, setDepPattKey] = useState('');
    const [preset, setPreset] = useState('');
    const [preUsies, setPreUsies] = useState<ThemaUtil.PresetUse[]>([]);

    const [errorList, setErrorList] = useState<string[]>([]);


    /** 親要素の条件でフィルターしたシンボルリスト */
    const symbolList = useMemo(() => {
        return TheoryUtil.SYMBOL_LIST.filter(
            (symbol) => attrData.symbols.includes(symbol.key)
        );
    }, []);
    useEffect(() => {
        if (node.data == null) {
            setRootIndex(rootData.minIdx);
            setSymbolKey(symbolList[0].key);
            setSource('');
            setDepPattKey('');
            setPreset('');
            setPreUsies([]);
        } else {
            const data = node.data as ThemaUtil.LevelVoic;
            setRootIndex(rootData.minIdx);
            setSymbolKey(symbolList[0].key);
            setSource(JSON.stringify(data.source));
            setDepPattKey('');
            setPreset('');
            setPreUsies(data.preUsies.slice());
        }
    }, [node]);

    const depPatts = useMemo(() => {
        if (sustData == null || pattData == null) {
            return null;
        } else {
            const curPattKey = pattData.key;
            return sustData.patts.filter(patt => {
                return patt.key !== curPattKey && patt.refer === curPattKey;
            });
        }
    }, [node]);

    useEffect(() => {
        const list: string[] = [];
        if (source === '') {
            list.push('source is null.');
        }
        setErrorList(list);
    }, [source]);

    const editorUpdate = (editor: BackingState.Editor) => {
        const voicSrc = BackingUtil.getVoicingListFromTable(editor.voicingTable);
        setSource(JSON.stringify(voicSrc));
        setBackingWnd(false);
    };
    const editorClose = () => {
        setBackingWnd(false);
    };

    const getPresetNameFromKey = (seq: number) => {
        const target = methData.prenams.find(prenam => prenam.seq === seq) as ThemaUtil.Prenam;
        return target.name;
    }

    return (<>
        <ThemaEditTabUtil.BorderPanel
            title="source"
            isEnable={true}
            innerJsx={<>
                <ThemaEditTabUtil.InputComboboxForm titleLabel="root" value={rootIndex.toString()} setValue={(value: string) => {
                    setRootIndex(Number.parseInt(value));
                }} list={TheoryUtil.KEY12_SHARP_LIST.filter((key12, i) => {
                    return i >= rootData.minIdx && i <= rootData.maxIdx;
                }).map((key12, idx) => (
                    // フィルターしたリストから連番を取得するため、ルートの最小値を加算
                    { value: (idx + rootData.minIdx).toString(), labelText: key12 }
                ))} />
                <ThemaEditTabUtil.InputComboboxForm titleLabel="symbol" value={symbolKey} setValue={(value: string) => {
                    setSymbolKey(value as TheoryUtil.SymbolKey);
                }} list={symbolList.map((symbol) => (
                    { value: symbol.key, labelText: `${symbol.key}: [${symbol.name}]` }
                ))} />
                <ThemaEditTabUtil.LabelTextItem
                    titleLabel="channel"
                    value={pattData == null ? '-' : pattData.source.channelSize.toString()}
                />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Edit',
                        isEnable: true,
                        callback: () => {
                            const voicingList = JSON.parse(source !== '' ? source : '[]') as VoicingProps[];
                            if (pattData == null || sustData == null) {
                                // サスティンバッキング
                                const chordBacking: ChordBacking = {
                                    pattern: null,
                                    voicingList
                                }
                                dispatcher.thema.openBackingEditor(
                                    4, 0, 0, symbolKey, rootIndex, chordBacking,
                                    editorUpdate,
                                    editorClose
                                );
                            } else {
                                // パターンバッキング
                                const chordBacking: ChordBacking = {
                                    pattern: pattData.source,
                                    voicingList
                                }
                                dispatcher.thema.openBackingEditor(
                                    sustData.beat, sustData.head, sustData.tail, symbolKey, rootIndex, chordBacking,
                                    editorUpdate,
                                    editorClose
                                );
                            }
                            setBackingWnd(true);
                        }
                    },
                ]} />
                <ThemaEditTabUtil.TextAreaView value={source} />
                {/* <ThemaEditTabUtil.LabelTextItem
                    titleLabel="List"
                    value={(() => {
                        return source.map(v => {
                            return `${v.octave}-${v.struct}`;
                        }).join(', ');
                    })()}
                /> */}
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="preset use"
            innerJsx={<>
                <ThemaEditTabUtil.InputComboboxForm
                    titleLabel="Depend"
                    // パターンメソッドの時のみ活性化
                    isEnable={pattData != null}
                    value={depPattKey}
                    setValue={(value: string) => {
                        setDepPattKey(value);
                    }}
                    list={[{ value: '', labelText: '' }].concat((depPatts ?? []).map(patt => ({
                        labelText: `${patt.key}: ${patt.pattName}`,
                        value: patt.key.toString()
                    })))}
                />
                <ThemaEditTabUtil.InputComboboxForm
                    titleLabel="Preset" value={preset}
                    setValue={(value: string) => {
                        setPreset(value);
                    }}
                    list={[{ value: '', labelText: '' }].concat(methData.prenams.filter(prenam => {
                        // return !preUsies.map(preUse => `${preUse.depPatt},${preUse.presKey}`).includes(`${depPattKey === '' ? -1 : depPattKey},${prenam.seq}`);
                        return !preUsies.map(preUse => preUse.presKey).includes(prenam.seq);
                    }).map(preset => ({
                        labelText: `${preset.seq}: ${preset.name}`,
                        value: preset.seq.toString()
                    })))}
                />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: preset !== '',
                        callback: () => {
                            preUsies.push({
                                depPatt: depPattKey === '' ? -1 : Number(depPattKey),
                                presKey: Number(preset)
                            });
                            setPreUsies(preUsies.slice());
                            setDepPattKey('');
                            setPreset('');
                        }
                    },
                ]} />
                <ThemaEditTabUtil.ListManager
                    height={100}
                    records={preUsies.map((preUse, i) => {
                        return {
                            label: `${preUse.depPatt === -1 ? '' : preUse.depPatt + '@'}[${getPresetNameFromKey(preUse.presKey)}]`,
                            buttons: [
                                {
                                    label: '↓', width: 30,
                                    isEnable: i < preUsies.length - 1,
                                    callback: () => {
                                        const cache = preUsies[i];
                                        preUsies[i] = preUsies[i + 1];
                                        preUsies[i + 1] = cache;
                                        setPreUsies(preUsies.slice());
                                    },
                                },
                                {
                                    label: '↑', width: 30,
                                    isEnable: i > 0,
                                    callback: () => {
                                        const cache = preUsies[i];
                                        preUsies[i] = preUsies[i - 1];
                                        preUsies[i - 1] = cache;
                                        setPreUsies(preUsies.slice());
                                    },
                                },
                                {
                                    label: 'Delete', width: 60, callback: () => {
                                        preUsies.splice(i, 1);
                                        setPreUsies(preUsies.slice());
                                    },
                                },
                            ]
                        };
                    })}
                />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="selft element"
            innerJsx={<>
                <ThemaEditTabUtil.TextAreaView value={errorList.join('\n')} height={50} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: errorList.length === 0,
                        callback: () => {
                            if (node.data == null) {
                                const voicData: ThemaUtil.LevelVoic = {
                                    source: JSON.parse(source),
                                    preUsies: preUsies.slice()
                                };
                                node.data = voicData;
                                rootData.voics.push(voicData);
                                setTreeDisable(false);
                            } else {
                                const data = node.data as ThemaUtil.LevelVoic;
                                data.source = JSON.parse(source);
                                data.preUsies = preUsies.slice();
                                invalidate();
                            }
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            const parentNode = node.parent as TreeUtil.ElementNode;
                            const parentData = parentNode.data as ThemaUtil.LevelRoot;
                            setFocusNode(parentNode);
                            const selfIndex = TreeUtil.getChildIndex(node);
                            parentNode.children.splice(selfIndex, 1);
                            parentData.voics.splice(selfIndex, 1);
                            setTreeDisable(false);
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
    </>);
}

export default VoicFormTab;