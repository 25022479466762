import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { Store } from "../../../../../redux/store/store";
import { KeyboardEvent } from "react";
import PresetSelectorFrame from "../../../../thema/presetSelectorFrame";

namespace PresetSelectorInput {

    export const main = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher) => {

        const config = store.userEnv.keyConfig.note;
        const keySwitch = store.scoreState.keySwitch;
        // alert(`[${e.key}]`);
        // alert(config.nextElement);

        const cache = store.systemState.cache as PresetSelectorFrame.CachePorps;
        const update = () => {
            // cache.updateBacking();
            cache.invalidate();
        }
        const field = cache.field;

        const moveList = (isDown: boolean) => {
            if (!isDown && field.listIndex >= 1) {
                field.listIndex--;
                update();
            } else if (isDown && field.listIndex < cache.presetList.length - 1) {
                field.listIndex++;
                update();
            }
        }

        switch (e.key) {
            case 'Enter': {
                cache.applyAction();
            } break;
            case 'e': {
                dispatcher.score.closeLibraryWnd();
            } break;
            case 'ArrowUp': {
                moveList(false);
            } break;
            case 'ArrowDown': {
                moveList(true);
            } break;
        }
    }
}
export default PresetSelectorInput;