import { useState, useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import Styles from "../../../../base/design/styles";
import DataUtil from "../../../../utils/dataUtil";
import { ThemaTreeContext } from "../backingLibraryTab";
import ThemaUtil from "../themaUtil";
import TreeUtil from "../treeUtil";
import ThemaEditTabUtil from "./themaEditTabUtil";

const MethFormTab = (props: {
}) => {
    const { node, setFocusNode, invalidate, setTreeDisable } = useContext(ThemaTreeContext);
    const data = node.data as ThemaUtil.LevelMethod;

    const [presetName, setPresetName] = useState<string>('');
    const [prenams, setPrenams] = useState<ThemaUtil.Prenam[]>([]);

    const [nextPresetSeq, setNextPresetSeq] = useState(-1);

    const incrementNextPresetSeq = () => { setNextPresetSeq(nextPresetSeq + 1) };

    const isSustMeth = node == node.parent?.children[0];

    useEffect(() => {
        setPrenams(data.prenams.slice());

        setNextPresetSeq(data.prenams.length === 0 ? 0 : (
            // 最大値 + 1を帰す
            data.prenams.map(pre => pre.seq).reduce((prev, cur) => {
                return prev < cur ? cur : prev;
            }, -1) + 1
        ));
    }, [node]);

    return (<>
        <ThemaEditTabUtil.LabelTextItem titleLabel="Method" value={isSustMeth ? 'Sustain' : 'Pattern'} />
        <ThemaEditTabUtil.BorderPanel
            title="preset define"
            innerJsx={<>
                <ThemaEditTabUtil.InputTextForm titleLabel="Preset" value={presetName} setValue={(value: string) => {
                    setPresetName(value);
                }} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: presetName !== '' && !prenams.map(p => p.name).includes(presetName),
                        callback: () => {
                            prenams.push({
                                name: presetName,
                                seq: nextPresetSeq
                            });
                            setPrenams(prenams.slice());
                            setPresetName('');
                            incrementNextPresetSeq();
                        }
                    },
                ]} />
                <ThemaEditTabUtil.ListManager
                    height={140}
                    records={prenams.map((prenam, i) => {
                        return {
                            label: `${prenam.seq}: ${prenam.name}`, buttons: [
                                {
                                    label: '↓', width: 30,
                                    isEnable: i < prenams.length - 1,
                                    callback: () => {
                                        const cache = prenams[i];
                                        prenams[i] = prenams[i + 1];
                                        prenams[i + 1] = cache;
                                        setPrenams(prenams.slice());
                                    },
                                },
                                {
                                    label: '↑', width: 30,
                                    isEnable: i > 0,
                                    callback: () => {
                                        const cache = prenams[i];
                                        prenams[i] = prenams[i - 1];
                                        prenams[i - 1] = cache;
                                        setPrenams(prenams.slice());
                                    },
                                },
                                {
                                    label: 'Delete', width: 60, callback: () => {
                                        prenams.splice(i, 1);
                                        setPrenams(prenams.slice());
                                    },
                                },
                            ]
                        };
                    })} />
            </>} />
        <ThemaEditTabUtil.BorderPanel
            title="selft operatioon"
            innerJsx={<>
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Update',
                        isEnable: true, callback: () => {
                            data.prenams = prenams.slice();
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
        <ThemaEditTabUtil.BorderPanel
            title="children operatioon"
            innerJsx={<>
                <ThemaEditTabUtil.LabelTextItem titleLabel="Child" value={(isSustMeth ? 'Sustain' : 'Pattern') + ' Element'} />
                <ThemaEditTabUtil.ButtonDiv buttons={[
                    {
                        name: 'Add',
                        isEnable: true, callback: () => {
                            // let newData: any = null;
                            // if (isSustMeth) {
                            //     const attrData: ThemaUtil.LevelAttr = {
                            //         attr: 'triad',
                            //         symbols: [],
                            //         roots: []
                            //     };
                            //     (data.defs as ThemaUtil.LevelAttr[]).push(attrData);
                            //     newData = attrData;
                            // } else {
                            //     const sustData: ThemaUtil.LevelSust = {
                            //         beat: 4,
                            //         head: 0,
                            //         tail: 0,
                            //         patts: []
                            //     };
                            //     (data.defs as ThemaUtil.LevelSust[]).push(sustData);
                            //     newData = sustData;
                            // }
                            const newNode = TreeUtil.createNode(null, node);
                            node.children.push(newNode);
                            setFocusNode(newNode);
                            setTreeDisable(true);
                        }
                    },
                    {
                        name: 'Del',
                        isEnable: true, callback: () => {
                            node.children = [];
                            data.defs = [];
                            invalidate();
                        }
                    },
                ]} />
            </>}
        />
    </>);
}

export default MethFormTab;

