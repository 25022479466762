import styled, { css } from "styled-components";
import { DetailFixed } from "../../../../../redux/store/score/scoreData";
import { ELEMENT_HEAD_WIDTH } from "../../../../../utils/systemConst";

const ElementFixedBorder = (props: {
    detail: DetailFixed;
}) => {

    // const jsxList: JSX.Element[] = [];
    // jsxList.push(<_Border key={0} />);
    // jsxList.push(<_Label key={1}>{props.detail.fixed}</_Label>);

    const value = props.detail.fixed;
    return (
        <_Wrap isStart={value === 'Start'}>
            {/* {props.detail.fixed === 'Start' ? jsxList : jsxList.reverse()} */}
            <_Label>{value}</_Label>
        </_Wrap>
    );
}

export default ElementFixedBorder;

const _Wrap = styled.div<{
    isStart: boolean;
}>`
    display: inline-block;
    position: relative;
    width: 80px;
    height: calc(100% - ${ELEMENT_HEAD_WIDTH}px);
    border-left: 1px solid #00000083;
    border-top: 1px solid #00000083;
    border-right: 1px solid #00000083;
    /* border: 1px solid #00000083; */
    box-sizing: border-box;
    background-color: #9b9b9b1f;
    ${props => props.isStart ? css`
        border-radius: 0 35px 0 0;
    ` : css`
        border-radius: 35px 0 0 0;
    `}
`;

// const _Border = styled.div`
//     display: inline-block;
//     width: 10px;
//     height: 100%;
//     background-color: #b4b4b4;
//     vertical-align: middle;
// `;


const _Label = styled.div`
    display: inline-block;
    /* width: calc(100% - 10px); */
    width: 100%;
    height: 48px;
    /* background-color: #47438678; */
    font-size: 26px;
    font-weight: 600;
    font-style: italic;
    line-height: 46px;
    color: #960000b0;
    text-align: center;
    margin-top: 30px;
    /* vertical-align: middle; */
`;
