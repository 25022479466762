import { KeyboardEvent } from "react";
import { Dispatcher } from "../../../../../redux/dispatcher/dispatcher";
import { MelodyPitchMode } from "../../../../../redux/store/score/melodyState";
import { Store } from "../../../../../redux/store/store";
import MelodyUtil, { } from "../../../../../utils/melodyUtil";

class MelodyInput {

    static keyDownEvent = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher) => {

        const config = store.userEnv.keyConfig.note;
        const keySwitch = store.melodyState.keySwitch;
        // alert(e.key);
        // alert(config.nextElement);

        const state = store.melodyState;

        // 選択しているノーツが1つである場合
        const isSingle = state.focusIndex === state.destIndex;
        const mouse = state.tableMouse;

        // プレビュー再生時
        if (state.isPreview) {
            switch (e.key) {
                case ' ': {
                    MelodyUtil.stopPreview(store, dispatcher, store.reserveTasks);
                }
                    break;
            }
            return;
        }

        // ボタンを押下するとマウス情報を消す
        if (mouse.notesState.index !== -1) {
            mouse.notesState.index = -1;
            mouse.notesState.isResize = false;
            mouse.notesState.side = 'normal';
        }
        mouse.mouseMeasurePos = -1;
        mouse.mousePitchIndex = -1;

        if (keySwitch.scaleMode || keySwitch.octave || keySwitch.resize || keySwitch.move || keySwitch.shift || keySwitch.control) {
            if (keySwitch.control) {
                // alert(e.key);
                switch (e.key) {
                    case 'c': dispatcher.melody.copyNotes(); break;
                    case 'v': dispatcher.melody.pasteNotes(); break;
                    case 'a': {
                        dispatcher.melody.allSelect();
                    }
                        break;
                    case 's': dispatcher.score.saveFile(); break;
                    case 'z': dispatcher.melody.undo(); break;
                    case 'y': dispatcher.melody.redo(); break;
                }
            } else if (keySwitch.scaleMode || keySwitch.octave) {
                const mode: MelodyPitchMode = keySwitch.scaleMode ? 'scale' : 'octave';
                switch (e.key) {
                    case 'ArrowUp': {
                        dispatcher.melody.incrementCurPitch(true, mode);
                    }
                        break;
                    case 'ArrowDown': {
                        dispatcher.melody.incrementCurPitch(false, mode);
                    }
                        break;
                }
            } else if (keySwitch.resize) {
                switch (e.key) {
                    case 'ArrowLeft': dispatcher.melody.resizeNotes(false); break;
                    case 'ArrowRight': dispatcher.melody.resizeNotes(true); break;
                }
            } else if (keySwitch.move) {
                switch (e.key) {
                    case 'ArrowLeft': dispatcher.melody.moveNotes(false); break;
                    case 'ArrowRight': dispatcher.melody.moveNotes(true); break;
                }
            } else if (keySwitch.shift) {
                switch (e.key) {
                    case 'ArrowLeft': dispatcher.melody.moveDestFocus(false); break;
                    case 'ArrowRight': dispatcher.melody.moveDestFocus(true); break;
                }
            }
        } else {
            switch (e.key) {
                case 'ArrowUp': dispatcher.melody.incrementCurPitch(true, 'normal'); break;
                case 'ArrowDown': dispatcher.melody.incrementCurPitch(false, 'normal'); break;
                case 'ArrowLeft': {
                    if (isSingle) {
                        // フォーカス移動
                        dispatcher.melody.moveFocus(false);
                    } else {
                        // 複数選択を解除
                        dispatcher.melody.releaseRange(false);
                    }
                }
                    break;
                case 'ArrowRight': {
                    if (isSingle) {
                        // フォーカス移動
                        dispatcher.melody.moveFocus(true);
                    } else {
                        // 複数選択を解除
                        dispatcher.melody.releaseRange(true);
                    }
                }
                    break;
                case 'a': dispatcher.melody.addNotes(); break;
                case 'q': {
                    alert(JSON.stringify(store.scoreData.notesList[state.focusIndex].len));
                }
                    break;
                case 'w': dispatcher.melody.adjustScrollX(); break;
                case 'e': dispatcher.melody.adjustScrollY(); break;
                case 'z': {
                    MelodyUtil.previewCurHarmony(store, dispatcher);
                }
                    break;
                case 'p': {
                    state.isSolo = !state.isSolo;
                    dispatcher.melody.setState(state);
                }
                    break;
                case 'Home': {
                    dispatcher.melody.firstNotes();
                }
                    break;
                case '0': {
                    state.quantize = 8;
                    dispatcher.melody.setState(state);
                }
                    break;
                case '1': {
                    state.quantize = 4;
                    dispatcher.melody.setState(state);
                }
                    break;
                case '2': {
                    state.quantize = 2;
                    dispatcher.melody.setState(state);
                }
                    break;
                case '3': {
                    state.quantize = 1;
                    dispatcher.melody.setState(state);
                }
                    break;
                case '/': {
                    if ([3, 6].includes(state.quantize)) {
                        state.quantize /= 3;
                    } else if([2, 1].includes(state.quantize)) {
                        state.quantize *= 3;
                    }
                    dispatcher.melody.setState(state);
                }
                    break;
                case 'Delete': {
                    dispatcher.melody.removeNotes();
                }
                    break;
                case ' ': {
                    dispatcher.melody.previewStart();
                }
                    break;
            }
        }

    }

    static keySwitchEvent = (e: KeyboardEvent, store: Store, dispatcher: Dispatcher, isPress: boolean) => {

        const config = store.userEnv.keyConfig.note;
        const keySwitch = store.melodyState.keySwitch;
        const state = store.melodyState;
        // alert(e.key);
        // alnfig.nextElement);

        if (!state.isPreview) {

            if (e.key === 'Control') {
                keySwitch.control = isPress;
                dispatcher.melody.setKeySwitch(keySwitch);
            }
            if (!keySwitch.control) {

                const isSingle = state.focusIndex === state.destIndex;
                switch (e.key) {
                    case 'x': {
                        keySwitch.octave = isPress;
                        dispatcher.melody.setKeySwitch(keySwitch);
                    }
                        break;
                    case 'c': {
                        if (isSingle) {
                            keySwitch.scaleMode = isPress;
                            dispatcher.melody.setKeySwitch(keySwitch);
                        }
                    }
                        break;
                    case 'f': {
                        if (isSingle) {
                            keySwitch.resize = isPress;
                            dispatcher.melody.setKeySwitch(keySwitch);
                        }
                    }
                        break;
                    case 'd': {
                        keySwitch.move = isPress;
                        dispatcher.melody.setKeySwitch(keySwitch);
                    }
                        break;
                    case 'Shift': {
                        keySwitch.shift = isPress;
                        dispatcher.melody.setKeySwitch(keySwitch);
                    }
                        break;
                }
            }
        }

        if (isPress) {
            MelodyInput.keyDownEvent(e, store, dispatcher);
        }
    }
}

export default MelodyInput;