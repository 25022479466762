import ThemaUtil from "../contents/custom/backing/themaUtil";
import BackingState from "../redux/store/score/backing/backingState";
import { BackingPatternProps, ChordBacking, DetailChord, VoicingProps } from "../redux/store/score/scoreData";
import { Store } from "../redux/store/store";
import TheoryUtil from "./theoryUtil";

// import Sample from "../assets/sample";


namespace LibraryUtil {

    export type EnableState = {
        /** コード要素選択中 */
        isChordElement: boolean;
        /** ライブラリ利用有無 */
        isUseLib: boolean;
        /** 範囲選択有無 */
        isRangeSelect: boolean;
    }

    export type Condition = {
        beatLen: number;
        head: number;
        tail: number;
        rootIndex: number;
        symbolKey: TheoryUtil.SymbolKey;
    }

    export type PattItemProps = {
        key: number;
        dispName: string;
        refer: number;
        source: BackingPatternProps | null;
    };

    export type VoicItemProps = {
        pattIdx: number;
        dispName: string;
        // soundFullNameList: string[];
        source: VoicingProps[];
    };

    export type PresetDef = {

    }

    export type Apply = {
        condition: Condition;
        backing: null | ChordBacking;
    };
    export type PresetItemProps = {
        method: ThemaUtil.Method;
        dispName: string;
        applyList: Apply[];
    };

    export const getEnableState = (store: Store): EnableState => {
        const chordList = store.scoreData.chordList;
        const state = store.scoreState;
        const cur = chordList[state.focusIndex];

        const isBackingEnable = cur.type === 'chord' && (cur.detail as DetailChord).root != null;
        const isUseLib = store.systemState.activeThemaIndex !== -1;
        const isRangeSelect = state.focusIndex !== state.distIndex;

        return {
            isChordElement: isBackingEnable, isUseLib, isRangeSelect
        };
    }

    const attrsSearch = (
        patts: ThemaUtil.LevelPatt[] | null,
        patt: ThemaUtil.LevelPatt,
        symbol: TheoryUtil.SymbolParams,
        rootIndex: number,
        preset: null | number,
        voicCallback: (voic: ThemaUtil.LevelVoic) => void,
        pattVoicCallback: (patt: ThemaUtil.LevelPatt, voic: ThemaUtil.LevelVoic) => void
    ) => {
        const getAttrs = () => {
            if (preset == null || patts == null || patt.refer === -1) return patt.attrs;
            else {
                const master = patts.find(fPatt => patt.refer === fPatt.key);
                return master == undefined ? [] : master.attrs;
            }
        }
        getAttrs().forEach((attr) => {
            const isMatchAttr = symbol.attr === attr.attr && attr.symbols.includes(symbol.key);
            if (!isMatchAttr) return 1;
            attr.roots.forEach(root => {
                // console.log(`rootIndex: ${rootIndex}, minIdx:${root.minIdx}～maxIdx:${root.maxIdx}`);
                const isMatchRoot = rootIndex >= root.minIdx && rootIndex <= root.maxIdx;
                if (!isMatchRoot) return 1;
                // console.log(`passed`);
                root.voics.forEach(voic => {
                    if (preset != null && !voic.preUsies.map(preUse => {
                        return `${preUse.depPatt === -1 ? patt.key : preUse.depPatt},${preUse.presKey}`;
                    }).includes(`${patt.key},${preset}`)) return 1;
                    voicCallback(voic);
                    pattVoicCallback(patt, voic);
                });
            });
        });
    }

    export const searchSustMeth = (
        sustMet: ThemaUtil.LevelMethod,
        condition: Condition,
        preset: null | number,
        callback: (voic: ThemaUtil.LevelVoic) => void
    ) => {
        // サスティンメソッドでない場合処理しない
        if (sustMet.method !== 'sustain') return;

        const symbol = TheoryUtil.getSymbolFromKey(condition.symbolKey) as TheoryUtil.SymbolParams;
        const rootIndex = condition.rootIndex;

        const attrs = sustMet.defs as ThemaUtil.LevelAttr[];
        // attrs.forEach((attr) => {
        //     const isMatchAttr = symbol.attr === attr.attr && attr.symbols.includes(symbol.key);
        //     if (!isMatchAttr) return 1;
        //     attr.roots.forEach(root => {
        //         const isMatchRoot = rootIndex >= root.minIdx && rootIndex <= root.maxIdx;
        //         if (!isMatchRoot) return 1;
        //         root.voics.forEach(voic => {
        //             callback(voic);
        //         });
        //     });
        // });
        const dummyPatt: ThemaUtil.LevelPatt = {
            attrs, key: -1, refer: -1, pattName: '', source: { channelSize: 0, layers: [], pedals: [] }
        }
        attrsSearch(null, dummyPatt, symbol, rootIndex, preset, callback, () => { });
    };

    export const searchPattMeth = (
        pattMet: ThemaUtil.LevelMethod,
        condition: Condition,
        preset: null | number,
        pattCallback: (voic: ThemaUtil.LevelPatt) => void,
        voicCallback: (voic: ThemaUtil.LevelVoic) => void,
        pattVoicCallback: (patt: ThemaUtil.LevelPatt, voic: ThemaUtil.LevelVoic) => void
    ) => {
        // パターンメソッドでない場合処理しない
        if (pattMet.method !== 'pattern') return;

        const symbol = TheoryUtil.getSymbolFromKey(condition.symbolKey) as TheoryUtil.SymbolParams;
        const rootIndex = condition.rootIndex;

        const susts = pattMet.defs as ThemaUtil.LevelSust[];
        susts.forEach(sust => {
            const isMatchSust = condition.beatLen === sust.beat && condition.head === sust.head && condition.tail === sust.tail;
            if (!isMatchSust) return 1;
            sust.patts.forEach(patt => {
                // patt.attrs.forEach((attr) => {
                //     const isMatchAttr = symbol.attr === attr.attr && attr.symbols.includes(symbol.key);
                //     if (!isMatchAttr) return 1;
                //     attr.roots.forEach(root => {
                //         const isMatchRoot = rootIndex >= root.minIdx && rootIndex <= root.maxIdx;
                //         if (!isMatchRoot) return 1;
                //         root.voics.forEach(voic => {
                //             voicCallback(voic);
                //         });
                //     });
                // });
                attrsSearch(sust.patts, patt, symbol, rootIndex, preset, voicCallback, pattVoicCallback);
                pattCallback(patt);
            });
        });
    };

    export const getDistinctionFromRelate = (struct: BackingState.StructInfo) => {
        switch (struct.relation) {
            case 'p1': return 1;
            case 'M2':
            case 'm2':
                return 9;
            case 'm3':
            case 'M3':
                return 3;
            case 'p4':
            case 'p5':
            case 'a5':
            case 'd5':
                return 5;
            case 'm7':
            case 'M7':
                return 7;
        }
        return -1;
    };

    /**
     * ボイシングを許容するかどうか判定する
     * @param voic ボイシング情報
     * @param patternList パターン情報リスト
     * @param pattIndex 選択中のパターン連番
     * @returns 許容するかどうか
     */
    export const acceptVoic = (voic: VoicItemProps, patternList: PattItemProps[], pattIndex: number) => {
        const curPatt = patternList[pattIndex];
        const depend = curPatt.refer;
        let masterIdx = -1;
        patternList.some((patt, i) => {
            if (depend !== -1 && patt.key === depend) {
                masterIdx = i;
                return;
            }
        });
        const isBlank = voic.pattIdx === -1;
        const isMatch = voic.pattIdx === pattIndex;
        const isDepend = masterIdx === voic.pattIdx;
        return isBlank || isMatch || isDepend;
    }

    export const getPresetList = (thema: ThemaUtil.LevelBase, conditionList: Condition[]): PresetItemProps[] => {
        const list: PresetItemProps[] = [];
        thema.meths.forEach(meth => {
            meth.prenams.forEach(preset => {
                const applyList = conditionList.map(con => {
                    const backingList: ChordBacking[] = [];
                    switch (meth.method) {
                        case 'sustain': {
                            searchSustMeth(meth, con, preset.seq, (voic: ThemaUtil.LevelVoic) => {
                                backingList.push({
                                    pattern: null,
                                    voicingList: voic.source
                                });
                            });
                        } break;
                        case 'pattern': {
                            searchPattMeth(meth, con, preset.seq, () => { }, () => { },
                                (patt: ThemaUtil.LevelPatt, voic: ThemaUtil.LevelVoic) => {
                                    backingList.push({
                                        pattern: patt.source,
                                        voicingList: voic.source
                                    });
                                });
                        } break;
                    }
                    const backing = backingList.length === 0 ? null : backingList[0];
                    const apply: Apply = {
                        condition: con,
                        backing
                    };
                    return apply;
                });
                list.push({
                    method: meth.method,
                    dispName: preset.name,
                    applyList
                });
            });
        });
        return list;
    }

    export const matchCondition = (a: Condition, b: Condition) => {
        return a.beatLen === b.beatLen && a.head === b.head && a.tail === b.tail &&
            a.rootIndex === b.rootIndex && a.symbolKey === b.symbolKey;
    }

    export const getDefaultLibs = (): ThemaUtil.LevelBase[] => {
        const list: ThemaUtil.LevelBase[] = [];
        return list;
    }
}

export default LibraryUtil;