import { BackingPatternProps, BeatSignature, ChordElement, DetailChord, DetailFixed, DetailInit, DetailSection, FixedItem, ScoreData, VoicingProps } from "../../../../redux/store/score/scoreData";
import DataUtil from "../../../../utils/dataUtil";
import TheoryUtil from "../../../../utils/theoryUtil";
import progBackingUtil from "./progBackingUtil";

/**
 * コード進行生成のユーティリティ
 */
namespace ProgUtil {

    /** 生成方法 */
    export type BuildType = 'blank' | 'custom' | 'template';

    export const STEP_LIST = [0, 2, 3, 4] as const;
    export type Step = typeof STEP_LIST[number];

    /**
     * ブロックに設定するプロパティ
     */
    export type UnitProps = {
        type: BlockType;
        detail: SectionProps | FixedItem;
    }

    export type BlockType = 'fixed' | 'section';

    export type SectionProps = {
        sectionName: string;
        sectionNo: string;
        chordList: DetailChord[];
        backingNo: number;
    }

    export const DEGREE_FILTER_LIST = [
        '', 'Ⅰ', 'Ⅴ', 'Ⅳ', 'Ⅵm', 'Ⅱm', 'Ⅲm'
    ] as const;
    export type DegreeFilter = typeof DEGREE_FILTER_LIST[number];

    /**
     * 初期状態のブロックリストを返す
     * @returns 初期状態のブロックリスト
     */
    export const getInitialBlockList = (): UnitProps[] => {
        return [
            { type: 'fixed', detail: 'Start' },
            { type: 'fixed', detail: 'End' },
        ];
    }

    /**
     * Blank選択時のブロックリストを返す
     * @returns 初期状態のブロックリスト
     */
    export const getBlankBlockList = (): UnitProps[] => {
        return [
            { type: 'fixed', detail: 'Start' },
            {
                type: 'section',
                detail: {
                    sectionName: 'Section',
                    sectionNo: '',
                    chordList: [
                        getDefaultChord()
                    ],
                    backingNo: 0
                }
            },
            { type: 'fixed', detail: 'End' },
        ];
    }

    export const getDefaultChord = (): DetailChord => {
        return {
            beatLen: 4, minute: { head: 0, tail: 0 }, root: null, on: null, symbolKey: 'major',
            backing: {
                voicingList: [],
                pattern: null,
                // hasError: false
            }
        }
    }

    /**
     * 拍子より選択可能なステップリストを絞り込む
     * @param beat ステップの拍
     * @returns 絞り込んだリスト
     */
    export const getFilteredStepList = (beat: BeatSignature) => {
        return STEP_LIST.filter((item) => {
            // 0は空白表示なので必ず加える
            if (item === 0) return true;

            switch (beat) {
                case '4/4':
                case '6/8': {
                    if (item === 3) return false;
                }
                    break;
                case '3/4':
                case '9/8': {
                    if (item !== 3) return false;
                }
                    break;
                case '2/4':
                case '6/8': {
                    if (item !== 2) return false;
                }
                    break;
            }
            return true;
        });
    }

    /**
     * ブロックリストより譜面データを生成する
     * @param outputUnits ユニットリスト
     * @returns 譜面データ
     */
    export const buildScoreData = (outputUnits: UnitProps[]): ScoreData => {
        const elementList: ChordElement[] = [];
        // イニシャル要素を設定
        const initElement: DetailInit = {
            keyIndex: 0,
            bpm: 100,
            scale: "major",
            beatSignature: "4/4"
        }
        elementList.push({ type: 'init', detail: initElement });
        outputUnits.forEach((block) => {
            if (block.type === 'fixed') {
                const fixed = block.detail as FixedItem;
                // 固定ブロックの処理
                elementList.push({ type: 'fixed', detail: { fixed } });
            } else {
                const blockDetail = block.detail as SectionProps;
                const getSectionFullName = () => {
                    return blockDetail.sectionName;
                }
                // セクションブロックの処理
                elementList.push({ type: 'section', detail: {labelName: getSectionFullName()} });

                // サンプルバッキングの適用
                const sample = progBackingUtil.BACKING_SAMPLE_LIST[blockDetail.backingNo];
                blockDetail.chordList.forEach((chord) => {
                    const detail = chord;

                    let voicingList: VoicingProps[] = [];
                    let pattern: BackingPatternProps | null = null;
                    sample.patternConditions.some(conPa => {
                        if(conPa.beatLen === detail.beatLen) {
                            // console.log(DataUtil.unZip(conPa.source));
                            pattern = JSON.parse(DataUtil.unZip(conPa.source));

                            conPa.voicingConditions.some(conVo => {
                                const symbol = TheoryUtil.getSymbolFromKey(detail.symbolKey) as TheoryUtil.SymbolParams;
                                if(conVo.attr === symbol.attr) {
                                    voicingList = JSON.parse(DataUtil.unZip(conVo.source));
                                    return true;
                                }
                            });
                            return true;
                        }
                    });
                    detail.backing.voicingList = voicingList;
                    detail.backing.pattern = pattern;

                    elementList.push({ type: 'chord', detail });
                });
            }
        });
        return { chordList: elementList, notesList: [] };
    }
}

export default ProgUtil;